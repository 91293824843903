import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import AppContext from "../../../Context/AppContext";
import NotesContext from "../../../Context/NotesContext";
import DeleteBddProvider from "../../../Providers/DeleteBddProvider";
import UpdateBddProvider from "../../../Providers/UpdateBddProvider";
import MsgAlert from "../../common/MsgAlert";
import Spinner from "../../common/Spinner";
import dateFormated from "../../tools/dateFormated";
import { autoCloseMsg } from "../../tools/messagesUtils";

const ModifNote = ({ n, el, mat }) => {
  const { url, user } = useContext(AppContext);
  const { setMaj, setGlobalMsg, autoCloseGlobal } = useContext(NotesContext);
  const [note, setNote] = useState(null);
  const [msg, setMsg] = useState(null);
  const [loaded, setLoaded] = useState(true);

  useEffect(() => {
    if (n !== null) {
      let noteString =
        n.note !== null && n.note !== undefined ? n.note.toString() : null;
      setNote({ ...n, note: noteString, formateur: n.formateur["@id"] });
    }
  }, [n]);

  const valide = () => {
    return note !== null && note.coef >= 0 && note.comment !== "";
  };

  const handleSubmit = () => {
    setLoaded(false);
    let uri = url + "api/notes/" + note.id;
    let data = JSON.parse(JSON.stringify(note));
    if (note.note !== undefined && note.note !== "") {
      data = { ...data, note: parseFloat(note.note) };
    } else {
      data = { ...data, note: null };
    }
    UpdateBddProvider(uri, data).then((res) => {
      setLoaded(true);
      if (typeof res === "object" && res.id) {
        setMaj(true);
        setMsg({ txt: "Modification enregistrée", type: "success" });
        autoCloseMsg(setMsg, 5000);
      } else {
        setMsg({ txt: "Erreur d'enregistrement", type: "danger" });
        autoCloseMsg(setMsg, 5000);
      }
    });
  };

  const delNote = () => {
    setLoaded(false);
    let uri = url + "api/notes/" + note.id;
    DeleteBddProvider(uri).then((res) => {
      setLoaded(true);
      if (res === 204) {
        setGlobalMsg({ txt: "La note a bien été supprimée", type: "success" });
        autoCloseGlobal(3000);
        setMaj(true);
      } else {
        setGlobalMsg({ txt: "Erreur de suppression", type: "danger" });
        autoCloseGlobal(3000);
      }
    });
  };

  return (
    <div
      className="modal fade"
      id="ModalModifNote"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header bg-info text-white">
            <h5 className="modal-title">
              <FontAwesomeIcon icon="edit" /> &nbsp; Modifier une note
            </h5>
            <button
              type="button"
              className="close text-white"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          {el === null || note === null || !loaded ? (
            <div className="modal-body">
              <Spinner />
            </div>
          ) : (
            <div className="modal-body">
              {msg !== null ? (
                <MsgAlert
                  msg={msg.txt}
                  type={msg.type}
                  close={() => autoCloseMsg(setMsg)}
                />
              ) : null}
              <div>
                <strong>
                  <u>
                    {el.user.name} {el.user.firstName}
                  </u>
                </strong>
              </div>
              <div>
                <em>
                  {dateFormated(n.createdAt)} - {n.matiere.name}
                </em>
              </div>
              <hr />
              <div className="form-row">
                <div className="from-group col-md-6">
                  <label>note</label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="NE"
                    min="0"
                    max="20"
                    step="0.01"
                    value={note.note || ""}
                    onChange={(e) => setNote({ ...note, note: e.target.value })}
                  />
                </div>
                <div className="from-group col-md-6">
                  <label>Coef</label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="coef"
                    min="0"
                    step="0.5"
                    value={note.coef || ""}
                    onChange={(e) =>
                      setNote({ ...note, coef: parseFloat(e.target.value) })
                    }
                  />
                </div>
              </div>
              {mat !== null && user.role <= 3 ? (
                <div className="form-group">
                  <label className="mt-4">Matière</label>
                  <select
                    className="form-control"
                    value={note.matiere || ""}
                    onChange={(e) => {
                      setNote({ ...note, matiere: e.target.value });
                    }}
                  >
                    <option value="" disabled hidden>
                      Sélectionnez une matière
                    </option>
                    {mat.map((m, i) => (
                      <option value={m["@id"]} key={i}>
                        {m.name}
                      </option>
                    ))}
                  </select>
                </div>
              ) : null}
              <div className="form-group">
                <label className="mt-2"> Commentaire</label>
                <input
                  type="text"
                  className="form-control"
                  maxLength="250"
                  value={note.comment || ""}
                  onChange={(e) =>
                    setNote({ ...note, comment: e.target.value })
                  }
                />
              </div>

              <div className="form-group">
                <div className="custom-control custom-switch">
                  <input
                    className="custom-control-input"
                    type="checkbox"
                    id="examCheckbox"
                    checked={note.exam || false}
                    onChange={(e) => setNote({ ...note, exam: !note.exam })}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="examCheckbox"
                  >
                    Examen Blanc
                  </label>
                </div>
              </div>
            </div>
          )}

          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
            >
              Fermer
            </button>
            <button
              type="button"
              className="btn btn-info"
              data-dismiss="modal"
              onClick={handleSubmit}
              disabled={!valide()}
            >
              Mettre à jour
            </button>
            <button
              type="button"
              className="btn btn-danger"
              onClick={() => {
                if (window.confirm("Supprimer la note ?")) {
                  delNote();
                }
              }}
              data-dismiss="modal"
            >
              Supprimer
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModifNote;
