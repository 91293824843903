import { Buffer } from "buffer";
import { StorageService } from "../Storage/StorageService";

const checkValidation = (token) => {
  return (
    JSON.parse(Buffer.from(token.split(".")[1], `base64`)).exp >
    Date.now() / 1000
  );
};

export default function checkToken(reload = true) {
  let token = StorageService.getItem("token");
  if (!token || !checkValidation(token)) {
    StorageService.clear();
    reload && window.location.reload();
  }
}
