import React, { useContext, useEffect, useState } from "react";
import GetBddProvider from "../../../Providers/GetBddProvider";
import AppContext from "../../../Context/AppContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MsgAlert from "../../common/MsgAlert";
import RtdPromoEdusign from "./RtdPromoEdusign";
import Spinner from "../../common/Spinner";
import { autoCloseMsg } from "../../tools/messagesUtils";
import { triByNAME } from "../../tools/sortUtils";

function SyncRetard({ start, end, site, load, setLoad }) {
  const [courses, setCourses] = useState(null);
  const [seances, setSeances] = useState(null);
  const [lockedCourses, setLockedCourses] = useState([]);
  const [unlockedCourses, setUnlockedCourses] = useState([]);
  const [connector, setConnector] = useState(null);
  const [msg, setMsg] = useState(null);

  const { urlEdusign, galiaUser } = useContext(AppContext);

  const token = site.TokenEdusign;

  useEffect(() => {
    getConnector();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (load) {
      getCourses();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [load]);

  const getConnector = async () => {
    setConnector(
      await GetBddProvider(urlEdusign.SCHOOL, token).then((res) => {
        if (typeof res === "object" && res.status === "success") {
          return res.result.CONNECTOR;
        }
        return "";
      })
    );
  };

  const getCourses = async () => {
    let uri = urlEdusign.COURSES + "?start=" + start + "&end=" + end;
    let tmpCourses = [];
    let tmpLockedCourses = [];
    let tmpUnlockedCourses = [];
    try {
      await GetBddProvider(uri, token).then((res) => {
        if (typeof res === "object" && res.status === "success") {
          tmpCourses = res.result;
          tmpUnlockedCourses = res.result.filter((r) => !r.LOCKED);
          tmpLockedCourses = res.result.sort(triByNAME).filter((r) => r.LOCKED);
        } else {
        }
      });
      setCourses(tmpCourses);
      setLockedCourses(tmpLockedCourses);
      setUnlockedCourses(tmpUnlockedCourses);
    } catch (error) {
      setMsg({ txt: "erreur de chargment Edusign !", type: "warning" });
    }
    if (site.idGaliaSociete) {
      let uriGalia = `https://groupe-alternance.sc-form.net/GaliaEDC/API/PresenceSta/GetSeance?IDSociete=${site.idGaliaSociete}&Debut=${start}&Fin=${end}`;
      try {
        await GetBddProvider(uriGalia, undefined, galiaUser).then((res) => {
          if (typeof res === "object") {
            setSeances(res.filter((r) => r.IDLieu === site.idGaliaLieu));
          }
        });
      } catch (error) {
        setMsg({ txt: "erreur de chargment Galia !", type: "warning" });
      }
    }

    setLoad(false);
  };

  if (load) {
    return (
      <div>
        <Spinner className="m-0" />
        <hr className="m-4" />
      </div>
    );
  }

  return courses === null ? null : (
    <div className="card border-info bg-ligth mx-4">
      <div className="card-header d-flex justify-content-between p-2">
        <h5 className="text-left m-0">
          Edusign : {lockedCourses.length} cours cloturés /{" "}
          {unlockedCourses.length} cours non cloturés
        </h5>
        <button
          className="btn btn-sm rounded-pill btn-outline-danger"
          onClick={() => {
            setCourses(null);
          }}
        >
          <FontAwesomeIcon icon="times" /> Fermer
        </button>
      </div>
      <div className="card-body">
        {msg !== null ? (
          <MsgAlert msg={msg.txt} type={msg.type} close={() => autoCloseMsg(setMsg)} />
        ) : null}
        {courses.length === 0 ? (
          <MsgAlert msg="Pas de cours dans Edusign !" type="warning" />
        ) : lockedCourses.length === 0 ? (
          <MsgAlert
            msg="Les cours ne sont pas cloturés dans Edusign !"
            type="info"
          />
        ) : (
          lockedCourses
            .filter((c) =>
              c.STUDENTS.some((s) => s.delay !== 0 || s.earlyDeparture !== null)
            )
            .map((c) => {
              let seance = undefined;
              if (c.API_ID) {
                let tmp = seances.filter((s) => s.ID === c.API_ID);
                if (tmp.length > 0) {
                  seance = tmp[0];
                }
              }

              return (
                <RtdPromoEdusign
                  cours={c}
                  token={token}
                  seance={seance}
                  connector={connector}
                  key={c.ID}
                />
              );
            })
        )}
      </div>
    </div>
  );
}

export default SyncRetard;
