import React from "react";

export default React.createContext({
  setSelected: () => {},
  del: () => {},
  setMsg: () => {},
  matieres: "",
  setSelectedItem: () => {},
  setPromoSelected: () => {},
  setMatiereSelected: () => {},
});
