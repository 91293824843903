import React, { useEffect } from "react";
import Layout from "../common/Layout";
import { useState } from "react";
import MsgAlert from "../common/MsgAlert";
import { useContext } from "react";
import AppContext from "../../Context/AppContext";
import GetBddProvider from "../../Providers/GetBddProvider";
import Spinner from "../common/Spinner";
import PostBddProvider from "../../Providers/PostBddProvider";
import UpdateBddProvider from "../../Providers/UpdateBddProvider";
import { autoCloseMsg } from "../tools/messagesUtils";
import { triByName } from "../tools/sortUtils";

const Semestres = () => {
  const { url, user } = useContext(AppContext);
  const [promos, setpromos] = useState(null);
  const [sites, setSites] = useState(null);
  const [siteSelected, setSiteSelected] = useState(null);
  const [listSites, setListSites] = useState(null);
  const [actif, setActif] = useState(true);
  const [msg, setMsg] = useState(null);
  const [loaded, setloaded] = useState(false);
  const [maj, setmaj] = useState(false);

  useEffect(() => {
    if (user.role === 1) {
      initSiteSelected();
    } else {
      load();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (maj) {
      setmaj(false);
      setloaded(false);
      load();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maj]);

  useEffect(() => {
    if (siteSelected !== null) {
      setloaded(false);
      let uri = url + "api/promos?site=" + siteSelected;
      if (actif) {
        uri += "&actif=true";
      }
      GetBddProvider(url + siteSelected.slice(1)).then((res) => {
        if (typeof res === "object") {
          setSites([res]);
          loadPromos(uri);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteSelected, actif]);

  const initSiteSelected = () => {
    setSiteSelected(user.sites.sort(triByName)[0]["@id"]);
    load();
  };

  const loadPromos = (uri) => {
    GetBddProvider(uri).then((res) => {
      if (typeof res === "object") {
        setpromos(res["hydra:member"]);
        setloaded(true);
      } else {
        setloaded(true);
        setMsg({ txt: "Erreur de chargement", type: "danger" });
      }
    });
  };

  const load = () => {
    let uri = url + "api/promos";
    let s = siteSelected !== null ? siteSelected : user.sites[0]["@id"];
    if (user.role > 1) {
      uri += "?";
      setSites(user.sites.filter((e) => e["@id"] === s));
      setListSites(user.sites.sort(triByName));
      user.sites.forEach((site, i, sites) => {
        uri += sites.length > 1 ? "site[]=" : "site=";
        uri += site["@id"];
        uri += i + 1 < sites.length ? "&" : "";
      });
    } else {
      uri += "?site=" + s;
      GetBddProvider(url + "api/sites").then((res) => {
        if (typeof res === "object") {
          setSites(res["hydra:member"].filter((e) => e["@id"] === s));
          setListSites(res["hydra:member"].sort(triByName));
        } else {
          setMsg({ txt: "Erreur de chargement des sites", type: "danger" });
        }
      });
    }
    if (actif) {
      uri += "&actif=true";
    }
    loadPromos(uri);
  };

  const initialise = async (promo) => {
    let uri = url + "api/semestres";
    let save = null;
    setloaded(false);
    for (let i = 1; i <= promo.formation.nbreSemestres; i++) {
      let semestre = {
        name: "semestre " + i,
        promo: promo["@id"],
        actif: i === 1 ? true : false,
      };
      save = await PostBddProvider(uri, semestre).then((res) => {
        if (typeof res === "object" && res.id) {
          return i;
        }
      });
    }
    setmaj(true);
    save === promo.formation.nbreSemestres
      ? setMsg({
          txt: promo.name + " : les semestres ont bien été créés",
          type: "success",
        })
      : setMsg({
          txt: "erreur d'enregistrement",
          type: "danger",
        });
    autoCloseMsg(setMsg, 5000);
  };

  const updateSem = (data, ent) => {
    let uri = url + ent.slice(1);
    setloaded(false);
    UpdateBddProvider(uri, data).then((res) => {
      if (typeof res === "object" && res.id) {
        setmaj(true);
        setMsg({ txt: "Le semestre a été mis à jour", type: "success" });
      } else {
        setMsg({ txt: "Erreur de mise à jour", type: "warning" });
      }
    });
  };

  const ListSemestres = ({ semestres }) => {
    return (
      <ul className="list-group list-group-flush">
        {semestres.map((el, i) => {
          return (
            <li
              className="list-group-item d-flex justify-content-between"
              key={i}
            >
              {el.name}
              {el.validate ? (
                <span
                  className="badge badge-pill badge-success m-1"
                  style={{ cursor: "pointer" }}
                  onClick={() => updateSem({ validate: false }, el["@id"])}
                >
                  Validé
                </span>
              ) : (
                <span
                  className="badge badge-pill badge-secondary m-1"
                  style={{ cursor: "pointer" }}
                  onClick={() => updateSem({ validate: true }, el["@id"])}
                >
                  Validé
                </span>
              )}
              {el.actif ? (
                <span
                  className="badge badge-pill badge-success m-1"
                  style={{ cursor: "pointer" }}
                  onClick={() => updateSem({ actif: false }, el["@id"])}
                >
                  Actif
                </span>
              ) : (
                <span
                  className="badge badge-pill badge-secondary m-1"
                  style={{ cursor: "pointer" }}
                  onClick={() => updateSem({ actif: true }, el["@id"])}
                >
                  Actif
                </span>
              )}
            </li>
          );
        })}
      </ul>
    );
  };

  const List = () => {
    return sites.map((site, i) => {
      let temp = promos.filter((promo) => promo.site.id === site.id);
      return (
        <div key={i}>
          <h2 className="h2">{site.name}</h2>
          <div className="d-flex flex-wrap justify-content-center">
            {temp.map((el, i) => {
              return (
                <div
                  className="card shadow border-info col-md-3 p-0 m-3"
                  style={{ maxWidth: "20rem" }}
                  key={i}
                >
                  <div className="card-header d-flex justify-content-between">
                    <h5 className="h5">{el.name}</h5>
                  </div>
                  <div className="card-body text-info">
                    {el.semestres.length === 0 ? (
                      <button
                        className="btn btn-info"
                        onClick={(e) => initialise(el)}
                      >
                        initialiser
                      </button>
                    ) : (
                      <ListSemestres semestres={el.semestres} />
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      );
    });
  };

  return Layout(
    <div className="mt-4">
      <h2 className="m-3 h2">Gestion des semestres</h2>
      <div className="d-flex justify-content-center">
        {listSites === null ? null : (
          <div className="form-group">
            <select
              className="form-control"
              value={siteSelected || ""}
              onChange={(e) => setSiteSelected(e.target.value)}
            >
              <option value="" disabled>
                Sites
              </option>
              {listSites !== null
                ? listSites.map((s, i) => (
                    <option value={s["@id"]} key={i}>
                      {s.name}
                    </option>
                  ))
                : null}
            </select>
          </div>
        )}
        <div className="ml-4">
          <div className="form-check">
            <input
              type="checkbox"
              className="form-check-input"
              checked={actif || false}
              onChange={(e) => setActif(!actif)}
            />
            <label className="form-check-label">
              <strong>Promos actives uniquement</strong>
            </label>
          </div>
        </div>
      </div>
      {msg !== null ? (
        <MsgAlert msg={msg.txt} type={msg.type} close={() => autoCloseMsg(setMsg)} />
      ) : null}
      <hr className="m-4" />
      {!loaded || sites === null ? (
        <Spinner />
      ) : (
        <div className="animFadeIn">
          <List />
        </div>
      )}
    </div>
  );
};

export default Semestres;
