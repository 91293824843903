import React, { useContext, useState } from "react";
import { useEffect } from "react";
import AppContext from "../../../Context/AppContext";
import PageFormateurContext from "../../../Context/PageFormateurContext";
import GetBddProvider from "../../../Providers/GetBddProvider";
import PostBddProvider from "../../../Providers/PostBddProvider";
import MsgAlert from "../../common/MsgAlert";
import Spinner from "../../common/Spinner";
import { autoCloseMsg } from "../../tools/messagesUtils";
import { triByUserName } from "../../tools/sortUtils";

const EditAbsences = () => {
  const { url, user } = useContext(AppContext);
  const { selectedAbsences, formateur } = useContext(PageFormateurContext);

  const [msg, setMsg] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [promo, setPromo] = useState(selectedAbsences);
  const [semestres, setSemestres] = useState(null);
  const [absence, setAbsence] = useState({
    createdAt: new Date().toISOString().split("T")[0],
    motif: "",
    comment: "",
    time: "04:00",
    justify: false,
    prev: user.role <= 3 ? true : false,
    semestre: null,
    rattrapage: false,
    user: user["@id"],
  });

  useEffect(() => {
    if (promo !== selectedAbsences && selectedAbsences !== null) {
      init();
      setPromo(selectedAbsences);
      setLoaded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAbsences]);

  const init = () => {
    let sem = selectedAbsences.semestres.filter((sem) => sem.actif);
    if (sem.length === 0) {
      sem[0] =
        selectedAbsences.semestres[selectedAbsences.semestres.length - 1];
    }
    user.role > 3
      ? setSemestres(sem)
      : setSemestres(selectedAbsences.semestres);
    setAbsence({
      createdAt: new Date().toISOString().split("T")[0],
      motif: "",
      time: "04:00",
      comment: "",
      justify: false,
      rattrapage: false,
      prev: user.role <= 3 ? true : false,
      promo: selectedAbsences["@id"],
      semestre: sem[0]["@id"],
      formateur: formateur["@id"],
      user: user["@id"],
    });
  };

  const valid = () => {
    return (
      absence.createdAt &&
      absence.eleve &&
      absence.formateur &&
      absence.moment &&
      absence.semestre &&
      absence.time
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoaded(false);
    let uriTest =
      url +
      "api/absences?eleve=" +
      absence.eleve +
      "&createdAt[after]=" +
      absence.createdAt +
      "&createdAt[before]=" +
      absence.createdAt +
      "&moment=" +
      absence.moment;
    GetBddProvider(uriTest).then((res) => {
      if (res["hydra:member"].length !== 0) {
        setMsg({ txt: "L'absence a déjà été enregistrée", type: "warning" });
        autoCloseMsg(setMsg, 5000);
        setLoaded(true);
      } else {
        let uri = url + "api/absences";
        PostBddProvider(uri, absence).then((res) => {
          setLoaded(true);
          if (typeof res === "object" && res.id) {
            init();
            setMsg({
              txt: "L'absence a bien été enregistrée",
              type: "success",
            });
            autoCloseMsg(setMsg, 5000);
          } else {
            setMsg({ txt: "Erreur d'enregistrement", type: "danger" });
            autoCloseMsg(setMsg, 5000);
          }
        });
      }
    });
  };

  return (
    <div
      className="modal fade"
      id="AbsenceModal"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <form onSubmit={handleSubmit}>
            <div className="modal-header bg-info text-white">
              <h5 className="modal-title">
                {promo !== null ? promo.name + " - " : null}Saisie d'une absence
              </h5>
              <button
                type="button"
                className="close text-white"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              {msg !== null ? (
                <div className="mx-4">
                  <MsgAlert msg={msg.txt} type={msg.type} close={()=>autoCloseMsg(setMsg)} />
                </div>
              ) : null}
              {!loaded ? (
                <Spinner />
              ) : (
                <div>
                  <div className="form-row align-items-center">
                    <div className="form-group col-md-6">
                      <label>
                        Date
                        {navigator.userAgent.indexOf("Safari") >= 1 &&
                        navigator.userAgent.indexOf("Chrome") <= 0 ? (
                          <em>
                            <small> (aaaa-mm-jj)</small>
                          </em>
                        ) : null}
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        placeholder={
                          navigator.userAgent.indexOf("Safari") >= 1 &&
                          navigator.userAgent.indexOf("Chrome") <= 0
                            ? "aaaa-mm-jj"
                            : null
                        }
                        pattern={
                          navigator.userAgent.indexOf("Safari") >= 1 &&
                          navigator.userAgent.indexOf("Chrome") <= 0
                            ? "\\d{4}-\\d{2}-\\d{2}"
                            : null
                        }
                        value={absence.createdAt || ""}
                        max={new Date().toISOString().split("T")[0]}
                        onChange={(e) =>
                          setAbsence({ ...absence, createdAt: e.target.value })
                        }
                        required
                      />
                    </div>

                    {promo.semestres.length === 1 ? (
                      <div className="form-group col-md-6">
                        <strong>
                          <em>
                            <u>{promo.semestres[0].name}</u>
                          </em>
                        </strong>
                      </div>
                    ) : semestres.length === 1 ? (
                      <div className="form-group col-md-6">
                        <strong>
                          <em>
                            <u>{semestres[0].name}</u>
                          </em>
                        </strong>
                      </div>
                    ) : (
                      <div className="form-group col-md-6">
                        <label>Semestre</label>
                        <select
                          name="selectSemestre"
                          className="form-control"
                          value={absence.semestre || ""}
                          onChange={(e) =>
                            setAbsence({ ...absence, semestre: e.target.value })
                          }
                          required
                        >
                          <option value="" disabled>
                            Sélectionnez un semestre
                          </option>
                          {semestres.map((sem, i) => (
                            <option value={sem["@id"]} key={i}>
                              {sem.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    )}
                  </div>
                  <div className="form-row align-items-center">
                    <div className="form-group col-md-6">
                      <label>Période</label>
                      <select
                        className="form-control"
                        value={absence.moment || ""}
                        onChange={(e) =>
                          setAbsence({ ...absence, moment: e.target.value })
                        }
                        required
                      >
                        <option value="" hidden disabled></option>
                        <option value="Matin">Matin</option>
                        <option value="Après-midi">Après-midi</option>
                      </select>
                    </div>
                    <div className="form-group col-md-6">
                      <label>Durée de la session</label>
                      <input
                        type="time"
                        title="Durée initiale de la session"
                        min="01:00"
                        max={user.role <= 3 ? "" : "04:00"}
                        step="1800"
                        pattern={
                          navigator.userAgent.indexOf("Safari") >= 1 &&
                          navigator.userAgent.indexOf("Chrome") <= 0
                            ? "0[1-4]:\\d{2}"
                            : null
                        }
                        value={absence.time || "00:00"}
                        className="form-control"
                        onChange={(e) =>
                          setAbsence({
                            ...absence,
                            time: e.target.value,
                          })
                        }
                        required
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label>Elève</label>
                    <select
                      name="selectEleve"
                      className="form-control"
                      value={absence.eleve || ""}
                      onChange={(e) =>
                        setAbsence({ ...absence, eleve: e.target.value })
                      }
                      required
                    >
                      <option value="" hidden disabled>
                        Sélectionnez un élève
                      </option>
                      {promo.eleves.sort(triByUserName).map((el, i) =>
                        el.user.actif ? (
                          <option value={el["@id"]} key={i}>
                            {el.user.name} {el.user.firstName}
                          </option>
                        ) : null
                      )}
                    </select>
                  </div>
                  <div className="form-group">
                    <label>Motif</label>
                    <select
                      className="form-control"
                      value={absence.motif || ""}
                      onChange={(e) =>
                        setAbsence({ ...absence, motif: e.target.value })
                      }
                    >
                      <option value="">Pas de motif</option>
                      <option value="Retenu(e) en entreprise">
                        Retenu(e) en entreprise
                      </option>
                      <option value="Arrêt de travail">Arrêt de travail</option>
                      <option value="Motif personnel">Motif personnel</option>
                      <option value="Congé payé">Congé payé</option>
                      <option value="Examen">Examen</option>
                      <option value="Cours non obligatoire">
                        Cours non obligatoire
                      </option>
                      <option value="COVID">COVID</option>
                      <option value="Accident du travail">
                        Accident du travail
                      </option>
                      <option value="Problème de connexion">
                        Problème de connexion
                      </option>
                    </select>
                  </div>
                  <div className="form-group">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Commentaire"
                      value={absence.comment || ""}
                      onChange={(e) =>
                        setAbsence({ ...absence, comment: e.target.value })
                      }
                    />
                  </div>
                  {user.role > 3 ? null : (
                    <div className="form-row align-items-center">
                      <div className="form-group col-md-6">
                        <div className="custom-control custom-switch">
                          <input
                            className="custom-control-input"
                            type="checkbox"
                            id="justifyCheckbox"
                            checked={absence.justify || false}
                            disabled={user.role > 3}
                            onChange={(e) =>
                              setAbsence({
                                ...absence,
                                justify: !absence.justify,
                              })
                            }
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="justifyCheckbox"
                          >
                            Justificatif
                          </label>
                        </div>
                      </div>
                      <div className="form-group col-md-6">
                        <div className="custom-control custom-switch">
                          <input
                            className="custom-control-input"
                            type="checkbox"
                            id="prevCheckbox"
                            checked={absence.prev || false}
                            disabled={user.role > 3}
                            onChange={(e) =>
                              setAbsence({
                                ...absence,
                                prev: !absence.prev,
                              })
                            }
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="prevCheckbox"
                          >
                            Prévenue
                          </label>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Fermer
              </button>
              <button
                type="submit"
                className="btn btn-info"
                disabled={!valid()}
              >
                Enregistrer
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditAbsences;
