import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Fragment, useContext, useEffect, useState } from "react";
import AppContext from "../../../Context/AppContext";
import GetBddProvider from "../../../Providers/GetBddProvider";
import Layout from "../../common/Layout";
import MsgAlert from "../../common/MsgAlert";
import { autoCloseMsg } from "../../tools/messagesUtils";
import { triByName } from "../../tools/sortUtils";

const SmecBeta = ({promo, eleve}) => {
  const _EVAL = [
    {
      name: "Subit",
      value: 0,
      icons: ["minus", "minus"],
      color: "danger",
    },
    {
      name: "Exécute",
      value: 1,
      icons: ["minus"],
      color: "warning",
    },
    {
      name: "Maîtrise",
      value: 2,
      icons: ["plus"],
      color: "green-light",
    },
    {
      name: "Maîtrise totale",
      value: 3,
      icons: ["plus", "plus"],
      color: "success",
    },
  ];

  const _TODAY = new Date()

  const { url, user } = useContext(AppContext);

  const [blocs, setBlocs] = useState([]);
  const [suivi, setSuivi] = useState({ notes: [], comment: null, createdAt: _TODAY,validateAt: _TODAY });
  const [siteSelected, setSiteSelected] = useState(null);
  const [promos, setPromos] = useState([]);
  const [promoSelected, setPromoSelected] = useState(null);
  const [promosLoading, setPromosLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [msg, setMsg] = useState(null);

  useEffect(() => {
    if (siteSelected !== null) {
      setPromoSelected(null);
      setPromosLoading(true);
      load();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteSelected]);

  useEffect(() => {
    if (promoSelected !== null) {
      setLoaded(false)
      loadBlocs();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promoSelected]);

  const load = () => {
    GetBddProvider(url + "api/promos?site=" + siteSelected).then((res) => {
      if (typeof res === "object") {
        setPromosLoading(false);
        setPromos(res["hydra:member"].filter((p) => p.actif));
      }
    });
  };

  const loadBlocs = () => {
    let uri =
      url +
      "api/bloc_afests?formation=" +
      promos.filter((p) => p["@id"] === promoSelected)[0].formation["@id"];
    GetBddProvider(uri).then((res) => {
      if (typeof res === "object") {
        setBlocs(res["hydra:member"]);
        setLoaded(true);
      } else {
        setLoaded(true);
        setMsg({
          txt: "Erreur de chargement des blocs de compétence",
          type: "danger",
        });
        autoCloseMsg(setMsg, 5000);
      }
    });
  };

  const removeCompetence = (comp) => {
    let tmp = [...suivi.notes];
    tmp.forEach((n, i) => {
      if (n.competence["@id"] === comp["@id"]) {
        tmp.splice(i, 1);
      }
    });
    setSuivi({ ...suivi, notes: tmp });
  };

  const addCompetence = (comp, b) => {
    let competence = { ...comp, bloc: b };
    let tmp = [...suivi.notes, { competence: competence }];
    setSuivi({ ...suivi, notes: tmp });
  };

  const validate = () => {
    let valid = true;
    suivi.notes.forEach((n) => {
      if (n.note === undefined) {
        valid = false;
      }
    });
    if (suivi.comment === undefined || suivi.comment === null) {
      valid = false;
    }

    return valid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return Layout(
    <>
      <h5 className="text-info text-left m-4">
        <FontAwesomeIcon icon="clipboard-list" /> Suivi Mensuel en Entreprise
        des Compétences
      </h5>
      <hr />

      <div className="form-row mx-4">
        <div className="form-group col-lg-4">
          <label>Site</label>
          <select
            className="form-control rounded-pill"
            value={siteSelected || ""}
            onChange={(e) => {
              setSiteSelected(e.target.value);
            }}
          >
            <option value="">Toutes les promos</option>
            {user.sites.map((s, i) => (
              <option value={s["@id"]} key={i}>
                {s.name}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group col-lg-4">
          <label>Promo</label>
          <select
            className="form-control rounded-pill"
            value={promoSelected || ""}
            onChange={(e) => {
              setPromoSelected(e.target.value);
            }}
            disabled={siteSelected === null}
          >
            <option value="">
              {siteSelected === null
                ? "Veuillez sélectionner un site"
                : promosLoading
                ? "Chargement ..."
                : promos.length < 1
                ? "Pas de promos"
                : "Selectionnez une promo"}
            </option>
            {promos.map((p, i) => (
              <option value={p["@id"]} key={i}>
                {p.name}
              </option>
            ))}
          </select>
        </div>
      </div>

      {!loaded ? null : (
        <>
          <div className="mb-3">
            {msg !== null ? (
              <MsgAlert msg={msg.txt} type={msg.type} close={() => autoCloseMsg(setMsg)} />
            ) : null}
            <div>
              <form>
                <table className="mt-3 table table-sm table-responsive-md">
                  <tbody>
                    {blocs.sort(triByName).map((b, i) => {
                      let notes = suivi.notes.filter(
                        (n) => n.competence.bloc["@id"] === b["@id"]
                      );
                      return (
                        <Fragment key={i}>
                          <tr>
                            <th colSpan="7" className="bg-info text-white">
                              {b.name}
                            </th>
                          </tr>
                          {b.competences.sort(triByName).map((comp, i) => {
                            let selected = notes.filter(
                              (n) => n.competence["@id"] === comp["@id"]
                            );
                            return (
                              <Fragment key={i}>
                                <tr className="text-left">
                                  <th colSpan="7">
                                    <FontAwesomeIcon
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        selected.length > 0
                                          ? removeCompetence(comp)
                                          : addCompetence(comp, b);
                                      }}
                                      className={
                                        selected.length > 0
                                          ? "text-danger"
                                          : "text-success"
                                      }
                                      icon={
                                        selected.length > 0
                                          ? "arrow-circle-left"
                                          : "arrow-circle-right"
                                      }
                                      title={
                                        selected.length > 0
                                          ? "Retirer la compétence"
                                          : "Ajouter la compétence"
                                      }
                                    />{" "}
                                    <em
                                      className={
                                        selected.length > 0
                                          ? "text-success"
                                          : null
                                      }
                                    >
                                      - {comp.name}
                                    </em>
                                  </th>
                                </tr>
                                {selected.map((n, i) => (
                                  <tr key={i}>
                                    <th></th>
                                    {_EVAL.map((val, i) => (
                                      <th key={i}>
                                        <div className="form-check form-check-inline">
                                          <input
                                            type="radio"
                                            className="form-check-input"
                                            id={`${val.name}-${comp["@id"]}`}
                                            name={n.competence["@id"]}
                                            value={val.value}
                                          />
                                          <label
                                            className="form-check-label"
                                            htmlFor={`${val.name}-${comp["@id"]}`}
                                          >
                                            {val.name}
                                            <span
                                              className={`text-${val.color}`}
                                            >
                                              {val.icons.map((icon, i) => (
                                                <FontAwesomeIcon
                                                  icon={icon}
                                                  key={i}
                                                />
                                              ))}
                                            </span>
                                          </label>
                                        </div>
                                      </th>
                                    ))}
                                    <th className="form-group">
                                      <input
                                        className="form-control"
                                        type="text"
                                        name="commentaire"
                                        placeholder="Activités réalisées"
                                      />
                                    </th>
                                  </tr>
                                ))}
                              </Fragment>
                            );
                          })}
                        </Fragment>
                      );
                    })}
                  </tbody>
                </table>
              </form>
            </div>
            <div className="form-group">
              <label>Commentaires</label>
              <textarea
                className="form-control"
                type="textarea"
                rows="5"
                placeholder={"Veuillez saisir un commentaire"}
                value={suivi.comment || ""}
                onChange={(e) =>
                  setSuivi({ ...suivi, comment: e.target.value })
                }
              />
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
            >
              Fermer
            </button>
            {!suivi.validate ? (
              <button
                type="button"
                className="btn btn-success"
                onClick={handleSubmit}
                disabled={!validate()}
              >
                Valider
              </button>
            ) : null}
          </div>
        </>
      )}
    </>
  );
};

export default SmecBeta;
