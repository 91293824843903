import { StorageService } from "../Components/tools/Storage/StorageService";
import checkToken from "../Components/tools/check/checkToken";
import BDDProvider from "./BDDProvider";

/**
 * Fetch -> DELETE data on BDD
 *
 * @param {String} url API url
 * @param {String} tk Token (optionnal)
 * @param {String} signal AbortController's signal
 * @returns {Promise<JOSN|Number>}
 */
export default function DeleteBddProvider(
  url,
  tk = undefined,
  signal = undefined
) {
  const token = tk || StorageService.getItem("token");

  if (!tk) {
    checkToken();
  }

  const method = "DELETE";

  const options = {
    signal,
    headers: {
      Authorization: "Bearer " + token,
    },
    method,
  };

  return BDDProvider(url, options, tk, method);
}
