import React, { useContext, useEffect, useState } from "react";
import AppContext from "../../../Context/AppContext";
import PageFormateurContext from "../../../Context/PageFormateurContext";
import GetBddProvider from "../../../Providers/GetBddProvider";
import PostBddProvider from "../../../Providers/PostBddProvider";
import MsgAlert from "../../common/MsgAlert";
import Spinner from "../../common/Spinner";
import { autoCloseMsg } from "../../tools/messagesUtils";
import { triByUserName } from "../../tools/sortUtils";

const EditRetard = ({ setMaj, promosList }) => {
  const { url, user } = useContext(AppContext);

  const { selectedRetards, formateur } = useContext(PageFormateurContext);

  const [msg, setMsg] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [promos, setPromos] = useState([]);
  const [promo, setPromo] = useState(null);
  const [eleves, setEleves] = useState(null);
  const [promoSelected, setPromoSelected] = useState(null);
  const [semestres, setSemestres] = useState(null);
  const [retard, setRetard] = useState({
    createdAt: new Date().toISOString().split("T")[0],
    semestre: null,
    justify: false,
    prev: false,
    depart: false,
    time: formateur ? "00:01" : null,
    motif: formateur ? "Pas de motif" : null,
    formateur: formateur ? formateur["@id"] : null,
  });

  useEffect(() => {
    !formateur && load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (promosList !== null) {
      load();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promosList]);

  useEffect(() => {
    if (promo !== null) {
      setPromoSelected(null);
      loadPromo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promo]);

  useEffect(() => {
    if (selectedRetards !== null) {
      setPromos([selectedRetards]);
      setPromo(selectedRetards["@id"]);
      setPromoSelected(selectedRetards);
      setLoaded(true);
    }
  }, [selectedRetards]);

  useEffect(() => {
    if (promoSelected !== null) {
      init();
      loadEleves();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promoSelected]);

  const load = () => {
    if (promosList) {
      setPromos(promosList);
      setLoaded(true);
    } else {
      let uri = url + "api/promos?actif=1&";
      user.sites.forEach((site, i, sites) => {
        uri += sites.length > 1 ? "site[]=" : "site=";
        uri += site["@id"];
        uri += i + 1 < sites.length ? "&" : "";
      });
      GetBddProvider(uri).then((res) => {
        setLoaded(true);
        if (typeof res === "object") {
          setPromos(res["hydra:member"]);
        } else {
          setMsg({ txt: "Erreur de chargement des promos", type: "danger" });
          autoCloseMsg(setMsg, 5000);;
        }
      });
    }
  };

  const loadPromo = () => {
    let uri = url + promo.slice(1);
    GetBddProvider(uri).then((res) => {
      if (typeof res === "object" && res.id) {
        setPromoSelected(res);
      } else {
        setMsg({ txt: "Erreur de chargement de la promo", type: "danger" });
        autoCloseMsg(setMsg, 5000);;
      }
    });
  };

  const loadEleves = () => {
    setLoaded(false);
    setEleves(null);
    let uri = url + "api/eleves?user.actif=true&promos=" + promoSelected["@id"];
    GetBddProvider(uri).then((res) => {
      setLoaded(true);
      if (typeof res === "object") {
        setEleves(res["hydra:member"]);
      } else {
        setMsg({ txt: "Erreur de cahrgement des élèves", type: "danger" });
        autoCloseMsg(setMsg, 5000);;
      }
    });
  };

  const init = () => {
    let sem = promoSelected.semestres.filter((sem) => sem.actif);
    if (sem.length === 0) {
      sem[0] = promoSelected.semestres[promoSelected.semestres.length - 1];
    }
    user.role > 3 ? setSemestres(sem) : setSemestres(promoSelected.semestres);
    setRetard({
      createdAt: new Date().toISOString().split("T")[0],
      promo: promo,
      semestre: sem[0]["@id"],
      justify: false,
      prev: false,
      depart: false,
      time: formateur ? "00:01" : null,
      motif: formateur ? "Pas de motif" : null,
      formateur: formateur ? formateur["@id"] : null,
    });
  };

  const valid = () => {
    return (
      retard.createdAt &&
      retard.eleve &&
      retard.moment &&
      retard.semestre &&
      retard.motif &&
      retard.time
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoaded(false);
    let uri = url + "api/retards";
    PostBddProvider(uri, retard).then((res) => {
      setLoaded(true);
      if (typeof res === "object" && res.id) {
        init();
        setMaj && setMaj(true);
        setMsg({ txt: "Le retard a bien été enregistré", type: "success" });
        autoCloseMsg(setMsg, 5000);;
      } else {
        setMsg({ txt: "Erreur d'enregistrement", type: "danger" });
        autoCloseMsg(setMsg, 5000);;
      }
    });
  };

  return (
    <div
      className="modal fade"
      id="RetardModal"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <form onSubmit={handleSubmit}>
            <div className="modal-header bg-info text-white">
              <h5 className="modal-title">
                Saisie d'un retard ou départ anticipé
              </h5>
              <button
                type="button"
                className="close text-white"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              {msg !== null ? (
                <div className="mx-4">
                  <MsgAlert msg={msg.txt} type={msg.type} close={() => autoCloseMsg(setMsg)} />
                </div>
              ) : null}
              {!loaded || promos.length < 1 ? (
                <Spinner />
              ) : (
                <div>
                  <div className="form-group">
                    <label>Promo</label>
                    <select
                      className="form-control"
                      value={promo || ""}
                      onChange={(e) => {
                        setPromo(e.target.value);
                        setRetard({ ...retard, promo: e.target.value });
                      }}
                      disabled={promos.length < 2}
                    >
                      <option value="" hidden disabled>
                        Selectionnez une promo
                      </option>
                      {promos.map((p, i) => (
                        <option
                          value={p["@id"]}
                          disabled={p.semestres.length === 0}
                          key={i}
                        >
                          {p.site.name} - {p.name}
                          {p.semestres.length === 0 && " (Pas de semestre)"}
                        </option>
                      ))}
                    </select>
                  </div>
                  {promoSelected === null ||
                  semestres === null ||
                  eleves === null ? (
                    <h4>Sélectionnez une promo</h4>
                  ) : (
                    <React.Fragment>
                      <div className="form-row align-items-center">
                        <div className="form-group col-md-6">
                          <label>
                            Date
                            {navigator.userAgent.indexOf("Safari") >= 1 &&
                            navigator.userAgent.indexOf("Chrome") <= 0 ? (
                              <em>
                                <small> (aaaa-mm-jj)</small>
                              </em>
                            ) : null}
                          </label>
                          <input
                            type="date"
                            className="form-control"
                            disabled={formateur}
                            placeholder={
                              navigator.userAgent.indexOf("Safari") >= 1 &&
                              navigator.userAgent.indexOf("Chrome") <= 0
                                ? "aaaa-mm-jj"
                                : null
                            }
                            pattern={
                              navigator.userAgent.indexOf("Safari") >= 1 &&
                              navigator.userAgent.indexOf("Chrome") <= 0
                                ? "\\d{4}-\\d{2}-\\d{2}"
                                : null
                            }
                            value={retard.createdAt || ""}
                            onChange={(e) =>
                              setRetard({
                                ...retard,
                                createdAt: e.target.value,
                              })
                            }
                            required
                          />
                        </div>

                        {promoSelected.semestres.length === 1 ? (
                          <div className="form-group col-md-6">
                            <strong>
                              <em>
                                <u>{promoSelected.semestres[0].name}</u>
                              </em>
                            </strong>
                          </div>
                        ) : semestres.length === 1 ? (
                          <div className="form-group col-md-6">
                            <strong>
                              <em>
                                <u>{semestres[0].name}</u>
                              </em>
                            </strong>
                          </div>
                        ) : (
                          <div className="form-group col-md-6">
                            <label>Semestre</label>
                            <select
                              name="selectSemestre"
                              className="form-control"
                              value={retard.semestre || ""}
                              onChange={(e) =>
                                setRetard({
                                  ...retard,
                                  semestre: e.target.value,
                                })
                              }
                              required
                            >
                              <option value="" disabled>
                                Sélectionnez un semestre
                              </option>
                              {semestres.map(
                                (sem, i) =>
                                  (sem.actif || !formateur) && (
                                    <option value={sem["@id"]} key={i}>
                                      {sem.name}
                                    </option>
                                  )
                              )}
                            </select>
                          </div>
                        )}
                      </div>
                      <div className="form-row align-items-center">
                        <div className="form-group col-md-6">
                          <label>Période</label>
                          <select
                            className="form-control"
                            value={retard.moment || ""}
                            onChange={(e) =>
                              setRetard({ ...retard, moment: e.target.value })
                            }
                            required
                          >
                            <option value="" hidden disabled></option>
                            <option value="Matin">Matin</option>
                            <option value="Après-midi">Après-midi</option>
                          </select>
                        </div>
                        <div className="form-group col-md-6">
                          <label>Temps</label>
                          <input
                            type="time"
                            title="Durée initiale de la session"
                            min="00:01"
                            max="04:00"
                            step="60"
                            pattern={
                              navigator.userAgent.indexOf("Safari") >= 1 &&
                              navigator.userAgent.indexOf("Chrome") <= 0
                                ? "0[1-4]:\\d{2}"
                                : null
                            }
                            value={retard.time || "00:00"}
                            className="form-control"
                            onChange={(e) =>
                              setRetard({
                                ...retard,
                                time: e.target.value,
                              })
                            }
                            required
                          />
                        </div>
                      </div>
                      <div className="form-group form-inline justify-content-between">
                        <div className="form-check col-6">
                          <input
                            type="radio"
                            className="form-check-input"
                            name="radioDepartOption"
                            id="radioRetard"
                            value="false"
                            onChange={(e) =>
                              setRetard({ ...retard, depart: false })
                            }
                            checked={retard.depart === false}
                          />
                          <label
                            htmlFor="radioRetard"
                            className="form-check-label"
                          >
                            Retard
                          </label>
                        </div>
                        <div className="form-check col-6">
                          <input
                            type="radio"
                            className="form-check-input"
                            name="radioDepartOption"
                            id="radioDepart"
                            value="true"
                            onChange={(e) =>
                              setRetard({ ...retard, depart: true })
                            }
                            checked={retard.depart === true}
                          />
                          <label
                            htmlFor="radioDepart"
                            className="form-check-label"
                          >
                            Départ anticipé
                          </label>
                        </div>
                      </div>
                      <div className="form-group">
                        <label>Elève</label>
                        <select
                          name="selectEleve"
                          className="form-control"
                          value={retard.eleve || ""}
                          onChange={(e) =>
                            setRetard({ ...retard, eleve: e.target.value })
                          }
                          required
                        >
                          <option value="" hidden disabled>
                            Sélectionnez un élève
                          </option>
                          {eleves.sort(triByUserName).map((el, i) =>
                            el.user.actif ? (
                              <option value={el["@id"]} key={i}>
                                {el.user.name} {el.user.firstName}
                              </option>
                            ) : null
                          )}
                        </select>
                      </div>
                      <div className="form-group">
                        <label>Motif</label>
                        <input
                          className="form-control"
                          value={retard.motif || ""}
                          placeholder="Veuillez saisir un motif"
                          onChange={(e) =>
                            setRetard({ ...retard, motif: e.target.value })
                          }
                          required
                        />
                      </div>
                      {!formateur && (
                        <div className="form-row align-items-center">
                          <div className="form-group col-md-6">
                            <div className="custom-control custom-switch">
                              <input
                                className="custom-control-input"
                                type="checkbox"
                                id="justifyCheckbox"
                                checked={retard.justify || false}
                                onChange={(e) =>
                                  setRetard({
                                    ...retard,
                                    justify: !retard.justify,
                                  })
                                }
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="justifyCheckbox"
                              >
                                Justificatif
                              </label>
                            </div>
                          </div>
                          <div className="form-group col-md-6">
                            <div className="custom-control custom-switch">
                              <input
                                className="custom-control-input"
                                type="checkbox"
                                id="prevCheckbox"
                                checked={retard.prev || false}
                                onChange={(e) =>
                                  setRetard({
                                    ...retard,
                                    prev: !retard.prev,
                                  })
                                }
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="prevCheckbox"
                              >
                                Prévenue
                              </label>
                            </div>
                          </div>
                        </div>
                      )}
                    </React.Fragment>
                  )}
                </div>
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Fermer
              </button>
              <button
                type="submit"
                className="btn btn-info"
                disabled={!valid()}
              >
                Enregistrer
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditRetard;
