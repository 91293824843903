import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import React, { useContext, useEffect, useState } from "react";
import AppContext from "../../Context/AppContext";
import DeleteBddProvider from "../../Providers/DeleteBddProvider";
import GetBddProvider from "../../Providers/GetBddProvider";
import Layout from "../common/Layout";
import MsgAlert from "../common/MsgAlert";
import Spinner from "../common/Spinner";
import dateFormated from "../tools/dateFormated";
import EditSuiviAfest from "../contents/edits/EditSuiviAfest";
import ViewSuiviAfest from "../contents/views/ViewSuiviAfest";
import { autoCloseMsg } from "../tools/messagesUtils";
import { triByName, triByUserName } from "../tools/sortUtils";

const SuiviAfest = () => {
  const { url, user } = useContext(AppContext);

  const toDay = new Date();
  const initDebut = new Date(toDay.getFullYear(), toDay.getMonth(), 1);
  const initFin = new Date(toDay.getFullYear(), toDay.getMonth() + 1, 0);

  const [debut, setDebut] = useState(
    new Date(
      initDebut.getFullYear(),
      initDebut.getMonth(),
      initDebut.getDate(),
      -initDebut.getTimezoneOffset() / 60
    )
      .toISOString()
      .split("T")[0]
  );
  const [fin, setFin] = useState(
    new Date(
      initFin.getFullYear(),
      initFin.getMonth(),
      initFin.getDate(),
      -initFin.getTimezoneOffset() / 60
    )
      .toISOString()
      .split("T")[0]
  );

  const [sites, setSites] = useState(null);
  const [siteSelected, setSiteSelected] = useState(null);
  const [promos, setPromos] = useState(null);
  const [promosUser, setPromosUser] = useState(null);
  const [promosLoading, setPromosLoading] = useState(false);
  const [promoSelected, setPromoSelected] = useState(null);
  const [eleveSelected, setEleveSelected] = useState(null);
  const [elevesloading, setElevesloading] = useState(null);
  const [eleves, setEleves] = useState(null);
  const [suivis, setSuivis] = useState(null);
  const [suiviSelected, setSuiviSelected] = useState(null);
  const [msg, setMsg] = useState(null);
  const [maj, setMaj] = useState(false);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    initPromosUser();
    loadSites();
    loadSuivis();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (maj) {
      setMaj(false);
      setSuivis(null);
      loadSuivis();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maj]);

  useEffect(() => {
    setPromosLoading(true);
    setPromoSelected(null);
    setPromos(null);
    setEleves(null);
    setEleveSelected(null);
    if (siteSelected !== null && siteSelected !== "") {
      load();
    } else {
      setPromosLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteSelected]);

  useEffect(() => {
    setEleves(null);
    setEleveSelected(null);
    setElevesloading(true);
    if (promoSelected !== null && promoSelected !== "") {
      loadEleves(promoSelected);
    } else {
      setElevesloading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promoSelected]);

  const initPromosUser = () => {
    let uri = url + "api/promos?";
    uri += "&";
    user.sites.forEach((site, i, sites) => {
      uri += sites.length > 1 ? "site[]=" : "site=";
      uri += site["@id"];
      uri += i + 1 < sites.length ? "&" : "";
    });
    GetBddProvider(uri).then((res) => {
      if (typeof res === "object") {
        setPromosUser(res["hydra:member"]);
      } else {
        setMsg({ txt: "Erreur de Chargement des promos", type: "danger" });
        autoCloseMsg(setMsg, 5000);
      }
    });
  };

  const loadSites = () => {
    if (user.role === 1) {
      let uri = url + "api/sites";
      GetBddProvider(uri).then((res) => {
        if (typeof res === "object") {
          setSites(res["hydra:member"]);
          setLoaded(true);
        } else {
          setMsg({ txt: "Erreur de Chargement des sites", type: "danger" });
          autoCloseMsg(setMsg, 5000);
        }
      });
    } else {
      setSites(user.sites);
      setLoaded(true);
    }
  };

  const load = () => {
    let uri = url + "api/promos?site=" + siteSelected;
    GetBddProvider(uri).then((res) => {
      setPromosLoading(false);
      if (typeof res === "object") {
        setPromos(res["hydra:member"]);
      } else {
        setMsg({ txt: "Erreur de Chargement des promos", type: "danger" });
        autoCloseMsg(setMsg, 5000);
      }
    });
  };

  const loadEleves = () => {
    let uri = url + "api/eleves?promos=" + promoSelected;
    GetBddProvider(uri).then((res) => {
      setElevesloading(false);
      if (typeof res === "object") {
        setEleves(res["hydra:member"]);
      } else {
        setMsg({ txt: "Erreur de Chargement des élèves", type: "danger" });
        autoCloseMsg(setMsg, 5000);
      }
    });
  };

  const loadSuivis = () => {
    let uri =
      url +
      "api/eval_afests?order[createdAt]=asc&createdAt[after]=" +
      debut +
      "&createdAt[before]=" +
      fin;
    if (eleveSelected !== null) {
      uri += "&eleve=" + eleveSelected;
    }
    if (promoSelected !== null && promoSelected !== "") {
      uri += "&promo=" + promoSelected;
    }
    if (siteSelected !== null && siteSelected !== "") {
      uri += "&promo.site=" + siteSelected;
    } else {
      uri += "&";
      user.sites.forEach((site, i, sites) => {
        uri += sites.length > 1 ? "promo.site[]=" : "promo.site=";
        uri += site["@id"];
        uri += i + 1 < sites.length ? "&" : "";
      });
    }
    GetBddProvider(uri).then((res) => {
      if (typeof res === "object") {
        setSuivis(res["hydra:member"]);
      } else {
        setMsg({ txt: "Erreur de chargement des suivis", type: "danger" });
        autoCloseMsg(setMsg, 5000);
      }
    });
  };

  const delSuivi = (iri) => {
    setSuivis(null);
    let uri = url + iri.slice(1);
    DeleteBddProvider(uri).then((res) => {
      loadSuivis();
      if (res === 204) {
        setMsg({
          txt: "Le suivi a été supprimée !",
          type: "success",
        });
        autoCloseMsg(setMsg, 5000);
      } else {
        setMsg({
          txt: "Erreur lors de la suppression",
          type: "danger",
        });
        autoCloseMsg(setMsg, 5000);
      }
    });
  };

  return Layout(
    <div className="mt-4">
      <div className="d-flex flex-wrap justify-content-md-between justify-content-center mx-4">
        <h2>Suivi Mensuel en Entreprise des Compétences</h2>
      </div>
      <EditSuiviAfest setMaj={setMaj} listPromos={promosUser} />
      <ViewSuiviAfest suiviSelected={suiviSelected} />
      <hr className=" mx-4 mt-4 mb-0" />
      {msg !== null ? (
        <MsgAlert msg={msg.txt} type={msg.type} close={() => autoCloseMsg(setMsg)} />
      ) : null}
      {!loaded ? (
        <Spinner />
      ) : (
        <React.Fragment>
          <h4 className="text-info">
            <em>Filtres</em>
          </h4>
          <div className="form-row mx-4">
            <div className="form-group col-lg-4">
              <label>Site</label>
              <select
                className="form-control"
                value={siteSelected || ""}
                onChange={(e) => {
                  setSiteSelected(e.target.value);
                }}
              >
                <option value="">Tous mes sites</option>
                {sites.sort(triByName).map((s, i) => (
                  <option value={s["@id"]} key={s["@id"]}>
                    {s.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group col-lg-4">
              <label>Promo</label>
              <select
                className="form-control"
                value={promoSelected || ""}
                onChange={(e) => {
                  setPromoSelected(e.target.value);
                }}
                disabled={promos === null}
              >
                <option value="">
                  {promosLoading
                    ? "Chargement en cours ..."
                    : promos === null
                    ? "Veuillez sélectionner un site"
                    : promos.length < 1
                    ? "Pas de promos"
                    : "Toutes les promos"}
                </option>
                {promos === null
                  ? null
                  : promos.map((p, i) => (
                      <option value={p["@id"]} key={i}>
                        {p.site.name} - {p.name}
                      </option>
                    ))}
              </select>
            </div>
            <div className="form-group col-lg-4">
              <label>Eleves</label>
              <select
                className="form-control"
                value={eleveSelected || ""}
                onChange={(e) => setEleveSelected(e.target.value)}
                disabled={eleves === null}
              >
                <option value="">
                  {elevesloading
                    ? "Chargement en cours ..."
                    : eleves === null
                    ? "Veuillez sélectionner une promo"
                    : eleves.length < 1
                    ? "Pas d'élèves"
                    : "Tous les élèves"}
                </option>
                {eleves === null
                  ? null
                  : eleves.sort(triByUserName).map((el, i) => (
                      <option value={el["@id"]} key={i}>
                        {el.user.name} {el.user.firstName}
                      </option>
                    ))}
              </select>
            </div>
          </div>
          <div className="form-row mx-4">
            <div className="form-group col-lg-5">
              <label>
                Début de période{" "}
                {navigator.userAgent.indexOf("Safari") >= 1 &&
                navigator.userAgent.indexOf("Chrome") <= 0 ? (
                  <em>
                    <small> (aaaa-mm-jj)</small>
                  </em>
                ) : null}
              </label>
              <input
                className="form-control"
                type="date"
                placeholder={
                  navigator.userAgent.indexOf("Safari") >= 1 &&
                  navigator.userAgent.indexOf("Chrome") <= 0
                    ? "aaaa-mm-jj"
                    : null
                }
                pattern={
                  navigator.userAgent.indexOf("Safari") >= 1 &&
                  navigator.userAgent.indexOf("Chrome") <= 0
                    ? "\\d{4}-\\d{2}-\\d{2}"
                    : null
                }
                value={debut || ""}
                onChange={(e) => setDebut(e.target.value)}
                required
              />
            </div>
            <div className="form-group col-lg-5">
              <label>
                Fin de période{" "}
                {navigator.userAgent.indexOf("Safari") >= 1 &&
                navigator.userAgent.indexOf("Chrome") <= 0 ? (
                  <em>
                    <small> (aaaa-mm-jj)</small>
                  </em>
                ) : null}
              </label>
              <input
                className="form-control"
                type="date"
                placeholder={
                  navigator.userAgent.indexOf("Safari") >= 1 &&
                  navigator.userAgent.indexOf("Chrome") <= 0
                    ? "aaaa-mm-jj"
                    : null
                }
                pattern={
                  navigator.userAgent.indexOf("Safari") >= 1 &&
                  navigator.userAgent.indexOf("Chrome") <= 0
                    ? "\\d{4}-\\d{2}-\\d{2}"
                    : null
                }
                value={fin || ""}
                onChange={(e) => setFin(e.target.value)}
                required
              />
            </div>
            <div className="form-group col-lg-2 align-self-end">
              <button
                className="btn btn-outline-info form-control"
                onClick={() => {
                  setSuivis(null);
                  loadSuivis();
                }}
              >
                Afficher
              </button>
            </div>
          </div>
          <hr className="m-4" />
          <div className="m-4 animFadeIn">
            {suivis === null ? (
              <Spinner />
            ) : (
              <div>
                {suivis.length < 1 ? (
                  <h4 className="text-danger m-4">
                    <strong>Pas de suivi sur la période</strong>
                  </h4>
                ) : (
                  <div>
                    <h4 className="m-4">Liste des suivis</h4>
                    <table
                      className="table table-sm table-bordered table-responsive-md"
                      id="TableSuivis"
                    >
                      <thead>
                        <tr className="bg-info text-white">
                          <th>Action</th>
                          <th>Début</th>
                          <th>Fin</th>
                          <th>Promo</th>
                          <th>Eleve</th>
                          <th>Intitulé</th>
                          <th>Validé</th>
                        </tr>
                      </thead>
                      <tbody className="text-left">
                        {suivis.map((s, i) => {
                          return (
                            <tr key={i}>
                              <td>
                                <FontAwesomeIcon
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    setSuiviSelected(
                                      JSON.parse(JSON.stringify(s))
                                    );
                                  }}
                                  data-toggle="modal"
                                  data-target="#ModalViewSuiviAfest"
                                  className="text-info"
                                  icon="clipboard-list"
                                  title="Visualiser"
                                />{" "}
                                &nbsp;{" "}
                                <FontAwesomeIcon
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    if (
                                      window.confirm("Supprimer le suivi ?")
                                    ) {
                                      delSuivi(s["@id"]);
                                    }
                                  }}
                                  className="text-danger"
                                  icon="trash-alt"
                                  title="Supprimer"
                                />
                              </td>
                              <td>{dateFormated(s.createdAt)}</td>
                              <td>{dateFormated(s.validateAt)}</td>
                              <td>{s.promo.name}</td>
                              <td>
                                {s.eleve.user.name} {s.eleve.user.firstName}
                              </td>
                              <td>{s.name}</td>
                              <td className="text-center">
                                {s.validate ? (
                                  <span className="badge badge-pill badge-success m-1 text-white">
                                    Oui
                                  </span>
                                ) : (
                                  <span className="badge badge-pill badge-danger m-1 text-white">
                                    Non
                                  </span>
                                )}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                    <ReactHTMLTableToExcel
                      className="btn btn-outline-primary"
                      table="TableSuivis"
                      filename={
                        "relevé-suivis-" +
                        (new Date(debut).getMonth() + 1) +
                        "-" +
                        new Date(debut).getFullYear()
                      }
                      sheet="Export"
                      buttonText="Export Excel"
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default SuiviAfest;
