import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import Spinner from "../../common/Spinner";
import addTime from "../../tools/addTime";
import dateFormated from "../../tools/dateFormated";

const ViewRtdEleve = ({ rtd }) => {
  let totalH = "0:00";
  return (
    <div
      className="modal fade"
      id="ModalViewRtd"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header bg-info text-white">
            <h5 className="modal-title">
              <FontAwesomeIcon icon="clipboard-list" /> &nbsp; Consultation des
              retards et départs anticipés
            </h5>
            <button
              type="button"
              className="close text-white"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          {rtd === null ? (
            <div className="pb-5 modal-body text-info">
              <Spinner />
            </div>
          ) : (
            <div className="p-3 modal-body">
              <div>
                <table className="table table-sm">
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Motif</th>
                      <th>Heures</th>
                    </tr>
                  </thead>
                  <tbody>
                    {rtd.map((r, i) => {
                      let h = r.time.split("T")[1];
                      h = h.split("+")[0];
                      h = h.split(":")[0] + ":" + h.split(":")[1];
                      totalH = addTime(totalH, h);
                      return (
                        <tr key={i}>
                          <td className="text-left">
                            {r.depart === false ? (
                              <span className="badge badge-pill badge-danger">
                                R
                              </span>
                            ) : (
                              <span className="badge badge-pill badge-warning">
                                D
                              </span>
                            )}{" "}
                            {dateFormated(r.createdAt)} - {r.moment}
                          </td>
                          <td className="text-left">
                            {r.motif === "" || r.motif === undefined
                              ? "Pas de motif"
                              : r.motif}
                          </td>
                          <td>{h}</td>
                        </tr>
                      );
                    })}
                    <tr className="text-info">
                      <td colSpan="2" className="text-right">
                        <h4>Total :</h4>
                      </td>
                      <td className="text-center">
                        <h4>
                          {String(totalH).split(".")[1] !== undefined
                            ? String(totalH).split(".")[0] +
                              "," +
                              String(totalH).split(".")[1]
                            : String(totalH).split(".")[0]}
                        </h4>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ViewRtdEleve;
