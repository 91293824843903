import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment, useContext, useEffect, useState } from "react";
import AppContext from "../../../Context/AppContext";
import GetBddProvider from "../../../Providers/GetBddProvider";
import UpdateBddProvider from "../../../Providers/UpdateBddProvider";
import MsgAlert from "../../common/MsgAlert";
import Spinner from "../../common/Spinner";
import dateFormated from "../../tools/dateFormated";
import heuresFormH from "../../tools/heuresFormH";
import { autoCloseMsg } from "../../tools/messagesUtils";
import PdfButton from "../../tools/PdfButton";
import {
  triByComportementName,
  triByRealisationName,
} from "../../tools/sortUtils";

const ViewSuivi = ({ suiviSelected, setMaj, site }) => {
  const { url, user } = useContext(AppContext);

  const _NOTES = [
    { value: 1, color: "text-danger" },
    { value: 2, color: "text-warning" },
    { value: 3, color: "text-green-light" },
    { value: 4, color: "text-success" },
  ];

  const [suivi, setSuivi] = useState(suiviSelected);
  const [loaded, setLoaded] = useState(false);
  const [msg, setMsg] = useState(null);

  useEffect(() => {
    if (suiviSelected !== null) {
      setSuivi(suiviSelected);
      setLoaded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [suiviSelected]);

  const reload = () => {
    setMaj(true);
    GetBddProvider(url + suivi["@id"].slice(1)).then((res) => {
      if (typeof res === "object" && res.id) {
        setSuivi(res);
        setLoaded(true);
      } else {
        setMsg({
          txt: "Erreur de chargement du suivi",
          type: "danger",
        });
        autoCloseMsg(setMsg, 5000);
        setLoaded(true);
      }
    });
  };

  const validSuivi = () => {
    setLoaded(false);
    let data = {
      validate: !suivi.validate,
    };
    UpdateBddProvider(url + suivi["@id"].slice(1), data).then((res) => {
      if (typeof res === "object" && res.id) {
        setMsg({
          txt: "La mise a jour a bien été enregistrée",
          type: "success",
        });
        autoCloseMsg(setMsg, 5000);
        reload();
      } else {
        setMsg({
          txt: "Erreur de mise à jour",
          type: "danger",
        });
        autoCloseMsg(setMsg, 5000);
        setLoaded(true);
      }
    });
  };

  return (
    <div
      className="modal fade"
      id="ModalViewSuivi"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header bg-info text-white">
            <h5 className="modal-title">
              <FontAwesomeIcon icon="clipboard-list" /> &nbsp; Consultation du
              suivi de{" "}
              {suiviSelected !== null
                ? suiviSelected.eleve.user.name +
                  " " +
                  suiviSelected.eleve.user.firstName
                : null}
            </h5>
            <button
              type="button"
              className="close text-white"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          {!loaded && !suivi ? (
            <div className="pb-5 modal-body text-info">
              <Spinner />
            </div>
          ) : (
            <div className="modal-body mb-3">
              {msg !== null ? (
                <MsgAlert
                  msg={msg.txt}
                  type={msg.type}
                  close={() => autoCloseMsg(setMsg)}
                />
              ) : null}
              <div className="d-flex">
                <div className="col-lg-3">
                  <h6>
                    <strong>
                      <u>Date</u>
                    </strong>
                  </h6>
                  <span>{dateFormated(suiviSelected.createdAt)}</span>
                </div>
                <div className="col-lg-3">
                  <h6>
                    <strong>
                      <u>Durée</u>
                    </strong>
                  </h6>
                  <span>{heuresFormH(suiviSelected.time)}</span>
                </div>
                <div className="col-lg-4">
                  <h6>
                    <strong>
                      <u>Évaluateur</u>
                    </strong>
                  </h6>
                  <span>
                    {suiviSelected.evaluateur.name}{" "}
                    {suiviSelected.evaluateur.firstName}
                  </span>
                </div>
                <div className="col-lg-2 text-right align-self-center">
                  <PdfButton
                    data={{ suivi: suiviSelected, site: site }}
                    uri="/suivi"
                  />
                </div>
              </div>
              <div>
                <form>
                  <table className="mt-3 table table-sm table-responsive-md">
                    <thead>
                      <tr>
                        <th></th>
                        <th>
                          Insuffisant <br />
                          <span className="text-danger">
                            <FontAwesomeIcon icon="minus" />{" "}
                            <FontAwesomeIcon icon="minus" />
                          </span>
                        </th>
                        <th>
                          A améliorer <br />
                          <span className="text-warning">
                            <FontAwesomeIcon icon="minus" />
                          </span>
                        </th>
                        <th>
                          Satisfaisant <br />
                          <span className="text-green-light">
                            <FontAwesomeIcon icon="plus" />
                          </span>
                        </th>
                        <th>
                          Très satisfaisant <br />
                          <span className="text-success">
                            <FontAwesomeIcon icon="plus" />{" "}
                            <FontAwesomeIcon icon="plus" />
                          </span>
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr>
                        <th colSpan="5">Comportement Général</th>
                      </tr>
                      {suiviSelected.notesComp
                        .sort(triByComportementName)
                        .map((n, i) => {
                          return (
                            <tr key={i}>
                              <th className="text-left">
                                {n.itemComportement.name}
                              </th>
                              {_NOTES.map((t, it) => {
                                let check = n.note === t.value;
                                return (
                                  <td
                                    className={check ? t.color : "text-muted"}
                                    key={"valueComp" + it}
                                  >
                                    {check ? (
                                      <FontAwesomeIcon icon="check-circle" />
                                    ) : (
                                      "-"
                                    )}
                                  </td>
                                );
                              })}
                            </tr>
                          );
                        })}
                      <tr>
                        <th colSpan="5">Réalisation du travail</th>
                      </tr>
                      {suiviSelected.notesReal
                        .sort(triByRealisationName)
                        .map((n, i) => {
                          return (
                            <tr key={i}>
                              <th className="text-left">
                                {n.itemRealisation.name}
                              </th>
                              {_NOTES.map((t, it) => {
                                let check = n.note === t.value;
                                return (
                                  <td
                                    className={check ? t.color : "text-muted"}
                                    key={"valueReal" + it}
                                  >
                                    {check ? (
                                      <FontAwesomeIcon icon="check-circle" />
                                    ) : (
                                      "-"
                                    )}
                                  </td>
                                );
                              })}
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </form>
              </div>
              <div className="mt-3 text-left border border-info p-1">
                <h6>
                  <strong>
                    <u>Commentaires :</u>
                  </strong>
                </h6>
                <p>
                  {suiviSelected.comment.split("\n").map((item, key) => {
                    return (
                      <Fragment key={key}>
                        {item}
                        <br />
                      </Fragment>
                    );
                  })}
                </p>
              </div>
              {user.role > 5 && !suivi.validate ? (
                <button
                  type="submit"
                  className="btn btn-success mt-2 mr-2"
                  onClick={validSuivi}
                  disabled={suivi.validate}
                >
                  Valider
                </button>
              ) : null}
              {user.role <= 3.5 && !suivi.validate ? (
                <button
                  type="submit"
                  className="btn btn-success mt-2 mr-2"
                  onClick={validSuivi}
                  disabled={suivi.validate}
                >
                  Covalidation
                </button>
              ) : null}
              <button
                type="button"
                className="btn btn-secondary mt-2"
                data-dismiss="modal"
              >
                Fermer
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ViewSuivi;
