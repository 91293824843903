/**
 * Calcul la moyenne générale de la classe
 * @param {array} moyennes - moyennes de la classe par matière
 * @param {array} coefs - listes des coefficients par matière
 * @returns {number} la moyenne générale de la classe
 */
const CalcMoyGenClasse = (moyennes, coefs) => {
  let noteTmp = 0;
  let coefTmp = 0;
  moyennes.forEach((m) => {
    if (m.moyenne !== null) {
      let temp = m.moyenne;
      let coef = coefs.filter((e) => e.matiere["@id"] === m.matiere)[0]
        .coefficient;
      noteTmp += temp * coef;
      coefTmp += coef;
    }
  });
  let result =
    noteTmp !== 0 ? Math.round((noteTmp / coefTmp) * 100) / 100 : null;
  return result;
};

export default CalcMoyGenClasse;
