import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faArrowCircleLeft,
  faArrowCircleRight,
  faBook,
  faBullhorn,
  faCalendarAlt,
  faChalkboard,
  faChalkboardTeacher,
  faChartLine,
  faCheck,
  faCheckCircle,
  faChevronDown,
  faChevronLeft,
  faChevronUp,
  faClipboardList,
  faCogs,
  faEdit,
  faExclamationTriangle,
  faEye,
  faFlask,
  faGraduationCap,
  faHistory,
  faHome,
  faMinus,
  faPaperPlane,
  faPlay,
  faPlus,
  faPlusCircle,
  faPowerOff,
  faPrint,
  faQuestionCircle,
  faSave,
  faSyncAlt,
  faTasks,
  faTimes,
  faTimesCircle,
  faTrashAlt,
  faUniversity,
  faUser,
  faUserCircle,
  faUserClock,
  faUserGraduate,
  faUsersCog,
  faUserTie,
  faUserTimes,
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faPowerOff,
  faUsersCog,
  faUserTie,
  faGraduationCap,
  faUserGraduate,
  faChalkboardTeacher,
  faCalendarAlt,
  faChalkboard,
  faUniversity,
  faUser,
  faHome,
  faEdit,
  faPlusCircle,
  faTrashAlt,
  faTasks,
  faChevronUp,
  faChevronDown,
  faChevronLeft,
  faChartLine,
  faClipboardList,
  faUserClock,
  faUserTimes,
  faExclamationTriangle,
  faHistory,
  faCogs,
  faPlus,
  faMinus,
  faArrowCircleRight,
  faArrowCircleLeft,
  faTimesCircle,
  faTimes,
  faEye,
  faPlay,
  faUserCircle,
  faCheckCircle,
  faCheck,
  faQuestionCircle,
  faSyncAlt,
  faSave,
  faPaperPlane,
  faBook,
  faFlask,
  faPrint,
  faBullhorn
);
