import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import AppContext from "../../Context/AppContext";
import NotesContext from "../../Context/NotesContext";
import GetBddProvider from "../../Providers/GetBddProvider";
import Layout from "../common/Layout";
import MsgAlert from "../common/MsgAlert";
import Spinner from "../common/Spinner";
import EditNote from "../contents/edits/EditNote";
import EditNotesAdmin from "../contents/edits/EditNotesAdmin";
import ListGestNotes from "../contents/lists/ListGestNotes";
import ModifNote from "../contents/modifs/ModifNote";
import { autoCloseMsg } from "../tools/messagesUtils";

const Notes = () => {
  const { url, user } = useContext(AppContext);

  const [promos, setPromos] = useState(null);
  const [promo, setPromo] = useState(null);
  const [matieres, setMatieres] = useState(null);
  const [matiere, setMatiere] = useState(null);
  const [formateur, setFormateur] = useState(null);
  const [actif, setActif] = useState(true);
  const [loaded, setLoaded] = useState(false);
  const [maj, setMaj] = useState(false);
  const [msg, setMsg] = useState(null);
  const [noteSelected, setNoteSelected] = useState(null);
  const [controleSelected, setControleSelected] = useState(undefined);
  const [eleveSelected, setEleveSelected] = useState(null);
  const [semestreSelected, setSemestreSelected] = useState(null);

  const contextValue = {
    promos,
    matieres,
    matiere,
    promoLoaded: promo,
    semestreSelected,
    formateur,
    controleSelected,
    setPromoLoaded: (e) => setPromo(e),
    setMatieres,
    setMatiere,
    setSemestreSelected,
    setFormateur,
    setNoteSelected,
    setEleveSelected,
    setMaj,
    setGlobalMsg: (e) => setMsg(e),
    autoCloseGlobal: (e) => autoCloseMsg(setMsg, e),
    setControleSelected,
  };

  useEffect(() => {
    if (user.role <= 3) {
      loadPromos();
    } else {
      loadFormateur();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user.role <= 3) {
      loadPromos();
    } else {
      formateur ? loadPromoFormateur(formateur["@id"]) : loadFormateur();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actif]);

  useEffect(() => {
    if (maj) {
      setMaj(false);
      if (user.role <= 3) {
        loadPromos();
      } else {
        loadPromoFormateur(formateur["@id"]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maj]);

  const loadFormateur = () => {
    let uri = url + "api/formateurs?user=" + user["@id"];
    GetBddProvider(uri).then((res) => {
      if (typeof res === "object") {
        setFormateur(res["hydra:member"][0]);
        loadPromoFormateur(res["hydra:member"][0]["@id"]);
      } else {
        setLoaded(true);
        setMsg({ txt: "Erreur de chargement", type: "danger" });
        autoCloseMsg(setMsg, 5000);
      }
    });
  };

  const loadPromoFormateur = (formateurId) => {
    let uri = url + "api/promos?formateurs=" + formateurId;
    // Ajout du filtre actif
    if (actif) {
      uri += "&actif=true";
    }
    GetBddProvider(uri).then((res) => {
      setLoaded(true);
      if (typeof res === "object") {
        let result = res["hydra:member"];
        if (result.length > 0) {
          let datas = result.filter((d) => d.semestres.length > 0);
          if (datas.length > 0) {
            setPromos(datas);
            if (promo === null) {
              setPromo(datas[0]);
            } else {
              let temp = datas.filter((e) => e["@id"] === promo["@id"]);
              setPromo(temp[0]);
            }
          } else {
            setMsg({
              txt: "Les semestres n'ont pas été initialisés",
              type: "warning",
            });
            autoCloseMsg(setMsg, 5000);
          }
        } else {
          setMsg({
            txt: "Aucune promo" + actif && " active",
            type: "warning",
          });
          autoCloseMsg(setMsg, 5000);
        }
      } else {
        setMsg({ txt: "Erreur de chargement", type: "danger" });
        autoCloseMsg(setMsg, 5000);
      }
    });
  };

  const loadPromos = () => {
    let uri = url + "api/promos?";
    user.sites.forEach((site, i) => {
      uri += user.sites.length > 1 ? "site[]=" : "site=";
      uri += site["@id"];
      uri += i + 1 < user.sites.length ? "&" : "";
    });
    if (actif) {
      uri += "&actif=true";
    }

    GetBddProvider(uri).then((res) => {
      setLoaded(true);
      if (typeof res === "object") {
        let result = res["hydra:member"];
        if (result.length > 0) {
          let datas = result.filter((d) => d.semestres.length > 0);
          if (datas.length > 0) {
            setPromos(datas);
            if (promo === null) {
              setPromo(datas[0]);
            } else {
              let temp = datas.filter((e) => e["@id"] === promo["@id"]);
              setPromo(temp[0]);
            }
          } else {
            setMsg({
              txt: "Les semestres n'ont pas été initialisés",
              type: "warning",
            });
            autoCloseMsg(setMsg, 5000);
          }
        } else {
          setMsg({
            txt: "Aucune promo" + actif && " active",
            type: "warning",
          });
          autoCloseMsg(setMsg, 5000);
        }
      } else {
        setMsg({ txt: "Erreur de chargement", type: "danger" });
        autoCloseMsg(setMsg, 5000);
      }
    });
  };

  return Layout(
    <div className="mt-4">
      <NotesContext.Provider value={contextValue}>
        <ModifNote n={noteSelected} el={eleveSelected} mat={matieres} />
        <EditNote eleve={eleveSelected} />
        {user.role <= 4 && <EditNotesAdmin />}
        <div className="d-flex flex-wrap justify-content-between mx-4">
          <h2>Liste des notes</h2>
          <div className="form-row align-items-center">
            <div className="form-check mx-4">
              <input
                type="checkbox"
                className="form-check-input"
                checked={actif || false}
                onChange={(e) => setActif(!actif)}
              />
              <label className="form-check-label">
                <strong>Promos actives uniquement</strong>
              </label>
            </div>
          </div>

          {user.role <= 4 ? (
            <button
              type="button"
              className="btn btn-info"
              data-toggle="modal"
              data-target="#ModalNoteAdmin"
            >
              <FontAwesomeIcon icon="plus-circle" /> Notes
            </button>
          ) : null}
        </div>

        <hr className="mx-4" />
        {msg !== null ? (
          <MsgAlert
            msg={msg.txt}
            type={msg.type}
            close={() => autoCloseMsg(setMsg)}
          />
        ) : null}
        {!loaded || promo === null ? (
          <Spinner />
        ) : (
          <div>
            {user.sites.map((site, i) => {
              let temp = promos.filter((p) => p.site["@id"] === site["@id"]);
              return <ListGestNotes promos={temp} site={site} key={i} />;
            })}
          </div>
        )}
      </NotesContext.Provider>
    </div>
  );
};

export default Notes;
