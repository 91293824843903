import React, { useContext, useState } from "react";
import AppContext from "../../Context/AppContext";

const PdfButton = ({ data, uri }) => {
  const { urlPdf } = useContext(AppContext);

  const [generate, setGenerate] = useState(false);

  const generatePDF = () => {
    let my_form = document.createElement("form");
    my_form.method = "POST";
    my_form.action = urlPdf + uri;
    my_form.target = "_blank";
    my_form.hidden = true;

    let my_tb = document.createElement("input");
    my_tb.type = "textarea";
    my_tb.name = "data";
    my_tb.hidden = true;
    my_tb.value = JSON.stringify(data);
    my_form.appendChild(my_tb);

    document.body.appendChild(my_form);
    my_form.submit();
    document.body.removeChild(my_form);
    setGenerate(false);
  };

  return generate ? (
    <button
      className="btn btn-sm btn-outline-warning"
      onClick={() => setGenerate(true)}
    >
      <span>
        chargement &nbsp;
        <div className="spinner-border spinner-border-sm" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </span>
    </button>
  ) : (
    <button
      className="btn btn-sm btn-success"
      onClick={() => {
        setGenerate(true);
        generatePDF();
      }}
    >
      PDF
    </button>
  );
};

export default PdfButton;
