import React from "react";

const SmallSpinner = () => {
  return (
    <div className="spinner-grow spinner-grow-sm" role="status">
      <span className="sr-only">Loading...</span>
    </div>
  );
};

export default SmallSpinner;
