import React from "react";
import { useState } from "react";
import { useContext } from "react";
import AppContext from "../../../Context/AppContext";
import Spinner from "../../common/Spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import GetBddProvider from "../../../Providers/GetBddProvider";
import PostBddProvider from "../../../Providers/PostBddProvider";
import MsgAlert from "../../common/MsgAlert";
import UpdateBddProvider from "../../../Providers/UpdateBddProvider";
import { autoCloseMsg } from "../../tools/messagesUtils";

const EditProgItems = ({ maj, selected, formations }) => {
  const [item, setitem] = useState(selected);
  const [loading, setloading] = useState(false);
  const [matieres, setmatieres] = useState(null);
  const [msg, setMsg] = useState(null);
  const [save, setsave] = useState(true);
  const { url } = useContext(AppContext);

  useEffect(() => {
    if (loading) {
      loadForm(item.formation);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  useEffect(() => {
    if (item !== selected) {
      setitem(selected);
      if (selected.id) {
        setmatieres(null);
        loadForm(selected.formation);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  const loadForm = (id) => {
    let uri = url + "api/matieres?formations=" + id;
    GetBddProvider(uri).then((res) => {
      if (typeof res === "object") {
        setmatieres(res["hydra:member"]);
        setloading(false);
      }
    });
  };

  const validate = () => {
    return item.name && item.formation && item.matiere;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setsave(false);
    let uri = url + "api/progression_items";
    if (item.id) {
      UpdateBddProvider(uri + "/" + item.id, item).then((res) => {
        setsave(true);
        if (typeof res === "object" && res.id) {
          setMsg({ text: "L'élément a bien été modifié", type: "success" });
          maj(true);
          autoCloseMsg(setMsg, 5000);
        } else {
          setMsg({ text: "Erreur lors de l'enregistrement", type: "danger" });
          autoCloseMsg(setMsg, 5000);
        }
      });
    } else {
      PostBddProvider(uri, item).then((res) => {
        setsave(true);
        if (typeof res === "object" && res.id) {
          setitem({ ...item, name: null });
          setMsg({ text: "L'élément a bien été enregistré", type: "success" });
          maj(true);
          autoCloseMsg(setMsg, 5000);
        } else {
          setMsg({ text: "Erreur lors de l'enregistrement", type: "danger" });
          autoCloseMsg(setMsg, 5000);
        }
      });
    }
  };

  return (
    <div
      className="modal fade"
      id="Modal"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <form onSubmit={handleSubmit}>
            <div className="modal-header bg-info text-white">
              <h5 className="modal-title">
                <FontAwesomeIcon icon="edit" /> &nbsp;{" "}
                {item.id ? "Modifier" : "Ajouter"} un élèment
              </h5>
              <button
                type="button"
                className="close text-white"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            {!save ? (
              <div className="pb-5 modal-body text-info">
                <Spinner />
              </div>
            ) : (
              <div className="modal-body">
                {msg !== null ? (
                  <div className="mx-4">
                    <MsgAlert
                      msg={msg.text}
                      type={msg.type}
                      close={() => autoCloseMsg(setMsg)}
                    />
                  </div>
                ) : null}
                <div className="form-group">
                  <label htmlFor="name">Nom de l'élèment</label>
                  <input
                    className="form-control"
                    type="text"
                    name="name"
                    autoComplete="off"
                    value={item.name || ""}
                    placeholder="Nom"
                    onChange={(e) =>
                      setitem({
                        ...item,
                        name: e.target.value,
                      })
                    }
                    required
                  />
                </div>

                <div className="form-group">
                  <label>Formation</label>
                  <select
                    name="selectFormation"
                    className="form-control"
                    value={item.formation || ""}
                    onChange={(e) => {
                      setloading(true);
                      setmatieres(null);
                      setitem({
                        ...item,
                        matiere: undefined,
                        formation: e.target.value,
                      });
                    }}
                    required
                  >
                    <option value="" disabled hidden>
                      Selectionnez une formation
                    </option>

                    {formations === null
                      ? null
                      : formations.map((formation, i) => {
                          return (
                            <option value={formation["@id"]} key={i}>
                              {formation.name}
                            </option>
                          );
                        })}
                  </select>
                </div>
                {item.formation === undefined ? null : matieres === null ? (
                  <Spinner />
                ) : (
                  <div className="form-group">
                    <label>Matière</label>
                    <select
                      name="selectMatiere"
                      className="form-control"
                      value={item.matiere || ""}
                      onChange={(e) =>
                        setitem({ ...item, matiere: e.target.value })
                      }
                      required
                    >
                      <option value="" disabled>
                        Selectionnez une matière
                      </option>
                      {matieres.map((matiere, i) => {
                        return (
                          <option value={matiere["@id"]} key={i}>
                            {matiere.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                )}
              </div>
            )}
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Fermer
              </button>
              <button
                type="submit"
                className="btn btn-info"
                disabled={!validate()}
              >
                Enregistrer
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditProgItems;
