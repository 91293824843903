import { useCallback, useEffect, useRef } from "react";
import GetBddProvider from "../Providers/GetBddProvider";

/**
 * hook use fetch GET
 * 
 * @returns GetBddProvider
 */
export default function useGet() {
  const abortController = useRef(new AbortController());

  useEffect(() => () => abortController.current.abort(), []);

  return useCallback(
    /**
     * Fetch -> GET data on BDD
     * 
     * @param {String} url API url
     * @param {Object} tk token (optionnal)
     * @param {String} galiaUser API url
     * @returns {Promise<JSON|Number>} the Fetch Result in JSON or Statu's error code
     */
    (url, tk = undefined, galiaUser = undefined) =>
      GetBddProvider(url, tk, galiaUser, abortController.current.signal),
    []
  );
}
