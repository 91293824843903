import { useCallback } from "react";
import GenPass from "../Components/GenPass";

function useGenPwd(url, PostBDD) {

  return useCallback(async (user) => {
    let uri = url + "genpass";
    let newPass = GenPass();
    let data = {
      username: user.pseudo,
      email: user.email,
      pwd: newPass,
    };
    const res = await PostBDD(uri, data);
    return res
  }, [PostBDD, url]);
}

export default useGenPwd;
