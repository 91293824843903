import React from "react";
import Layout from "../common/Layout";
import { useContext } from "react";
import AppContext from "../../Context/AppContext";
import { useState } from "react";
import GetBddProvider from "../../Providers/GetBddProvider";
import { useEffect } from "react";
import Spinner from "../common/Spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import EditProgItems from "../contents/edits/EditProgItems";
import DeleteBddProvider from "../../Providers/DeleteBddProvider";
import MsgAlert from "../common/MsgAlert";
import { autoCloseMsg } from "../tools/messagesUtils";

const ProgessionItems = () => {
  const { url } = useContext(AppContext);
  const [loaded, setloaded] = useState(false);
  const [items, setitems] = useState(null);
  const [maj, setmaj] = useState(false);
  const [msg, setMsg] = useState(null);
  const [selected, setselected] = useState({});
  const [formations, setformations] = useState(null);

  const load = () => {
    let uri = url + "api/progression_items";
    GetBddProvider(uri).then((res) => {
      if (typeof res === "object") {
        setitems(res["hydra:member"]);
        uri = url + "api/formations";
        GetBddProvider(uri).then((res) => {
          if (typeof res === "object") {
            setformations(res["hydra:member"]);
            setloaded(true);
          }
        });
      }
    });
  };

  useEffect(() => {
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (maj) {
      setmaj(false);
      setloaded(false);
      load();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maj]);

  const delProg = (iri) => {
    let uri = url + iri.slice(1);
    
    DeleteBddProvider(uri).then((res) => {
      if (res === 204) {
        setmaj(true);
        setMsg({
          txt: "l'élément de progression a bien été supprimé !",
          type: "success",
        });
        autoCloseMsg(setMsg, 5000);
      } else {
        setMsg({ txt: "Erreur de suppression !", type: "danger" });
        autoCloseMsg(setMsg, 5000);
      }
    });
  };

  const ListProg = ({ matiere, formation }) => {
    return (
      <div
        className="card align-self-start shadow border-info col-md-4 p-0 m-1"
        style={{ maxWidth: "20rem" }}
      >
        <div
          className="card-header text-info d-flex justify-content-between"
          style={{ minHeight: "180px" }}
          id={"headingEl" + matiere.id + "form" + formation.id}
        >
          <button
            className="btn btn-block pb-0"
            type="button"
            data-toggle="collapse"
            data-target={"#collapseEl" + matiere.id + "form" + formation.id}
            aria-expanded={false}
            aria-controls={"collapseEl" + matiere.id + "form" + formation.id}
          >
            <h5 className="m-0">{matiere.name}</h5>
            <FontAwesomeIcon icon="chevron-down" />
          </button>
        </div>

        <div
          className="card-body text-info collapse"
          id={"collapseEl" + matiere.id + "form" + formation.id}
          aria-labelledby={"headingEl" + matiere.id + "form" + formation.id}
          data-parent={"#accordionListItemsProg" + formation.id}
        >
          <ul className="list-group list-group-flush text-left">
            {items.map((item, i) => {
              if (
                item.formation === formation["@id"] &&
                item.matiere === matiere["@id"]
              ) {
                return (
                  <li
                    className="list-group-item d-flex justify-content-between"
                    key={i}
                  >
                    <div>{item.name}</div>
                    <div>
                      <div>
                        <FontAwesomeIcon
                          className="text-info"
                          style={{ cursor: "pointer" }}
                          title="modifier"
                          data-toggle="modal"
                          data-target="#Modal"
                          onClick={(e) =>
                            setselected(JSON.parse(JSON.stringify(item)))
                          }
                          icon="edit"
                        />
                      </div>
                      <div>
                        <FontAwesomeIcon
                          className="text-danger"
                          style={{ cursor: "pointer" }}
                          icon="trash-alt"
                          onClick={() => {
                            if (window.confirm("Supprimer la progression ?")) {
                              delProg(item["@id"]);
                            }
                          }}
                        />
                      </div>
                    </div>
                  </li>
                );
              } else {
                return null;
              }
            })}
          </ul>
        </div>
      </div>
    );
  };

  const List = ({ formation }) => {
    return (
      <div id={formation.name.replace(/ /g, "")}>
        <h2>{formation.name}</h2>
        <div
          className="d-flex flex-wrap justify-content-center"
          id={"accordionListItemsProg" + formation.id}
        >
          {formation.matieres.map((matiere, i) =>
            matiere.name !== "Travail Personnel" &&
            matiere.name !== "Comportement et participation" ? (
              <ListProg matiere={matiere} formation={formation} key={i} />
            ) : null
          )}
        </div>
        <hr className="m-4" />
      </div>
    );
  };

  return Layout(
    <div className="mt-4">
      {msg !== null ? (
        <MsgAlert msg={msg.txt} type={msg.type} close={() => autoCloseMsg(setMsg)} />
      ) : null}
      <span className="d-flex flex-wrap justify-content-md-between justify-content-center m-3">
        <div></div>
        <h2 className="m-0">
          Liste des éléments des progressions pédagogiques
        </h2>
        <button
          className="btn btn-info"
          type="button"
          data-toggle="modal"
          data-target="#Modal"
          onClick={(e) => setselected({})}
        >
          <FontAwesomeIcon icon="plus-circle" /> &nbsp; Ajouter
        </button>
      </span>
      <EditProgItems maj={setmaj} selected={selected} formations={formations} />

      {!loaded ? (
        <Spinner />
      ) : formations === null ? null : (
        <div>
          {formations.map((el, i) => (
            <a
              className="btn btn-outline-info mx-1"
              href={"#" + el.name.replace(/ /g, "")}
              key={i}
            >
              {el.name}
            </a>
          ))}
          <hr className="m-4" />
          {formations.map((formation, i) => (
            <List formation={formation} key={i} />
          ))}
        </div>
      )}
    </div>
  );
};

export default ProgessionItems;
