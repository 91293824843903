import React, { useState, useContext, useEffect } from "react";
import Spinner from "../../common/Spinner";
import GetBddProvider from "../../../Providers/GetBddProvider";
import AppContext from "../../../Context/AppContext";
import PostBddProvider from "../../../Providers/PostBddProvider";
import MsgAlert from "../../common/MsgAlert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UpdateBddProvider from "../../../Providers/UpdateBddProvider";
import { autoCloseMsg } from "../../tools/messagesUtils";

const EditPromo = ({ maj,sites, selected }) => {
  const { url } = useContext(AppContext);

  const [formations, setformations] = useState(null);
  const [promo, setpromo] = useState(selected);
  const [save, setsave] = useState(true);
  const [msg, setMsg] = useState(null);

  if (promo.site !== undefined && typeof promo.site === "object") {
    setpromo({ ...promo, site: promo.site["@id"] });
  }
  if (promo.formation !== undefined && typeof promo.formation === "object") {
    setpromo({ ...promo, formation: promo.formation["@id"] });
  }
  if (promo.eleves !== undefined) {
    promo.eleves.forEach((el, i) => {
      if (typeof el === "object") {
        promo.eleves[i] = el["@id"];
      }
    });
  }
  if (promo.formateurs !== undefined) {
    promo.formateurs.forEach((el, i) => {
      if (typeof el === "object") {
        promo.formateurs[i] = el["@id"];
      }
    });
  }
  if (promo.semestres !== undefined) {
    promo.semestres.forEach((el, i) => {
      if (typeof el === "object") {
        promo.semestres[i] = el["@id"];
      }
    });
  }

  useEffect(() => {
    GetBddProvider(url + "api/formations").then((res) => {
      if (typeof res === "object") {
        setformations(res["hydra:member"]);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (promo !== selected) {
      setpromo(selected);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  const validateForm = () => {
    return promo.name && promo.formation && promo.site;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setsave(false);
    if (promo.id) {
      let uri = url + "api/promos/" + promo.id;
      UpdateBddProvider(uri, promo).then((res) => {
        setsave(true);

        if (typeof res === "object" && res.id) {
          setMsg({ text: "la promo à bien été mise à jour", type: "success" });
          maj(true);
          autoCloseMsg(setMsg, 5000);;
        } else {
          setMsg({ text: "erreur", type: "danger" });
          autoCloseMsg(setMsg, 5000);;
        }
      });
    } else {
      let uri = url + "api/promos";

      PostBddProvider(uri, promo).then((res) => {
        setsave(true);

        if (typeof res === "object" && res.id) {
          setpromo({ actif: true });
          setMsg({
            text:
              "la promo à bien été enregistrée, pensez a initialiser les semestres dans le menu gestion des semestres",
            type: "success",
          });
          maj(true);
          autoCloseMsg(setMsg, 5000);;
        } else {
          setMsg({ text: "erreur", type: "danger" });
          autoCloseMsg(setMsg, 5000);;
        }
      });
    }
  };

  const formulaire = () => {
    return (
      <form>
        <div className="modal-header bg-info text-white">
          <h5 className="modal-title">
            <FontAwesomeIcon icon="edit" /> &nbsp;{" "}
            {promo.id ? "Modification" : "Création"} d'une promo
          </h5>
          <button
            type="button"
            className="close text-white"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        {save === false ? (
          <div className="pb-5 modal-body text-info ">
            <Spinner />
          </div>
        ) : (
          <div className="modal-body">
            {msg !== null ? (
              <div className="mx-4">
                <MsgAlert msg={msg.text} type={msg.type} close={() => autoCloseMsg(setMsg)} />
              </div>
            ) : null}
            <div className="form-group">
              <label htmlFor="name">Nom de la promo</label>
              <input
                className="form-control"
                type="text"
                id="name"
                value={promo.name || ""}
                placeholder="Nom de la promo"
                onChange={(e) => setpromo({ ...promo, name: e.target.value })}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="selectSite">Site</label>
              <select
                className="form-control"
                value={promo.site !== undefined ? promo.site : ""}
                id="selectSite"
                onChange={(e) => {
                  setpromo({ ...promo, site: e.target.value });
                }}
                required
              >
                <option value="" disabled>
                  Selectionnez un site
                </option>
                {sites.map((data, i) => {
                  return (
                    <option value={data["@id"]} key={i}>
                      {data.name}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="selectFormation">formation</label>
              <select
                id="selectFormation"
                className="form-control"
                value={promo.formation !== undefined ? promo.formation : ""}
                onChange={(e) =>
                  setpromo({ ...promo, formation: e.target.value })
                }
                required
              >
                <option value="" disabled>
                  Selectionnez une formation
                </option>
                {formations.map((data, i) => {
                  return (
                    <option value={data["@id"]} key={i}>
                      {data.name}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="custom-control custom-switch">
              <input
                type="checkbox"
                className="custom-control-input"
                id="chActif"
                checked={promo.actif || false}
                onChange={(e) => setpromo({ ...promo, actif: !promo.actif })}
              />
              <label className="custom-control-label" htmlFor="chActif">
                Actif
              </label>
            </div>
          </div>
        )}
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-secondary"
            data-dismiss="modal"
          >
            Fermer
          </button>
          <button
            type="submit"
            className="btn btn-info"
            onClick={handleSubmit}
            disabled={!validateForm()}
          >
            Enregistrer
          </button>
        </div>
      </form>
    );
  };

  return (
    <div
      className="modal fade"
      id="Modal"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          {sites === null || formations === null ? (
            <Spinner />
          ) : (
            formulaire()
          )}
        </div>
      </div>
    </div>
  );
};

export default EditPromo;
