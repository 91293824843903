function GenPass(l = 8) {
  /* c : chaîne de caractères alphanumérique */
  let c = "abcdefghijknopqrstuvwxyzACDEFGHJKLMNPQRSTUVWXYZ12345679",
    n = c.length,
    /* p : chaîne de caractères spéciaux */
    p = "!@#$+-*&_",
    o = p.length,
    r = "",
    /* s : determine la position du caractère spécial dans le mdp */
    s = Math.floor(Math.random() * l);

  for (var i = 0; i < l; ++i) {
    s === i
      ? /* on insère à la position donnée un caractère spécial aléatoire */
        (r += p.charAt(Math.floor(Math.random() * o)))
      : /* on insère un caractère alphanumérique aléatoire */
        (r += c.charAt(Math.floor(Math.random() * n)));
  }
  return r;
}
export default GenPass;
