import React from "react";
import { NavLink } from "react-router-dom";

const List = ({ datas, title, nav = false, actif = false, promo = null }) => {
  return (
    <div
      className="card shadow border-info col-lg-3 p-0 m-3 animFadeIn"
      style={{ minWidth: "20rem" }}
    >
      <div className="card-header">
        <h5>{title}</h5>
      </div>
      <div className="card-body text-info">
        <ul className="list-group list-group-flush">
          {datas
            .filter((e) => e.user.actif)
            .map((el, i, tab) => {
              let elName = el.user
                ? el.user.name + " " + el.user.firstName
                : el.name + " " + el.firstName;
              let state = {
                id: el.id,
                list: tab,
              };
              if (promo !== null) {
                state = { ...state, promo };
              }
              return actif && !el.user.actif ? null : (
                <li className="list-group-item text-left" key={i}>
                  <span className="d-block">
                    {el.actif === undefined ? null : el.actif ? (
                      <span className="badge badge-pill badge-success m-1 text-success">
                        I
                      </span>
                    ) : (
                      <span className="badge badge-pill badge-secondary m-1 text-secondary">
                        I
                      </span>
                    )}{" "}
                    {!nav ? (
                      elName
                    ) : (
                      <NavLink
                        className="text-info"
                        to={{
                          pathname: "/" + nav,
                          state: state,
                        }}
                      >
                        {elName}
                      </NavLink>
                    )}
                  </span>
                  {nav === "eleve" && (
                    <span className="d-block text-primary">
                      <em>{el.user.pseudo}</em>
                    </span>
                  )}
                </li>
              );
            })}
        </ul>
      </div>
    </div>
  );
};

export default List;
