import React, { useContext, useEffect, useState } from "react";
import AppContext from "../../../Context/AppContext";
import GetBddProvider from "../../../Providers/GetBddProvider";
import PostBddProvider from "../../../Providers/PostBddProvider";
import UpdateBddProvider from "../../../Providers/UpdateBddProvider";
import SmallSpinner from "../../common/SmallSpinner";
import { Motifs } from "../../Motifs";
import SaveAbsGalia from "../syncGalia/SaveAbsGalia";

const AbsElevesEdusign = ({
  eleve,
  token,
  student,
  seance,
  connector,
  promos,
  periode,
  duree,
  absencesNocx,
  cours,
  setMaj,
}) => {
  const { user, url, urlEdusign, connectors } = useContext(AppContext);

  const _DATE_COURS = cours.START.split("T")[0];

  // Chercher les absences déjà saisie.

  const [motif, setMotif] = useState("");
  const [motifGalia, setMotifGalia] = useState(null);
  const [etudiant, setEtudiant] = useState(eleve);
  const [eleveEdusign, setEleveEdusign] = useState(null);
  const [justify, setJustify] = useState(false);
  const [prevent, setPrevent] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [verif, setVerif] = useState([]);
  const [promo, setPromo] = useState(null);
  const [errorSync, setErrorSync] = useState("Elève non synchronisé ");
  const [comment, setComment] = useState("");
  const [saved, setSaved] = useState(true);
  const [semestres, setSemestres] = useState(null);
  const [semestre, setSemestre] = useState(null);

  const _MOTIFS = [
    "Retenu(e) en entreprise",
    "Arrêt de travail",
    "Motif personnel",
    "Congé payé",
    "Examen",
    "Cours non obligatoire",
    "COVID",
    "Accident du travail",
    "Problème de connexion",
  ];

  const _MOTIFEDUSIGN = {
    1: _MOTIFS[1],
    2: _MOTIFS[2],
    3: _MOTIFS[2],
    4: _MOTIFS[2],
    5: _MOTIFS[2],
    6: _MOTIFS[0],
    7: _MOTIFS[2],
  };

  useEffect(() => {
    setComment(student.comment || "");
    if (student.absenceId) {
      loadAbsJust();
    }
    if (seance) {
      loadStudent();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (etudiant !== undefined && etudiant !== false) {
      setVerif(
        absencesNocx.filter(
          (a) =>
            a.eleve["@id"] === etudiant["@id"] &&
            a.moment === periode &&
            a.createdAt.split("T")[0] === _DATE_COURS
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [absencesNocx]);

  useEffect(() => {
    if (etudiant !== undefined) {
      if (etudiant === false) {
        setLoaded(true);
      } else {
        affectPromo();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [etudiant]);

  const affectPromo = () => {
    if (promos !== null) {
      promos.forEach((p) => {
        etudiant.promos.forEach((ep) => {
          if (p["@id"] === ep) {
            let sem = p.semestres;
            setSemestres(sem);
            setSemestre(
              sem.filter((e) => e.actif).length > 0
                ? sem.filter((e) => e.actif)[0]["@id"]
                : ""
            );
            setPromo(p);
          }
        });
      });
    }
    setVerif(
      absencesNocx.filter(
        (a) =>
          a.eleve["@id"] === etudiant["@id"] &&
          a.moment === periode &&
          a.createdAt.split("T")[0] === _DATE_COURS
      )
    );
    setLoaded(true);
  };

  const loadAbsJust = async () => {
    let uri = urlEdusign.ABSENCES + student.absenceId;
    await GetBddProvider(uri, token).then((res) => {
      if (typeof res === "object") {
        let result = res.result;
        if (result !== undefined) {
          if (result.TYPE) {
            let tempMotifGalia =
              result.TYPE > 199
                ? Motifs.filter((m) => m.typeEdusign === result.TYPE)[0]
                : null;
            let tempMotif =
              tempMotifGalia !== null
                ? tempMotifGalia.nom
                : _MOTIFEDUSIGN[result.TYPE];
            setJustify(true);
            setMotif(tempMotif);
            setMotifGalia(tempMotifGalia);
          }
          setComment(result.COMMENT);
        }
      } else {
        // Message d'erreur
      }
    });
  };

  const loadStudent = async () => {
    let uri = urlEdusign.STUDENTS + "/" + student.studentId;
    let tmpEleve = "";
    await GetBddProvider(uri, token).then((res) => {
      if (typeof res === "object") {
        let result = res.result;
        if (result !== undefined) {
          tmpEleve = result;
          setEleveEdusign(result);
        }
      } else {
        setErrorSync("Erreur etudiant Edusign");
        setLoaded(true);
      }
    });
    let tmpEtudiant;
    if (tmpEleve.API_ID) {
      let uriEleve = `${url}api/eleves?`;
      if (connector === connectors.V1) {
        uriEleve += `idGalia=${tmpEleve.API_ID}`;
      }
      if (connector === connectors.V2) {
        uriEleve += `idStagiaire=${tmpEleve.API_ID}`;
      }
      await GetBddProvider(uriEleve).then((res) => {
        if (typeof res === "object" && res["hydra:member"].length > 0) {
          tmpEtudiant = res["hydra:member"].reverse()[0];
        } else {
          tmpEtudiant = false;
          setEleveEdusign(tmpEleve);
          setErrorSync("Elève non synchronisé dans NOC'x ");
          setLoaded(true);
        }
      });
    } else {
      tmpEtudiant = false;
      setEleveEdusign(tmpEleve);
      setErrorSync("Elève non créé dans Galia ");
      setLoaded(true);
    }

    if (tmpEtudiant !== false) {
      let tmpPromos = [];
      for (const p of tmpEtudiant.promos) {
        let uriPromo = url + p.slice(1);
        await GetBddProvider(uriPromo).then((res) => {
          if (typeof res === "object") {
            tmpPromos.push(res);
          }
        });
      }
      let tmpPromo = tmpPromos.filter((p) => p.actif)[0];
      let sem = tmpPromo.semestres;
      setSemestres(sem);
      setSemestre(
        sem.filter((e) => e.actif).length > 0
          ? sem.filter((e) => e.actif)[0]["@id"]
          : ""
      );
      setPromo(tmpPromo);
    }
    setEtudiant(tmpEtudiant);
  };

  const updateAbs = async (abs) => {
    setSaved(false);
    let data = { IdEdusign: cours.ID, time: duree, user: user["@id"] };
    let uri = url + abs["@id"].slice(1);

    if (seance && etudiant.idGalia && !abs.saveGalia) {
      let result = await SaveAbsGalia(
        seance,
        etudiant.idGalia,
        motifGalia ? motifGalia.IDTypAbsen : 9,
        promo.site.idGaliaSociete
      );
      if (result.ok) {
        data = { ...data, saveGalia: true };
      } else {
        // message d'erreur
      }
    }

    UpdateBddProvider(uri, data).then((res) => {
      setSaved(true);
      if (typeof res === "object" && res.id) {
        setMaj(true);
      } else {
        // message erreur
      }
    });
  };

  const saveAbs = () => {
    setSaved(false);
    let uri = url + "api/absences";
    let data = {
      IdEdusign: cours.ID,
      createdAt: _DATE_COURS,
      eleve: etudiant["@id"],
      motif: motif,
      semestre: semestre,
      promo: promo["@id"],
      time: duree,
      moment: periode,
      justify: justify,
      prev: prevent,
      comment: comment,
      rattrapage: false,
      user: user["@id"],
    };
    let uriTest =
      url +
      "api/absences?eleve=" +
      data.eleve +
      "&createdAt[after]=" +
      data.createdAt +
      "&createdAt[before]=" +
      data.createdAt +
      "&moment=" +
      data.moment +
      "&rattrapage=" +
      data.rattrapage;

    GetBddProvider(uriTest).then(async (res) => {
      if (res["hydra:member"].length !== 0) {
        setSaved(true);
        setMaj(true);
      } else {
        if (seance && etudiant.idGalia) {
          let result = await SaveAbsGalia(
            seance,
            etudiant.idGalia,
            motifGalia ? motifGalia.IDTypAbsen : 9,
            promo.site.idGaliaSociete
          );
          if (result.ok) {
            data = { ...data, saveGalia: true };
          } else {
            // message d'erreur
          }
        }
        PostBddProvider(uri, data).then((res) => {
          setSaved(true);
          if (typeof res === "object" && res.id) {
            setMaj(true);
          } else {
            // mesage d'erreur
          }
        });
      }
    });
  };

  return !loaded ? (
    <li className="list-group-item text-center">
      <SmallSpinner />
    </li>
  ) : !etudiant ? (
    <li className="list-group-item list-group-item-warning">
      {errorSync}({eleveEdusign.LASTNAME} {eleveEdusign.FIRSTNAME})
    </li>
  ) : verif.length > 0 ? (
    verif[0].IdEdusign ? (
      <li className="list-group-item list-group-item-success ">
        {etudiant.user.name} {etudiant.user.firstName} -{" "}
        {verif[0].motif === "" ? "Pas de motif" : verif[0].motif} - L'absence
        est synchronisée !
      </li>
    ) : (
      <li className="list-group-item text-warning ">
        <button
          className="btn btn-sm rounded-pill btn-outline-info mr-4"
          onClick={() => updateAbs(verif[0])}
          disabled={!saved}
        >
          {saved ? "Synchroniser l'absence" : <SmallSpinner />}
        </button>
        {etudiant.user.name} {etudiant.user.firstName} -{" "}
        {verif[0].motif === "" ? "Pas de motif" : verif[0].motif}
      </li>
    )
  ) : (
    <li className="list-group-item form-inline d-flex">
      <div>
        <label className="col-form-label">
          {etudiant.user.name} {etudiant.user.firstName}
        </label>
      </div>
      <div className="form-group ml-3 mr-1">
        <select
          value={semestre}
          className="form-control rounded-pill"
          onChange={(e) => setSemestre(e.target.value)}
        >
          <option value="" disabled>
            Choisir un semestre
          </option>
          {semestres.map((s) => (
            <option value={s["@id"]} key={s["@id"]}>
              {s.name}
            </option>
          ))}
        </select>
      </div>
      <div className="form-group mx-1">
        <select
          value={motif}
          className="form-control rounded-pill"
          onChange={(e) => setMotif(e.target.value)}
        >
          <option value="">Pas de motif</option>
          {seance
            ? Motifs.map((m) => (
                <option value={m.nom} key={m.typeEdusign}>
                  {m.nom}
                </option>
              ))
            : _MOTIFS.map((m, i) => (
                <option value={m} key={i}>
                  {m}
                </option>
              ))}
        </select>
      </div>
      <div className="form-group mx-1">
        <input
          className="form-control rounded-pill"
          type="text"
          placeholder="Commentaire"
          value={comment || ""}
          onChange={(e) => setComment(e.target.value)}
        />
      </div>
      <div className="form-group mx-1">
        <div className="form-check">
          <input
            id={etudiant["@id"] + "-" + cours.ID + "-justify"}
            type="checkbox"
            className="form-check-input"
            checked={justify}
            onChange={() => setJustify(!justify)}
          />
          <label htmlFor={etudiant["@id"] + "-" + cours.ID + "-justify"}>
            Justifiée
          </label>
        </div>
      </div>
      <div className="form-group ml-1 mr-3">
        <div className="form-check">
          <input
            id={etudiant["@id"] + "-" + cours.ID + "-prevent"}
            type="checkbox"
            className="form-check-input"
            checked={prevent}
            onChange={() => setPrevent(!prevent)}
          />
          <label htmlFor={etudiant["@id"] + "-" + cours.ID + "-prevent"}>
            Prévenue
          </label>
        </div>
      </div>
      <div>
        <button
          className="btn btn-sm rounded-pill btn-outline-info"
          disabled={semestre === "" || !saved}
          onClick={() => saveAbs()}
        >
          {saved ? "Enregistrer" : <SmallSpinner />}
        </button>
      </div>
    </li>
  );
};

export default AbsElevesEdusign;
