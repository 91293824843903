import React, { useContext, useEffect, useState } from "react";
import AppContext from "../../../Context/AppContext";
import GetBddProvider from "../../../Providers/GetBddProvider";
import MsgAlert from "../../common/MsgAlert";
import SmallSpinner from "../../common/SmallSpinner";
import EleveItem from "./EleveItem";
import { triByUserName } from "../../tools/sortUtils";

const SyncEleves = ({ promo, groups, group, tokenEdusign }) => {
  const { url } = useContext(AppContext);

  const [eleves, setEleves] = useState(null);
  const [maj, setMaj] = useState(false);
  const [children, setChildren] = useState([]);

  useEffect(() => {
    if (promo !== null) {
      setEleves(null);
      loadEleves();
      setChildren(groups.filter((g) => g.PARENT === group.ID));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promo]);

  useEffect(() => {
    if (maj) {
      loadEleves();
      setMaj(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maj]);

  const loadEleves = () => {
    let uri = url + "api/eleves?promos=" + promo["@id"];
    GetBddProvider(uri).then((res) => {
      if (typeof res === "object") {
        setEleves(res["hydra:member"]);
      } else {
        // message d'erreur
      }
    });
  };

  return (
    <div className="card text-left" style={{ minHeight: "100%" }}>
      <div className="card-header">
        <h5 className="card-title text-center">
          Synchronisation des élèves {promo ? promo.name : null}
        </h5>
      </div>
      <div className="card-body p-0">
        {!promo || !group ? (
          <MsgAlert
            msg="Veuillez selectionner une promo synchronisée"
            type="info"
          />
        ) : !eleves ? (
          <div className="text-center text-info">
            <SmallSpinner />
          </div>
        ) : (
          <ul className="list-group list-group-flush">
            {group.STUDENTS.map((student) => (
              <EleveItem
                student={student}
                elevesList={eleves.sort(triByUserName)}
                token={tokenEdusign}
                maj={setMaj}
                key={student}
              />
            ))}
            {children.length > 0 &&
              children.map((c) => (
                <React.Fragment key={c.ID}>
                  <li className="list-group-item text-primary text-center bg-light">
                    Sous groupe {c.NAME}
                  </li>
                  {c.STUDENTS.map((s) => (
                    <EleveItem
                      student={s}
                      elevesList={eleves.sort(triByUserName)}
                      token={tokenEdusign}
                      maj={setMaj}
                      key={s}
                    />
                  ))}
                </React.Fragment>
              ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default SyncEleves;
