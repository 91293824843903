import React from "react";

export default React.createContext({
  url: "",
  urlPdf: "",
  appName: "",
  su: [],
  connectors:{
    V1: String,
    V2: String
  },
  betaUsers: [],
  urlEdusign: {},
  galiaUser: "",
  user: {},
  setUser: val => { },
});
