import React, { useContext, useEffect, useState } from "react";
import AppContext from "../../Context/AppContext";
import useGet from "../../hooks/useGet";
import Layout from "../common/Layout";
import MsgAlert from "../common/MsgAlert";
import Spinner from "../common/Spinner";
import ListExportAllPromos from "../contents/lists/ListExportAllPromos";
import { autoCloseMsg } from "../tools/messagesUtils";
import { triByName } from "../tools/sortUtils";

export default function ExportEleves() {
  const { user, url } = useContext(AppContext);

  const getBdd = useGet();

  const [siteSelected, setSiteSelected] = useState(null);
  const [sites, setSites] = useState(user.sites);

  const [promoSelected, setPromoSelected] = useState(null);
  const [promos, setPromos] = useState([]);
  const [promosloaded, setPromosloaded] = useState(false);

  const [generate, setGenerate] = useState(false);

  const [actif, setActif] = useState(true);
  const [eleveActif, setEleveActif] = useState(true);

  const [loaded, setloaded] = useState(false);

  const [msg, setMsg] = useState(null);

  useEffect(() => {
    loadSites();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (siteSelected !== null) {
      setPromosloaded(false);
      setGenerate(false);
      setPromoSelected(null);
      loadPromos();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteSelected, actif]);

  useEffect(() => {
    setGenerate(false);
  }, [promoSelected]);

  const loadSites = () => {
    if (user.role === 1) {
      let uri = `${url}api/sites`;
      getBdd(uri).then((res) => {
        if (typeof res === "object") {
          let result = res["hydra:member"];
          setSites(result);
          setloaded(true);
        } else {
          setMsg({
            txt: "Erreur de chargement des sites",
            type: "danger",
          });
          autoCloseMsg(setMsg, 5000);
        }
      });
    } else {
      setSites(user.sites);
      if (user.sites.length === 1) {
        setSiteSelected(user.sites[0]["@id"]);
      }
      setloaded(true);
    }
  };

  const loadPromos = () => {
    let uri = `${url}api/promos?site=${siteSelected}`;
    if (actif) {
      uri += `&actif=${actif}`;
    }
    getBdd(uri).then((res) => {
      if (typeof res === "object") {
        setPromos(res["hydra:member"]);
        setPromosloaded(true);
      } else {
        setMsg({
          txt: "Erreur de chargement des promos",
          type: "danger",
        });
        autoCloseMsg(setMsg, 5000);
      }
    });
  };

  const select = (setter, value) => {
    setGenerate(false);
    setter(value);
  };

  const launchGenerate = () => {
    setGenerate(true);
  };

  return Layout(
    <div className="m-4">
      <h2>Export des élèves</h2>
      <hr />
      {!loaded ? (
        <Spinner />
      ) : (
        <>
          <div className="ml-4 form-group">
            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                checked={actif || false}
                onChange={(e) => setActif(!actif)}
              />
              <label className="form-check-label">
                <strong>Promos actives uniquement</strong>
              </label>
            </div>
          </div>
          <div className="text-center d-md-flex justify-content-md-around align-items-center">
            <div className="form-group">
              <label htmlFor="choixSite">Site</label>
              <select
                className="form-control w-auto m-auto"
                id="choixSite"
                value={siteSelected || ""}
                onChange={(e) => select(setSiteSelected, e.target.value)}
              >
                <option value="" disabled>
                  Choix du site
                </option>
                {sites.sort(triByName).map((s) => (
                  <option value={s["@id"]} key={s["@id"]}>
                    {s.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="choixPromo">Promo</label>
              <select
                className="form-control w-auto m-auto"
                name="choixPromo"
                value={promoSelected || ""}
                onChange={(e) => select(setPromoSelected, e.target.value)}
                disabled={!promosloaded}
              >
                <option value="" disabled>
                  Choix de la promo
                </option>
                <option value="ALL">Toutes les promos</option>
                {promos.sort(triByName).map((p) => (
                  <option value={p["@id"]} key={p["@id"]}>
                    {p.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group form-check">
              <input
                type="checkbox"
                className="form-check-input"
                checked={eleveActif || false}
                onChange={() => select(setEleveActif, !eleveActif)}
              />
              <label className="form-check-label">
                <strong>Elèves actives uniquement</strong>
              </label>
            </div>
            <div className="form-group">
              <button
                className="btn btn-info"
                onClick={launchGenerate}
                disabled={promoSelected === null}
              >
                Générer
              </button>
            </div>
          </div>
          <hr />
        </>
      )}
      {msg && (
        <MsgAlert
          msg={msg.txt}
          type={msg.type}
          close={() => autoCloseMsg(setMsg)}
        />
      )}
      {generate && (
        <ListExportAllPromos
          promos={
            promoSelected === "ALL"
              ? promos
              : promos.filter((p) => p["@id"] === promoSelected)
          }
          eleveActif={eleveActif}
          setMsg={setMsg}
        />
      )}
    </div>
  );
}
