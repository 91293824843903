import { StorageService } from "../Components/tools/Storage/StorageService";

/**
 * custom FETCH method for NOC'x
 *
 * @param {String} url
 * @param {Object} options
 * @param {String} tk
 * @param {String} method
 * @returns Promise - the Fetch Result in JSON or Statu's error code
 */
export default async function BDDProvider(
  url,
  options,
  tk,
  method,
  galiaUser = undefined
) {
  try {
    const response = await fetch(url, options);

    if (galiaUser !== undefined && response.status === 204) {
      return [];
    }

    if (galiaUser !== undefined && method === "POST") {
      return response;
    }

    if (method !== "DELETE" && response.ok) {
      return response.json();
    } else if (response.status === 401 && tk === undefined) {
      if (StorageService.getItem("token")) {
        StorageService.clear();
        window.location.reload();
      }
    } else {
      return method !== "DELETE" ? response.json() : response.status;
    }
  } catch (e) {
    if (e instanceof DOMException && e.name === "AbortError") {
      return 666;
    }
  }
}
