import React, { useContext, useState } from "react";
import { useEffect } from "react";
import AppContext from "../../../Context/AppContext";
import GetBddProvider from "../../../Providers/GetBddProvider";
import PostBddProvider from "../../../Providers/PostBddProvider";
import MsgAlert from "../../common/MsgAlert";
import Spinner from "../../common/Spinner";
import { autoCloseMsg } from "../../tools/messagesUtils";
import { triByUserName } from "../../tools/sortUtils";

const EditAbsencesAdmin = ({ setMaj, promos }) => {
  const { url, user } = useContext(AppContext);

  const [msg, setMsg] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [time, setTime] = useState(["04:00"]);
  const [moment, setMoment] = useState(null);
  const [eleves, setEleves] = useState(null);
  const [promo, setPromo] = useState(null);
  const [promoSelected, setPromoSelected] = useState(null);
  const [semestres, setSemestres] = useState(null);
  const [absence, setAbsence] = useState({
    createdAt: new Date().toISOString().split("T")[0],
    motif: "",
    comment: "",
    justify: false,
    rattrapage: false,
    prev: user.role <= 3 ? true : false,
    semestre: null,
    user: user["@id"],
  });

  useEffect(() => {
    if (moment !== null) {
      if (moment === "all") {
        setTime([
          { moment: "Matin", time: "04:00" },
          { moment: "Après-midi", time: "04:00" },
        ]);
      } else {
        setTime(["04:00"]);
      }
    }
  }, [moment]);

  useEffect(() => {
    if (promos !== null) {
      setLoaded(true);
    }
  }, [promos]);

  useEffect(() => {
    if (promo !== null) {
      setPromoSelected(null);
      loadPromo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promo]);

  useEffect(() => {
    if (promoSelected !== null) {
      init();
      loadEleves();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promoSelected]);

  const loadPromo = () => {
    promos.forEach((p) => {
      if (p["@id"] === promo) {
        setPromoSelected(p);
      }
    });
  };

  const loadEleves = () => {
    setLoaded(false);
    setEleves(null);
    let uri = url + "api/eleves?user.actif=true&promos=" + promoSelected["@id"];
    GetBddProvider(uri).then((res) => {
      setLoaded(true);
      if (typeof res === "object") {
        setEleves(res["hydra:member"]);
      } else {
        setMsg({ txt: "Erreur de cahrgement des élèves", type: "danger" });
        autoCloseMsg(setMsg, 5000);
      }
    });
  };

  const init = () => {
    let sem = promoSelected.semestres.filter((sem) => sem.actif);
    if (sem.length === 0) {
      sem[0] = promoSelected.semestres[promoSelected.semestres.length - 1];
    }
    user.role > 3 ? setSemestres(sem) : setSemestres(promoSelected.semestres);
    setAbsence({
      promo: promo,
      motif: "",
      comment: "",
      justify: false,
      rattrapage: false,
      prev: user.role <= 3 ? true : false,
      semestre: sem[0]["@id"],
      user: user["@id"],
    });
    setTime(["04:00"]);
    setMoment(null);
  };

  const valid = () => {
    let testTime = true;
    if (moment !== "all") {
      if (time[0] === "" || time[0] === "00:00") {
        testTime = false;
      }
    } else {
      time.forEach((t) => {
        if (t.time === "" || t.time === "00:00") {
          testTime = false;
        }
      });
    }
    return (
      absence.createdAt &&
      absence.eleve &&
      moment &&
      absence.semestre &&
      testTime
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoaded(false);
    if (moment !== "all") {
      let uriTest =
        url +
        "api/absences?eleve=" +
        absence.eleve +
        "&createdAt[after]=" +
        absence.createdAt +
        "&createdAt[before]=" +
        absence.createdAt +
        "&moment=" +
        moment +
        "&rattrapage=false";
      GetBddProvider(uriTest).then((res) => {
        if (res["hydra:member"].length !== 0) {
          setMsg({ txt: "L'absence a déjà été enregistrée", type: "warning" });
          autoCloseMsg(setMsg, 5000);
          setLoaded(true);
        } else {
          let data = { ...absence, time: time[0], moment: moment };
          let uri = url + "api/absences";
          PostBddProvider(uri, data).then((res) => {
            setLoaded(true);
            if (typeof res === "object" && res.id) {
              init();
              setMaj(true);
              setMsg({
                txt: "L'absence a bien été enregistrée",
                type: "success",
              });
              autoCloseMsg(setMsg, 5000);
            } else {
              setMsg({ txt: "Erreur d'enregistrement", type: "danger" });
              autoCloseMsg(setMsg, 5000);
            }
          });
        }
      });
    } else {
      let error = 0;
      let txtError = null;
      time.forEach(async (t, i, a) => {
        let uriTest =
          url +
          "api/absences?eleve=" +
          absence.eleve +
          "&createdAt[after]=" +
          absence.createdAt +
          "&createdAt[before]=" +
          absence.createdAt +
          "&moment=" +
          t.moment +
          "&rattrapage=false";
        await GetBddProvider(uriTest).then((res) => {
          if (res["hydra:member"].length !== 0) {
            error += i + 1;
            if (error === 1) {
              txtError = "L'absence du matin a déjà été enregistrée";
            } else if (error === 2) {
              txtError = "L'absence de l'après-midi a déjà été enregistrée";
            } else if (error === 3) {
              txtError = "L'absence de la journée a déjà été enregistrée";
            }
            setMsg({ txt: txtError, type: "warning" });
            autoCloseMsg(setMsg, 5000);
            if (i + 1 === a.length) {
              setLoaded(true);
            }
          } else {
            let data = { ...absence, time: t.time, moment: t.moment };
            let uri = url + "api/absences";
            PostBddProvider(uri, data).then((res) => {
              setLoaded(true);
              if (typeof res === "object" && res.id) {
                init();
                setMaj(true);
                setMsg({
                  txt: "L'absence a bien été enregistrée",
                  type: "success",
                });
                autoCloseMsg(setMsg, 5000);
              } else {
                setMsg({ txt: "Erreur d'enregistrement", type: "danger" });
                autoCloseMsg(setMsg, 5000);
              }
            });
          }
        });
      });
    }
  };

  return (
    <div
      className="modal fade"
      id="AbsenceModalAdmin"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <form onSubmit={handleSubmit}>
            <div className="modal-header bg-info text-white">
              <h5 className="modal-title">Saisie d'une absence</h5>
              <button
                type="button"
                className="close text-white"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              {msg !== null ? (
                <div className="mx-4">
                  <MsgAlert msg={msg.txt} type={msg.type} close={()=>autoCloseMsg(setMsg)} />
                </div>
              ) : null}
              {!loaded || promos === null ? (
                <Spinner />
              ) : (
                <div>
                  <div className="form-group">
                    <label>Promo</label>
                    <select
                      className="form-control"
                      value={promo || ""}
                      onChange={(e) => {
                        setPromo(e.target.value);
                        setAbsence({ ...absence, promo: e.target.value });
                      }}
                    >
                      <option value="" hidden disabled>
                        Selectionnez une promo
                      </option>
                      {promos.map((p, i) => (
                        <option
                          value={p["@id"]}
                          disabled={p.semestres.length === 0}
                          key={i}
                        >
                          {p.site.name} - {p.name}
                          {p.semestres.length === 0 && " (Pas de semestre)"}
                        </option>
                      ))}
                    </select>
                  </div>
                  {promoSelected === null ||
                  semestres === null ||
                  eleves === null ? (
                    <h4>Sélectionnez une promo</h4>
                  ) : (
                    <React.Fragment>
                      <div className="form-row align-items-center">
                        <div className="form-group col-md-6">
                          <label>
                            Date
                            {navigator.userAgent.indexOf("Safari") >= 1 &&
                            navigator.userAgent.indexOf("Chrome") <= 0 ? (
                              <em>
                                <small> (aaaa-mm-jj)</small>
                              </em>
                            ) : null}
                          </label>
                          <input
                            type="date"
                            className="form-control"
                            placeholder={
                              navigator.userAgent.indexOf("Safari") >= 1 &&
                              navigator.userAgent.indexOf("Chrome") <= 0
                                ? "aaaa-mm-jj"
                                : null
                            }
                            pattern={
                              navigator.userAgent.indexOf("Safari") >= 1 &&
                              navigator.userAgent.indexOf("Chrome") <= 0
                                ? "\\d{4}-\\d{2}-\\d{2}"
                                : null
                            }
                            value={absence.createdAt || ""}
                            onChange={(e) =>
                              setAbsence({
                                ...absence,
                                createdAt: e.target.value,
                              })
                            }
                            required
                          />
                        </div>

                        {promoSelected.semestres.length === 1 ? (
                          <div className="form-group col-md-6">
                            <strong>
                              <em>
                                <u>{promoSelected.semestres[0].name}</u>
                              </em>
                            </strong>
                          </div>
                        ) : semestres.length === 1 ? (
                          <div className="form-group col-md-6">
                            <strong>
                              <em>
                                <u>{semestres[0].name}</u>
                              </em>
                            </strong>
                          </div>
                        ) : (
                          <div className="form-group col-md-6">
                            <label>Semestre</label>
                            <select
                              name="selectSemestre"
                              className="form-control"
                              value={absence.semestre || ""}
                              onChange={(e) =>
                                setAbsence({
                                  ...absence,
                                  semestre: e.target.value,
                                })
                              }
                              required
                            >
                              <option value="" disabled>
                                Sélectionnez un semestre
                              </option>
                              {semestres.map((sem, i) => (
                                <option value={sem["@id"]} key={i}>
                                  {sem.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        )}
                      </div>
                      <div className="form-row align-items-center">
                        <div className="form-group col-md-6">
                          <label>Période</label>
                          <select
                            className="form-control"
                            value={moment || ""}
                            onChange={(e) =>
                              setMoment(e.target.value)
                            }
                            required
                          >
                            <option value="" hidden disabled></option>
                            <option value="Matin">Matin</option>
                            <option value="Après-midi">Après-midi</option>
                            <option value="all">Journée</option>
                          </select>
                        </div>
                        {moment !== "all" ? (
                          <div className="form-group col-md-6">
                            <label>Durée de la session</label>
                            <input
                              type="time"
                              title="Durée initiale de la session"
                              min="01:00"
                              max={user.role <= 3 ? "" : "04:00"}
                              step="60"
                              pattern={
                                navigator.userAgent.indexOf("Safari") >= 1 &&
                                navigator.userAgent.indexOf("Chrome") <= 0
                                  ? "0[1-4]:\\d{2}"
                                  : null
                              }
                              value={time[0] || "00:00"}
                              className="form-control"
                              onChange={(e) => setTime([e.target.value])}
                              required
                            />
                          </div>
                        ) : (
                          time.map((t, i) => (
                            <div className="form-group col-md-3" key={i}>
                              <label>{t.moment}</label>
                              <input
                                type="time"
                                title="Durée initiale de la session"
                                min="01:00"
                                max={user.role <= 3 ? "" : "04:00"}
                                step="60"
                                pattern={
                                  navigator.userAgent.indexOf("Safari") >= 1 &&
                                  navigator.userAgent.indexOf("Chrome") <= 0
                                    ? "0[1-4]:\\d{2}"
                                    : null
                                }
                                value={t.time || "00:00"}
                                className="form-control"
                                onChange={(e) => {
                                  let temp = JSON.parse(JSON.stringify(time));
                                  temp[i] = {
                                    ...temp[i],
                                    time: e.target.value,
                                  };
                                  setTime(temp);
                                }}
                                required
                              />
                            </div>
                          ))
                        )}
                      </div>
                      <div className="form-group">
                        <label>Elève</label>
                        <select
                          name="selectEleve"
                          className="form-control"
                          value={absence.eleve || ""}
                          onChange={(e) =>
                            setAbsence({ ...absence, eleve: e.target.value })
                          }
                          required
                        >
                          <option value="" hidden disabled>
                            Sélectionnez un élève
                          </option>
                          {eleves.sort(triByUserName).map((el, i) =>
                            el.user.actif ? (
                              <option value={el["@id"]} key={i}>
                                {el.user.name} {el.user.firstName}
                              </option>
                            ) : null
                          )}
                        </select>
                      </div>
                      <div className="form-group">
                        <label>Motif</label>
                        <select
                          className="form-control"
                          value={absence.motif || ""}
                          onChange={(e) =>
                            setAbsence({ ...absence, motif: e.target.value })
                          }
                        >
                          <option value="">Pas de motif</option>
                          <option value="Retenu(e) en entreprise">
                            Retenu(e) en entreprise
                          </option>
                          <option value="Arrêt de travail">
                            Arrêt de travail
                          </option>
                          <option value="Motif personnel">
                            Motif personnel
                          </option>
                          <option value="Congé payé">Congé payé</option>
                          <option value="Examen">Examen</option>
                          <option value="Cours non obligatoire">
                            Cours non obligatoire
                          </option>
                          <option value="COVID">COVID</option>
                          <option value="Accident du travail">
                            Accident du travail
                          </option>
                          <option value="Problème de connexion">
                            Problème de connexion
                          </option>
                        </select>
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Commentaire"
                          value={absence.comment || ""}
                          onChange={(e) =>
                            setAbsence({ ...absence, comment: e.target.value })
                          }
                        />
                      </div>
                      <div className="form-row align-items-center">
                        <div className="form-group col-md-6">
                          <div className="custom-control custom-switch">
                            <input
                              className="custom-control-input"
                              type="checkbox"
                              id="justifyCheckbox"
                              checked={absence.justify || false}
                              onChange={(e) =>
                                setAbsence({
                                  ...absence,
                                  justify: !absence.justify,
                                })
                              }
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="justifyCheckbox"
                            >
                              Justificatif
                            </label>
                          </div>
                        </div>
                        <div className="form-group col-md-6">
                          <div className="custom-control custom-switch">
                            <input
                              className="custom-control-input"
                              type="checkbox"
                              id="prevCheckbox"
                              checked={absence.prev || false}
                              onChange={(e) =>
                                setAbsence({
                                  ...absence,
                                  prev: !absence.prev,
                                })
                              }
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="prevCheckbox"
                            >
                              Prévenue
                            </label>
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  )}
                </div>
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Fermer
              </button>
              <button
                type="submit"
                className="btn btn-info"
                disabled={!valid()}
              >
                Enregistrer
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditAbsencesAdmin;
