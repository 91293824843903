import { useCallback, useEffect, useRef } from "react";
import PostBddProvider from "../Providers/PostBddProvider";

/**
 * hook use fetch POST
 *
 * @returns postBddProvider
 */
export default function usePost() {
  const abortController = useRef(new AbortController());

  useEffect(() => () => abortController.current.abort(), []);

  return useCallback(
    /**
     * Fetch -> POST data on BDD
     *
     * @param {String} url API url
     * @param {Object} data Data to go BDD
     * @param {String} tk token (optionnal)
     * @param {String} galiaUser API url (optionnal)
     * @returns {Promise<JSON|Number>} the Fetch Result in JSON or Statu's error code
     */
    (url, data, tk = undefined, galiaUser = undefined) =>
      PostBddProvider(url, data, tk, galiaUser, abortController.current.signal),
    []
  );
}
