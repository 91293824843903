import React, { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import AppContext from "../../Context/AppContext";
import GetBddProvider from "../../Providers/GetBddProvider";
import PostBddProvider from "../../Providers/PostBddProvider";
import UpdateBddProvider from "../../Providers/UpdateBddProvider";
import Layout from "../common/Layout";
import MsgAlert from "../common/MsgAlert";
import Spinner from "../common/Spinner";
import { autoCloseMsg } from "../tools/messagesUtils";
import { triByName } from "../tools/sortUtils";

const GestSites = () => {
  const { url } = useContext(AppContext);

  const [sites, setSites] = useState(null);
  const [maj, setMaj] = useState(false);
  const [newSite, setNewSite] = useState({});
  const [loaded, setLoaded] = useState(false);
  const [msg, setMsg] = useState(null);

  useEffect(() => {
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (maj) {
      setMaj(false);
      setNewSite({});
      load();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maj]);

  const load = () => {
    let uri = url + "api/sites";
    GetBddProvider(uri).then((res) => {
      if (typeof res === "object") {
        setSites(res["hydra:member"].sort(triByName));
        setLoaded(true);
      } else {
        setMsg({ txt: "Erreur de Chargement des sites", type: "danger" });
        autoCloseMsg(setMsg, 5000);
        setLoaded(true);
      }
    });
  };

  const handleSubmit = () => {
    setLoaded(false);
    let data = { name: newSite.name };
    if (newSite["@id"]) {
      //Update
      let uri = url + newSite["@id"].slice(1);
      UpdateBddProvider(uri, data).then((res) => {
        if (typeof res === "object" && res.id) {
          setMaj(true);
          setMsg({ txt: "Modification enregistrée", type: "success" });
          autoCloseMsg(setMsg, 5000);
        } else {
          setLoaded(true);
          setMsg({ txt: "Erreur d'enregistrement", type: "danger" });
          autoCloseMsg(setMsg, 5000);
        }
      });
    } else {
      //Post
      let uri = url + "api/sites";
      PostBddProvider(uri, data).then((res) => {
        if (typeof res === "object" && res.id) {
          setMaj(true);
          setMsg({ txt: "Site créé", type: "success" });
          autoCloseMsg(setMsg, 5000);
        } else {
          setLoaded(true);
          setMsg({ txt: "Erreur d'enregistrement", type: "danger" });
          autoCloseMsg(setMsg, 5000);
        }
      });
    }
  };

  return Layout(
    <div className="mt-4">
      <h2>Gestion des sites</h2>
      <hr className="m-4" />
      {msg !== null ? <MsgAlert msg={msg.txt} type={msg.type} /> : null}
      {!loaded ? (
        <Spinner />
      ) : (
        <React.Fragment>
          <div className="form-row align-items-center m-4">
            <div className="form-group col-md-8 col-xl-9 m-0 mt-2">
              <input
                type="text"
                className="form-control"
                placeholder="Nom du site"
                value={newSite.name || ""}
                onChange={(e) =>
                  setNewSite({ ...newSite, name: e.target.value })
                }
              />
            </div>
            <div className="col-md-4 col-xl-3 mt-2">
              <button
                type="submit"
                className="btn btn-info mr-2"
                onClick={handleSubmit}
                disabled={!newSite.name}
              >
                {newSite["@id"] ? "Mettre à jour" : "Enregistrer"}
              </button>
              <button
                type="submit"
                className="btn btn-info"
                onClick={() => setNewSite({})}
                disabled={!newSite.name}
              >
                Reset
              </button>
            </div>
          </div>
          <hr className="m-4" />
          <div className="d-flex flex-wrap justify-content-center m-4">
            {sites.map((site, i) => (
              <div
                className="card shadow border-info col-md-3 p-0 m-3"
                onClick={() => setNewSite(site)}
                key={i}
              >
                <div className="card-header d-flex justify-content-center text-info">
                  <h5>{site.name}</h5>
                </div>
              </div>
            ))}
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default GestSites;
