import React, { useState } from "react";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { useEffect } from "react";
import { useContext } from "react";
import AppContext from "../../Context/AppContext";
import GetBddProvider from "../../Providers/GetBddProvider";
import Layout from "../common/Layout";
import MsgAlert from "../common/MsgAlert";
import Spinner from "../common/Spinner";
import convertDecimal from "../tools/convertDecimal";
import CalcMoyClasse from "../tools/Calculs/CalcMoyClasse";
import CalcMoyGenClasse from "../tools/Calculs/CalcMoyGenClasse";
import CalcMinClasse from "../tools/Calculs/CalcMinClasse";
import CalcMoyEleves from "../tools/Calculs/CalcMoyEleves";
import CalcMoyGenEleves from "../tools/Calculs/CalcMoyGenEleves";
import CalcMinGenClasse from "../tools/Calculs/CalcMinGenClasse";

import $ from "jquery";
import dateFormated from "../tools/dateFormated";
import { autoCloseMsg } from "../tools/messagesUtils";
import { triByMoyenneDESC, triByName, triByUserName } from "../tools/sortUtils";

const PrepaConseil = () => {
  const { url, user } = useContext(AppContext);

  const [actifs, setActifs] = useState(true);
  const [notes, setNotes] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [charging, setCharging] = useState(false);
  const [promos, setPromos] = useState(null);
  const [promoSelected, setPromoSelected] = useState(null);
  const [promo, setPromo] = useState(null);
  const [eleves, setEleves] = useState(null);
  const [semestres, setSemestres] = useState(null);
  const [semestreSelected, setSemestreSelected] = useState(null);
  const [semestre, setSemestre] = useState(null);
  const [formation, setFormation] = useState(null);
  const [moyennesClasse, setMoyennesClasse] = useState(null);
  const [moyennesClasseExam, setMoyennesClasseExam] = useState(null);
  const [moyenneGeneraleClasse, setMoyenneGeneraleClasse] = useState(null);
  const [moyenneGeneraleClasseExam, setMoyenneGeneraleClasseExam] =
    useState(null);
  const [moyennesEleves, setMoyennesEleves] = useState(null);
  const [moyennesElevesExam, setMoyennesElevesExam] = useState(null);
  const [moyennesGeneralesEleves, setMoyennesGeneralesEleves] = useState(null);
  const [moyennesGeneralesElevesExam, setMoyennesGeneralesElevesExam] =
    useState(null);
  const [minClasse, setMinClasse] = useState(null);
  const [minClasseExam, setMinClasseExam] = useState(null);
  const [minGenClasse, setMinGenClasse] = useState(null);
  const [minGenClasseExam, setMinGenClasseExam] = useState(null);
  const [maxClasse, setMaxClasse] = useState(null);
  const [maxClasseExam, setMaxClasseExam] = useState(null);
  const [maxGenClasse, setMaxGenClasse] = useState(null);
  const [maxGenClasseExam, setMaxGenClasseExam] = useState(null);
  const [msg, setMsg] = useState(null);

  $(function () {
    $('[data-toggle="tooltip"]').tooltip();
  });

  useEffect(() => {
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setLoaded(false);
    setPromoSelected(null);
    init();
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actifs]);

  useEffect(() => {
    setSemestreSelected(null);
    init();
    if (promoSelected !== null && promoSelected !== "") {
      loadSemestres();
      loadPromo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promoSelected]);

  useEffect(() => {
    init();
    if (semestreSelected !== null && semestreSelected !== "") {
      loadSemestre();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [semestreSelected]);

  useEffect(() => {
    if (notes !== null) {
      setMoyennesEleves(
        CalcMoyEleves(formation.matieres, eleves, notes, semestreSelected)
      );
      setMoyennesElevesExam(
        CalcMoyEleves(
          formation.matieres,
          eleves,
          notes.filter((n) => n.exam),
          semestreSelected,
          false,
          true
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notes]);

  useEffect(() => {
    if (moyennesEleves !== null) {
      setMoyennesClasse(CalcMoyClasse(formation.matieres, moyennesEleves));
      setMoyennesClasseExam(
        CalcMoyClasse(formation.matieres, moyennesElevesExam, false)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [moyennesEleves]);

  useEffect(() => {
    if (moyennesClasse !== null && moyennesEleves !== null) {
      let minMax = CalcMinClasse(formation.matieres, moyennesEleves);
      setMinClasse(minMax.min);
      setMaxClasse(minMax.max);
      let minMaxExam = CalcMinClasse(formation.matieres, moyennesElevesExam);
      setMinClasseExam(minMaxExam.min);
      setMaxClasseExam(minMaxExam.max);
      setMoyenneGeneraleClasse(
        CalcMoyGenClasse(moyennesClasse, formation.coefs)
      );
      setMoyenneGeneraleClasseExam(
        CalcMoyGenClasse(moyennesClasseExam, formation.coefs)
      );
      setMoyennesGeneralesEleves(
        CalcMoyGenEleves(eleves, moyennesEleves, formation.coefs)
      );
      setMoyennesGeneralesElevesExam(
        CalcMoyGenEleves(eleves, moyennesElevesExam, formation.coefs)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [moyennesClasse]);

  useEffect(() => {
    if (
      moyennesGeneralesEleves !== null &&
      moyennesGeneralesElevesExam !== null
    ) {
      let minMax = CalcMinGenClasse(moyennesGeneralesEleves);
      setMinGenClasse(minMax.min);
      setMaxGenClasse(minMax.max);
      let minMaxExam = CalcMinGenClasse(moyennesGeneralesElevesExam);
      setMinGenClasseExam(minMaxExam.min);
      setMaxGenClasseExam(minMaxExam.max);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [moyennesGeneralesEleves]);

  const init = () => {
    setNotes(null);
    setSemestre(null);
    setMoyennesClasse(null);
    setMoyennesEleves(null);
    setMinClasse(null);
    setMaxClasse(null);
    setMoyenneGeneraleClasse(null);
    setMoyennesGeneralesEleves(null);
  };

  const load = () => {
    let uri = url + "api/promos?";
    user.sites.forEach((s, i) => {
      uri += user.sites.length > 1 ? "site[]=" : "site=";
      uri += s["@id"];
      uri += i + 1 < user.sites.length ? "&" : "";
    });
    if (actifs) {
      uri += "&actif=" + actifs;
    }
    GetBddProvider(uri).then((res) => {
      if (typeof res === "object") {
        setLoaded(true);
        setPromos(res["hydra:member"]);
      } else {
        setMsg({ txt: "Erreur de chargement des promos", type: "danger" });
        autoCloseMsg(setMsg, 5000);
      }
    });
  };

  const loadPromo = () => {
    let tmp = promos.filter((p) => p["@id"] === promoSelected)[0];
    setPromo(tmp);
    let uri = url + "api/eleves?user.actif=true&promos=" + tmp["@id"];
    GetBddProvider(uri).then((res) => {
      if (typeof res === "object") {
        setEleves(res["hydra:member"].sort(triByUserName));
      } else {
        setMsg({ txt: "Erreur de chargement des élèves", type: "danger" });
        autoCloseMsg(setMsg, 5000);
      }
    });
  };

  const loadSemestre = () => {
    setSemestre(semestres.filter((s) => s["@id"] === semestreSelected)[0]);
  };

  const loadSemestres = () => {
    promos.forEach((p) => {
      if (p["@id"] === promoSelected) {
        setSemestres(p.semestres);
        p.semestres.forEach((s) =>
          s.actif ? setSemestreSelected(s["@id"]) : null
        );
      }
    });
  };

  const valid = () => {
    return promoSelected !== null && semestreSelected !== null;
  };

  const LoadNotes = () => {
    setCharging(true);
    let uriMat = url + promo.formation["@id"].slice(1);
    GetBddProvider(uriMat).then((res) => {
      if (typeof res === "object") {
        setFormation(res);
        let uri = url + "api/notes?semestre=" + semestreSelected;
        GetBddProvider(uri).then((res) => {
          if (typeof res === "object") {
            setCharging(false);
            setNotes(res["hydra:member"]);
          } else {
            setMsg({ txt: "Erreur de chargement des notes", type: "danger" });
          }
        });
      } else {
        setMsg({ txt: "Erreur de chargement des matières", type: "danger" });
        autoCloseMsg(setMsg, 5000);
      }
    });
  };

  return Layout(
    <div className="mt-4">
      <h2>Préparation des conseils</h2>
      <div className="form-check">
        <input
          type="checkbox"
          className="form-check-input"
          checked={actifs || false}
          onChange={(e) => setActifs(!actifs)}
        />
        <label className="form-check-label">
          <strong>Promos actives uniquement</strong>
        </label>
      </div>
      {msg !== null ? (
        <MsgAlert
          msg={msg.txt}
          type={msg.type}
          close={() => autoCloseMsg(setMsg)}
        />
      ) : null}
      <hr className="m-4" />
      {promos === null || !loaded ? (
        <Spinner />
      ) : (
        <div>
          <div className="form-row mx-4">
            <div className="form-group col-lg-4">
              <label>promo</label>
              <select
                className="form-control"
                value={promoSelected || ""}
                onChange={(e) => setPromoSelected(e.target.value)}
              >
                <option value="" disabled>
                  Sélectionnez une promo
                </option>
                {promos.map((p, i) => (
                  <option value={p["@id"]} key={i}>
                    {p.site.name} - {p.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group col-lg-4">
              <label>semestre</label>
              <select
                className="form-control"
                value={semestreSelected || ""}
                onChange={(e) => setSemestreSelected(e.target.value)}
                disabled={promoSelected === null}
              >
                <option value="" disabled>
                  {promoSelected === null
                    ? "Veuillez sélectionner une promo"
                    : "Sélectionnez un semestre"}
                </option>
                {semestres !== null
                  ? semestres.map((s, i) => (
                      <option value={s["@id"]} key={i}>
                        {s.name}
                      </option>
                    ))
                  : null}
              </select>
            </div>
            <div className="form-group col-lg-4">
              <label>&nbsp;</label>
              <button
                className="btn btn-outline-primary form-control"
                onClick={() => LoadNotes()}
                disabled={!valid()}
              >
                Afficher
              </button>
            </div>
          </div>
          <hr className="m-4" />
          {charging ? (
            <Spinner />
          ) : notes === null || moyennesClasse === null ? null : (
            <React.Fragment>
              <ReactHTMLTableToExcel
                className="btn btn-outline-primary my-2"
                table="tabConseil"
                filename={
                  "Préparation Conseil " + promo.name + " " + semestre.name
                }
                sheet="Export"
                buttonText="Export Excel"
              />

              <div className="table-responsive table-perso">
                <table
                  className="table table-sm table-bordered border-0"
                  id="tabConseil"
                >
                  <tbody>
                    <tr>
                      <th colSpan={eleves.length + 5}>
                        <h4>
                          {promo.name} - {semestre.name}
                        </h4>
                      </th>
                    </tr>
                    <tr>
                      <th className="px-2">Matières</th>
                      <th className="px-2">Coef</th>
                      <th className="px-2">Moy classe</th>
                      <th className="px-2">Max</th>
                      <th className="px-2">Min</th>
                      {eleves.map((e, i) => {
                        let rang = null;
                        if (moyennesGeneralesEleves !== null) {
                          moyennesGeneralesEleves
                            .sort(triByMoyenneDESC)
                            .forEach((m, i) => {
                              if (m.eleve === e["@id"]) {
                                rang = i + 1;
                              }
                            });
                        }
                        return (
                          <th className="px-2" key={i}>
                            {e.user.name} {e.user.firstName} <br />{" "}
                            <span className="badge badge-success badge-pill">
                              {rang}
                            </span>
                          </th>
                        );
                      })}
                    </tr>
                    {formation.matieres
                      .filter((m) => !m.hidden)
                      .sort(triByName)
                      .map((m, i) => {
                        let coef = formation.coefs.filter(
                          (c) => c.matiere["@id"] === m["@id"]
                        )[0].coefficient;
                        let moyCl =
                          moyennesClasse !== null
                            ? moyennesClasse.filter(
                                (e) => e.matiere === m["@id"]
                              )[0]
                            : [];
                        let minCl =
                          minClasse !== null
                            ? minClasse.filter((e) => e.matiere === m["@id"])[0]
                            : [];
                        let maxCl =
                          maxClasse !== null
                            ? maxClasse.filter((e) => e.matiere === m["@id"])[0]
                            : [];
                        let moyEl =
                          moyennesEleves !== null
                            ? moyennesEleves.filter(
                                (e) => e.matiere === m["@id"]
                              )
                            : [];
                        return (
                          <tr key={i}>
                            <td className="text-left">
                              <strong>{m.name}</strong>
                            </td>
                            <td>
                              <strong>{convertDecimal(coef)}</strong>
                            </td>
                            <td>
                              <strong>
                                {moyCl !== undefined
                                  ? convertDecimal(moyCl.moyenne)
                                  : null}
                              </strong>
                            </td>
                            <td>
                              <strong>
                                {maxCl !== undefined
                                  ? convertDecimal(maxCl.note)
                                  : null}
                              </strong>
                            </td>
                            <td>
                              <strong>
                                {minCl !== undefined
                                  ? convertDecimal(minCl.note)
                                  : null}
                              </strong>
                            </td>
                            {eleves.map((el, i) => {
                              let tmp = moyEl.filter(
                                (e) => e.eleve === el["@id"]
                              )[0];
                              let title = "";
                              notes
                                .filter(
                                  (n) =>
                                    n.eleve === el["@id"] &&
                                    n.matiere === m["@id"]
                                )
                                .forEach((t, i, a) => {
                                  title +=
                                    dateFormated(t.createdAt) +
                                    " <em>(" +
                                    t.formateur.user.firstName.slice(0, 1) +
                                    t.formateur.user.name.slice(0, 1) +
                                    ")</em> : ";
                                  title += t.note !== undefined ? t.note : "NE";
                                  title += i + 1 < a.length ? "<br>" : "";
                                });
                              return tmp !== undefined &&
                                tmp.moyenne !== null ? (
                                <td
                                  key={i}
                                  data-toggle="tooltip"
                                  data-placement="left"
                                  data-html="true"
                                  title={title}
                                >
                                  {convertDecimal(tmp.moyenne)}
                                </td>
                              ) : (
                                <td className="text-danger" key={i}>
                                  NE
                                </td>
                              );
                            })}
                          </tr>
                        );
                      })}
                    <tr>
                      <td colSpan="2">
                        <strong>Moyennes générales</strong>
                      </td>
                      <td>
                        <strong>{convertDecimal(moyenneGeneraleClasse)}</strong>
                      </td>
                      <td>
                        <strong>{convertDecimal(maxGenClasse)}</strong>
                      </td>
                      <td>
                        <strong>{convertDecimal(minGenClasse)}</strong>
                      </td>
                      {eleves.map((el, i) => {
                        let tmp =
                          moyennesGeneralesEleves !== null
                            ? moyennesGeneralesEleves.filter(
                                (e) => e.eleve === el["@id"]
                              )[0]
                            : [];
                        return tmp !== undefined && tmp.moyenne !== null ? (
                          <td key={i}>
                            <strong>{convertDecimal(tmp.moyenne)}</strong>
                          </td>
                        ) : (
                          <td className="text-danger" key={i}>
                            <strong>NE</strong>
                          </td>
                        );
                      })}
                    </tr>
                    <tr>
                      <th colSpan={eleves.length + 5}>
                        <h4>Examen Blanc</h4>
                      </th>
                    </tr>
                    <tr>
                      <th className="px-2">Matières</th>
                      <th className="px-2">Coef</th>
                      <th className="px-2">Moy classe</th>
                      <th className="px-2">Max</th>
                      <th className="px-2">Min</th>
                      {eleves.map((e, i) => {
                        let rang = null;
                        if (moyennesGeneralesElevesExam !== null) {
                          moyennesGeneralesElevesExam
                            .sort(triByMoyenneDESC)
                            .forEach((m, i) => {
                              if (m.eleve === e["@id"]) {
                                rang = i + 1;
                              }
                            });
                        }
                        return (
                          <th className="px-2" key={i}>
                            {e.user.name} {e.user.firstName.slice(0, 1)} <br />{" "}
                            <span className="badge badge-pill badge-success">
                              {rang}
                            </span>
                          </th>
                        );
                      })}
                    </tr>
                    {formation.matieres
                      .filter((m) => m.name.slice(0, 2) !== "z-" && !m.hidden)
                      .sort(triByName)
                      .map((m, i) => {
                        let coef = formation.coefs.filter(
                          (c) => c.matiere["@id"] === m["@id"]
                        )[0].coefficient;
                        let moyCl =
                          moyennesClasseExam !== null
                            ? moyennesClasseExam.filter(
                                (e) => e.matiere === m["@id"]
                              )[0]
                            : [];
                        let minCl =
                          minClasseExam !== null
                            ? minClasseExam.filter(
                                (e) => e.matiere === m["@id"]
                              )[0]
                            : [];
                        let maxCl =
                          maxClasseExam !== null
                            ? maxClasseExam.filter(
                                (e) => e.matiere === m["@id"]
                              )[0]
                            : [];
                        let moyEl =
                          moyennesElevesExam !== null
                            ? moyennesElevesExam.filter(
                                (e) => e.matiere === m["@id"]
                              )
                            : [];
                        return (
                          <tr key={i}>
                            <td className="text-left">
                              <strong>{m.name}</strong>
                            </td>
                            <td>
                              <strong>{convertDecimal(coef)}</strong>
                            </td>
                            <td>
                              <strong>
                                {moyCl !== undefined
                                  ? convertDecimal(moyCl.moyenne)
                                  : null}
                              </strong>
                            </td>
                            <td>
                              <strong>
                                {maxCl !== undefined
                                  ? convertDecimal(maxCl.note)
                                  : null}
                              </strong>
                            </td>
                            <td>
                              <strong>
                                {minCl !== undefined
                                  ? convertDecimal(minCl.note)
                                  : null}
                              </strong>
                            </td>
                            {eleves.map((el, i) => {
                              let tmp = moyEl.filter(
                                (e) => e.eleve === el["@id"]
                              )[0];
                              return tmp !== undefined &&
                                tmp.moyenne !== null ? (
                                <td key={i}>{convertDecimal(tmp.moyenne)}</td>
                              ) : (
                                <td className="text-danger" key={i}>
                                  NE
                                </td>
                              );
                            })}
                          </tr>
                        );
                      })}
                    <tr>
                      <td colSpan="2">
                        <strong>Moyennes générales</strong>
                      </td>
                      <td>
                        <strong>
                          {convertDecimal(moyenneGeneraleClasseExam)}
                        </strong>
                      </td>
                      <td>
                        <strong>{convertDecimal(maxGenClasseExam)}</strong>
                      </td>
                      <td>
                        <strong>{convertDecimal(minGenClasseExam)}</strong>
                      </td>
                      {eleves.map((el, i) => {
                        let tmp =
                          moyennesGeneralesElevesExam !== null
                            ? moyennesGeneralesElevesExam.filter(
                                (e) => e.eleve === el["@id"]
                              )[0]
                            : [];
                        return tmp !== undefined && tmp.moyenne !== null ? (
                          <td key={i}>
                            <strong>{convertDecimal(tmp.moyenne)}</strong>
                          </td>
                        ) : (
                          <td className="text-danger" key={i}>
                            <strong>NE</strong>
                          </td>
                        );
                      })}
                    </tr>
                  </tbody>
                </table>
              </div>
            </React.Fragment>
          )}
        </div>
      )}
    </div>
  );
};

export default PrepaConseil;
