import React, { useContext, useEffect, useState } from "react";
import AppContext from "../../../Context/AppContext";
import GetBddProvider from "../../../Providers/GetBddProvider";
import MsgAlert from "../../common/MsgAlert";
import Spinner from "../../common/Spinner";
import dateFormated from "../../tools/dateFormated";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UpdateBddProvider from "../../../Providers/UpdateBddProvider";
import { autoCloseMsg } from "../../tools/messagesUtils";
import { triByCompetenceName } from "../../tools/sortUtils";

const SasiAfest = ({ suiviSelected, setMaj }) => {
  const { url } = useContext(AppContext);

  const [blocs, setBlocs] = useState([]);
  const [suivi, setSuivi] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [msg, setMsg] = useState(null);

  useEffect(() => {
    if (suiviSelected !== null) {
      setSuivi(suiviSelected);
      loadBlocs();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [suiviSelected]);

  const loadBlocs = () => {
    let uri =
      url + "api/bloc_afests?formation=" + suiviSelected.promo.formation;
    GetBddProvider(uri).then((res) => {
      if (typeof res === "object") {
        setBlocs(res["hydra:member"]);
        setLoaded(true);
      } else {
        setLoaded(true);
        setMsg({
          txt: "Erreur de chargement des blocs de compétence",
          type: "danger",
        });
        autoCloseMsg(setMsg, 5000);
      }
    });
  };

  const validate = () => {
    let valid = true;
    suivi.notes.forEach((n) => {
      if (n.note === undefined) {
        valid = false;
      }
    });
    if (suivi.comment === undefined || suivi.comment === null) {
      valid = false;
    }

    return valid;
  };

  const handleSubmit = () => {
    setLoaded(false);
    setSuivi({ ...suivi, validate: true });
    let tmpSuivi = {
      validate: true,
      comment: suivi.comment,
    };
    let save = 0;
    suivi.notes.forEach(async (n) => {
      let tmp = {
        note: n.note,
      };
      save = await UpdateBddProvider(url + n["@id"].slice(1), tmp).then(
        (res) => {
          if (typeof res === "object" && res.id) {
            return save + 1;
          }
          setLoaded(true);
          setMsg({ txt: "erreur d'enregistrement des notes", type: "danger" });
          autoCloseMsg(setMsg, 5000);
        }
      );
      if (save === suivi.notes.length) {
        UpdateBddProvider(url + suivi["@id"].slice(1), tmpSuivi).then((res) => {
          if (typeof res === "object" && res.id) {
            setMaj(true);
            setLoaded(true);
            setMsg({
              txt: "L'évaluation a bien été enregistrée",
              type: "success",
            });
            autoCloseMsg(setMsg, 5000);
          } else {
            setLoaded(true);
            setMsg({
              txt: "erreur d'enregistrement de l'évaluation",
              type: "danger",
            });
            autoCloseMsg(setMsg, 5000);
          }
        });
      }
    });
  };

  return (
    <div
      className="modal fade"
      id="ModalSaisiAfest"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header bg-info text-white">
            <h5 className="modal-title">
              <FontAwesomeIcon icon="clipboard-list" /> &nbsp; Consultation du
              suivi de{" "}
              {suivi !== null
                ? suivi.eleve.user.name + " " + suivi.eleve.user.firstName
                : null}
            </h5>
            <button
              type="button"
              className="close text-white"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          {!loaded ? (
            <div className="pb-5 modal-body text-info">
              <Spinner />
            </div>
          ) : (
            <React.Fragment>
              <div className="modal-body mb-3">
                <MsgAlert
                  msg="Ne sélectionnez que les compétences abordées sur la période."
                  type="danger h4"
                />
                {msg !== null ? (
                  <MsgAlert msg={msg.txt} type={msg.type} close={() => autoCloseMsg(setMsg)} />
                ) : null}
                <div className="d-flex">
                  <div className="col-lg-4">
                    <h6>
                      <strong>
                        <u>Début</u>
                      </strong>
                    </h6>
                    <span>{dateFormated(suivi.createdAt)}</span>
                  </div>
                  <div className="col-lg-4">
                    <h6>
                      <strong>
                        <u>Fin</u>
                      </strong>
                    </h6>
                    <span>{dateFormated(suivi.validateAt)}</span>
                  </div>
                  <div className="col-lg-4">
                    <h6>
                      <strong>
                        <u>Évaluateur</u>
                      </strong>
                    </h6>
                    <span>
                      {suivi.tuteur
                        ? suivi.tuteur.user.name +
                          " " +
                          suivi.tuteur.user.firstName
                        : "Pas de tuteur"}
                    </span>
                  </div>
                </div>
                <div>
                  <form>
                    <table className="mt-3 table table-sm table-responsive-md">
                      <thead>
                        <tr>
                          <th></th>
                          <th className="align-top">
                            Non Evaluable
                            <br />
                            <span className="text-secondary">
                              <FontAwesomeIcon icon="times" />
                            </span>
                            <p className="text-left">
                              <small>Compétence non mise en œuvre</small>
                            </p>
                          </th>
                          <th className="align-top">
                            Non maitrisé <br />
                            <span className="text-danger">
                              <FontAwesomeIcon icon="minus" />{" "}
                              <FontAwesomeIcon icon="minus" />
                            </span>
                            <p className="text-left">
                              <small>
                                - Agit sans méthodologie ou avec une
                                méthodologie inadaptée
                                <br />- N’utilise pas d’outils ou ne les
                                maitrise pas
                                <br />- N’analyse pas
                                <br />- Communique de façon non appropriée
                                <br />- N’utilise pas d’information
                                <br />- N’atteint pas les objectifs
                                <br />- Ne formule aucune proposition ou des
                                propositions incohérentes
                                <br />- Ne prend pas en compte les contraintes
                              </small>
                            </p>
                          </th>
                          <th className="align-top">
                            Maitrise partielle <br />
                            <span className="text-warning">
                              <FontAwesomeIcon icon="minus" />
                            </span>
                            <p className="text-left">
                              <small>
                                - Met en œuvre une méthodologie incomplète
                                <br />- Mobilise correctement quelques outils
                                <br />- Analyse de manière incomplète
                                <br />- Rend compte sans argumentation
                                <br />- Utilise partiellement les informations
                                <br />- Atteint en partie les objectifs
                                <br />- Formule quelques propositions
                                <br />- Prend en compte partiellement les
                                contraintes
                              </small>
                            </p>
                          </th>
                          <th className="align-top">
                            Bonne maitrise <br />
                            <span className="text-green-light">
                              <FontAwesomeIcon icon="plus" />
                            </span>
                            <p className="text-left">
                              <small>
                                - Comprend et met en œuvre une méthodologie
                                rigoureuse
                                <br />- Choisit les outils adaptés
                                <br />- Analyse de manière pertinente
                                <br />- Argumente et fait comprendre
                                <br />- Recherche et mobilise l’information
                                <br />- Atteint les objectifs
                                <br />- Justifie et argumente ses propositions
                                <br />- Intègre l’ensemble des contraintes
                              </small>
                            </p>
                          </th>
                          <th className="align-top">
                            Excellente maitrise <br />
                            <span className="text-success">
                              <FontAwesomeIcon icon="plus" />{" "}
                              <FontAwesomeIcon icon="plus" />
                            </span>
                            <p className="text-left">
                              <small>
                                - Propose une méthodologie pertinente
                                <br />- Adapte et/ou élabore des outils
                                opérationnels
                                <br />- Analyse et remédie
                                <br />- Fait adhérer
                                <br />- Produit des informations pertinentes et
                                exploitables
                                <br />- Dépasse les objectifs
                                <br />- Est force de proposition
                                <br />- Anticipe les contraintes
                              </small>
                            </p>
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                        {blocs.map((b, i) => {
                          let notes = suivi.notes.filter(
                            (n) => n.competence.bloc["@id"] === b["@id"]
                          );
                          return notes.length === 0 ? null : (
                            <React.Fragment key={i}>
                              <tr>
                                <th colSpan="6">{b.name}</th>
                              </tr>

                              {suivi.notes.sort(triByCompetenceName).map((n, i) =>
                                n.competence.bloc["@id"] === b["@id"] ? (
                                  <tr key={i}>
                                    <td className="text-left">
                                      {n.competence.name}
                                    </td>
                                    <th>
                                      <div className="form-check form-check-inline">
                                        <input
                                          type="radio"
                                          name={n.competence["@id"]}
                                          value="0"
                                          onChange={(e) => {
                                            let tmp = suivi.notes;
                                            tmp[i] = {
                                              ...tmp[i],
                                              note: parseInt(e.target.value),
                                            };
                                            setSuivi({
                                              ...suivi,
                                              notes: tmp,
                                            });
                                          }}
                                          checked={
                                            n.note !== undefined
                                              ? n.note === 0
                                              : false
                                          }
                                        />
                                      </div>
                                    </th>
                                    <th>
                                      <div className="form-check form-check-inline">
                                        <input
                                          type="radio"
                                          name={n.competence["@id"]}
                                          value="1"
                                          onChange={(e) => {
                                            let tmp = suivi.notes;
                                            tmp[i] = {
                                              ...tmp[i],
                                              note: parseInt(e.target.value),
                                            };
                                            setSuivi({
                                              ...suivi,
                                              notes: tmp,
                                            });
                                          }}
                                          checked={
                                            n.note !== undefined
                                              ? n.note === 1
                                              : false
                                          }
                                        />
                                      </div>
                                    </th>
                                    <th>
                                      <div className="form-check form-check-inline">
                                        <input
                                          type="radio"
                                          name={n.competence["@id"]}
                                          value="2"
                                          onChange={(e) => {
                                            let tmp = suivi.notes;
                                            tmp[i] = {
                                              ...tmp[i],
                                              note: parseInt(e.target.value),
                                            };
                                            setSuivi({
                                              ...suivi,
                                              notes: tmp,
                                            });
                                          }}
                                          checked={
                                            n.note !== undefined
                                              ? n.note === 2
                                              : false
                                          }
                                        />
                                      </div>
                                    </th>
                                    <th>
                                      <div className="form-check form-check-inline">
                                        <input
                                          type="radio"
                                          name={n.competence["@id"]}
                                          value="3"
                                          onChange={(e) => {
                                            let tmp = suivi.notes;
                                            tmp[i] = {
                                              ...tmp[i],
                                              note: parseInt(e.target.value),
                                            };
                                            setSuivi({
                                              ...suivi,
                                              notes: tmp,
                                            });
                                          }}
                                          checked={
                                            n.note !== undefined
                                              ? n.note === 3
                                              : false
                                          }
                                        />
                                      </div>
                                    </th>
                                    <th>
                                      <div className="form-check form-check-inline">
                                        <input
                                          type="radio"
                                          name={n.competence["@id"]}
                                          value="4"
                                          onChange={(e) => {
                                            let tmp = suivi.notes;
                                            tmp[i] = {
                                              ...tmp[i],
                                              note: parseInt(e.target.value),
                                            };
                                            setSuivi({
                                              ...suivi,
                                              notes: tmp,
                                            });
                                          }}
                                          checked={
                                            n.note !== undefined
                                              ? n.note === 4
                                              : false
                                          }
                                        />
                                      </div>
                                    </th>
                                  </tr>
                                ) : null
                              )}
                            </React.Fragment>
                          );
                        })}
                      </tbody>
                    </table>
                  </form>
                </div>
                <div className="form-group">
                  <label>Commentaires</label>
                  <textarea
                    className="form-control"
                    type="textarea"
                    rows="5"
                    placeholder={"Veuillez saisir un commentaire"}
                    value={suivi.comment || ""}
                    onChange={(e) =>
                      setSuivi({ ...suivi, comment: e.target.value })
                    }
                  />
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Fermer
                </button>
                {!suivi.validate ? (
                  <button
                    type="button"
                    className="btn btn-success"
                    onClick={handleSubmit}
                    disabled={!validate()}
                  >
                    Valider
                  </button>
                ) : null}
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
    </div>
  );
};

export default SasiAfest;
