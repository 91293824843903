import React from "react";
import { useContext } from "react";
import AppContext from "../../../Context/AppContext";
import PageFormateurContext from "../../../Context/PageFormateurContext";
import { useState } from "react";
import Spinner from "../../common/Spinner";
import MsgAlert from "../../common/MsgAlert";
import GetBddProvider from "../../../Providers/GetBddProvider";
import { useEffect } from "react";
import PostBddProvider from "../../../Providers/PostBddProvider";
import { autoCloseMsg } from "../../tools/messagesUtils";
import { triByName } from "../../tools/sortUtils";

const EditCommentMat = () => {
  const { url, user } = useContext(AppContext);
  const { formateur, selectedAppreciations } = useContext(PageFormateurContext);

  const [comments, setComments] = useState(null);
  const [msg, setMsg] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [matieres, setMatieres] = useState(null);
  const [matiereSelected, setMatiereSelected] = useState(null);
  const [semestres, setSemestres] = useState(null);
  const [semestreSelected, setSemestreSelected] = useState(null);
  const [promo, setPromo] = useState(selectedAppreciations);
  const [empty, setEmpty] = useState(true);

  useEffect(() => {
    if (selectedAppreciations !== null && selectedAppreciations !== promo) {
      setLoaded(false);
      setPromo(selectedAppreciations);
      load();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAppreciations]);

  useEffect(() => {
    if (matiereSelected !== null) {
      setLoaded(false);
      let uri = `${url}api/comment_matieres?semestre=${semestreSelected}&matiere=${matiereSelected}&formateur=${formateur["@id"]}`;
      GetBddProvider(uri).then((res) => {
        if (typeof res === "object" && res["hydra:member"].length > 0) {
          setLoaded(true);
          setEmpty(false);
          setComments(null);
          setMsg({
            txt: "Les appréciations ont déjà été saisies !",
            type: "danger",
          });
          autoCloseMsg(setMsg, 5000);
        } else {
          setLoaded(true);
          setEmpty(true);
          setMsg(null);
        }
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matiereSelected]);

  const init = () => {
    let sem = selectedAppreciations.semestres.filter((sem) => sem.actif);
    user.role > 3
      ? setSemestres(sem)
      : setSemestres(selectedAppreciations.semestres);
    setComments(null);
    setEmpty(true);
    setMatiereSelected(null);
    setSemestreSelected(sem[0] ? sem[0]["@id"] : null);
  };

  const load = () => {
    init();
    let uri =
      url + "api/matieres?formations=" + selectedAppreciations.formation["@id"];

    if (user.role > 3) {
      uri += "&formateurs=" + formateur["@id"];
    }

    GetBddProvider(uri).then((res) => {
      setLoaded(true);
      if (typeof res === "object") {
        setMatieres(res["hydra:member"].sort(triByName));
      } else {
        setMsg({ txt: "Erreur de chargement", type: "danger" });
        autoCloseMsg(setMsg, 5000);
      }
    });
  };

  const valid = () => {
    let x = 0;
    let y = 0;
    if (comments !== null) {
      promo.eleves
        .filter((e) => e.user.actif)
        .forEach((el) => {
          x++;
          if (comments[el["@id"]] !== undefined && comments[el["@id"]] !== "") {
            y++;
          }
        });
    } else {
      x = 1;
    }
    return semestreSelected && matiereSelected && x === y;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoaded(false);
    let uri = url + "api/comment_matieres";
    let n = 0;
    promo.eleves
      .filter((e) => e.user.actif)
      .forEach(async (el) => {
        let data = {
          comment: comments[el["@id"]],
          eleve: el["@id"],
          formateur: formateur["@id"],
          matiere: matiereSelected,
          semestre: semestreSelected,
        };
        n = await PostBddProvider(uri, data).then((res) => {
          if (typeof res === "object" && res.id) {
            return n + 1;
          } else {
            setLoaded(true);
            setMsg({ txt: "erreur d'enregistrement", type: "danger" });
            autoCloseMsg(setMsg, 5000);
          }
        });
        if (n === promo.eleves.filter((e) => e.user.actif).length) {
          init();
          setLoaded(true);
          setMsg({
            txt: "les appréciations ont été enregistrées",
            type: "success",
          });
          autoCloseMsg(setMsg, 5000);
        }
      });
  };

  return (
    <div
      className="modal fade"
      id="CommentMatModal"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header bg-info text-white">
            <h5 className="modal-title">
              {promo !== null ? promo.name : ""} - Saisie des Appréciations
            </h5>
            <button
              type="button"
              className="close text-white"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div className="modal-body">
            {msg !== null ? (
              <div className="mx-4">
                <MsgAlert msg={msg.txt} type={msg.type} close={()=>autoCloseMsg(setMsg)} />
              </div>
            ) : null}
            {!loaded || promo === null || matieres === null ? (
              <Spinner />
            ) : semestreSelected === null ? (
              <h4>Aucun semestre actif</h4>
            ) : (
              <div>
                <div className="form-row align-items-center">
                  <div className="form-group col-md-6">
                    <label>Matière</label>
                    <select
                      name="selectMatiere"
                      className={
                        matiereSelected !== null
                          ? "form-control"
                          : "is-invalid form-control"
                      }
                      value={matiereSelected || ""}
                      onChange={(e) => setMatiereSelected(e.target.value)}
                      required
                    >
                      <option value="" disabled>
                        Selectionnez une matière
                      </option>
                      {matieres
                        .filter((m) => !m.hidden && m.name.slice(0, 2) !== "z-")
                        .map((mat, i) => (
                          <option value={mat["@id"]} key={i}>
                            {mat.name}
                          </option>
                        ))}
                    </select>
                  </div>
                  {semestres.length === 1 ? (
                    <div className="form-group col-md-6">
                      <strong>
                        <em>
                          <u>{semestres[0].name}</u>
                        </em>
                      </strong>
                    </div>
                  ) : (
                    <div className="form-group col-md-6">
                      <label>Semestre</label>
                      <select
                        name="selectSemestre"
                        className="form-control"
                        value={semestreSelected || ""}
                        onChange={(e) => setSemestreSelected(e.target.value)}
                        required
                      >
                        <option value="" disabled>
                          Sélectionnez un semestre
                        </option>
                        {semestres.map((sem, i) => (
                          <option value={sem["@id"]} key={i}>
                            {sem.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}
                </div>
                <hr />
                {!empty
                  ? null
                  : promo.eleves
                      .filter((e) => e.user.actif)
                      .map((el, i) => {
                        return (
                          <div
                            className={
                              i + 1 < promo.eleves.length
                                ? "form-row align-items-center border-bottom border-info text-left"
                                : "form-row align-items-center text-left"
                            }
                            key={i}
                          >
                            <div className="col-lg-4 d-flex justify-content-center justify-content-lg-start my-3">
                              <div>
                                <span>
                                  <strong>
                                    {el.user.name} {el.user.firstName}
                                  </strong>
                                </span>
                              </div>
                            </div>
                            <div className="flex-grow-1 my-2">
                              <input
                                type="text"
                                className="form-control"
                                value={
                                  comments !== null &&
                                  comments[el["@id"]] !== undefined
                                    ? comments[el["@id"]]
                                    : ""
                                }
                                placeholder="Appréciation semestrielle"
                                maxLength="250"
                                onChange={(e) =>
                                  setComments({
                                    ...comments,
                                    [el["@id"]]: e.target.value,
                                  })
                                }
                              />
                            </div>
                          </div>
                        );
                      })}
              </div>
            )}
          </div>

          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
            >
              Fermer
            </button>
            <button
              type="button"
              className="btn btn-info"
              onClick={handleSubmit}
              disabled={!valid()}
            >
              Enregistrer
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditCommentMat;
