import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavLink } from "react-router-dom";
import { useContext } from "react";
import AppContext from "../../Context/AppContext";

const SidebarItem = ({
  name,
  role = 7,
  icon,
  path,
  exact = false,
  color = "",
  beta = false,
  exclude = null,
}) => {
  const { user, betaUsers } = useContext(AppContext);

  if (beta && !betaUsers.includes(user.pseudo)) {
    return null;
  }

  return user.role <= role && (exclude === null || user.role !== exclude) ? (
    <li className="nav-item ">
      <NavLink
        className={"nav-link py-1 " + color}
        activeClassName="active text-light bg-secondary font-weight-bold rounded-lg"
        exact={exact}
        to={path}
      >
        <FontAwesomeIcon icon={icon} style={{ width: "20px" }} /> {name}
      </NavLink>
    </li>
  ) : null;
};

export default SidebarItem;
