import { StorageService } from "../../../../Components/tools/Storage/StorageService";
import { LoaderErrorMessage } from "../LoadersMessage";

const setRole = (roles = [], username, su) => {
  if (roles.find((el) => el === "ROLE_DEV")) {
    if (su.includes(username)) {
      return 1;
    } else {
      return "hack";
    }
  } else if (roles.includes("ROLE_DIRECTION")) {
    return 2;
  } else if (roles.includes("ROLE_PEDAGO")) {
    return 3;
  } else if (roles.includes("ROLE_REFERENT")) {
    return 3.5;
  } else if (roles.includes("ROLE_FORMATEUR")) {
    return 4;
  } else if (roles.includes("ROLE_ELEVE")) {
    return 5;
  } else if (roles.includes("ROLE_ENTREPRISE_ADMIN")) {
    return 5.5;
  } else if (roles.includes("ROLE_ENTREPRISE")) {
    return 6;
  } else {
    return 7;
  }
};

export const loginProcess = (res, setter, options) => {
  if (res.actif) {
    res = { ...res, role: setRole(res.roles, res.pseudo, options.su) };
    if (res.role !== "hack") {
      StorageService.setItem("user", JSON.stringify(res));
      setter(res);
    } else {
      LoaderErrorMessage(res, {
        ...options,
        errorMessage: "Violation des droits administrateur",
        typeError: "danger",
      });
    }
  } else {
    StorageService.clear();
    LoaderErrorMessage(res, {
      ...options,
      errorMessage: "Compte inactif",
      typeError: "danger",
    });
  }
};
