import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import AppContext from "../../../Context/AppContext";
import PostBddProvider from "../../../Providers/PostBddProvider";
import UpdateBddProvider from "../../../Providers/UpdateBddProvider";
import MsgAlert from "../../common/MsgAlert";
import Spinner from "../../common/Spinner";
import { autoCloseMsg } from "../../tools/messagesUtils";

const EditMatiere = ({ maj, selected }) => {
  const { url } = useContext(AppContext);
  const [matiere, setmatiere] = useState({ hidden: false });
  const [save, setsave] = useState(true);
  const [msg, setMsg] = useState(null);

  useEffect(() => {
    if (selected !== null && matiere !== selected) {
      setmatiere(selected);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setsave(false);
    let uri = url + "api/matieres";
    if (matiere.id) {
      uri += "/" + matiere.id;
      UpdateBddProvider(uri, matiere).then((res) => {
        setsave(true);
        if (typeof res === "object" && res.id) {
          setMsg({
            text: "La matière a bien été modifée",
            type: "success",
          });
          setmatiere({ hidden: false });
          maj(true);
          autoCloseMsg(setMsg, 5000);
        } else {
          setMsg({
            text: "erreur",
            type: "danger",
          });
        }
      });
    } else {
      PostBddProvider(uri, matiere).then((res) => {
        setsave(true);
        if (typeof res === "object" && res.id) {
          setMsg({
            text: "La matière a bien été créée",
            type: "success",
          });
          setmatiere({ hidden: false });
          maj(true);
          autoCloseMsg(setMsg, 5000);
        } else {
          setMsg({
            text: "erreur",
            type: "danger",
          });
        }
      });
    }
  };

  return (
    <div
      className="modal fade"
      id="Modal"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <form>
            <div className="modal-header bg-info text-white">
              <h5 className="modal-title">
                <FontAwesomeIcon icon="edit" /> &nbsp;{" "}
                {matiere.id ? "Modifier" : "Ajouter"} une matière
              </h5>
              <button
                type="button"
                className="close text-white"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            {!save ? (
              <div className="pb-5 modal-body text-info">
                <Spinner />
              </div>
            ) : (
              <div className="modal-body">
                {msg !== null ? (
                  <div className="mx-4">
                    <MsgAlert
                      msg={msg.text}
                      type={msg.type}
                      close={() => autoCloseMsg(setMsg)}
                    />
                  </div>
                ) : null}
                <div className="form-group">
                  <label htmlFor="name">Nom de la matière</label>
                  <input
                    className="form-control"
                    type="text"
                    name="name"
                    value={matiere.name || ""}
                    placeholder="Nom"
                    onChange={(e) => {
                      let value = e.target.value;
                      setmatiere((mat) => ({
                        ...mat,
                        name: value,
                      }));
                    }}
                    required
                  />
                </div>
                <div className="form-group form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="checkHidden"
                    checked={matiere.hidden}
                    onChange={() =>
                      setmatiere((mat) => ({ ...mat, hidden: !mat.hidden }))
                    }
                  />
                  <label className="form-check-label" htmlFor="checkHidden">
                    Caché
                  </label>
                </div>
              </div>
            )}
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Fermer
              </button>
              <button
                type="submit"
                className="btn btn-info"
                onClick={handleSubmit}
                disabled={!matiere.name}
              >
                Enregistrer
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditMatiere;
