import { StorageService } from "../Components/tools/Storage/StorageService";
import checkToken from "../Components/tools/check/checkToken";
import BDDProvider from "./BDDProvider";

/**
 * Fetch -> POST data on BDD
 *
 * @param {String} url API url
 * @param {Object} data Data to go BDD
 * @param {String} tk token (optionnal)
 * @param {String} signal AbortController's signal (optionnal)
 * @returns {Promise<JSON|Number>} the Fetch Result in JSON or Statu's error code
 */
export default function PostBddProvider(
  url,
  data,
  tk = undefined,
  galiaUser = undefined,
  signal = undefined
) {
  const token = tk || StorageService.getItem("token");

  const UNLOG_URL = [
    process.env.REACT_APP_LOGIN_URL,
    process.env.REACT_APP_PWD_FORGET_URL,
  ];

  if (!tk) {
    checkToken(!UNLOG_URL.includes(url));
  }

  const method = "POST";

  const options = {
    signal,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: galiaUser ? "Basic " + galiaUser : "Bearer " + token,
    },
    method,
    body: JSON.stringify(data),
  };

  let result = BDDProvider(url, options, tk, method, galiaUser);

  return result;
}
