import React, { useContext, useEffect, useRef, useState } from "react";
import AppContext from "../../../Context/AppContext";
import PostBddProvider from "../../../Providers/PostBddProvider";
import UpdateBddProvider from "../../../Providers/UpdateBddProvider";
import MsgAlert from "../../common/MsgAlert";
import { autoCloseMsg } from "../../tools/messagesUtils";

export const EditSanction = ({
  sanction,
  semestres,
  msg,
  setMsg,
  setMaj,
  motifs,
}) => {
  const { url } = useContext(AppContext);

  const [data, setData] = useState(JSON.parse(JSON.stringify(sanction)));

  const ref = useRef(null);

  useEffect(() => {
    setData(JSON.parse(JSON.stringify(sanction)));
  }, [sanction]);

  const validate = () => {
    let valid = true;
    if (!data.semestre || !data.comment || !data.motif) {
      valid = false;
    }
    return valid;
  };

  const handleSubmit = () => {
    if (data.id) {
      UpdateBddProvider(url + data["@id"].slice(1), data).then((res) => {
        if (typeof res === "object" && res.id) {
          setMsg({ txt: "Evènement mis à jour", type: "success" });
          autoCloseMsg(setMsg, 5000);
          setMaj(true);
          ref.current?.click();
        } else {
          setMsg({ txt: "Erreur de mise à jour", type: "danger" });
          autoCloseMsg(setMsg, 5000);
        }
      });
    } else {
      PostBddProvider(url + "api/sanctions", data).then((res) => {
        if (typeof res === "object" && res.id) {
          setMsg({ txt: "Evènement ajouté", type: "success" });
          autoCloseMsg(setMsg, 5000);
          setMaj(true);
          ref.current?.click();
        } else {
          setMsg({ txt: "Erreur d'enregistrement", type: "danger" });
          autoCloseMsg(setMsg, 5000);
        }
      });
    }
  };

  return (
    <div
      className="modal fade"
      id="ModalEditSanction"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header bg-info text-white">
            <h5 className="modal-title">
              {sanction["@id"] ? "Modifier" : "Ajouter"} un évènement
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true" className="text-white">
                &times;
              </span>
            </button>
          </div>
          <div className="modal-body">
            {msg !== null && (
              <MsgAlert
                msg={msg.txt}
                type={msg.type}
                close={() => autoCloseMsg(setMsg)}
              />
            )}
            <div className="form-group">
              <select
                name="selectSemestre"
                className="form-control"
                value={data.semestre || ""}
                onChange={(e) => {
                  setData({ ...data, semestre: e.target.value });
                }}
                required
              >
                <option value="" disabled>
                  Sélectionnez un semestre
                </option>
                {semestres.map((sem, i) => (
                  <option value={sem["@id"]} key={i}>
                    {sem.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <select
                name="selectMotif"
                className="form-control"
                value={data.motif || ""}
                onChange={(e) => {
                  setData({ ...data, motif: e.target.value });
                }}
                required
              >
                <option value="" disabled>
                  Sélectionnez un motif
                </option>
                {motifs.map((motif, i) => (
                  <option value={motif["@id"]} key={i}>
                    {motif.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label>Commentaire</label>
              <textarea
                className="form-control"
                type="textarea"
                rows="5"
                placeholder={"Veuillez saisir un commentaire"}
                value={data.comment || ""}
                onChange={(e) => {
                  setData({ ...data, comment: e.target.value });
                }}
              />
            </div>
            <div className="form-group">
              <label>Notes</label>
              <textarea
                className="form-control"
                type="textarea"
                rows="5"
                placeholder={"Vous pouvez saisir des notes"}
                value={data.notes || ""}
                onChange={(e) => {
                  setData({ ...data, notes: e.target.value });
                }}
              />
            </div>
          </div>
          <div className="modal-footer">
            <button
              id="closeModalEditSanction"
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
              ref={ref}
            >
              Fermer
            </button>
            <button
              type="button"
              className="btn btn-success"
              onClick={handleSubmit}
              disabled={!validate()}
            >
              Valider
            </button>
          </div>
        </div>
      </div>
      EditSanction
    </div>
  );
};
