import React from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { useState } from "react";
import AppContext from "../../../Context/AppContext";
import GetBddProvider from "../../../Providers/GetBddProvider";
import PostBddProvider from "../../../Providers/PostBddProvider";
import UpdateBddProvider from "../../../Providers/UpdateBddProvider";
import MsgAlert from "../../common/MsgAlert";
import Spinner from "../../common/Spinner";
import heuresFormH from "../../tools/heuresFormH";
import { autoCloseMsg } from "../../tools/messagesUtils";
import { triByUserName } from "../../tools/sortUtils";

const EditRattrapage = ({ setMaj, selected, promos }) => {
  const { url, user } = useContext(AppContext);

  const [rattrapage, setRattrapage] = useState({
    createdAt: new Date().toISOString().split("T")[0],
    rattrapage: true,
    user: user["@id"],
  });
  const [promoSelected, setPromoSelected] = useState(null);
  const [eleves, setEleves] = useState(null);
  const [time, setTime] = useState(["04:00"]);
  const [msg, setMsg] = useState(null);
  const [moment, setMoment] = useState(null);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (promos !== null) {
      init();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promos]);

  useEffect(() => {
    if (selected !== null) {
      setRattrapage({
        ...selected,
        createdAt: selected.createdAt.split("T")[0],
        time: heuresFormH(selected.time),
        eleve: selected.eleve["@id"],
        promo: selected.promo["@id"],
        semestre: selected.semestre["@id"],
      });
      promos.forEach((p) => {
        if (p["@id"] === selected.promo["@id"]) {
          setPromoSelected(p);
        }
      });
      setLoaded(true);
    } else {
      init();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  useEffect(() => {
    if (moment !== null) {
      if (moment === "all") {
        setTime([
          { moment: "Matin", time: "04:00" },
          { moment: "Après-midi", time: "04:00" },
        ]);
      } else {
        setTime(["04:00"]);
      }
    }
  }, [moment]);

  useEffect(() => {
    if (promoSelected !== null) {
      loadEleves();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promoSelected]);

  const init = () => {
    setRattrapage({
      createdAt: new Date().toISOString().split("T")[0],
      rattrapage: true,
      user: user["@id"],
    });
    setTime(["04:00"]);
    setMoment(null);
    setPromoSelected(null);
    setLoaded(true);
  };

  const loadSemestres = (id) => {
    let temp = { ...rattrapage, promo: id, semestre: null };
    promos.forEach((p) => {
      if (p["@id"] === id) {
        setPromoSelected(p);
        p.semestres.forEach((s) => {
          if (s.actif) {
            temp = {
              ...temp,
              semestre: s["@id"],
            };
          }
        });
      }
    });
    setRattrapage(temp);
  };

  const loadEleves = () => {
    setLoaded(false);
    setEleves(null);
    let uri = url + "api/eleves?user.actif=true&promos=" + promoSelected["@id"];
    GetBddProvider(uri).then((res) => {
      setLoaded(true);
      if (typeof res === "object") {
        setEleves(res["hydra:member"]);
      } else {
        setMsg({ txt: "Erreur de cahrgement des élèves", type: "danger" });
        autoCloseMsg(setMsg, 5000);
      }
    });
  };

  const valid = () => {
    //verif
    let testTime = true;
    if (moment !== "all") {
      if (time[0] === "" || time[0] === "00:00") {
        testTime = false;
      }
    } else {
      time.forEach((t) => {
        if (t.time === "" || t.time === "00:00") {
          testTime = false;
        }
      });
    }
    return (
      rattrapage.createdAt &&
      rattrapage.promo &&
      rattrapage.eleve &&
      moment &&
      rattrapage.semestre &&
      testTime
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoaded(false);
    let uri = url + "api/absences";
    if (rattrapage["@id"]) {
      UpdateBddProvider(url + rattrapage["@id"].slice(1), rattrapage).then(
        (res) => {
          if (typeof res === "object" && res.id) {
            init();
            setMaj(true);
            setMsg({
              txt: "Le rattrapage a bien été modifié",
              type: "success",
            });
            autoCloseMsg(setMsg, 5000);
          } else {
            setMsg({ txt: "Erreur d'enregistrement", type: "danger" });
            autoCloseMsg(setMsg, 5000);
            setLoaded(true);
          }
        }
      );
    } else {
      if (moment !== "all") {
        let uriTest =
          url +
          "api/absences?eleve=" +
          rattrapage.eleve +
          "&createdAt[after]=" +
          rattrapage.createdAt +
          "&createdAt[before]=" +
          rattrapage.createdAt +
          "&moment=" +
          moment +
          "&rattrapage=true";
        GetBddProvider(uriTest).then((res) => {
          if (res["hydra:member"].length !== 0) {
            setMsg({
              txt: "Le rattrapage a déjà été enregistrée",
              type: "warning",
            });
            autoCloseMsg(setMsg, 5000);
            setLoaded(true);
          } else {
            let data = { ...rattrapage, time: time[0], moment: moment };
            PostBddProvider(uri, data).then((res) => {
              if (typeof res === "object" && res.id) {
                init();
                setMaj(true);
                setMsg({
                  txt: "Le rattrapage a bien été enregistré",
                  type: "success",
                });
                autoCloseMsg(setMsg, 5000);
              } else {
                setMsg({ txt: "Erreur d'enregistrement", type: "danger" });
                autoCloseMsg(setMsg, 5000);
                setLoaded(true);
              }
            });
          }
        });
      } else {
        let error = 0;
        let txtError = null;
        time.forEach(async (t, i, a) => {
          let uriTest =
            url +
            "api/absences?eleve=" +
            rattrapage.eleve +
            "&createdAt[after]=" +
            rattrapage.createdAt +
            "&createdAt[before]=" +
            rattrapage.createdAt +
            "&moment=" +
            t.moment +
            "&rattrapage=true";
          await GetBddProvider(uriTest).then((res) => {
            if (res["hydra:member"].length !== 0) {
              error += i + 1;
              if (error === 1) {
                txtError = "Le rattrapage du matin a déjà été enregistrée";
              } else if (error === 2) {
                txtError =
                  "Le rattrapage de l'après-midi a déjà été enregistrée";
              } else if (error === 3) {
                txtError = "Le rattrapage de la journée a déjà été enregistrée";
              }
              setMsg({ txt: txtError, type: "warning" });
              autoCloseMsg(setMsg, 5000);
              if (i + 1 === a.length) {
                setLoaded(true);
              }
            } else {
              let data = { ...rattrapage, time: t.time, moment: t.moment };
              let uri = url + "api/absences";
              PostBddProvider(uri, data).then((res) => {
                setLoaded(true);
                if (typeof res === "object" && res.id) {
                  init();
                  setMaj(true);
                  setMsg({
                    txt: "Le rattrapage a bien été enregistrée",
                    type: "success",
                  });
                  autoCloseMsg(setMsg, 5000);
                } else {
                  setMsg({ txt: "Erreur d'enregistrement", type: "danger" });
                  autoCloseMsg(setMsg, 5000);
                }
              });
            }
          });
        });
      }
    }
  };

  return (
    <div
      className="modal fade"
      id="RattrapageModal"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <form onSubmit={handleSubmit}>
            <div className="modal-header bg-info text-white">
              <h5 className="modal-title">
                {rattrapage["@id"] ? "Modification" : "Saisie"} d'un rattrapage
              </h5>
              <button
                type="button"
                className="close text-white"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              {msg !== null ? (
                <div className="mx-4">
                  <MsgAlert msg={msg.txt} type={msg.type} close={() => autoCloseMsg(setMsg)} />
                </div>
              ) : null}
              {!loaded || promos === null ? (
                <Spinner />
              ) : (
                <div>
                  <div className="form-group">
                    <label>Promo</label>
                    <select
                      className="form-control"
                      value={rattrapage.promo || ""}
                      onChange={(e) => {
                        loadSemestres(e.target.value);
                      }}
                    >
                      <option value="" hidden disabled>
                        Selectionnez une promo
                      </option>
                      {promos.map((p, i) => (
                        <option
                          value={p["@id"]}
                          disabled={p.semestres.length === 0}
                          key={i}
                        >
                          {p.site.name} - {p.name}
                          {p.semestres.length === 0 && " (Pas de semestre)"}
                        </option>
                      ))}
                    </select>
                  </div>
                  {promoSelected === null || eleves === null ? (
                    <h4>Sélectionnez une promo</h4>
                  ) : (
                    <React.Fragment>
                      <div className="form-row align-items-center">
                        <div className="form-group col-md-6">
                          <label>
                            Date
                            {navigator.userAgent.indexOf("Safari") >= 1 &&
                            navigator.userAgent.indexOf("Chrome") <= 0 ? (
                              <em>
                                <small> (aaaa-mm-jj)</small>
                              </em>
                            ) : null}
                          </label>
                          <input
                            type="date"
                            className="form-control"
                            placeholder={
                              navigator.userAgent.indexOf("Safari") >= 1 &&
                              navigator.userAgent.indexOf("Chrome") <= 0
                                ? "aaaa-mm-jj"
                                : null
                            }
                            pattern={
                              navigator.userAgent.indexOf("Safari") >= 1 &&
                              navigator.userAgent.indexOf("Chrome") <= 0
                                ? "\\d{4}-\\d{2}-\\d{2}"
                                : null
                            }
                            value={rattrapage.createdAt || ""}
                            onChange={(e) =>
                              setRattrapage({
                                ...rattrapage,
                                createdAt: e.target.value,
                              })
                            }
                            required
                          />
                        </div>

                        {promoSelected.semestres.length === 1 ? (
                          <div className="form-group col-md-6">
                            <strong>
                              <em>
                                <u>{promoSelected.semestres[0].name}</u>
                              </em>
                            </strong>
                          </div>
                        ) : (
                          <div className="form-group col-md-6">
                            <label>Semestre</label>
                            <select
                              name="selectSemestre"
                              className="form-control"
                              value={rattrapage.semestre || ""}
                              onChange={(e) =>
                                setRattrapage({
                                  ...rattrapage,
                                  semestre: e.target.value,
                                })
                              }
                              required
                            >
                              <option value="" disabled>
                                Sélectionnez un semestre
                              </option>
                              {promoSelected.semestres.map((sem, i) => (
                                <option value={sem["@id"]} key={i}>
                                  {sem.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        )}
                      </div>
                      <div className="form-row align-items-center">
                        <div className="form-group col-md-6">
                          <label>Période</label>
                          <select
                            className="form-control"
                            value={moment || ""}
                            onChange={(e) =>
                              setMoment(e.target.value)
                            }
                            required
                          >
                            <option value="" hidden disabled></option>
                            <option value="Matin">Matin</option>
                            <option value="Après-midi">Après-midi</option>
                            <option value="all">Journée</option>
                          </select>
                        </div>
                        {moment !== "all" ? (
                          <div className="form-group col-md-6">
                            <label>Durée de la session</label>
                            <input
                              type="time"
                              title="Durée initiale de la session"
                              min="00:30"
                              max="04:00"
                              pattern={
                                navigator.userAgent.indexOf("Safari") >= 1 &&
                                navigator.userAgent.indexOf("Chrome") <= 0
                                  ? "0[1-4]:\\d{2}"
                                  : null
                              }
                              value={time[0] || "00:00"}
                              className="form-control"
                              onChange={(e) =>
                                setTime([e.target.value])
                              }
                              required
                            />
                          </div>
                        ) : (
                          time.map((t, i) => (
                            <div className="form-group col-md-3" key={i}>
                              <label>{t.moment}</label>
                              <input
                                type="time"
                                title="Durée initiale de la session"
                                min="01:00"
                                max={user.role <= 3 ? "" : "04:00"}
                                step="60"
                                pattern={
                                  navigator.userAgent.indexOf("Safari") >= 1 &&
                                  navigator.userAgent.indexOf("Chrome") <= 0
                                    ? "0[1-4]:\\d{2}"
                                    : null
                                }
                                value={t.time || "00:00"}
                                className="form-control"
                                onChange={(e) => {
                                  let temp = JSON.parse(JSON.stringify(time));
                                  temp[i] = {
                                    ...temp[i],
                                    time: e.target.value,
                                  };
                                  setTime(temp);
                                }}
                                required
                              />
                            </div>
                          ))
                        )}
                      </div>
                      <div className="form-group">
                        <label>Elève</label>
                        <select
                          name="selectEleve"
                          className="form-control"
                          value={rattrapage.eleve || ""}
                          onChange={(e) =>
                            setRattrapage({
                              ...rattrapage,
                              eleve: e.target.value,
                            })
                          }
                          required
                        >
                          <option value="" hidden disabled>
                            Sélectionnez un élève
                          </option>
                          {eleves.sort(triByUserName).map((el, i) =>
                            el.user.actif ? (
                              <option value={el["@id"]} key={i}>
                                {el.user.name} {el.user.firstName}
                              </option>
                            ) : null
                          )}
                        </select>
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Commentaire"
                          value={rattrapage.motif || ""}
                          onChange={(e) =>
                            setRattrapage({
                              ...rattrapage,
                              motif: e.target.value,
                            })
                          }
                        />
                      </div>
                    </React.Fragment>
                  )}
                </div>
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Fermer
              </button>
              <button
                type="submit"
                className="btn btn-info"
                disabled={!valid()}
              >
                {rattrapage["@id"] ? "Mettre à jour" : "Enregistrer"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditRattrapage;
