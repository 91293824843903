import React, { Fragment } from "react";
import Spinner from "../../common/Spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext } from "react";
import AppContext from "../../../Context/AppContext";
import ProgContext from "../../../Context/ProgContext";

const ListMatiereProgDetail = ({
  progItem,
  progressions,
  matiere,
  formateur,
  promo,
}) => {
  const { user } = useContext(AppContext);
  const { setSelected, del, setSelectedItem } = useContext(ProgContext);

  const dateFormated = (e) => {
    e = new Date(e);
    let month = parseInt(e.getMonth()) + 1;
    return e.getDate() + "/" + month + "/" + e.getFullYear();
  };

  const classItem = () => {
    let val = "success";
    progressions.forEach((progression) =>
      !progression.activate ? (val = "warning") : null
    );
    return "badge-" + val + " text-" + val;
  };

  return progressions === null ? (
    <div>
      <Spinner />
    </div>
  ) : (
    <div className="border">
      <div className="text-left d-flex p-3 justify-content-between">
        <div>
          <span
            className={
              progressions.length === 0
                ? "badge badge-pill mx-2 badge-danger text-danger"
                : "badge badge-pill mx-2 " + classItem()
            }
          >
            I
          </span>
          <span className="h5 m-0">{progItem.name} {progItem.name === "Autre" && <small className="text-danger"><em>Cet item ne doit plus être utilisé !</em></small>  }</span>
        </div>
        {(user.role <= 3 ||
          (formateur &&
            formateur.matieres.filter((m) => m["@id"] === matiere["@id"])
              .length > 0)) &&
        progItem.name !== "Autre" ? (
          <div className="text-info">
            <FontAwesomeIcon
              icon="plus-circle"
              title="Ajouter une progression"
              className="h4 m-0"
              style={{ cursor: "pointer" }}
              onClick={(e) =>
                setSelectedItem({
                  prog: {
                    promos: [promo["@id"]],
                    progressionItem: [progItem["@id"]],
                    createdAt: new Date().toISOString().split("T")[0],
                    activate: false,
                  },
                  matiere: matiere["@id"],
                  promo: promo.name,
                  uriPromo: promo["@id"],
                  progressionItem: progItem.name,
                })
              }
              data-toggle="modal"
              data-target="#ModalFastProg"
            />
          </div>
        ) : null}
      </div>
      <div className="p-0">
        <ul className="list-group list-group-flush text-left">
          {progressions.map((progression, i) => {
            return (
              <li
                className={
                  progression.activate
                    ? "list-group-item d-flex justify-content-between list-group-item-success"
                    : "list-group-item d-flex justify-content-between list-group-item-warning"
                }
                key={i}
              >
                <p className="m-0 p-0">
                  <em>
                    <u>
                      {dateFormated(progression.createdAt)}{" "}
                      {progression.formateur.user.name}{" "}
                      {progression.formateur.user.firstName} (
                      {new Date(progression.time).getHours()}h
                      {new Date(progression.time).getMinutes() === 0
                        ? "00"
                        : new Date(progression.time).getMinutes()}
                      )
                    </u>
                  </em>
                  <br />
                  {progression.description.split("\n").map((item, key) => {
                    return (
                      <Fragment key={key}>
                        {item}
                        <br />
                      </Fragment>
                    );
                  })}
                </p>
                <div>
                  {user.role <= 3 ||
                  (formateur &&
                    formateur["@id"] === progression.formateur["@id"]) ? (
                    <span>
                      <FontAwesomeIcon
                        icon="edit"
                        title="Modifer la date, la durée, la description ou activer"
                        className="shadow h4"
                        style={{ cursor: "pointer" }}
                        onClick={(e) =>
                          setSelected(JSON.parse(JSON.stringify(progression)))
                        }
                        data-toggle="modal"
                        data-target="#ModalModifProg"
                      />{" "}
                      <FontAwesomeIcon
                        icon="trash-alt"
                        title="Supprimer la progression"
                        className="shadow h4 text-danger"
                        style={{ cursor: "pointer" }}
                        onClick={(e) => {
                          if (window.confirm("Supprimer la progression ?")) {
                            del(progression["@id"]);
                          }
                        }}
                      />
                    </span>
                  ) : null}
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default ListMatiereProgDetail;
