import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { useContext } from "react";
import AppContext from "../../../Context/AppContext";
import Spinner from "../../common/Spinner";
import MsgAlert from "../../common/MsgAlert";
import { useEffect } from "react";
import GetBddProvider from "../../../Providers/GetBddProvider";
import PostBddProvider from "../../../Providers/PostBddProvider";
import { autoCloseMsg } from "../../tools/messagesUtils";
import { triByName } from "../../tools/sortUtils";

const EditProgression = ({ currentFormateur, selected, promos }) => {
  const { url, user } = useContext(AppContext);

  const [progression, setProgression] = useState({
    createdAt: new Date().toISOString().split("T")[0],
    activate: false,
    appel: false,
    formateur: currentFormateur ? currentFormateur["@id"] : null,
  });
  const [promo, setPromo] = useState(selected);
  const [formateurs, setFormateurs] = useState(null);
  const [matieres, setMatieres] = useState(null);
  const [matSelect, setMatSelect] = useState(undefined);
  const [progItems, setProgItems] = useState(null);
  const [progItems2, setProgItems2] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [msg, setMsg] = useState(null);
  const [multiPromo, setMultiPromo] = useState(false);

  useEffect(() => {
    setProgression({
      ...progression,
      formateur: currentFormateur ? currentFormateur["@id"] : null,
    });
    setLoaded(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFormateur]);

  useEffect(() => {
    if (promo !== selected) {
      setMultiPromo(false);
      setPromo(selected);
      setProgression({ ...progression, promos: [selected["@id"]] });
      setMatSelect(undefined);
      if (selected.formation) {
        setLoaded(false);
        load(selected.formation["@id"]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  useEffect(() => {
    if (matSelect !== undefined && user.role <= 3) {
      loadFormateuurs(promo["@id"], matSelect);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matSelect]);

  const loadFormateuurs = (promo, matiere) => {
    let uri = `${url}api/formateurs?promos=${promo}&matieres=${matiere}`;
    GetBddProvider(uri).then((res) => {
      if (typeof res === "object") {
        setFormateurs(res["hydra:member"]);
        let tmp = [];
        if (currentFormateur) {
          tmp = res["hydra:member"].filter(
            (f) => f["@id"] === currentFormateur["@id"]
          );
        }
        if (tmp.length === 0) {
          setProgression({
            ...progression,
            formateur: null,
            progressionItem: undefined,
          });
        } else {
          setProgression({
            ...progression,
            progressionItem: undefined,
            formateur: currentFormateur["@id"],
          });
        }
      } else {
        setMsg({ txt: "Erreur de chargement des formateurs", type: "danger" });
        autoCloseMsg(setMsg, 5000);;
      }
    });
  };

  const loadPromo = (promoId) => {
    setFormateurs(null);
    let uri = url + promoId.substring(1);
    GetBddProvider(uri).then((res) => {
      if (typeof res === "object" && res.id) {
        setPromo(res);
        setMatieres(null);
        setProgItems(null);
        setProgItems2(null);
        setProgression({
          ...progression,
          promos: [res["@id"]],
          progressionItem: undefined,
          formateur: currentFormateur ? currentFormateur["@id"] : null,
        });
        setMatSelect(undefined);
        load(res.formation["@id"]);
      } else {
        setMsg({ txt: "Erreur de chargement", type: "danger" });
        autoCloseMsg(setMsg, 5000);;
      }
    });
  };

  const load = (iri) => {
    let uri = url + "api/matieres?formations=" + iri;
    if (user.role > 3) {
      uri += "&formateurs=" + currentFormateur["@id"];
    }

    GetBddProvider(uri).then((res) => {
      setLoaded(true);
      if (typeof res === "object") {
        setMatieres(res["hydra:member"].sort(triByName));
      } else {
        setMsg({ txt: "Erreur de chargement", type: "danger" });
        autoCloseMsg(setMsg, 5000);;
      }
    });
  };

  const loadItems = (iri, formationIri) => {
    let uri =
      url +
      "api/progression_items?formation=" +
      promo.formation["@id"] +
      "&matiere=" +
      iri;
    GetBddProvider(uri).then((res) => {
      if (typeof res === "object") {
        setProgItems(res["hydra:member"]);
        setProgression({
          ...progression,
          promos: [promo["@id"]],
          progressionItem: undefined,
        });
      } else {
        setMsg({ txt: "Erreur de chargement", type: "danger" });
        autoCloseMsg(setMsg, 5000);;
      }
    });
  };

  const loadItems2 = (itemName) => {
    let uri =
      url + "api/progression_items?name=" + itemName + "&matiere=" + matSelect;
    GetBddProvider(uri).then((res) => {
      if (typeof res === "object") {
        setProgItems2(res["hydra:member"]);
      } else {
        setMsg({ txt: "Erreur de chargement", type: "danger" });
        autoCloseMsg(setMsg, 5000);;
      }
    });
  };

  const validate = () => {
    if (multiPromo) {
      return (
        progression.progressionItem.length === 2 &&
        progression.promos.length === 2 &&
        progression.description &&
        progression.createdAt &&
        progression.time &&
        progression.formateur !== null &&
        progression.activate !== undefined
      );
    } else {
      return (
        progression.promos &&
        progression.progressionItem &&
        progression.description &&
        progression.createdAt &&
        progression.time &&
        progression.formateur !== null &&
        progression.activate !== undefined
      );
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoaded(false);
    let uri = url + "api/progressions";
    PostBddProvider(uri, progression).then((res) => {
      setLoaded(true);
      if (typeof res === "object" && res.id) {
        setMultiPromo(false);
        setProgItems2(null);
        setProgression({
          ...progression,
          promos: undefined,
          matieres: undefined,
          progressionItem: undefined,
          description: undefined,
          time: undefined,
          appel: false,
        });

        setMsg({ txt: "Progression enregistrée", type: "success" });
        autoCloseMsg(setMsg, 5000);;
      } else {
        setMsg({ txt: "Erreur d'enregistrement", type: "danger" });
        autoCloseMsg(setMsg, 5000);;
      }
    });
  };

  return (
    <div
      className="modal fade"
      id="ModalProg"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <form onSubmit={handleSubmit}>
            <div className="modal-header bg-info text-white">
              <h5 className="modal-title">
                <FontAwesomeIcon icon="edit" /> &nbsp;Progression pédagogique
              </h5>
              <button
                type="button"
                className="close text-white"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              {msg !== null ? (
                <div className="mx-4">
                  <MsgAlert msg={msg.txt} type={msg.type} close={() => autoCloseMsg(setMsg)} />
                </div>
              ) : null}
              {!loaded ? (
                <Spinner />
              ) : (
                <div>
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <label>
                        Date
                        {navigator.userAgent.indexOf("Safari") >= 1 &&
                        navigator.userAgent.indexOf("Chrome") <= 0 ? (
                          <em>
                            <small> (aaaa-mm-jj)</small>
                          </em>
                        ) : null}
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        placeholder={
                          navigator.userAgent.indexOf("Safari") >= 1 &&
                          navigator.userAgent.indexOf("Chrome") <= 0
                            ? "aaaa-mm-jj"
                            : null
                        }
                        pattern={
                          navigator.userAgent.indexOf("Safari") >= 1 &&
                          navigator.userAgent.indexOf("Chrome") <= 0
                            ? "\\d{4}-\\d{2}-\\d{2}"
                            : null
                        }
                        value={progression.createdAt || ""}
                        onChange={(e) => {
                          if (
                            new Date(e.target.value) > new Date() ||
                            e.target.value === ""
                          ) {
                            setProgression({
                              ...progression,
                              activate: false,
                              appel: false,
                              createdAt: e.target.value,
                            });
                          } else {
                            setProgression({
                              ...progression,
                              createdAt: e.target.value,
                            });
                          }
                        }}
                        required
                      />
                    </div>
                    <div className="form-group col-md-6">
                      <label>
                        Durée{" "}
                        <em>
                          <small>(de 00:15 à 05:00)</small>
                        </em>{" "}
                      </label>
                      <input
                        type="time"
                        title="Heures entre 00:15 et 05:00"
                        min="00:15"
                        max="05:00"
                        step="900"
                        pattern={
                          navigator.userAgent.indexOf("Safari") >= 1 &&
                          navigator.userAgent.indexOf("Chrome") <= 0
                            ? "0[1-4]:\\d{2}"
                            : null
                        }
                        value={progression.time || "00:00"}
                        className="form-control"
                        onChange={(e) =>
                          setProgression({
                            ...progression,
                            time: e.target.value,
                          })
                        }
                        required
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label>Promo</label>
                    <select
                      className="form-control"
                      name="selectPromo"
                      value={
                        progression.promos !== undefined
                          ? progression.promos[0]
                          : ""
                      }
                      onChange={(e) => {
                        setProgItems2(null);
                        setMultiPromo(false);
                        loadPromo(e.target.value);
                      }}
                    >
                      <option value="" disabled hidden>
                        Sélectionnez une promo
                      </option>
                      {promos === null
                        ? null
                        : promos.map((promo, i) => {
                            return (
                              <option value={promo["@id"]} key={i}>
                                {promo.name} ({promo.site.name})
                              </option>
                            );
                          })}
                    </select>
                  </div>
                  {promo === null || promo === undefined ? null : matieres ===
                    null ? (
                    <Spinner />
                  ) : (
                    <div className="form-group">
                      <label>Matière</label>
                      <select
                        name="selectmatiere"
                        className="form-control"
                        value={matSelect !== undefined ? matSelect : ""}
                        onChange={(e) => {
                          setProgItems(null);
                          setProgItems2(null);
                          setMultiPromo(false);
                          setMatSelect(e.target.value);
                          loadItems(e.target.value);
                        }}
                        required
                      >
                        <option value="" disabled hidden>
                          Sélectionnez une matière
                        </option>
                        {matieres === null
                          ? null
                          : matieres.map((matiere, i) =>
                              matiere.name.slice(0, 2) !== "z-" ? (
                                <option value={matiere["@id"]} key={i}>
                                  {matiere.name}
                                </option>
                              ) : null
                            )}
                      </select>
                    </div>
                  )}
                  {matSelect === undefined ? null : progItems === null ? (
                    <Spinner />
                  ) : (
                    <div className="form-group">
                      <label>Elément de progression</label>
                      <select
                        name="selectProg"
                        className="form-control"
                        value={
                          progression.progressionItem !== undefined
                            ? progression.progressionItem[0]
                            : ""
                        }
                        onChange={(e) => {
                          setProgItems2(null);
                          setMultiPromo(false);
                          setProgression({
                            ...progression,
                            progressionItem: [e.target.value],
                            promos: [promo["@id"]],
                          });
                        }}
                        required
                      >
                        <option value="" disabled>
                          Sélectionnez un élément
                        </option>
                        {progItems.filter((p) => p.name !== "Autre").map((progitem, i) => (
                          <option value={progitem["@id"]} key={i}>
                            {progitem.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}
                  {progression.progressionItem === undefined ||
                  progItems === null ? null : !multiPromo ? (
                    <button
                      className="btn btn-outline-info mb-2"
                      onClick={(e) => {
                        let temp;
                        progItems.forEach((progItem) => {
                          if (
                            progItem["@id"] === progression.progressionItem[0]
                          ) {
                            temp = progItem.name;
                          }
                        });
                        loadItems2(temp);
                        setMultiPromo(true);
                      }}
                    >
                      Ajouter une promo
                    </button>
                  ) : progItems2 === null ? (
                    <Spinner />
                  ) : (
                    <div className="form-group">
                      <hr />
                      <label>Seconde promo</label>
                      <select
                        value={
                          progression.promos[1] !== undefined
                            ? progression.promos[1]
                            : ""
                        }
                        className="form-control"
                        onChange={(e) => {
                          let val = [progression.promos[0]];
                          val.push(e.target.value);
                          let val2 = [progression.progressionItem[0]];
                          promos.forEach((el1) => {
                            if (el1["@id"] === e.target.value) {
                              progItems2.forEach((el2) => {
                                if (el2.formation === el1.formation["@id"]) {
                                  val2.push(el2["@id"]);
                                }
                              });
                            }
                          });

                          setProgression({
                            ...progression,
                            promos: val,
                            progressionItem: val2,
                          });
                        }}
                      >
                        <option value="" disabled hidden>
                          Sélectionnez une promo
                        </option>
                        {progItems2.map((progItem2) => {
                          let temp = [];
                          promos.forEach((promo) => {
                            if (
                              promo["@id"] !== progression.promos[0] &&
                              promo.formation["@id"] === progItem2.formation
                            ) {
                              temp.push(promo);
                            }
                          });
                          return temp.length === 0
                            ? null
                            : temp.map((tmp, i) => (
                                <option value={tmp["@id"]} key={i}>
                                  {tmp.name} ({tmp.site.name})
                                </option>
                              ));
                        })}
                      </select>
                    </div>
                  )}
                  {matSelect !== undefined && user.role <= 3 ? (
                    formateurs !== null ? (
                      <div className="form-group">
                        <hr />
                        <label>Formateur</label>
                        <select
                          className="form-control"
                          value={progression.formateur || ""}
                          onChange={(e) =>
                            setProgression({
                              ...progression,
                              formateur: e.target.value,
                            })
                          }
                        >
                          <option value="" disabled>
                            Selectionnez un formateur
                          </option>
                          {formateurs.map((f, i) => (
                            <option value={f["@id"]} key={i}>
                              {f.user.firstName} {f.user.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    ) : null
                  ) : null}
                  <div className="form-group">
                    <hr />
                    <label>Description</label>
                    <textarea
                      type="textarea"
                      className="form-control"
                      placeholder="Description de la session"
                      required
                      rows="3"
                      value={progression.description || ""}
                      onChange={(e) =>
                        setProgression({
                          ...progression,
                          description: e.target.value,
                        })
                      }
                    ></textarea>
                  </div>
                  <div className="form-group d-flex">
                    <div className="custom-control custom-switch col-6">
                      <input
                        className="custom-control-input"
                        type="checkbox"
                        id="inlineCheckbox2"
                        checked={progression.activate || false}
                        disabled={
                          new Date(progression.createdAt) > new Date() ||
                          progression.createdAt === undefined ||
                          progression.createdAt === ""
                        }
                        onChange={(e) =>
                          setProgression({
                            ...progression,
                            activate: !progression.activate,
                            appel: progression.activate
                              ? false
                              : progression.appel,
                          })
                        }
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="inlineCheckbox2"
                      >
                        Réalisé
                      </label>
                    </div>
                    <div className="custom-control custom-switch col-6">
                      <input
                        className="custom-control-input"
                        type="checkbox"
                        id="CheckboxAppel2"
                        checked={progression.appel || false}
                        disabled={
                          new Date(progression.createdAt) > new Date() ||
                          progression.createdAt === undefined ||
                          progression.createdAt === "" ||
                          !progression.activate
                        }
                        onChange={() => {
                          setProgression({
                            ...progression,
                            appel: !progression.appel,
                          });
                        }}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="CheckboxAppel2"
                      >
                        Appel fait
                      </label>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Fermer
              </button>
              <button
                type="submit"
                className="btn btn-info"
                disabled={!validate()}
              >
                Enregistrer
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditProgression;
