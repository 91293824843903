import React, { useContext, useEffect, useState } from "react";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import AppContext from "../../Context/AppContext";
import useGet from "../../hooks/useGet";
import Layout from "../common/Layout";
import MsgAlert from "../common/MsgAlert";
import Spinner from "../common/Spinner";
import dateFormated from "../tools/dateFormated";
import heuresFormH from "../tools/heuresFormH";
import { autoCloseMsg } from "../tools/messagesUtils";
import { triByCreatedAt, triByName } from "../tools/sortUtils";

export default function ExportProgression() {
  const _PROMO = {
      value: "1",
      name: "promo",
    },
    _FORMATEUR = {
      value: "2",
      name: "Formateur",
    },
    _LISTCHOIX = [
      _PROMO,
      // _FORMATEUR
    ];

  const { user, url } = useContext(AppContext);

  const [typeExport, setTypeExport] = useState(null);
  const [filtres, setFiltres] = useState(null);
  const [filtre, setFiltre] = useState(null);
  const [actif, setActif] = useState(true);
  const [site, setSite] = useState(null);
  const [loaded, setLoaded] = useState(true);
  const [progressions, setprogressions] = useState([]);
  const [matières, setMatières] = useState([]);
  const [progItems, setProgItems] = useState([]);
  const [msg, setMsg] = useState(null);

  const getBdd = useGet();

  useEffect(() => {
    reset();
    setTypeExport(null);
  }, [site]);

  useEffect(() => {
    if (typeExport !== null) {
      reset();
      loadFiltres();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeExport, actif]);

  useEffect(() => {
    setprogressions([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtre]);

  const reset = () => {
    setFiltre(null);
    setFiltres(null);
    setprogressions([]);
  };

  const loadFiltres = () => {
    if (typeExport === _PROMO.value) {
      let uri = `${url}api/promos?site=${site}${actif && '&actif=true'}`;
      getBdd(uri).then((res) => {
        if (typeof res === "object") {
          setFiltres(res["hydra:member"]);
        } else {
          setMsg({ txt: "Erreur de chargement des promos", type: "danger" });
          autoCloseMsg(setMsg, 5000);
        }
      });
    } else if (typeExport === _FORMATEUR.value) {
      // Get Formateurs
      setFiltres(null);
    } else {
      setFiltres(null);
    }
  };

  const validate = () => {
    return site !== null && typeExport !== null && filtre !== null;
  };

  // Get Matières
  const loadMatieres = () => {
    // if promo
    let formation = filtres.filter((f) => f["@id"] === filtre)[0].formation[
      "@id"
    ];
    let uri = `${url}api/matieres?formations=${formation}`;
    getBdd(uri).then((res) => {
      if (typeof res === "object") {
        setMatières(res["hydra:member"]);
        loadProgitems();
      } else {
        setMsg({ txt: "Erreur de chargement des matieres", type: "danger" });
        autoCloseMsg(setMsg, 5000);
      }
    });
  };

  // Get Progression_items
  const loadProgitems = () => {
    // if promo
    let formation = filtres.filter((f) => f["@id"] === filtre)[0].formation[
      "@id"
    ];
    let uri = `${url}api/progression_items?formation=${formation}`;
    getBdd(uri).then((res) => {
      if (typeof res === "object") {
        setProgItems(res["hydra:member"]);
        loadProgressions();
      } else {
        setMsg({
          txt: "Erreur de chargement des items de progressions",
          type: "danger",
        });
        autoCloseMsg(setMsg, 5000);
      }
    });
  };

  // Get Progressions
  const loadProgressions = () => {
    // if promo
    let promo = filtres.filter((f) => f["@id"] === filtre)[0]["@id"];
    let uri = `${url}api/progressions?promos=${promo}`;
    getBdd(uri).then((res) => {
      if (typeof res === "object") {
        setprogressions(res["hydra:member"]);
        setLoaded(true);
      } else {
        setMsg({
          txt: "Erreur de chargement des items de progressions",
          type: "danger",
        });
        autoCloseMsg(setMsg, 5000);
      }
    });
  };

  const submit = () => {
    setLoaded(false);
    loadMatieres();
  };

  return Layout(
    <div className="mx-2">
      <h4 className="text-left mb-2">Export des progressions pédagogiques</h4>
      <hr />
      <div className="ml-4 form-group">
        <div className="form-check">
          <input
            type="checkbox"
            className="form-check-input"
            checked={actif || false}
            onChange={(e) => setActif(!actif)}
          />
          <label className="form-check-label">
            <strong>Promos actives uniquement</strong>
          </label>
        </div>
      </div>
      <div className="text-center d-md-flex justify-content-md-around">
        <div className="form-group">
          <label htmlFor="choixType">Site</label>
          <select
            id="choixType"
            className="form-control w-auto m-auto"
            value={site || ""}
            onChange={(e) => setSite(e.target.value)}
          >
            <option value="" disabled>
              Choix du site
            </option>
            {user.sites.map((s) => (
              <option value={s["@id"]} key={s["@id"]}>
                {s.name}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="choixType">Export par</label>
          <select
            id="choixType"
            className="form-control w-auto m-auto"
            value={typeExport || ""}
            onChange={(e) => setTypeExport(e.target.value)}
            disabled={!site}
          >
            <option value="" disabled>
              Choix du type d'export
            </option>
            {_LISTCHOIX.map((type, i) => (
              <option value={type.value} key={i}>
                {type.name}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="choixType">Filtre</label>
          <select
            id="choixType"
            className="form-control w-auto m-auto"
            value={filtre || ""}
            onChange={(e) => setFiltre(e.target.value)}
            disabled={!filtres}
          >
            <option value="" disabled>
              Choix du filtre
            </option>
            {filtres &&
              filtres.map((f) => (
                <option value={f["@id"]} key={f["@id"]}>
                  {f.name}
                </option>
              ))}
          </select>
        </div>
      </div>
      <div className="form-group">
        <button
          className="btn btn-outline-info btn-sm"
          onClick={submit}
          disabled={!validate()}
        >
          Générer
        </button>
      </div>
      <hr />
      {msg && <MsgAlert msg={msg.txt} type={msg.type} close={() => autoCloseMsg(setMsg)} />}
      {!loaded ? (
        <Spinner />
      ) : (
        progressions.length > 0 && (
          <div>
            <ReactHTMLTableToExcel
              className="btn btn-outline-info btn-sm my-2"
              table="tableProg"
              filename={
                "relevé progressions-" +
                filtres.find((f) => f["@id"] === filtre).name  +
                "-" +
                (new Date().getMonth() + 1) +
                "-" +
                new Date().getFullYear()
              }
              sheet="Export"
              buttonText="Export Excel"
            />
            <table className="table table-sm table-bordered" id="tableProg">
              <thead>
                <tr>
                  <th colSpan="4">
                    <h2>
                      {typeExport === _PROMO.value
                        ? filtres.filter((f) => f["@id"] === filtre)[0].name
                        : null}
                    </h2>
                  </th>
                </tr>
              </thead>
              <tbody>
                {matières
                  .filter((m) => m.name.slice(0, 2) !== "z-")
                  .sort(triByName)
                  .map((m) => (
                    <React.Fragment key={m["@id"]}>
                      <tr className="table-info">
                        <td colSpan="4">
                          <h3 className="text-left">{m.name}</h3>
                        </td>
                      </tr>
                      {progItems
                        .filter((p) => p.matiere === m["@id"])
                        .sort(triByName)
                        .map((pi) => {
                          return (
                            <React.Fragment key={pi["@id"]}>
                              <tr>
                                <th colSpan="4">
                                  <h4>{pi.name}</h4>
                                </th>
                              </tr>
                              <tr className="table-secondary">
                                <th>Date</th>
                                <th>Durée</th>
                                <th>Formateur</th>
                                <th>Contenu des séances</th>
                              </tr>
                              {progressions
                                .filter((prog) => {
                                  let result = false;
                                  prog.progressionItem.forEach((tmp) => {
                                    if (tmp["@id"] === pi["@id"]) {
                                      result = true;
                                    }
                                  });
                                  return result;
                                })
                                .sort(triByCreatedAt)
                                .map((p) => {
                                  return (
                                    <React.Fragment key={p["@id"]}>
                                      <tr>
                                        <td className="align-middle">
                                          {dateFormated(p.createdAt)}
                                        </td>
                                        <td className="align-middle">
                                          {heuresFormH(p.time)}
                                        </td>
                                        <td className="align-middle">
                                          {`${p.formateur.user.firstName} ${p.formateur.user.name}`}
                                        </td>
                                        <td className="text-break text-left">
                                          <p>
                                            {p.description
                                              .split("\n")
                                              .map((item, key) => {
                                                return (
                                                  <React.Fragment key={key}>
                                                    {item}
                                                    <br />
                                                  </React.Fragment>
                                                );
                                              })}
                                          </p>
                                        </td>
                                      </tr>
                                    </React.Fragment>
                                  );
                                })}
                            </React.Fragment>
                          );
                        })}
                    </React.Fragment>
                  ))}
              </tbody>
            </table>
          </div>
        )
      )}
    </div>
  );
}
