import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import formateTel from "../../tools/formateTel";

export default function ListEntrepriseAdmins({
  data,
  site,
  setSelected,
  GenPWD,
  delEntreprise,
}) {
  const List = () => {
    let entreprises = data;
    let siteName = site.name;

    return entreprises === null ? null : (
      <div className="p-0">
        <div className="m-3 d-flex justify-content-between text-left">
          <div>
            <h4>{siteName}</h4>
            <em>
              (
              {entreprises.length > 1
                ? entreprises.length + " entreprises"
                : entreprises.length + " entreprise"}
              )
            </em>
          </div>
          <div>
            <ReactHTMLTableToExcel
              className="btn btn-outline-primary"
              table="tableEntreprises"
              filename={"ListeEntreprisesAdmin"}
              sheet="Export"
              buttonText="Export Excel"
            />
          </div>
        </div>
        <div className="table-responsive table-perso">
          <table
            className="table table-striped table-info text-left"
            id="tableEntreprises"
          >
            <thead>
              <tr>
                <th>Actions</th>
                <th>Raison sociale</th>
                <th>E-mail</th>
                <th>Téléphone</th>
                <th>Adresse postale</th>
                <th>CP</th>
                <th>Ville</th>
              </tr>
            </thead>
            <tbody>
              {entreprises.map((el, i) => {
                return (
                  <tr key={i}>
                    <td className="text-primary text-center">
                      <FontAwesomeIcon
                        icon="edit"
                        onClick={() =>
                          setSelected(JSON.parse(JSON.stringify(el)))
                        }
                        data-toggle="modal"
                        data-target="#ModalEntrepriseAdmin"
                        title="Modifier"
                        style={{ cursor: "pointer" }}
                      />
                    </td>
                    <td>
                      {el.name}
                      <br />
                      <span
                        className="badge badge-pill badge-secondary"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          if (
                            window.confirm("Envoyer un mail de connexion ?")
                          ) {
                            GenPWD(JSON.parse(JSON.stringify(el.user)));
                          }
                        }}
                      >
                        <FontAwesomeIcon icon="paper-plane" /> Mail de connexion
                      </span>
                      <br />
                      <em className="text-primary">{el.user.pseudo}</em>
                    </td>
                    <td>{el.user.email}</td>
                    <td>{formateTel(el.phone)}</td>
                    <td>{el.adresse}</td>
                    <td>{el.cp}</td>
                    <td>{el.ville}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  return (
    <div className="animFadeIn">
      {data === null || data.length === 0 ? (
        <div>
          <h4>{site.name}</h4>
          <div className="alert alert-danger m-3">
            <strong>Pas d'entreprises !</strong>
          </div>
        </div>
      ) : (
        <List />
      )}
    </div>
  );
}
