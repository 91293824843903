import React, { useState, useContext, useEffect } from "react";
import AppContext from "../../Context/AppContext";
import GetBddProvider from "../../Providers/GetBddProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Layout from "../common/Layout";
import Spinner from "../common/Spinner";
import EditEleve from "../contents/edits/EditEleve";
import ListElevesPromo from "../contents/lists/ListElevesPromo";
import usePost from "../../hooks/usePost";
import useGenPwd from "../../hooks/useGenPwd";
import MsgAlert from "../common/MsgAlert";
import useGet from "../../hooks/useGet";
import { autoCloseMsg } from "../tools/messagesUtils";
import { triByName } from "../tools/sortUtils";

const Eleves = () => {
  const { url, user } = useContext(AppContext);
  const [promos, setpromos] = useState();
  const [maj, setmaj] = useState(false);
  const [msg, setMsg] = useState(null);
  const [loaded, setloaded] = useState(false);
  const [sites, setSites] = useState(null);
  const [actif, setActif] = useState(true);
  const [sitesSelected, setSitesSelected] = useState(null);
  const [listSites, setListSites] = useState([]);
  const [selected, setselected] = useState({
    user: { actif: true, roles: ["ROLE_ELEVE"] },
    promos: [],
  });

  const PostBDD = usePost();
  const GetBDD = useGet();
  const GenPWD = useGenPwd(url, PostBDD);

  useEffect(() => {
    initSiteSelected();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (maj) {
      setloaded(false);
      load();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maj]);

  useEffect(() => {
    if (sitesSelected !== null) {
      setloaded(false);
      let uri = "api/promos?site=" + sitesSelected;
      GetBddProvider(url + sitesSelected.slice(1)).then((res) => {
        if (typeof res === "object") {
          setSites([res]);
          loadPromos(uri);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sitesSelected, actif]);

  const initSiteSelected = () => {
    setSitesSelected(user.sites[0]["@id"]);
    load();
  };

  const load = () => {
    let s = sitesSelected !== null ? sitesSelected : user.sites[0]["@id"];
    if (user.role > 1) {
      setSites(user.sites.filter((e) => e["@id"] === s));
      if (user.sites.length > 0) {
        setListSites(user.sites.sort(triByName));
      }
    } else {
      GetBddProvider(url + "api/sites").then((res) => {
        if (typeof res === "object") {
          let datas = res["hydra:member"];
          setSites(datas.filter((e) => e["@id"] === s));
          setListSites(datas.sort(triByName));
        }
      });
    }
    if (maj) {
      let uri = "api/promos?site=" + sitesSelected;
      loadPromos(uri);
      setmaj(false);
    }
  };

  const loadPromos = (uri) => {
    if (actif) {
      uri += "&actif=true";
    }
    GetBddProvider(url + uri).then((res) => {
      if (typeof res === "object") {
        setpromos(res["hydra:member"]);
        setloaded(true);
      }
    });
  };

  const mailConnexion = async (user) => {
    setMsg({
      txt: "Envoie du mail encours ...",
      type: "success",
    });
    let eleve = await GetBDD(url + user["@id"].slice(1));
    let res = await GenPWD(eleve);
    if (res.status === "ok") {
      setMsg({
        txt: `Un mail de connexion pour ${eleve.name} ${eleve.firstName} a été envoyé à l'adresse : ${res.email}`,
        type: "success",
      });
      autoCloseMsg(setMsg, 5000);
    } else if (res.status === 404) {
      setMsg({ txt: res.message, type: "danger" });
      autoCloseMsg(setMsg, 5000);
    } else if (res !== 666) {
      setMsg({ txt: "Erreur serveur !", type: "danger" });
      autoCloseMsg(setMsg, 5000);
    }
  };

  const content = () => {
    if (!loaded || sites === null) {
      return <Spinner />;
    }
    let data = sites.map((site, i) => {
      let temp = promos.filter((promo) => promo.site.id === site.id);
      return (
        <div className="animFadeIn" key={i}>
          <h1>{site.name}</h1>
          <hr className="m-4"></hr>
          <div className="d-flex flex-wrap justify-content-center">
            {temp.map((el, i) => (
              <ListElevesPromo
                setselected={setselected}
                GenPwd={mailConnexion}
                datas={el}
                key={i}
              />
            ))}
          </div>
        </div>
      );
    });
    return data;
  };

  return Layout(
    <div className="mt-4">
      <span className="d-flex flex-wrap flex-column flex-xl-row justify-content-center justify-content-xl-between m-3">
        <h2 className="m-0">Liste des élèves</h2>
        <div className="form-row align-items-center justify-content-center">
          {listSites === null ? null : (
            <div className="mr-2">
              <select
                className="form-control"
                value={sitesSelected || ""}
                onChange={(e) => setSitesSelected(e.target.value)}
              >
                <option value="" disabled hidden>
                  Sites
                </option>
                {listSites !== null
                  ? listSites.map((s, i) => (
                      <option value={s["@id"]} key={i}>
                        {s.name}
                      </option>
                    ))
                  : null}
              </select>
            </div>
          )}
          <div className="ml-2">
            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                checked={actif || false}
                onChange={(e) => setActif(!actif)}
              />
              <label className="form-check-label">
                <strong>Promos actives uniquement</strong>
              </label>
            </div>
          </div>
        </div>
        <div className="align-self-center">
          <button
            type="button"
            className="btn btn-info align-self-center"
            onClick={(e) => {
              setselected({
                user: {
                  actif: true,
                  roles: ["ROLE_ELEVE"],
                  sites: [sites[0]["@id"]],
                },
                promos: [],
              });
            }}
            data-toggle="modal"
            data-target="#Modal"
          >
            <FontAwesomeIcon icon="plus-circle" /> &nbsp; Ajouter
          </button>
        </div>
      </span>
      <EditEleve
        maj={setmaj}
        selected={selected}
        sites={sites}
        listSites={listSites}
        actif={actif}
      />
      <hr className="m-4" />
      {msg !== null && (
        <MsgAlert msg={msg.txt} type={msg.type} close={() => autoCloseMsg(setMsg)} />
      )}
      <div id={"accordionListEleves"}>{content()}</div>
    </div>
  );
};

export default Eleves;
