import React, { useState } from "react";
import { useEffect } from "react";
import { useContext } from "react";
import AppContext from "../../../Context/AppContext";
import useGet from "../../../hooks/useGet";
import usePost from "../../../hooks/usePost";
import useUpdate from "../../../hooks/useUpdate";
import SmallSpinner from "../../common/SmallSpinner";
import GenPass from "../../GenPass";
import { autoCloseMsg } from "../../tools/messagesUtils";

export default function TuteurGalia({ student, initEleve, site, setMsg }) {
  const _GET_BDD = useGet();
  const _POST_BDD = usePost();
  const _UPDATE_BDD = useUpdate();
  const _CONVENTION = student.Convention || null;

  const { url } = useContext(AppContext);

  const [tuteur, setTuteur] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [eleve, setEleve] = useState(initEleve);
  const [save, setSave] = useState(true);
  const [maj, setMaj] = useState(false);

  useEffect(() => {
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (maj) {
      reload();
      setMaj(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maj]);

  useEffect(() => {
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eleve]);

  const reload = () => {
    let uri = url + eleve["@id"].slice(1);
    _GET_BDD(uri).then((res) => {
      if (typeof res === "object") {
        setEleve(res);
      } else {
        setMsg({ txt: "Erreur de chargement de l'élève", type: "danger" });
        autoCloseMsg(setMsg, 10000);
      }
    });
  };

  const load = () => {
    if (_CONVENTION && eleve.entreprises && eleve.entreprises.length > 0) {
      getTuteur(eleve.entreprises[0]["@id"]);
    } else {
      setLoaded(true);
    }
  };

  const getTuteur = (UriTuteur) => {
    _GET_BDD(url + UriTuteur.slice(1)).then((res) => {
      if (typeof res === "object") {
        setTuteur(res);
        setLoaded(true);
      }
    });
  };

  const testTuteur = async (tuteurID) => {
    let uri = url + "api/entreprises?idGalia=" + tuteurID;
    let result = await _GET_BDD(uri).then((res) => {
      if (typeof res === "object") {
        let tmp = res["hydra:member"];
        if (tmp.length > 0) {
          return tmp[0]["@id"];
        } else {
          return null;
        }
      } else {
        setMsg({ txt: "Echec du test Tuteur", type: "danger" });
        autoCloseMsg(setMsg, 10000);
        setSave(true);
        return "FAIL";
      }
    });
    return result;
  };

  const testEntreprise = async (entrepriseID) => {
    let uri = url + "api/entreprise_admins?idGalia=" + entrepriseID;
    let result = await _GET_BDD(uri).then((res) => {
      if (typeof res === "object") {
        let tmp = res["hydra:member"];
        if (tmp.length > 0) {
          return tmp[0]["@id"];
        } else {
          return null;
        }
      } else {
        setMsg({ txt: "Echec du test entreprise", type: "danger" });
        autoCloseMsg(setMsg, 10000);
        setSave(true);
        return "FAIL";
      }
    });
    return result;
  };

  const check = async (update = false) => {
    let checkTuteur = await testTuteur(_CONVENTION.Tuteur.ID);
    if (!checkTuteur) {
      let checkEntreprise = await testEntreprise(_CONVENTION.Client.ID);
      if (!checkEntreprise) {
        createEntreprise(update);
      } else if (checkEntreprise !== "FAIL") {
        if (update) {
          updateTuteur(checkEntreprise);
        } else {
          createTuteur(checkEntreprise);
        }
      }
    } else if (checkTuteur !== "FAIL") {
      updateEleve(checkTuteur);
    }
  };

  const createUser = async (data) => {
    let saved = false;
    let user = { ...data };
    for (let ok = false; ok === false; ) {
      let pseudoTest = user.pseudo.slice(-1);
      pseudoTest === " "
        ? (user.pseudo = user.pseudo.slice(0, -1))
        : (ok = true);
    }
    let pseudo = user.pseudo;
    for (let variation = 0; saved === false; variation++) {
      let uri = url + "api/users";

      variation > 0 && (user.pseudo = pseudo + variation);

      saved = await _POST_BDD(uri, user).then((res) => {
        if (typeof res === "object" && res.id) {
          return res;
        } else {
          let txt = "Erreur ";
          if (res.violations) {
            let violations = res.violations;
            if (
              violations.filter(
                (v) => v.message === "Nom d'utilisateur déjà utilisé."
              ).length > 0
            ) {
              return false;
            }
            res.violations.forEach((v) => (txt += " - " + v.message));
          }
          setSave(true);
          setMsg({ txt: txt, type: "danger" });
          autoCloseMsg(setMsg, 10000);
          return "FAIL";
        }
      });
    }
    return saved;
  };

  const createTuteur = async (uriEntreprise) => {
    let temp = _CONVENTION.Tuteur;
    let test = testDatas(temp);
    if (test.length > 0) {
      let txt = "Les champs suivant du tuteur sont vide dans galia : ";

      test.forEach((t, i, tab) => {
        txt += t;
        if (i + 1 < tab.length) {
          txt += ", ";
        }
      });
      setSave(true);
      setMsg({ txt: txt, type: "danger" });
      autoCloseMsg(setMsg, 10000);
    } else {
      let pseudo = `${temp.Prenom.toLowerCase().slice(
        0,
        1
      )}.${temp.Nom.toLowerCase()}`;

      let user = await createUser({
        email: temp.Email_Pro,
        password: GenPass(),
        roles: ["ROLE_ENTREPRISE"],
        name: temp.Nom,
        firstName: temp.Prenom,
        sites: [site],
        actif: true,
        validate: false,
        pseudo: pseudo,
      });

      if (user !== "FAIL") {
        let uri = url + "api/entreprises";
        let data = {
          user: `/api/users/${user.id}`,
          raisonSociale: _CONVENTION.Client.Nom,
          mailAdmin: _CONVENTION.Client.Email,
          adresse: _CONVENTION.Client.Adresse.Adr1,
          cp: _CONVENTION.Client.Adresse.Code_Postal,
          ville: _CONVENTION.Client.Adresse.Ville,
          tel: _CONVENTION.Client.Telephone || null,
          entrepriseAdmin: uriEntreprise,
          idGalia: _CONVENTION.Tuteur.ID,
        };

        _POST_BDD(uri, data).then((res) => {
          if (typeof res === "object" && res.id) {
            setTuteur(res);
            updateEleve(`/api/entreprises/${res.id}`);
          } else {
            setMsg({ txt: "Ereur d'enregistrement du tuteur", type: "danger" });
            autoCloseMsg(setMsg, 10000);
            setSave(true);
          }
        });
      }
    }
  };

  const createEntreprise = async (update = false) => {
    let temp = _CONVENTION.Client;
    let test = testDatasEnt(temp);
    if (test.length > 0) {
      let txt = "Les champs suivant de l'entreprise sont vide dans galia : ";

      test.forEach((t, i, tab) => {
        txt += t;
        if (i + 1 < tab.length) {
          txt += ", ";
        }
      });

      setSave(true);

      setMsg({ txt: txt, type: "danger" });
      autoCloseMsg(setMsg, 10000);
    } else {
      let pseudo = `ent.${temp.Nom.toLowerCase().replace(/\s/g, "")}`;
      let user = await createUser({
        email: temp.Email,
        password: GenPass(),
        roles: ["ROLE_ENTREPRISE_ADMIN"],
        name: temp.Nom,
        firstName: "Entreprise",
        sites: [site],
        actif: true,
        validate: false,
        pseudo: pseudo,
      });

      if (user !== "FAIL") {
        let uri = url + "api/entreprise_admins";
        let data = {
          user: `/api/users/${user.id}`,
          name: temp.Nom,
          adresse: temp.Adresse.Adr1,
          cp: temp.Adresse.Code_Postal,
          ville: temp.Adresse.Ville,
          phone: temp.Telephone || null,
          idGalia: temp.ID,
        };
        let result = await _POST_BDD(uri, data).then((res) => {
          if (typeof res === "object" && res.id) {
            return res;
          } else {
            setMsg({ txt: "Ereur d'enregistrement du tuteur", type: "danger" });
            autoCloseMsg(setMsg, 10000);
            setSave(true);
            return null;
          }
        });
        if (result) {
          if (update) {
            updateTuteur(`/api/entreprise_admins/${result.id}`);
          } else {
            createTuteur(`/api/entreprise_admins/${result.id}`);
          }
        }
      }
    }
  };

  const updateTuteur = async (uriEntreprise) => {
    let uri = url + tuteur["@id"].slice(1);
    let data = {
      entrepriseAdmin: uriEntreprise,
      idGalia: _CONVENTION.Tuteur.ID,
    };
    await _UPDATE_BDD(uri, data).then((res) => {
      if (typeof res === "object" && res.id) {
        setSave(true);
        setMsg({
          txt: "le tuteur est synchronisé",
          type: "success",
        });
        autoCloseMsg(setMsg, 10000);
        setMaj(true);
      } else {
        setSave(true);
        setMsg({
          txt: "Erreur de synchronisation",
          type: "danger",
        });
        autoCloseMsg(setMsg, 10000);
      }
    });
  };

  // const updateEntreprise = async () => {};

  const updateEleve = async (uriTuteur) => {
    let uri = url + eleve["@id"].slice(1);
    let data = {
      entreprises: [uriTuteur],
    };
    _UPDATE_BDD(uri, data).then((res) => {
      if (typeof res === "object" && res.id) {
        setMsg({
          txt: "le tuteur est synchronisé",
          type: "success",
        });
        autoCloseMsg(setMsg, 10000);
        setMaj(true);
        setSave(true);
      } else {
        setMsg({
          txt: "Erreur de synchronisation",
          type: "danger",
        });
        setSave(true);
        autoCloseMsg(setMsg, 10000);
      }
    });
  };

  const testDatas = (datas) => {
    let tmp = [];
    if (!datas.Nom) {
      tmp.push("Nom");
    }
    if (!datas.Prenom) {
      tmp.push("Prénom");
    }
    if (!datas.Email_Pro) {
      tmp.push("Email_Pro");
    }
    return tmp;
  };

  const testDatasEnt = (datas) => {
    let tmp = [];
    if (!datas.Nom) {
      tmp.push("Nom");
    }
    if (!datas.Email) {
      tmp.push("Email_Pro");
    }
    return tmp;
  };

  // Vérifier si l'entreprise est déjà synchro dans NOCx
  // Vérifier si le tuteur est déjà synchro dans NOCx
  // Vérifier si l'éléve à un tuteur si oui est-il synchro NOCx - GALIA

  const NoTuteurGaliaContent = () => {
    return (
      <em className="text-danger font-weight-bold">Pas de Tuteur dans Galia</em>
    );
  };

  const NoEntrepriseGaliaContent = () => {
    return (
      <em className="text-danger font-weight-bold">
        Pas d'entreprise dans Galia
      </em>
    );
  };

  const NoTuteurNocxContent = () => {
    return !_CONVENTION.Client ? (
      <NoEntrepriseGaliaContent />
    ) : !_CONVENTION.Tuteur ? (
      <NoTuteurGaliaContent />
    ) : (
      <CreateContent />
    );
  };

  const TuteurNocxContent = () => {
    return tuteur.idGalia ? <SyncContent /> : <NoSyncContent />;
  };

  const NoSyncContent = () => {
    return !_CONVENTION.Client ? (
      <NoEntrepriseGaliaContent />
    ) : !_CONVENTION.Tuteur ? (
      <NoTuteurGaliaContent />
    ) : (
      <em className="text-info font-weight-bold">
        NOCx (
        {`${tuteur.user.name} ${tuteur.user.firstName} - ${tuteur.raisonSociale}`}
        ) - Galia (
        {`${_CONVENTION.Tuteur.Nom} ${_CONVENTION.Tuteur.Prenom} - ${_CONVENTION.Client.Nom}`}
        ) <br />
        {save ? (
          <>
            {!tuteur.idGalia && (
              <button
                className="btn btn-sm btn-outline-info mr-2 mt-1"
                disabled={!save}
                onClick={() => {
                  setSave(false);
                  check(true);
                }}
              >
                Synchroniser
              </button>
            )}
            <button
              className="btn btn-sm btn-outline-danger mt-1"
              disabled={!save}
              onClick={() => {
                setSave(false);
                check();
              }}
            >
              Remplacer le tuteur de NOCX par celui de Galia
            </button>{" "}
          </>
        ) : (
          <button className="btn btn-sm btn-outline-secondary mx-1" disabled>
            <SmallSpinner />
          </button>
        )}
      </em>
    );
  };

  const SyncContent = () => {
    // Vérifier si changement
    return !_CONVENTION.Client ? (
      <NoEntrepriseGaliaContent />
    ) : !_CONVENTION.Tuteur ? (
      <NoTuteurGaliaContent />
    ) : tuteur.idGalia === _CONVENTION.Tuteur.ID ? (
      <em className="text-success font-weight-bold">
        {`${tuteur.user.name} ${tuteur.user.firstName} - ${tuteur.raisonSociale}`}{" "}
        <span className="badge badge-pill badge-success ml-2">Synchronisé</span>
      </em>
    ) : (
      <NoSyncContent />
    );
  };

  const CreateContent = () => {
    return (
      <em className="text-danger font-weight-bold">
        {`${_CONVENTION.Tuteur.Nom} ${_CONVENTION.Tuteur.Prenom} - ${_CONVENTION.Client.Nom}`}
        <br />
        <button
          className="btn btn-sm btn-outline-success"
          disabled={!save}
          onClick={() => {
            setSave(false);
            check();
          }}
        >
          {save ? "Créer" : <SmallSpinner />}
        </button>
      </em>
    );
  };

  const Content = () => {
    return (
      <small>
        Tuteur : {tuteur ? <TuteurNocxContent /> : <NoTuteurNocxContent />}
      </small>
    );
  };

  return loaded ? (
    _CONVENTION ? (
      <Content />
    ) : (
      <small>
        <em className="text-danger font-weight-bold">
          Pas de Convention dans Galia
        </em>
      </small>
    )
  ) : (
    <SmallSpinner />
  );
}
