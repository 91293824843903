import React from "react";
import logo from "../../logo-symbol.svg";
import Layout from "../common/Layout";

const Deploiement = ({page = false}) => {
  const Content = () => {
    return (
      <div>
        <h2 className="m-4">Développement en cours !</h2>
        <img src={logo} alt="logo" className="mt-4 App-logo-home" />
      </div>
    );
  };
  return page ? Layout(<Content/>) : <Content/>;
};

export default Deploiement;
