/**
 *
 * @param {array} matieres - liste des matières
 * @param {array} moyennes - moyennes des élèves par matière
 * @returns {object} object contenant un tableau contenant les moyennes minimum et un tableau contenant les moyennes maximum par matières
 */
const CalcMinClasse = (matieres, moyennes) => {
  let minTmp = [];
  let maxTmp = [];
  matieres.forEach((m) => {
    let tmp = moyennes
      .filter((n) => n.matiere === m["@id"] && n.moyenne !== null)
      .sort((a, b) => {
        return a.moyenne > b.moyenne ? 1 : -1;
      });
    let tempMin = tmp[0];
    let tempMax = tmp[tmp.length - 1];
    minTmp = [
      ...minTmp,
      {
        matiere: m["@id"],
        note:
          tempMin !== undefined && tempMin.moyenne !== undefined
            ? tempMin.moyenne
            : null,
      },
    ];
    maxTmp = [
      ...maxTmp,
      {
        matiere: m["@id"],
        note:
          tempMax !== undefined && tempMax.moyenne !== undefined
            ? tempMax.moyenne
            : null,
      },
    ];
  });
  return { min: minTmp, max: maxTmp };
};

export default CalcMinClasse;
