import React, { useContext, useEffect, useState } from "react";
import AppContext from "../../Context/AppContext";
import GetBddProvider from "../../Providers/GetBddProvider";
import Layout from "../common/Layout";
import MsgAlert from "../common/MsgAlert";
import Spinner from "../common/Spinner";
import ListAdminSites from "../contents/lists/ListAdminSites";
import { autoCloseMsg } from "../tools/messagesUtils";
import { triByName } from "../tools/sortUtils";

const AdminSites = () => {
  const { user, url, galiaUser } = useContext(AppContext);

  const _EXCLUDE = "ROLE_ELEVE";

  const [sites, setSites] = useState([]);
  const [selected, setSelected] = useState(null);
  const [listSocietes, setListSocietes] = useState([]);
  const [msg, setMsg] = useState(null);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (user.role <= 2 || !user.roles.includes(_EXCLUDE)) {
      load();
    } else {
      setLoaded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const load = () => {
    let uriGalia =
      "https://groupe-alternance.sc-form.net/GaliaEDC/API/Entite/GetEntite?ACTION=SOCIETE";
    let uri = url + "api/sites";
    if (user.role > 1) {
      uri += "?users=" + user["@id"];
    }
    GetBddProvider(uriGalia, undefined, galiaUser).then((res) => {
      if (typeof res === "object") {
        setListSocietes(res);
      } else {
        setMsg({ txt: "Erreur de Chargement des sociétés", type: "danger" });
        autoCloseMsg(setMsg, 5000);
      }
    });
    GetBddProvider(uri).then((res) => {
      setLoaded(true);
      if (typeof res === "object") {
        setSites(res["hydra:member"].sort(triByName));
      } else {
        setMsg({ txt: "Erreur de Chargement des sites", type: "danger" });
        autoCloseMsg(setMsg, 5000);
      }
    });
  };

  const init = () => {
    setSelected(null);
    load();
  };

  return Layout(
    <div className="m-4">
      <h2 className="text-left">
        Gestion d{user.sites.length > 1 ? "es" : "u"} site
        {user.sites.length > 1 ? "s" : ""}
      </h2>
      <hr />
      {msg !== null ? <MsgAlert msg={msg.txt} type={msg.type} /> : null}

      {user.role > 2 && user.roles.includes(_EXCLUDE) ? (
        <MsgAlert msg="Accès Refusé" type="danger" />
      ) : null}
      {!loaded ? (
        <Spinner />
      ) : (
        sites.map((site, i) => (
          <ListAdminSites
            site={site}
            listSocietes={listSocietes}
            selected={selected}
            setSelected={setSelected}
            init={init}
            key={i}
          />
        ))
      )}
    </div>
  );
};

export default AdminSites;
