import React, { useContext, useEffect, useState } from "react";
import AppContext from "../../../Context/AppContext";
import GetBddProvider from "../../../Providers/GetBddProvider";
import MsgAlert from "../../common/MsgAlert";
import SmallSpinner from "../../common/SmallSpinner";
import dateFormated from "../../tools/dateFormated";
import { autoCloseMsg } from "../../tools/messagesUtils";
import AbsEleveNoSync from "./AbsEleveNoSync";
import AbsElevesEdusign from "./AbsElevesEdusign";
import AbsPromoNoSync from "./AbsPromoNoSync";

// checker si l'absence a été saisie
// si oui vérifier la liason avec IdEdusign et lier(Attention à la durée)

const AbsPromoEdusign = ({
  cours,
  seance,
  connector,
  token,
  absencesNocx,
  setMaj,
}) => {
  const { url, urlEdusign, connectors } = useContext(AppContext);

  const duree = () => {
    let h;
    let m;
    if (seance) {
      let time = seance.Duree.split(":");
      h = time[0];
      m = time[1];
    } else {
      let start = new Date(cours.START);
      let end = new Date(cours.END);
      let diff = new Date(end - start);
      h =
        diff.getUTCHours() < 10 ? "0" + diff.getUTCHours() : diff.getUTCHours();
      m = diff.getMinutes() < 10 ? "0" + diff.getMinutes() : diff.getMinutes();
    }
    return h + ":" + m;
  };

  const periode = () => {
    let H;
    if (seance) {
      H = parseInt(seance.Fin.split(":")[0]);
    } else {
      H = new Date(cours.END).getHours();
    }
    if (H < 13) {
      return "Matin";
    } else {
      return "Après-midi";
    }
  };

  const _PERIODE = periode();
  const _DUREE = duree();

  const [msg, setMsg] = useState(null);
  const [promos, setPromos] = useState(null);
  const [load, setLoad] = useState(false);
  const [absencesEdusign, setAbsencesEdusign] = useState([]);
  const [eleves, setEleves] = useState([]);

  useEffect(() => {
    setAbsencesEdusign(cours.STUDENTS.filter((s) => !s.state));
    if (cours.SCHOOL_GROUP.length > 0) {
      getPromo();
    } else {
      setLoad(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (promos !== null && promos.length > 0) {
      getEleves();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promos]);

  const getPromo = async () => {
    let groupsDetail = [];
    for (const g of cours.SCHOOL_GROUP) {
      let uriGroup = urlEdusign["GROUPS"] + "/" + g;
      groupsDetail.push(
        await GetBddProvider(uriGroup, token).then((res) => {
          if (typeof res === "object" && res.result) {
            return res.result;
          }
        })
      );
    }

    let uri = url + "api/promos?";
    groupsDetail.forEach((group, i, groups) => {
      uri += groups.length > 1 ? "IdEdusign[]=" : "IdEdusign=";
      uri += group.PARENT ? group.PARENT : group.ID;
      uri += i + 1 < groups.length ? "&" : "";
    });
    await GetBddProvider(uri).then((res) => {
      setLoad(true);
      if (typeof res === "object") {
        setPromos(res["hydra:member"]);
      } else {
        setMsg({ txt: "Erreur de chargement de la promo", type: "warning" });
        autoCloseMsg(setMsg, 2000);
      }
    });
  };

  const getEleves = () => {
    let uri = url + "api/eleves?";
    promos.forEach((promo, i, promos) => {
      uri += promos.length > 1 ? "promos[]=" : "promos=";
      uri += promo["@id"];
      uri += i + 1 < promos.length ? "&" : "";
    });
    GetBddProvider(uri).then((res) => {
      if (typeof res === "object") {
        setEleves(res["hydra:member"]);
      } else {
        // gérer les erreurs
      }
    });
  };

  if (!load) {
    return (
      <div className="text-center">
        <SmallSpinner />
      </div>
    );
  }

  if (
    Object.values(connectors).some((v) => v === connector) &&
    cours.API_ID &&
    !seance
  ) {
    return (
      <div className="card text-white bg-warning my-2">
        <div className="card-header text-left p-2">
          <h5 className="text-left m-0">
            {cours.NAME} - La session est inexistante dans Galia !
          </h5>
        </div>
      </div>
    );
  }

  return (promos === null && seance === undefined) || absencesNocx === null ? (
    cours.API_ID ? (
      <div className="card text-white bg-warning my-2">
        <div className="card-header d-flex justify-content-between p-2">
          <h5 className="text-left m-0">
            {cours.NAME} - la session est inexistante dans Galia
          </h5>
        </div>
      </div>
    ) : (
      <AbsPromoNoSync
        cours={cours}
        periode={_PERIODE}
        duree={_DUREE}
        absences={absencesEdusign}
        absencesNocx={absencesNocx}
        token={token}
        setMaj={setMaj}
      />
    )
  ) : (promos !== null &&
      promos.length > 0 &&
      promos.length === cours.SCHOOL_GROUP.length) ||
    seance !== undefined ? (
    <div
      className={`card my-2 ${
        absencesEdusign.length === 0 ? "text-white bg-success" : ""
      }`}
    >
      <div className="card-header text-left p-2">
        <h5 className="text-left m-0">
          {seance
            ? `${seance.Libelle_Action} - ${seance.Libelle_Court}`
            : `${promos.map((p) => p.name + " - ")}${cours.NAME}`}
          {absencesEdusign.length === 0 ? " - Pas d'absent !" : null}
        </h5>
        <small className="mr-4">
          {`${dateFormated(cours.START)} - ${_PERIODE} (${_DUREE})`}
        </small>
      </div>
      {absencesEdusign.length === 0 ? null : (
        <div className="card-body text-left p-0">
          {msg !== null ? (
            <MsgAlert
              msg={msg.txt}
              type={msg.type}
              close={() => autoCloseMsg(setMsg)}
            />
          ) : null}
          <ul className="list-group list-group-flush">
            {absencesEdusign.map((student, i) => {
              let el = [];
              if (seance === undefined) {
                el = eleves.filter((e) => e.IdEdusign === student.studentId)[0];
              } else {
                el = undefined;
              }
              if (seance !== undefined || el !== undefined) {
                return (
                  <AbsElevesEdusign
                    token={token}
                    eleve={el}
                    student={student}
                    seance={seance}
                    connector={connector}
                    periode={_PERIODE}
                    duree={_DUREE}
                    promos={promos}
                    absencesNocx={absencesNocx}
                    cours={cours}
                    setMaj={setMaj}
                    key={i}
                  />
                );
              }
              return (
                <AbsEleveNoSync
                  student={student}
                  token={token}
                  key={student._id}
                />
              );
            })}
          </ul>
        </div>
      )}
    </div>
  ) : (
    <AbsPromoNoSync
      cours={cours}
      periode={_PERIODE}
      duree={_DUREE}
      absences={absencesEdusign}
      absencesNocx={absencesNocx}
      token={token}
      setMaj={setMaj}
    />
  );
};

export default AbsPromoEdusign;
