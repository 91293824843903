import React, { useContext, useEffect, useState } from "react";
import AppContext from "../../../Context/AppContext";
import PostBddProvider from "../../../Providers/PostBddProvider";
import UpdateBddProvider from "../../../Providers/UpdateBddProvider";
import MsgAlert from "../../common/MsgAlert";
import Spinner from "../../common/Spinner";
import { autoCloseMsg } from "../../tools/messagesUtils";

const EditAppreciationSem = ({ appreciation, maj }) => {
  const { url } = useContext(AppContext);
  const [comment, setComment] = useState({});
  const [save, setSave] = useState(true);
  const [msg, setMsg] = useState(null);

  useEffect(() => {
    setComment({ ...appreciation });
  }, [appreciation]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setSave(false);
    if (comment.id) {
      let uri = url + comment["@id"].slice(1);
      UpdateBddProvider(uri, comment).then((res) => {
        setSave(true);
        if (typeof res === "object" && res.id) {
          maj(true);
          setMsg({
            txt: "L'appréciation a bien été mise à jour",
            type: "success",
          });
          autoCloseMsg(setMsg, 5000);
        } else {
          maj(true);
          setMsg({
            txt: "Erreur de mise à jour",
            type: "danger",
          });
          autoCloseMsg(setMsg, 5000);
        }
      });
    } else {
      let uri = url + "api/comment_semestres";
      PostBddProvider(uri, comment).then((res) => {
        setSave(true);
        if (typeof res === "object" && res.id) {
          setComment(res);
          maj(true);
          setMsg({
            txt: "L'appréciation a bien été enregistrée",
            type: "success",
          });
          autoCloseMsg(setMsg, 5000);
        } else {
          maj(true);
          setMsg({
            txt: "Erreur d'enregistrement",
            type: "danger",
          });
          autoCloseMsg(setMsg, 5000);
        }
      });
    }
  };

  const validated = () => {
    return comment.comment === "" || comment.comment === null || !save;
  };

  return (
    <div
      className="modal fade"
      id="ModalAppreciation"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header bg-info text-white">
            <h5 className="modal-title">Saisie de l'appréciation générale</h5>
            <button
              className="close text-white"
              type="button"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          {!save ? (
            <div className="pb-5 modal-body text-info">
              <Spinner />
            </div>
          ) : (
            <div className="modal-body">
              {msg !== null ? (
                <div className="mx-4">
                  <MsgAlert
                    msg={msg.txt}
                    type={msg.type}
                    close={() => autoCloseMsg(setMsg)}
                  />
                </div>
              ) : null}
              <div className="form-group">
                <label>Appréciation</label>
                <textarea
                  className="form-control"
                  placeholder="Saisir l'appréciation"
                  required
                  rows="5"
                  value={comment.comment || ""}
                  onChange={(e) =>
                    setComment({ ...comment, comment: e.target.value })
                  }
                ></textarea>
              </div>
            </div>
          )}
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-outline-secondary"
              data-dismiss="modal"
            >
              Fermer
            </button>
            <button
              type="submit"
              className="btn btn-info"
              onClick={handleSubmit}
              disabled={validated()}
            >
              Enregistrer
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditAppreciationSem;
