import React from "react";

export default React.createContext({
  formateur: null,
  selectedProg: {},
  selectedNotes: {},
  selectedRetards: null,
  selectedAbsences: {},
  selectedAppreciations: {},
  modifAbs: null,
  promoSelected: null,
  setSelected: () => {},
  setSelectedNotes: () => {},
  setSelectedRetards: () => {},
  setSelectedAbsences: () => {},
  setSelectedAppreciations: () => {},
  setModifAbs: () => {},
  setModifRtd: ()=>{},
  setPromoSelected: () => {},
  delAbsence: () => {},
});
