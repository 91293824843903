import { useCallback, useEffect, useRef } from "react";
import DeleteBddProvider from "../Providers/DeleteBddProvider";

/**
 * hook use fetch DELETE
 *
 * @returns DeleteBddProvider
 */
export default function useDelete() {
  const abortController = useRef(new AbortController());

  useEffect(() => () => abortController.current.abort(), []);

  return useCallback(
    /**
     * Fetch -> DELETE data on BDD
     * 
     * @param {String} url API url
     * @param {Object} tk token (optionnal)
     * @returns {Promise<JSON|Number>} the Fetch Result in JSON or Statu's error code
     */
    (url, tk = undefined) =>
      DeleteBddProvider(url, tk, abortController.current.signal),
    []
  );
}
