import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import Spinner from "../../common/Spinner";
import MsgAlert from "../../common/MsgAlert";
import { useContext } from "react";
import AppContext from "../../../Context/AppContext";
import UpdateBddProvider from "../../../Providers/UpdateBddProvider";
import GetBddProvider from "../../../Providers/GetBddProvider";
import { autoCloseMsg } from "../../tools/messagesUtils";

const EditFormateurPromos = ({ maj, sites, selected }) => {
  const { url } = useContext(AppContext);
  const [formateur, setformateur] = useState(selected);
  const [promos, setpromos] = useState(null);
  const [save, setsave] = useState(false);
  const [msg, setMsg] = useState(null);

  if (formateur.matieres !== undefined) {
    formateur.matieres.forEach((el, i) => {
      if (typeof el === "object") {
        formateur.matieres[i] = el["@id"];
      }
    });
  }

  if (formateur.user.sites !== undefined) {
    formateur.user.sites.forEach((el, i) => {
      if (typeof el === "object") {
        formateur.user.sites[i] = el["@id"];
      }
    });
  }

  if (formateur.promos !== undefined) {
    formateur.promos.forEach((el, i) => {
      if (typeof el === "object") {
        formateur.promos[i] = el["@id"];
      }
    });
  }

  const load = async () => {
    let uri = url + "api/promos?";
    sites.forEach((site, i, sites) => {
      uri += sites.length > 1 ? "site[]=" : "site=";
      uri += site["@id"];
      uri += i + 1 < sites.length ? "&" : "";
    });
    await GetBddProvider(uri).then((res) => {
      if (typeof res === "object") {
        let result = res["hydra:member"];
        setpromos(result);
        setsave(true);
      } else {
        setsave(true);
      }
    });
  };

  useEffect(() => {
    if (sites.length > 0) {
      load();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sites]);

  useEffect(() => {
    if (formateur !== selected) {
      setformateur(selected);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  const ChangePromos = (promo) => {
    if (formateur.promos === undefined) {
      setformateur({
        ...formateur,
        promos: [promo],
      });
    } else if (formateur.promos.includes(promo)) {
      setformateur({
        ...formateur,
        promos: formateur.promos.filter((p) => p !== promo),
      });
    } else {
      setformateur({
        ...formateur,
        promos: [...formateur.promos, promo],
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setsave(false);
    let temp = Object.assign({}, formateur);
    temp = { ...temp, user: temp.user["@id"] };
    UpdateBddProvider(url + "api/formateurs/" + temp.id, temp).then((res) => {
      setsave(true);
      if (typeof res === "object" && res.id) {
        setMsg({
          text: "Mise a jour éffectué",
          type: "success",
        });
        maj(true);
        autoCloseMsg(setMsg, 5000);
      } else {
        setMsg({ text: "erreur", type: "danger" });
        autoCloseMsg(setMsg, 5000);
      }
    });
  };

  return (
    <div
      className="modal fade"
      id="ModalPromos"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-lg modal-dialog-centered"
        role="document"
      >
        <div className="modal-content">
          <form>
            <div className="modal-header bg-info text-white">
              <h5 className="modal-title">
                <FontAwesomeIcon icon="edit" /> &nbsp; Affecter une promo à{" "}
                {formateur.user.firstName} {formateur.user.name}
              </h5>
              <button
                type="button"
                className="close text-white"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            {!save ? (
              <div className="pb-5 modal-body text-info">
                <Spinner />
              </div>
            ) : (
              <div className="modal-body">
                {msg !== null ? (
                  <div className="mx-4">
                    <MsgAlert
                      msg={msg.text}
                      type={msg.type}
                      close={()=>autoCloseMsg(setMsg)}
                    />
                  </div>
                ) : null}

                <div className="form-group">
                  <label>Promos</label>
                  <div className="custom-control custom-switch d-flex justify-content-between flex-wrap text-left">
                    {formateur.user.sites !== undefined &&
                    formateur.user.sites.length > 0 ? (
                      sites.map((site, i) => {
                        return promos.map((promo, i) => {
                          if (
                            promo.site["@id"] === site["@id"] &&
                            formateur.user.sites.includes(site["@id"])
                          ) {
                            return (
                              <div key={i} className="col-md-6">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  id={"chPromo" + promo.id}
                                  value={promo["@id"]}
                                  checked={
                                    formateur.promos !== null &&
                                    formateur.promos !== undefined
                                      ? formateur.promos.includes(promo["@id"])
                                      : false
                                  }
                                  onChange={(e) => ChangePromos(promo["@id"])}
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor={"chPromo" + promo.id}
                                >
                                  {site.name} - {promo.name}
                                </label>
                              </div>
                            );
                          } else {
                            return null;
                          }
                        });
                      })
                    ) : (
                      <span className="text-danger">
                        Le formateur n'a pas de site d'affectation !
                      </span>
                    )}
                  </div>
                </div>
              </div>
            )}
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Fermer
              </button>
              <button
                type="submit"
                className="btn btn-info"
                onClick={handleSubmit}
              >
                Enregistrer
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditFormateurPromos;
