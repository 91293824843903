import React, { useState, useContext, useEffect } from "react";
import Spinner from "../../common/Spinner";
import GetBddProvider from "../../../Providers/GetBddProvider";
import AppContext from "../../../Context/AppContext";
import PostBddProvider from "../../../Providers/PostBddProvider";
import MsgAlert from "../../common/MsgAlert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GenPass from "../../GenPass";
import UpdateBddProvider from "../../../Providers/UpdateBddProvider";
import { autoCloseMsg } from "../../tools/messagesUtils";
import { triByName } from "../../tools/sortUtils";

const EditEleve = ({ maj, selected, sites, listSites, actif }) => {
  const [promos, setpromos] = useState(null);
  const [entreprises, setentreprises] = useState(null);
  const [eleve, seteleve] = useState(selected);
  const [save, setsave] = useState(true);
  const [msg, setMsg] = useState(null);
  const [loading, setloading] = useState(false);
  const { url } = useContext(AppContext);

  useEffect(() => {
    if (loading) {
      loadForm(eleve.user.sites[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  useEffect(() => {
    if (selected !== eleve) {
      setpromos(null);
      if (selected["@id"]) {
        load(selected.id);
      } else {
        seteleve(selected);
        setpromos(
          actif ? sites[0].promos.filter((p) => p.actif) : sites[0].promos
        );
        loadEntreprises(sites[0].id);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  const load = (id) => {
    setsave(false);
    GetBddProvider(url + "api/eleves/" + id).then((res) => {
      setsave(true);
      if (typeof res === "object") {
        res.promos.forEach((el, i) => {
          if (typeof el === "object") {
            res.promos[i] = el["@id"];
          }
        });
        res.user.sites.forEach((el, i) => {
          if (typeof el === "object") {
            res.user.sites[i] = el["@id"];
          }
        });
        res.entreprises.forEach((el, i) => {
          if (typeof el === "object") {
            res.entreprises[i] = el["@id"];
          }
        });
        seteleve(res);
        loadForm(res.user.sites[0]);
      }
    });
  };

  const loadEntreprises = (id) => {
    let uri = url + "api/entreprises?user.sites=" + id;
    GetBddProvider(uri).then((res) => {
      setloading(false);
      if (typeof res === "object") {
        setentreprises(res["hydra:member"]);
      } else {
        setloading(false);
        setMsg({ text: "erreur", type: "danger" });
        autoCloseMsg(setMsg, 5000);
      }
    });
  };

  const loadForm = (id) => {
    let uri = url + "api/promos?site=" + id;
    GetBddProvider(uri).then((res) => {
      if (typeof res === "object") {
        setpromos(
          actif
            ? res["hydra:member"].filter((p) => p.actif)
            : res["hydra:member"]
        );
        loadEntreprises(id);
      } else {
        setloading(false);
        setMsg({ text: "erreur", type: "danger" });
        autoCloseMsg(setMsg, 5000);
      }
    });
  };

  const ChangePromos = (promo) => {
    let temp = eleve.promos;
    temp.push(promo);
    seteleve({
      ...eleve,
      promos: temp,
    });
  };

  const removePromo = (p) => {
    let tmp = eleve.promos.filter((val) => val !== p);
    seteleve({
      ...eleve,
      promos: tmp,
    });
  };

  const changeProfil = (role) => {
    if (eleve.user.roles.includes(role)) {
      seteleve({
        ...eleve,
        user: {
          ...eleve.user,
          roles: eleve.user.roles.filter((el) => el !== role),
        },
      });
    } else {
      seteleve({
        ...eleve,
        user: { ...eleve.user, roles: [...eleve.user.roles, role] },
      });
    }
  };

  const validateForm = () => {
    if (eleve.user === undefined) {
      return false;
    }
    return (
      eleve.user.name &&
      eleve.user.firstName &&
      eleve.user.pseudo !== undefined &&
      eleve.user.pseudo.length >= 5 &&
      eleve.user.email &&
      eleve.promos !== undefined &&
      eleve.promos.length > 0 &&
      eleve.user.sites
    );
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setsave(false);

    if (eleve.id) {
      let temp = Object.assign({}, eleve);
      for (let ok = false; ok === false; ) {
        let pseudoTest = temp.user.pseudo.slice(-1);
        pseudoTest === " "
          ? (temp.user.pseudo = temp.user.pseudo.slice(0, -1))
          : (ok = true);
      }
      let uri = url + "api/users/" + temp.user.id;
      UpdateBddProvider(uri, temp.user).then((res) => {
        if (typeof res === "object" && res.id) {
          temp = { ...temp, user: temp.user["@id"] };
          uri = url + "api/eleves/" + temp.id;
          UpdateBddProvider(uri, temp).then((res) => {
            setsave(true);
            if (typeof res === "object" && res.id) {
              setMsg({
                text: "l'élève à bien été modifié",
                type: "success",
              });
              maj(true);
              autoCloseMsg(setMsg, 5000);
            } else {
              let txt = res.violations
                ? res.violations.map((violation, i) => {
                    return <div key={i}>- {violation.message}</div>;
                  })
                : "Erreur";
              setMsg({ text: txt, type: "danger" });
              autoCloseMsg(setMsg, 5000);
            }
          });
        } else {
          let txt = res.violations
            ? res.violations.map((violation, i) => {
                return <div key={i}>- {violation.message}</div>;
              })
            : "Erreur";
          setsave(true);
          setMsg({ text: txt, type: "danger" });
          autoCloseMsg(setMsg, 5000);
        }
      });
    } else {
      let temp = Object.assign({}, eleve);
      for (let ok = false; ok === false; ) {
        let pseudoTest = temp.user.pseudo.slice(-1);
        pseudoTest === " "
          ? (temp.user.pseudo = temp.user.pseudo.slice(0, -1))
          : (ok = true);
      }
      let uri = url + "api/users";
      temp.user.password = GenPass();
      PostBddProvider(uri, temp.user).then((res) => {
        if (typeof res === "object" && res.id) {
          uri = url + "api/eleves";
          let data = {
            ...temp,
            user: "/api/users/" + res.id,
          };
          uri = url + "api/eleves";
          PostBddProvider(uri, data).then((res) => {
            setsave(true);
            // réinitialisation de l'élève
            if (typeof res === "object" && res.id) {
              seteleve({
                promos: eleve.promos,
                user: {
                  actif: true,
                  roles: ["ROLE_ELEVE"],
                  sites: [sites[0]["@id"]],
                },
              });
              setMsg({
                text: "l'élève à bien été enregistrée",
                type: "success",
              });
              maj(true);
              autoCloseMsg(setMsg, 5000);
            } else {
              let txt = res.violations
                ? res.violations.map((violation, i) => {
                    return <div key={i}>- {violation.message}</div>;
                  })
                : "Erreur";
              setMsg({ text: txt, type: "danger" });
              autoCloseMsg(setMsg, 5000);
            }
          });
        } else {
          let txt = res.violations
            ? res.violations.map((violation, i) => {
                return <div key={i}>- {violation.message}</div>;
              })
            : "Erreur";
          setsave(true);
          setMsg({ text: txt, type: "danger" });
          autoCloseMsg(setMsg, 5000);
        }
      });
    }
  };

  const formulaire = () => {
    return (
      <>
        <div className="modal-header bg-info text-white">
          <h5 className="modal-title">
            <FontAwesomeIcon icon="edit" /> &nbsp;{" "}
            {eleve.id ? "Modifier" : "Ajouter"} un élève
          </h5>
          <button
            type="button"
            className="close text-white"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        {save === false ? (
          <div className="pb-5 modal-body text-info ">
            <Spinner />
          </div>
        ) : (
          <div className="modal-body">
            {msg !== null ? (
              <div className="mx-4">
                <MsgAlert msg={msg.text} type={msg.type} close={()=>autoCloseMsg(setMsg)} />
              </div>
            ) : null}
            <div className="form-group">
              <label htmlFor="name">Nom *</label>
              <input
                className="form-control"
                type="text"
                name="name"
                autoComplete="off"
                value={eleve.user.name || ""}
                placeholder="Nom"
                onChange={(e) =>
                  seteleve({
                    ...eleve,
                    user: {
                      ...eleve.user,
                      name: e.target.value,
                      pseudo: `${
                        eleve.user.firstName
                          ? eleve.user.firstName.toLowerCase().slice(0, 1)
                          : ""
                      }.${e.target.value.toLowerCase()}`,
                    },
                  })
                }
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="firstName">Prénom *</label>
              <input
                className="form-control"
                type="text"
                name="firstName"
                autoComplete="off"
                value={eleve.user.firstName || ""}
                disabled={!eleve.user.name}
                placeholder="Prénom"
                onChange={(e) => {
                  seteleve({
                    ...eleve,
                    user: {
                      ...eleve.user,
                      firstName: e.target.value,
                      pseudo: `${e.target.value.toLowerCase().slice(0, 1)}.${
                        eleve.user.name.toLowerCase() || ""
                      }`,
                    },
                  });
                }}
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="pseudo">Nom d'utilisateur *</label>
              <input
                className={`form-control ${
                  (eleve.user.pseudo === undefined ||
                    eleve.user.pseudo.length < 5) &&
                  "is-invalid"
                }`}
                type="text"
                name="pseudo"
                minLength={5}
                autoComplete="off"
                value={eleve.user.pseudo || ""}
                placeholder="Nom d'utilisateur"
                onChange={(e) => {
                  seteleve({
                    ...eleve,
                    user: { ...eleve.user, pseudo: e.target.value },
                  });
                }}
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="form-email">adresse mail *</label>
              <input
                className="form-control"
                type="email"
                name="form-email"
                autoComplete="off"
                value={eleve.user.email || ""}
                placeholder="adresse mail"
                onChange={(e) =>
                  seteleve({
                    ...eleve,
                    user: { ...eleve.user, email: e.target.value },
                  })
                }
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="form-edusign">ID Edusign</label>
              <div className="input-group">
                <input
                  className="form-control"
                  type="text"
                  name="form-edusign"
                  value={eleve.IdEdusign || ""}
                  placeholder="IDEdusign"
                  disabled
                />
                <div
                  className="input-group-append"
                  role="button"
                  onClick={() => {
                    if (window.confirm("supprimer l'ID Edusign ?")) {
                      seteleve({
                        ...eleve,
                        IdEdusign: null,
                      });
                    }
                  }}
                >
                  <span
                    className="input-group-text text-danger"
                    id="basic-addon2"
                  >
                    <FontAwesomeIcon icon="trash-alt" />
                  </span>
                </div>
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="selectSite">Site *</label>
              <select
                className="form-control"
                value={
                  eleve.user.sites !== undefined ? eleve.user.sites[0] : ""
                }
                name="selectSite"
                onChange={(e) => {
                  setpromos(null);
                  setentreprises(null);
                  setloading(true);
                  seteleve({
                    ...eleve,
                    entreprises: undefined,
                    promos: [],
                    user: { ...eleve.user, sites: [e.target.value] },
                  });
                }}
                required
              >
                <option value="" disabled>
                  Selectionnez un site
                </option>
                {listSites.map((data, i) => {
                  return (
                    <option value={data["@id"]} key={i}>
                      {data.name}
                    </option>
                  );
                })}
              </select>
            </div>

            {eleve.user.sites === undefined ? null : promos === null ||
              entreprises === null ? (
              <Spinner />
            ) : (
              <div>
                <label>promos *</label>

                {eleve.promos !== undefined && eleve.promos.length > 0 ? (
                  <div className="mb-2 d-flex flex-wrap justify-content-center">
                    {eleve.promos.map((p, i) => {
                      let tmpPromo = promos.filter((e) => e["@id"] === p)[0];
                      return tmpPromo !== undefined ? (
                        <div
                          className="m-2 bg-light border border-info rounded-pill px-2 font-italic"
                          key={i}
                        >
                          {tmpPromo.name}{" "}
                          <FontAwesomeIcon
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              if (window.confirm("Supprimer la promo ?")) {
                                removePromo(p);
                              }
                            }}
                            className="text-danger"
                            icon="times-circle"
                            title="Retirer la compétence"
                          />
                        </div>
                      ) : null;
                    })}
                  </div>
                ) : (
                  <div className="m-2 text-danger">
                    <span>Aucune promo sélectionnée</span>
                  </div>
                )}
                <div className="form-group d-flex">
                  <select
                    className="form-control"
                    value=""
                    onChange={(e) => {
                      ChangePromos(e.target.value);
                    }}
                    required
                  >
                    <option value="" disabled hidden>
                      Ajouter une promo
                    </option>
                    {promos.sort(triByName).map((data, i) => {
                      return (
                        <option
                          value={data["@id"]}
                          disabled={eleve.promos.includes(data["@id"])}
                          hidden={eleve.promos.includes(data["@id"])}
                          key={i}
                        >
                          {data.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="form-group">
                  <label htmlFor="selectEnt">Tuteur</label>
                  <select
                    name="selectEnt"
                    className="form-control"
                    value={
                      eleve.entreprises !== undefined &&
                      eleve.entreprises.length > 0
                        ? eleve.entreprises[0]
                        : ""
                    }
                    onChange={(e) => {
                      e.target.value !== ""
                        ? seteleve({ ...eleve, entreprises: [e.target.value] })
                        : seteleve({ ...eleve, entreprises: [] });
                    }}
                  >
                    <option value="">Pas de tuteur</option>
                    {entreprises.map((entreprise, i) => {
                      return (
                        <option value={entreprise["@id"]} key={i}>
                          {entreprise.raisonSociale} - {entreprise.user.name}{" "}
                          {entreprise.user.firstName}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            )}

            <div className="custom-control custom-switch">
              <input
                type="checkbox"
                className="custom-control-input"
                id="checkPedago"
                checked={eleve.user.roles.includes("ROLE_PEDAGO")}
                onChange={(e) => changeProfil("ROLE_PEDAGO")}
              />
              <label className="custom-control-label" htmlFor="checkPedago">
                Membre de l'équipe pédagogique du centre
              </label>
            </div>

            <div className="custom-control custom-switch">
              <input
                type="checkbox"
                className=" custom-control-input"
                id="checkActif"
                checked={eleve.user.actif}
                onChange={(e) =>
                  seteleve({
                    ...eleve,
                    user: { ...eleve.user, actif: !eleve.user.actif },
                  })
                }
              />
              <label className=" custom-control-label" htmlFor="checkActif">
                Actif
              </label>
            </div>
          </div>
        )}
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-secondary"
            data-dismiss="modal"
          >
            Fermer
          </button>
          <button
            type="submit"
            className="btn btn-info"
            onClick={handleSubmit}
            disabled={!validateForm()}
          >
            Enregistrer
          </button>
        </div>
      </>
    );
  };

  return (
    <div
      className="modal fade"
      id="Modal"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-dialog-centered modal-dialog-scrollable"
        role="document"
      >
        <div className="modal-content">
          {sites === null ? <Spinner /> : formulaire()}
        </div>
      </div>
    </div>
  );
};

export default EditEleve;
