import React, { useState, useEffect, useContext } from "react";
import AppContext from "../../Context/AppContext";
import GetBddProvider from "../../Providers/GetBddProvider";
import Spinner from "../common/Spinner";
import Layout from "../common/Layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import EditFormation from "../contents/edits/EditFormation";
import ListFormations from "../contents/lists/ListFormations";
import DeleteBddProvider from "../../Providers/DeleteBddProvider";
import MsgAlert from "../common/MsgAlert";
import EditCoefMat from "../contents/edits/EditCoefMat";
import { autoCloseMsg } from "../tools/messagesUtils";

const Formations = () => {
  const [formations, setformations] = useState(null);
  const [selected, setselected] = useState({ matieres: null });
  const [selectedMatiere, setselectedMatiere] = useState(null);
  const [selectedCoef, setSelectedCoef] = useState(null);
  const [loaded, setloaded] = useState(false);
  const [maj, setmaj] = useState(false);
  const [msg, setMsg] = useState(null);
  const { url } = useContext(AppContext);

  const load = () => {
    GetBddProvider(url + "api/formations").then((res) => {
      if (typeof res === "object") {
        setformations(res["hydra:member"]);
        setloaded(true);
      }
    });
  };

  useEffect(() => {
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (maj) {
      setmaj(false);
      setloaded(false);
      load();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maj]);

  const del = (id) => {
    DeleteBddProvider(url + "api/formations/" + id).then((res) => {
      res === 204
        ? setMsg({ txt: "La formation à bien été supprimmée", type: "warning" })
        : setMsg({ txt: "Erreur lors de la suppression", type: "danger" });
      autoCloseMsg(setMsg, 5000);
      setmaj(true);
    });
  };

  return Layout(
    <div className="mt-4">
      <span className="d-flex flex-wrap justify-content-md-between justify-content-center m-3">
        <div></div>
        <h2 className="m-0">liste des formations</h2>
        <button
          type="button"
          onClick={(e) => setselected({ matieres: null })}
          className="btn btn-info"
          data-toggle="modal"
          data-target="#Modal"
        >
          <FontAwesomeIcon icon="plus-circle" /> &nbsp; Ajouter
        </button>
      </span>
      {msg !== null ? (
        <MsgAlert msg={msg.txt} type={msg.type} close={() => autoCloseMsg(setMsg)} />
      ) : null}
      <EditFormation maj={setmaj} selected={selected} />
      <EditCoefMat
        maj={setmaj}
        formation={selected}
        matiere={selectedMatiere}
        coefSelect={selectedCoef}
      />
      <hr className="m-4" />
      {!loaded ? (
        <Spinner />
      ) : (
        <div className="d-flex flex-wrap justify-content-center">
          <ListFormations
            formations={formations}
            setselected={setselected}
            setselectedMatiere={setselectedMatiere}
            setSelectedCoef={setSelectedCoef}
            del={(e) => del(e)}
          />
        </div>
      )}
    </div>
  );
};

export default Formations;
