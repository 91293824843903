import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import AppContext from "../../Context/AppContext";
import DeleteBddProvider from "../../Providers/DeleteBddProvider";
import GetBddProvider from "../../Providers/GetBddProvider";
import Layout from "../common/Layout";
import MsgAlert from "../common/MsgAlert";
import Spinner from "../common/Spinner";
import EditSuiviItem from "../contents/edits/EditSuiviItem";
import { autoCloseMsg } from "../tools/messagesUtils";

const SetItemSuivi = () => {
  const { url } = useContext(AppContext);

  const [comportementItems, setComportementItems] = useState(null);
  const [realisationItems, setRealisationItems] = useState(null);
  const [selected, setSelected] = useState(null);
  const [maj, setMaj] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [msg, setMsg] = useState(null);

  useEffect(() => {
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (maj) {
      setMaj(false)
      setLoaded(false)
      load()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maj])

  const load = () => {
    loadCompItems();
  };

  const loadCompItems = () => {
    let uri = url + "api/comportement_items";
    GetBddProvider(uri).then((res) => {
      if (typeof res === "object") {
        setComportementItems(res["hydra:member"]);
        loadRealItems();
      } else {
        setMsg({
          txt: "Erreur de chargement des items Comportement",
          type: "danger",
        });
        autoCloseMsg(setMsg, 5000);
      }
    });
  };

  const loadRealItems = () => {
    let uri = url + "api/realisation_items";
    GetBddProvider(uri).then((res) => {
      setLoaded(true)
      if (typeof res === "object") {
        setRealisationItems(res["hydra:member"]);
      } else {
        setMsg({
          txt: "Erreur de chargement des items Réalisation",
          type: "danger",
        });
        autoCloseMsg(setMsg, 5000);
      }
    });
  };

  const delItem = (iri) => {
    let uri = url + iri.slice(1);
    DeleteBddProvider(uri).then((res) => {
      if (res === 204) {
        setMaj(true);
        setMsg({ txt: "l'item a bien été supprimé !", type: "success" });
        autoCloseMsg(setMsg, 5000);
      } else {
        setMsg({ txt: "Erreur de supression !", type: "danger" });
        autoCloseMsg(setMsg, 5000);
      }
    });
  };

  return Layout(
    <div className="m-4">
      <EditSuiviItem selected={selected} setMaj={setMaj} />
      <span className="d-flex flex-wrap justify-content-md-between justify-content-center m-3">
        <div></div>
        <h2 className="m-0">Items des suivis pédagogiques</h2>
        <button
          type="button"
          className="btn btn-info"
          data-toggle="modal"
          data-target="#ModalItem"
          onClick={() => setSelected(null)}
        >
          <FontAwesomeIcon icon="plus-circle" /> &nbsp; Ajouter
        </button>
      </span>
      <hr className="m-4" />
      {msg !== null ? (
        <MsgAlert msg={msg.txt} type={msg.type} close={() => autoCloseMsg(setMsg)} />
      ) : null}
      {comportementItems === null || realisationItems === null || !loaded ? (
        <Spinner />
      ) : (
        <React.Fragment>
          <h3>Items Comportement</h3>
          <div className="d-flex flex-wrap justify-content-center">
            {comportementItems.length < 1 ? (
              <h3 className="text-danger">Pas d'item</h3>
            ) : (
              comportementItems.map((item, i) => (
                <div
                  className="card shadow border-info col-md-4 p-0 m-3"
                  style={{ maxWidth: "20rem" }}
                  key={i}
                >
                  <div className="card-header d-flex justify-content-between text-info h-100 text-left">
                    <h5>{item.name}</h5>
                    <div>
                      <div>
                        <FontAwesomeIcon
                          className="text-success"
                          style={{ cursor: "pointer" }}
                          data-toggle="modal"
                          data-target="#ModalItem"
                          onClick={() => {
                            setSelected({
                              uri: url + "api/comportement_items",
                              item: item,
                            });
                          }}
                          icon="edit"
                        />
                      </div>
                      <div>
                        <FontAwesomeIcon
                          className="text-danger"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            if (window.confirm("Supprimer l'item ?")) {
                              delItem(item["@id"]);
                            }
                          }}
                          icon="trash-alt"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
          <h3>Items Réalisation</h3>
          <div className="d-flex flex-wrap justify-content-center">
            {realisationItems.length < 1 ? (
              <h3 className="text-danger">Pas d'item</h3>
            ) : (
              realisationItems.map((item, i) => (
                <div
                  className="card shadow border-info col-md-4 p-0 m-3"
                  style={{ maxWidth: "20rem" }}
                  key={i}
                >
                  <div className="card-header d-flex justify-content-between text-info h-100 text-left">
                    <h5>{item.name}</h5>
                    <div>
                      <div>
                        <FontAwesomeIcon
                          className="text-success"
                          style={{ cursor: "pointer" }}
                          data-toggle="modal"
                          data-target="#ModalItem"
                          onClick={() => {
                            setSelected({
                              uri: url + "api/realisation_items",
                              item: item,
                            });
                          }}
                          icon="edit"
                        />
                      </div>
                      <div>
                        <FontAwesomeIcon
                          className="text-danger"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            if (window.confirm("Supprimer l'item ?")) {
                              delItem(item["@id"]);
                            }
                          }}
                          icon="trash-alt"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default SetItemSuivi;
