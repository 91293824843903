import React from 'react'
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';

// Prévoir bar multiple par evaluation pour afficher la progression

const max = 5

const data = [
  {
    name: 'Item A',
    note: Math.floor(Math.random() * max),
    nb: Math.floor(Math.random() * max),
  },
  {
    name: 'Item B',
    note: Math.floor(Math.random() * max),
    nb: Math.floor(Math.random() * max),
  },
  {
    name: 'Item C',
    note: Math.floor(Math.random() * max),
    nb: Math.floor(Math.random() * max),
  },
  {
    name: 'Item D',
    note: Math.floor(Math.random() * max),
    nb: Math.floor(Math.random() * max),
  },
  {
    name: 'Item E',
    note: Math.floor(Math.random() * max),
    nb: Math.floor(Math.random() * max),
  },
  {
    name: 'Item F',
    note: Math.floor(Math.random() * max),
    nb: Math.floor(Math.random() * max),
  },
  {
    name: 'Item G',
    note: Math.floor(Math.random() * max),
    nb: Math.floor(Math.random() * max),
  },
  {
    name: 'Item H',
    note: Math.floor(Math.random() * max),
    nb: Math.floor(Math.random() * max),
  },
  {
    name: 'Item I',
    note: Math.floor(Math.random() * max),
    nb: Math.floor(Math.random() * max),
  },
  {
    name: 'Item J',
    note: Math.floor(Math.random() * max),
    nb: Math.floor(Math.random() * max),
  },
  {
    name: 'Item K',
    note: Math.floor(Math.random() * max),
    nb: Math.floor(Math.random() * max),
  },
  {
    name: 'Item L',
    note: Math.floor(Math.random() * max),
    nb: Math.floor(Math.random() * max),
  },
  {
    name: 'Item M',
    note: Math.floor(Math.random() * max),
    nb: Math.floor(Math.random() * max),
  },
  {
    name: 'Item N',
    note: Math.floor(Math.random() * max),
    nb: Math.floor(Math.random() * max),
  },
];


function BarChartsConponent() {
  return (
    <div className='d-flex justify-content-center'>
        <BarChart
          width={1500}
          height={600}
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="2 2" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="note" fill="blue" />
          <Bar dataKey="nb" fill="#82ca9d" />
        </BarChart>
      </div>
  )
}

export default BarChartsConponent