import React, { useContext, useEffect, useState } from "react";
import AppContext from "../../Context/AppContext";
import PostBddProvider from "../../Providers/PostBddProvider";
import UpdateBddProvider from "../../Providers/UpdateBddProvider";
import { useLoadBackCollectionData } from "../../Providers/Utils/Loaders/Nocx/Loaders";
import Layout from "../common/Layout";
import MsgAlert from "../common/MsgAlert";
import Spinner from "../common/Spinner";
import { autoCloseMsg } from "../tools/messagesUtils";

export const SanctionMotifs = () => {
  const { url } = useContext(AppContext);

  const [loaded, setLoaded] = useState(false);
  const [motifs, setMotifs] = useState([]);
  const [maj, setMaj] = useState(false);
  const [newMotif, setNewMotif] = useState({});
  const [msg, setMsg] = useState(null);

  const dataLoader = useLoadBackCollectionData();

  useEffect(() => {
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (maj) {
      setMaj(false);
      setNewMotif({});
      load();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maj]);

  const load = () => {
    let uri = url + "api/sanction_motifs";
    dataLoader(uri, setMotifs, {
      loader: setLoaded,
      setMsg: setMsg,
      errorMessage: `Erreur de chargement des motifs de vie scolaire !`,
      TypeError: "danger",
    });
  };

  const handleSubmit = () => {
    setLoaded(false);
    let data = { name: newMotif.name, priority: parseInt(newMotif.priority) };
    if (newMotif["@id"]) {
      //Update
      let uri = url + newMotif["@id"].slice(1);
      UpdateBddProvider(uri, data).then((res) => {
        if (typeof res === "object" && res.id) {
          setMaj(true);
          setMsg({ txt: "Modification enregistrée", type: "success" });
          autoCloseMsg(setMsg, 5000);
        } else {
          setLoaded(true);
          setMsg({ txt: "Erreur d'enregistrement", type: "danger" });
          autoCloseMsg(setMsg, 5000);
        }
      });
    } else {
      //Post
      let uri = url + "api/sanction_motifs";
      PostBddProvider(uri, data).then((res) => {
        if (typeof res === "object" && res.id) {
          setMaj(true);
          setMsg({ txt: "Motif créé", type: "success" });
          autoCloseMsg(setMsg, 5000);
        } else {
          setLoaded(true);
          setMsg({ txt: "Erreur d'enregistrement", type: "danger" });
          autoCloseMsg(setMsg, 5000);
        }
      });
    }
  };

  if (!loaded) {
    return Layout(<Spinner />);
  }

  return Layout(
    <div>
      <h1 className="mt-4">Liste des motifs</h1>
      <hr className="m-4" />
      {msg !== null ? (
        <MsgAlert msg={msg.txt} type={msg.type} close={() => setMsg(null)} />
      ) : null}
      <div className="form-row align-items-center m-4">
        <div className="form-group col-md-2 col-xl-2 m-0 mt-2">
          <input
            type="number"
            className="form-control"
            placeholder="Priority"
            value={newMotif.priority || ""}
            onChange={(e) =>
              setNewMotif({ ...newMotif, priority: e.target.value })
            }
          />
        </div>
        <div className="form-group col-md-6 col-xl-7 m-0 mt-2">
          <input
            type="text"
            className="form-control"
            placeholder="Motif"
            value={newMotif.name || ""}
            onChange={(e) => setNewMotif({ ...newMotif, name: e.target.value })}
          />
        </div>
        <div className="col-md-4 col-xl-3 mt-2">
          <button
            className="btn btn-info mr-2"
            onClick={handleSubmit}
            disabled={!newMotif.name}
          >
            {newMotif["@id"] ? "Mettre à jour" : "Enregistrer"}
          </button>
          <button
            className="btn btn-info"
            onClick={() => setNewMotif({})}
            disabled={!newMotif.name}
          >
            Reset
          </button>
        </div>
      </div>
      <hr className="m-4" />
      {motifs.length === 0 ? (
        <h3>Pas de motif !</h3>
      ) : (
        <ul className="list-group">
          {motifs.map((motif, index) => (
            <li
              className="list-group-item"
              onClick={() => setNewMotif(motif)}
              key={index}
            >
              {motif.priority} - {motif.name}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
