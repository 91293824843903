import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { useEffect } from "react";
import { useContext } from "react";
import AppContext from "../../../Context/AppContext";
import PostBddProvider from "../../../Providers/PostBddProvider";
import UpdateBddProvider from "../../../Providers/UpdateBddProvider";
import MsgAlert from "../../common/MsgAlert";
import Spinner from "../../common/Spinner";
import { autoCloseMsg } from "../../tools/messagesUtils";

const EditSuiviItem = ({ selected, setMaj }) => {
  const { url } = useContext(AppContext);
  const [item, setItem] = useState(selected !== null ? selected.item : {});
  const [uri, setUri] = useState(selected !== null ? selected.uri : null);
  const [save, setSave] = useState(true);
  const [msg, setMsg] = useState(null);

  useEffect(() => {
    if (selected === null) {
      setItem({});
      setUri(null);
    } else {
      if (selected.item !== item) {
        setItem(selected.item);
      }
      if (selected.uri !== uri) {
        setUri(selected.uri);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setSave(false);
    if (item.id) {
      UpdateBddProvider(uri + "/" + item.id, item).then((res) => {
        setSave(true);
        if (typeof res === "object" && res.id) {
          setMsg({
            text: "L'item a bien été modifé",
            type: "success",
          });
          autoCloseMsg(setMsg, 5000);
          setItem({});
          setUri(null);
          setMaj(true);
        } else {
          setMsg({
            text: "Erreur de modification",
            type: "danger",
          });
          autoCloseMsg(setMsg, 5000);
        }
      });
    } else {
      PostBddProvider(uri, item).then((res) => {
        setSave(true);
        if (typeof res === "object" && res.id) {
          setMsg({
            text: "L'item a bien été créé",
            type: "success",
          });
          autoCloseMsg(setMsg, 5000);
          setItem({});
          setUri(null);
          setMaj(true);
        } else {
          setMsg({
            text: "Erreur de modification",
            type: "danger",
          });
          autoCloseMsg(setMsg, 5000);
        }
      });
    }
  };

  return (
    <div
      className="modal fade"
      id="ModalItem"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header bg-info text-white">
            <h5 className="modal-title">
              <FontAwesomeIcon icon="edit" /> &nbsp;{" "}
              {item !== null && item.id ? "Modifier" : "Ajouter"} un item
            </h5>
            <button
              type="button"
              className="close text-white"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          {!save ? (
            <div className="pb-5 modal-body text-info">
              <Spinner />
            </div>
          ) : (
            <div className="modal-body">
              {msg !== null ? (
                <div className="mx-4">
                  <MsgAlert msg={msg.text} type={msg.type} close={() => autoCloseMsg(setMsg)} />
                </div>
              ) : null}
              <div className="form-group">
                <label>Catégorie</label>
                <select
                  className="form-control"
                  value={uri || ""}
                  onChange={(e) => setUri(e.target.value)}
                  disabled={item.id}
                >
                  <option value="">Sélectionnez une catégorie</option>
                  <option value={url + "api/comportement_items"}>
                    Comportement
                  </option>
                  <option value={url + "api/realisation_items"}>
                    Réalisation
                  </option>
                </select>
              </div>
              <div className="form-group">
                <label>Nom de l'item</label>
                <input
                  type="text"
                  className="form-control"
                  value={item.name || ""}
                  placeholder="Nom de l'item"
                  onChange={(e) => setItem({ ...item, name: e.target.value })}
                />
              </div>
            </div>
          )}
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
            >
              Fermer
            </button>
            <button
              type="submit"
              className="btn btn-info"
              onClick={handleSubmit}
              disabled={!item.name || !uri}
            >
              Enregistrer
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditSuiviItem;
