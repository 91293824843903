import React from "react";
import { useContext } from "react";
import AppContext from "../../Context/AppContext";
import { useState } from "react";
import UpdateBddProvider from "../../Providers/UpdateBddProvider";
import { Redirect } from "react-router-dom";
import MsgAlert from "../common/MsgAlert";
import Spinner from "../common/Spinner";
import { StorageService } from "../tools/Storage/StorageService";
import { autoCloseMsg } from "../tools/messagesUtils";

const Validate = () => {
  const { url, user, setUser } = useContext(AppContext);

  const [newPassword, setnewPassword] = useState("");
  const [passConfirm, setpassConfirm] = useState("");
  const [loaded, setloaded] = useState(true);
  const [redirection, setredirection] = useState(false);
  const [msg, setMsg] = useState(null);

  const validateForm = () => {
    return newPassword.length >= 8 && passConfirm === newPassword;
  };

  const redirect = () => {
    setTimeout(() => {
      setMsg(null);
      StorageService.clear();
      setUser(null);
      setredirection(true);
    }, 3000);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setloaded(false);
    setMsg(null);
    let uri = url + "api/updatePass";

    UpdateBddProvider(uri, { pwd: newPassword, username: user.pseudo }).then(
      (res) => {
        if (res.status === "ok") {
          setMsg({
            texte: "Changement de mot de passe réussi, redirection encours...",
            type: "success",
          });
          redirect();
        } else {
          setMsg({ texte: res.message, type: "danger" });
          autoCloseMsg(setMsg, 5000);
          setloaded(true);
        }
      }
    );
  };

  const formulaire = () => {
    return (
      <div>
        <div className="form-group mt-3">
          <label>Nouveau mot de passe</label>
          <input
            type="password"
            className={
              newPassword.length >= 8
                ? "is-valid form-control"
                : "is-invalid form-control"
            }
            minLength="8"
            value={newPassword}
            placeholder="Nouveau Mot de passe"
            onChange={(e) => setnewPassword(e.target.value)}
            required
          />          
        </div>
        <div className="form-group">
          <label>Confirmation du mot de passe</label>
          <input
            value={passConfirm}
            placeholder="Confirmation du mot de passe"
            className={
              newPassword === passConfirm && newPassword.length >= 8
                ? "is-valid form-control"
                : "is-invalid form-control"
            }
            minLength="8"
            onChange={(e) => setpassConfirm(e.target.value)}
            type="password"
            required
          />
        </div>
        <button
          disabled={!validateForm()}
          type="submit"
          className="btn btn-outline-info"
        >
          Enregistrer
        </button>
      </div>
    );
  };

  return (
    <div className="text-info">
          {redirection || user.validate ? <Redirect to="/" /> : ""}
          <h2 className="mb-3 p-5 font-weight-normal">
          Afin de sécuriser votre compte merci de saisir un mot de passe personalisé, puis de vous reconnecter
        </h2>
      <form
        className="m-auto"
        style={{ maxWidth: "330px", width: "100%", padding: "5px" }}
        onSubmit={handleSubmit}
      >        
        {msg === null ? (
          <div className="text-danger">
            Le mot de passe doit contenir minimum 8 caractères !
          </div>
        ) : (
          <MsgAlert msg={msg.texte} type={msg.type} close={() => autoCloseMsg(setMsg)} />
        )}
        {!loaded ? <Spinner color="info" /> : formulaire()}
      </form>
    </div>
  );
};

export default Validate;
