import { useContext, useEffect, useState } from "react";
import AppContext from "../../Context/AppContext";
import DeleteBddProvider from "../../Providers/DeleteBddProvider";
import { useLoadBackCollectionData } from "../../Providers/Utils/Loaders/Nocx/Loaders";
import Layout from "../common/Layout";
import MsgAlert from "../common/MsgAlert";
import { triByName } from "../tools/sortUtils";

function AdminPurgeProgressions() {
  const { url } = useContext(AppContext);

  const getData = useLoadBackCollectionData();

  const [sites, setSites] = useState([]);
  const [siteSelected, setSiteSelected] = useState(null);
  const [promos, setPromos] = useState([]);
  const [promoSelected, setPromoSelected] = useState(null);
  const [progressions, setProgressions] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState([]);
  const [detailProcess, setDetailProcess] = useState(null);

  useEffect(() => {
    getData(url + "api/sites", setSites, { loader: setLoaded });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (siteSelected !== null) {
      let site = sites.find((s) => s["@id"] === siteSelected);
      setPromos((prev) => site.promos);
    } else setPromos([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteSelected]);

  useEffect(() => {
    if (promoSelected !== null) {
      loadProgressions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promoSelected]);

  const loadProgressions = () => {
    getData(`${url}api/progressions?promos=${promoSelected}`, setProgressions, {
      loader: setLoaded,
    });
  };

  const deleteProg = async () => {
    let n = 0;
    progressions.forEach(async (prog) => {
      n = await DeleteBddProvider(url + prog["@id"].slice(1)).then((res) => {
        if (res === 204) {
          return n + 1;
        } else {
          setError((prev) => [
            ...prev,
            `La progression ${prog["@id"]} n'a pas pu être supprimée`,
          ]);
          return n;
        }
      });
      setDetailProcess(
        `(${n} progressions supprimées sur ${progressions.length})`
      );

      if (n === progressions.length) {
        setDetailProcess(null);
        loadProgressions();
      }
    });
  };

  return Layout(
    <>
      <h1>Purge des progressions</h1>
      {!loaded && <MsgAlert msg="Chargement..." type="info" />}

      {sites.length > 0 && (
        <select
          value={siteSelected || ""}
          className="form-control"
          onChange={(e) => setSiteSelected(e.target.value)}
        >
          <option value="" disabled>
            Selectionner un site
          </option>
          {sites.sort(triByName).map((site) => (
            <option value={site["@id"]} key={site["@id"]}>
              {site.name}
            </option>
          ))}
        </select>
      )}

      {promos.length > 0 && (
        <select
          value={promoSelected || ""}
          className="form-control"
          onChange={(e) => setPromoSelected(e.target.value)}
        >
          <option value="" disabled>
            Selectionner une promo
          </option>
          {promos.sort(triByName).map((promo) => (
            <option value={promo["@id"]} key={promo["@id"]}>
              {promo.name}
            </option>
          ))}
        </select>
      )}
      {progressions.length > 0 && (
        <div>nbr de progressions : {progressions.length}</div>
      )}

      <button
        type="button"
        className="btn btn-danger"
        onClick={() =>
          window.confirm("Supprimer les progressions ?") && deleteProg()
        }
        disabled={progressions.length === 0}
      >
        Purger les progressions
      </button>
      {error.length > 0 && (
        <MsgAlert msg={error.map((e) => e + "\n")} type="danger" />
      )}
      {detailProcess && <MsgAlert msg={detailProcess} />}
    </>
  );
}

export default AdminPurgeProgressions;
