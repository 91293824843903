import React, { useContext, useEffect, useState } from "react";
import $ from "jquery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SmallSpinner from "../../common/SmallSpinner";
import UpdateBddProvider from "../../../Providers/UpdateBddProvider";
import AppContext from "../../../Context/AppContext";
import MsgAlert from "../../common/MsgAlert";
import GetBddProvider from "../../../Providers/GetBddProvider";
import { autoCloseMsg } from "../../tools/messagesUtils";

const ListAdminSites = ({
  site,
  listSocietes,
  selected,
  setSelected,
  init,
}) => {
  const { url, user, galiaUser } = useContext(AppContext);

  const [dataSelected, setDataSelected] = useState(null);
  const [listBase, setListBase] = useState([]);
  const [listLieu, setListLieu] = useState([]);
  const [dataEdusign, setDataEdusign] = useState(null);
  const [errorToken, setErrorToken] = useState(false);
  const [saved, setSaved] = useState(true);
  const [msg, setMsg] = useState(null);

  useEffect(() => {
    setErrorToken(false);
    if (site.TokenEdusign) {
      laodEdusign();
    }
    if (site.idGaliaSociete) {
      loadBase();
      loadLieu();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [site]);

  $(function () {
    $('[data-toggle="tooltip"]').tooltip();
  });

  const raz = () => {
    init();
    setDataEdusign(null);
    setDataSelected(null);
    setSaved(true);
  };

  const laodEdusign = () => {
    let uri = "https://ext.edusign.fr/v1/school";
    if (site.TokenEdusign) {
      GetBddProvider(uri, site.TokenEdusign).then((res) => {
        if (typeof res === "object") {
          if (res.status === "success") {
            setDataEdusign(res.result);
            setErrorToken(false);
          } else {
            setErrorToken(true);
          }
        } else {
          setErrorToken(true);
        }
      });
    }
  };

  const loadBase = () => {
    let uri = `https://groupe-alternance.sc-form.net/GaliaEDC/API/Entite/GetEntite?IDSociete=${site.idGaliaSociete}&ACTION=BASE`;
    GetBddProvider(uri, undefined, galiaUser).then((res) => {
      if (typeof res === "object") {
        setListBase(res);
      } else {
        setMsg({ txt: "Erreur de Chargement des base", type: "danger" });
        autoCloseMsg(setMsg, 5000);
      }
    });
  };

  const loadLieu = () => {
    let uri = `https://groupe-alternance.sc-form.net/GaliaEDC/API/Entite/GetEntite?IDSociete=${site.idGaliaSociete}&ACTION=LIEU`;
    GetBddProvider(uri, undefined, galiaUser).then((res) => {
      if (typeof res === "object") {
        setListLieu(res);
      } else {
        setMsg({ txt: "Erreur de Chargement des lieux", type: "danger" });
        autoCloseMsg(setMsg, 5000);
      }
    });
  };

  const saveChange = (uriSite) => {
    setSaved(false);
    let uri = url + uriSite.slice(1);
    let data;
    if (dataSelected.hasOwnProperty("cp")) {
      data = { ...dataSelected, cp: parseInt(dataSelected.cp) };
    } else if (dataSelected.hasOwnProperty("idGaliaSociete")) {
      data = { idGaliaSociete: parseInt(dataSelected.idGaliaSociete) };
    } else if (dataSelected.hasOwnProperty("idGaliaBase")) {
      data = { idGaliaBase: parseInt(dataSelected.idGaliaBase) };
    } else if (dataSelected.hasOwnProperty("idGaliaLieu")) {
      data = { idGaliaLieu: parseInt(dataSelected.idGaliaLieu) };
    } else if (dataSelected.hasOwnProperty("secteurGalia")) {
      data = { secteurGalia: dataSelected.secteurGalia };
    } else {
      data = { TokenEdusign: dataSelected };
    }
    UpdateBddProvider(uri, data).then((res) => {
      if (typeof res === "object" && res.id) {
        setMsg({
          txt: "les données ont bien été modifiées",
          type: "success",
        });
        autoCloseMsg(setMsg, 5000);
        raz();
      } else {
        setSaved(true);
        setMsg({ txt: "Erreur d'enregistrement", type: "danger" });
        autoCloseMsg(setMsg, 5000);
      }
    });
  };

  const BtnEdit = ({ datas, selector }) => {
    return (
      <span
        className="btn btn-outline-info btn-sm ml-2"
        onClick={() => {
          setSelected(selector);
          setDataSelected(datas);
        }}
      >
        <FontAwesomeIcon icon="edit" />
      </span>
    );
  };

  const BtnValid = ({ site }) => {
    let disabled = true;
    if (!saved) {
      disabled = true;
    } else {
      if (dataSelected === undefined) {
        disabled = true;
      } else if (
        dataSelected.hasOwnProperty("cp") ||
        dataSelected.hasOwnProperty("adresse") ||
        dataSelected.hasOwnProperty("ville")
      ) {
        disabled =
          dataSelected.adresse === undefined ||
          dataSelected.cp === undefined ||
          dataSelected.ville === undefined;
      } else if (dataSelected.hasOwnProperty("idGaliaSociete")) {
        disabled = dataSelected.idGaliaSociete === undefined;
      } else if (dataSelected.hasOwnProperty("idGaliaBase")) {
        disabled = dataSelected.idGaliaBase === undefined;
      } else if (dataSelected.hasOwnProperty("idGaliaLieu")) {
        disabled = dataSelected.idGaliaLieu === undefined;
      } else if (dataSelected.hasOwnProperty("secteurGalia")) {
        disabled = dataSelected.secteurGalia === undefined;
      } else {
        disabled = false;
      }
    }
    return (
      <button
        className="btn btn-outline-success btn-sm ml-2"
        disabled={disabled}
        onClick={() => {
          saveChange(site);
        }}
      >
        {saved ? <FontAwesomeIcon icon="check" /> : <SmallSpinner />}
      </button>
    );
  };

  return (
    <div className="card bg-light mb-3">
      <div className="card-header p-2 d-flex justify-content-between">
        <h5 className="m-0">{site.name}</h5>
      </div>
      <div className="card-body">
        {msg !== null ? (
          <MsgAlert msg={msg.txt} type={msg.type} close={() => autoCloseMsg(setMsg)} />
        ) : null}
        <div className="text-left d-flex justify-content-between">
          <div className="w-50 mr-4">
            {selected === site.id + "-adresse" ? (
              <div>
                <div className="input-group input-group-sm">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Adresse"
                    value={dataSelected.adresse || ""}
                    onChange={(e) =>
                      setDataSelected({
                        ...dataSelected,
                        adresse: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="d-flex">
                  <div className="input-group input-group-sm w-25">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="CP"
                      value={dataSelected.cp || ""}
                      onChange={(e) =>
                        setDataSelected({
                          ...dataSelected,
                          cp: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="input-group input-group-sm">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="VILLE"
                      value={dataSelected.ville || ""}
                      onChange={(e) =>
                        setDataSelected({
                          ...dataSelected,
                          ville: e.target.value,
                        })
                      }
                    />
                  </div>
                  <BtnValid site={site["@id"]} />
                </div>
              </div>
            ) : site.adresse ? (
              <div className="card-text">{site.adresse}</div>
            ) : (
              <div className="card-text text-muted">
                <em>adresse postale</em>
              </div>
            )}
            <h6 className="m-0">
              {selected === site.id + "-adresse" ? null : (
                <>
                  {site.cp ? (
                    <span>
                      {site.cp.toString().length < 5 ? "0" + site.cp : site.cp}{" "}
                    </span>
                  ) : (
                    <em className="text-muted">CP </em>
                  )}
                  {site.ville ? (
                    <span>
                      {site.ville}{" "}
                      <BtnEdit
                        selector={site.id + "-adresse"}
                        datas={{
                          adresse: site.adresse,
                          cp: site.cp,
                          ville: site.ville,
                        }}
                      />
                    </span>
                  ) : (
                    <em className="text-muted">
                      VILLE{" "}
                      <BtnEdit
                        selector={site.id + "-adresse"}
                        datas={{
                          adresse: site.adresse,
                          cp: site.cp,
                          ville: site.ville,
                        }}
                      />
                    </em>
                  )}
                </>
              )}
            </h6>
            {selected === site.id + "-societe" && user.role === 1 ? (
              <div className="input-group input-group-sm">
                <select
                  className="form-control"
                  value={dataSelected.idGaliaSociete || ""}
                  onChange={(e) =>
                    setDataSelected({
                      ...dataSelected,
                      idGaliaSociete: parseInt(e.target.value),
                    })
                  }
                >
                  <option value="" disabled>
                    société
                  </option>
                  {listSocietes.map((s) => (
                    <option value={s.ID} key={site.id + s.ID}>
                      {s.Libelle}
                    </option>
                  ))}
                </select>
                <BtnValid site={site["@id"]} />
              </div>
            ) : site.idGaliaSociete ? (
              <div className="card-text">
                Société Galia :{" "}
                {
                  listSocietes.filter((l) => l.ID === site.idGaliaSociete)[0]
                    ?.Libelle ?? "Chargment Galia"
                }{" "}
                {user.role === 1 && (
                  <BtnEdit
                    selector={site.id + "-societe"}
                    datas={{
                      idGaliaSociete: site.idGaliaSociete,
                    }}
                  />
                )}
              </div>
            ) : (
              <div className="card-text text-muted">
                <em>société</em>{" "}
                {user.role === 1 && (
                  <BtnEdit
                    selector={site.id + "-societe"}
                    datas={{
                      idGaliaSociete: site.idGaliaSociete,
                    }}
                  />
                )}
              </div>
            )}
            {selected === site.id + "-base" && user.role === 1 ? (
              <div className="input-group input-group-sm">
                <select
                  className="form-control"
                  value={dataSelected.idGaliaBase || ""}
                  onChange={(e) =>
                    setDataSelected({
                      ...dataSelected,
                      idGaliaBase: parseInt(e.target.value),
                    })
                  }
                >
                  <option value="" disabled>
                    base
                  </option>
                  {listBase.map((b) => (
                    <option value={b.ID} key={site.id + b.ID}>
                      {b.Libelle}
                    </option>
                  ))}
                </select>
                <BtnValid site={site["@id"]} />
              </div>
            ) : site.idGaliaBase ? (
              <div className="card-text">
                Base Galia :{" "}
                {listBase.length > 0 &&
                  listBase.filter((l) => l.ID === site.idGaliaBase)[0] ?
                  listBase.filter((l) => l.ID === site.idGaliaBase)[0].Libelle : <span className="text-danger">Erreur Lieu</span>}{" "}
                {user.role === 1 && site.idGaliaSociete && (
                  <BtnEdit
                    selector={site.id + "-base"}
                    datas={{
                      idGaliaBase: site.idGaliaBase,
                    }}
                  />
                )}
              </div>
            ) : (
              <div className="card-text text-muted">
                <em>base</em>{" "}
                {user.role === 1 && site.idGaliaSociete && (
                  <BtnEdit
                    selector={site.id + "-base"}
                    datas={{
                      idGaliaBase: site.idGaliaBase,
                    }}
                  />
                )}
              </div>
            )}
            {selected === site.id + "-lieu" && user.role === 1 ? (
              <div className="input-group input-group-sm">
                <select
                  className="form-control"
                  value={dataSelected.idGaliaLieu || ""}
                  onChange={(e) =>
                    setDataSelected({
                      ...dataSelected,
                      idGaliaLieu: parseInt(e.target.value),
                    })
                  }
                >
                  <option value="" disabled>
                    lieu
                  </option>
                  {listLieu.map((l) => (
                    <option value={l.ID} key={site.id + l.ID}>
                      {l.Libelle_Lieu}
                    </option>
                  ))}
                </select>
                <BtnValid site={site["@id"]} />
              </div>
            ) : site.idGaliaLieu ? (
              <div className="card-text">
                Lieu Galia :{" "}
                {listLieu.length > 0 &&
                  listLieu.filter((l) => l.ID === site.idGaliaLieu)[0] ?
                  listLieu.filter((l) => l.ID === site.idGaliaLieu)[0].Libelle_Lieu : <span className="text-danger">Erreur Lieu</span>}{" "}
                {user.role === 1 && site.idGaliaSociete && (
                  <BtnEdit
                    selector={site.id + "-lieu"}
                    datas={{
                      idGaliaLieu: site.idGaliaLieu,
                    }}
                  />
                )}
              </div>
            ) : (
              <div className="card-text text-muted">
                <em>lieu</em>{" "}
                {user.role === 1 && site.idGaliaSociete && (
                  <BtnEdit
                    selector={site.id + "-lieu"}
                    datas={{
                      idGaliaLieu: site.idGaliaLieu,
                    }}
                  />
                )}
              </div>
            )}
            {selected === site.id + "-secteur" && user.role === 1 ? (
              <div className="input-group input-group-sm">
                <input
                  type="text"
                  className="form-control"
                  placeholder="secteur"
                  value={dataSelected.secteurGalia || ""}
                  onChange={(e) =>
                    setDataSelected({
                      ...dataSelected,
                      secteurGalia: e.target.value,
                    })
                  }
                />
                <BtnValid site={site["@id"]} />
              </div>
            ) : site.secteurGalia ? (
              <div className="card-text">
                Secteur Galia : {site.secteurGalia}{" "}
                {user.role === 1 && site.idGaliaSociete && (
                  <BtnEdit
                    selector={site.id + "-secteur"}
                    datas={{
                      secteurGalia: site.secteurGalia,
                    }}
                  />
                )}
              </div>
            ) : (
              <div className="card-text text-muted">
                <em>Secteur Galia</em>{" "}
                {user.role === 1 && site.idGaliaSociete && (
                  <BtnEdit
                    selector={site.id + "-secteur"}
                    datas={{
                      secteurGalia: site.secteurGalia,
                    }}
                  />
                )}
              </div>
            )}
          </div>
          <div className="w-50">
            {selected === site.id + "-edusign" ? (
              <div className="input-group input-group-sm">
                <label className="h6 card-title mt-1 mr-1 mb-0">
                  Token Edusign
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Token Edusign"
                  value={dataSelected || ""}
                  onChange={(e) => setDataSelected(e.target.value)}
                />
                <BtnValid site={site["@id"]} />
              </div>
            ) : site.TokenEdusign ? (
              <div>
                <span className="h6 m-0 card-title">
                  Token Edusign{" "}
                  :{" "}
                </span>
                <span>{site.TokenEdusign}</span>
                <BtnEdit
                  selector={site.id + "-edusign"}
                  datas={site.TokenEdusign}
                />
              </div>
            ) : (
              <div>
                <span className="h6 m-0 card-title">
                  Token Edusign{" "}
                  :{" "}
                </span>
                <span className="card-text text-muted">
                  Aucun token enregistré
                </span>
                <BtnEdit
                  selector={site.id + "-edusign"}
                  datas={site.TokenEdusign}
                />
              </div>
            )}
            {errorToken ? (
              <p className="text-danger m-0">Token Erronné</p>
            ) : null}
            {dataEdusign === null ? null : (
              <p className="m-0">
                <span className="text-info h6 m-0">
                  Edusign - Nom du centre :{" "}
                </span>
                {dataEdusign.NAME || <span className="text-primary">Nom non renseigné.</span> } <br />
                <span className="text-info h6 m-0">Edusign - Contact : </span>
                {dataEdusign.PHONE || <span className="text-primary">Contact non renseigné</span> } <br />
                <span className="text-info h6 m-0">Edusign - Connecteur : </span>
                {dataEdusign.CONNECTOR || <span className="text-primary">Pas de connecteur</span> }
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListAdminSites;
