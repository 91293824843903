import React from "react";
import SidebarMenu from "./SidebarMenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Layout = (content) => {

  const __TODAY = new Date()
  const __MONTH = __TODAY.getMonth()
  const __YEAR = __TODAY.getFullYear()

  return (
    <div className="d-flex">
      <SidebarMenu />
      <main className="w-100 mx-2">
        {__MONTH === 0 && <h1 className='happyNewYear mt-2 display-4 mb-0
        '>Bonne Année {__YEAR} !!</h1>}
        {content}
      </main>
      <button
        className="btn btn-secondary"
        id="scroll-to-top"
        onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
      >
        <FontAwesomeIcon icon="chevron-up" />
      </button>
    </div>
  );
};

export default Layout;
