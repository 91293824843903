import { getconfig } from "../../../config";
import PostBddProvider from "../../../Providers/PostBddProvider";

const SaveAbsGalia = async (
  seance,
  IDStudent,
  motif,
  societe,
  suppr = false
) => {
  const galiaUser = getconfig().galiaUser;
  const url = `https://groupe-alternance.sc-form.net/GaliaEDC/API/AbsenceSta/Post?IDSociete=${societe}`;
  const absence = {
    IDInscrit: IDStudent,
    Date: seance.Date,
    Debut: seance.Debut,
    Fin: seance.Fin,
    IDTypAbsen: motif,
    Suppr: suppr,
  };
  return PostBddProvider(url, absence, undefined, galiaUser);
};
export default SaveAbsGalia;
