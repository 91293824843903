import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import AppContext from "../../Context/AppContext";
import GetBddProvider from "../../Providers/GetBddProvider";
import logo from "../../logo-symbol.svg";
import { StorageService } from "../tools/Storage/StorageService";
import { triByIdDESC } from "../tools/sortUtils";

const Navbar = () => {
  const { appName, user, url } = useContext(AppContext);

  const [messages, setMessages] = useState([]);

  useEffect(() => {
    if (user !== null) {
      load();
    } else {
      setMessages([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const load = () => {
    let uri = url + "api/message_infos?actif=1&role[gte]=" + user.role;
    GetBddProvider(uri).then((res) => {
      if (typeof res === "object") {
        setMessages(res["hydra:member"]);
      }
    });
  };

  const disconnect = () => {
    StorageService.clear();
    window.location.reload();
  };

  return user === null ? null : (
    <header
      className="navbar flex-nowrap justify-content-between navbar-expand-lg navbar-dark bg-info sticky-top shadow"
      style={{ height: "40px" }}
    >
      <button
        className="navbar-toggler p-0"
        type="button"
        data-toggle="collapse"
        data-target="#sidebarMenu"
        aria-controls="sidebarMenu"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon" style={{ height: "20px" }}></span>
      </button>

      <NavLink className="navbar-brand align-middle" exact to="/">
        <img
          className="m-0"
          src={logo}
          width="25px"
          height="30px"
          alt="Logo Groupe Alterance"
          loading="lazy"
        ></img>
        <span className="align-middle h5">{appName}</span>
      </NavLink>
      {messages.length > 0 ? (
        <div className="bg-light rounded-pill annonce">
          <div className="font-weight-bold">
            {messages.sort(triByIdDESC).map((message, i) => (
              <React.Fragment key={i}>
                <span className="text-danger">{message.title} :</span>{" "}
                {message.message.replace("//pseudo//", user.pseudo || "none")}
                {i + 1 < messages.length ? " | " : null}
              </React.Fragment>
            ))}
          </div>
        </div>
      ) : null}

      <div
        className="collapse navbar-collapse justify-content-end flex-grow-0"
        id="navbarColor01"
      >
        <ul className="navbar-nav">
          <li className="nav-item">
            <span className="nav-link text-white">
              <FontAwesomeIcon icon="user" />
            </span>
          </li>
          <li className="nav-item">
            <span className="nav-link text-white">
              {user.firstName} {user.name}
            </span>
          </li>
          <li className="nav-item">
            <span className="nav-link">
              <FontAwesomeIcon
                icon="power-off"
                className="text-danger"
                onClick={disconnect}
                style={{ cursor: "pointer" }}
              />
            </span>
          </li>
        </ul>
      </div>
    </header>
  );
};

export default Navbar;
