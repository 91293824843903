import React from "react";
import { useContext } from "react";
import AppContext from "../../../Context/AppContext";
import { useState } from "react";
import { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Spinner from "../../common/Spinner";
import MsgAlert from "../../common/MsgAlert";
import UpdateBddProvider from "../../../Providers/UpdateBddProvider";
import GenPass from "../../GenPass";
import PostBddProvider from "../../../Providers/PostBddProvider";
import GetBddProvider from "../../../Providers/GetBddProvider";
import { autoCloseMsg } from "../../tools/messagesUtils";
import { triByName } from "../../tools/sortUtils";

const EditEntreprise = ({ maj, entrepriseAdmins, selected }) => {
  const { url, user } = useContext(AppContext);
  const [save, setsave] = useState(false);
  const [sites, setsites] = useState(null);
  const [msg, setMsg] = useState(null);
  const [entreprise, setentreprise] = useState(selected);

  useEffect(() => {
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (entreprise !== selected) {
      let data = { ...selected };
      if (selected.entrepriseAdmin) {
        let tmp = entrepriseAdmins.filter(
          (e) => e["@id"] === selected.entrepriseAdmin["@id"]
        );
        if (tmp.length > 0) {
          data = {
            ...selected,
            entrepriseAdmin: tmp[0]["@id"],
          };
        }
      }
      setentreprise(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  const load = () => {
    let uri = url + "api/sites";
    GetBddProvider(uri).then((res) => {
      if (typeof res === "object") {
        setsites(res["hydra:member"]);
        setsave(true);
      }
    });
  };

  const ChangeSites = (site) => {
    if (entreprise.user.sites === undefined) {
      setentreprise({
        ...entreprise,
        user: { ...entreprise.user, sites: [site] },
      });
    } else if (entreprise.user.sites.includes(site)) {
      setentreprise({
        ...entreprise,
        user: {
          ...entreprise.user,
          sites: entreprise.user.sites.filter((el) => el !== site),
        },
      });
    } else {
      setentreprise({
        ...entreprise,
        user: { ...entreprise.user, sites: [...entreprise.user.sites, site] },
      });
    }
  };

  const validate = () => {
    let site = false;
    let cp = true;
    let tel = true;
    if (entreprise.user.sites) {
      if (entreprise.user.sites.length > 0) {
        site = true;
      }
    }
    if (
      entreprise.cp !== undefined &&
      entreprise.cp !== null &&
      entreprise.cp !== "" &&
      entreprise.cp.match(/[0-9]{5}/) === null
    ) {
      cp = false;
    }
    if (
      entreprise.tel !== undefined &&
      entreprise.tel !== null &&
      entreprise.tel !== "" &&
      entreprise.tel.match(/[0-9]{10}/) === null
    ) {
      tel = false;
    }
    return (
      entreprise.raisonSociale &&
      entreprise.user.name &&
      entreprise.user.pseudo &&
      entreprise.user.firstName &&
      entreprise.user.email &&
      entreprise.user.sites &&
      entreprise.user.sites.length > 0 &&
      site &&
      cp &&
      tel
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setsave(false);
    let temp = Object.assign({}, entreprise);
    for (let ok = false; ok === false; ) {
      let pseudoTest = temp.user.pseudo.slice(-1);
      pseudoTest === " "
        ? (temp.user.pseudo = temp.user.pseudo.slice(0, -1))
        : (ok = true);
    }
    if (temp.id) {
      UpdateBddProvider(url + "api/users/" + temp.user.id, temp.user).then(
        (res) => {
          if (typeof res === "object" && res.id) {
            temp = { ...temp, user: temp.user["@id"] };
            UpdateBddProvider(url + "api/entreprises/" + temp.id, temp).then(
              (res) => {
                setsave(true);
                if (typeof res === "object" && res.id) {
                  setMsg({
                    text: "Le tuteur a bien été mise à jour",
                    type: "success",
                  });
                  maj(true);
                  autoCloseMsg(setMsg, 5000);
                } else {
                  setMsg({ text: "erreur", type: "danger" });
                  autoCloseMsg(setMsg, 5000);
                }
              }
            );
          } else {
            let txt = res.violations
              ? res.violations.map((violation, i) => {
                  return <div key={i}>- {violation.message}</div>;
                })
              : "Erreur";
            setMsg({ text: txt, type: "danger" });
            setsave(true);
            autoCloseMsg(setMsg, 5000);
          }
        }
      );
    } else {
      temp.user.password = GenPass();
      PostBddProvider(url + "api/users", temp.user).then((res) => {
        if (typeof res === "object" && res.id) {
          temp.user = "/api/users/" + res.id;
          PostBddProvider(url + "api/entreprises", temp).then((res) => {
            setsave(true);
            if (typeof res === "object" && res.id) {
              setMsg({
                text: "Le tuteur a bien été créée",
                type: "success",
              });
              setentreprise({
                user: { actif: true, roles: ["ROLE_ENTREPRISE"] },
              });
              maj(true);
              autoCloseMsg(setMsg, 5000);
            } else {
              let txt = res.violations
                ? res.violations.map((violation, i) => {
                    return <div key={i}>- {violation.message}</div>;
                  })
                : "Erreur";
              setMsg({ text: txt, type: "danger" });
              autoCloseMsg(setMsg, 5000);
            }
          });
        } else {
          let txt = res.violations
            ? res.violations.map((violation, i) => {
                return <div key={i}>- {violation.message}</div>;
              })
            : "Erreur";
          setMsg({ text: txt, type: "danger" });
          setsave(true);
          autoCloseMsg(setMsg, 5000);
        }
      });
    }
  };

  return (
    <div
      className="modal fade"
      id="Modal"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-dialog-centered modal-dialog-scrollable"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header bg-info text-white">
            <h5 className="modal-title">
              <FontAwesomeIcon icon="edit" /> &nbsp;{" "}
              {entreprise.id ? "Modifier" : "Ajouter"} un tuteur
            </h5>
            <button
              type="button"
              className="close text-white"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          {!save ? (
            <div className="pb-5 modal-body text-info">
              <Spinner />
            </div>
          ) : (
            <div className="modal-body">
              {msg !== null ? (
                <div className="mx-4">
                  <MsgAlert msg={msg.text} type={msg.type} close={()=>autoCloseMsg(setMsg)} />
                </div>
              ) : null}
              <div className="form-group d-flex">
                <select
                  className="form-control"
                  value={entreprise.entrepriseAdmin || ""}
                  onChange={(e) => {
                    if (e.target.value !== "") {
                      let admin = entrepriseAdmins.filter(
                        (admin) => admin["@id"] === e.target.value
                      )[0];
                      setentreprise({
                        ...entreprise,
                        entrepriseAdmin: e.target.value,
                        raisonSociale: admin.name,
                        mailAdmin: admin.user.email,
                      });
                    } else {
                      setentreprise({
                        ...entreprise,
                        entrepriseAdmin: null,
                        raisonSociale: null,
                        mailAdmin: null,
                      });
                    }
                  }}
                >
                  <option value="">Affecter une entreprise</option>
                  {entrepriseAdmins.sort(triByName).map((admin) => (
                    <option value={admin["@id"]} key={admin["@id"]}>
                      {admin.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="rs">Raison sociale *</label>
                <input
                  className="form-control"
                  type="text"
                  name="rs"
                  placeholder="Raison Sociale"
                  value={entreprise.raisonSociale || ""}
                  disabled={entreprise.entrepriseAdmin}
                  onChange={(e) =>
                    setentreprise({
                      ...entreprise,
                      raisonSociale: e.target.value,
                    })
                  }
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="name">Nom du tuteur *</label>
                <input
                  className="form-control"
                  type="text"
                  name="name"
                  placeholder="Nom du tuteur"
                  value={entreprise.user.name || ""}
                  onChange={(e) =>
                    setentreprise({
                      ...entreprise,
                      user: {
                        ...entreprise.user,
                        name: e.target.value,
                        pseudo: `${
                          entreprise.user.firstName
                            ? entreprise.user.firstName
                                .toLowerCase()
                                .slice(0, 1)
                            : ""
                        }.${e.target.value.toLowerCase()}`,
                      },
                    })
                  }
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="firstName">Prénom du tuteur *</label>
                <input
                  className="form-control"
                  type="text"
                  name="firstName"
                  placeholder="Prénom du tuteur"
                  value={entreprise.user.firstName || ""}
                  disabled={!entreprise.user.name}
                  onChange={(e) =>
                    setentreprise({
                      ...entreprise,
                      user: {
                        ...entreprise.user,
                        firstName: e.target.value,
                        pseudo: `${e.target.value.toLowerCase().slice(0, 1)}.${
                          entreprise.user.name.toLowerCase() || ""
                        }`,
                      },
                    })
                  }
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="pseudo">Nom d'utilisateur *</label>
                <input
                  className={`form-control ${
                    (entreprise.user.pseudo === undefined ||
                      entreprise.user.pseudo.length < 5) &&
                    "is-invalid"
                  }`}
                  type="text"
                  name="pseudo"
                  minLength={5}
                  autoComplete="off"
                  value={entreprise.user.pseudo || ""}
                  placeholder="Nom d'utilisateur"
                  onChange={(e) => {
                    setentreprise({
                      ...entreprise,
                      user: { ...entreprise.user, pseudo: e.target.value },
                    });
                  }}
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="email">Adresse mail *</label>
                <input
                  className="form-control"
                  type="email"
                  name="email"
                  placeholder="Adresse mail"
                  value={entreprise.user.email || ""}
                  onChange={(e) =>
                    setentreprise({
                      ...entreprise,
                      user: {
                        ...entreprise.user,
                        email: e.target.value,
                      },
                    })
                  }
                  required
                />
              </div>
              <hr />
              <div className="form-group">
                <label>Adresse Postale</label>
                <input
                  className="form-control"
                  type="text"
                  placeholder="Adresse postale"
                  value={entreprise.adresse || ""}
                  onChange={(e) =>
                    setentreprise({
                      ...entreprise,
                      adresse: e.target.value,
                    })
                  }
                />
              </div>

              <div className="form-row">
                <div className="form-group col-3">
                  <label>CP</label>
                  <input
                    className={
                      entreprise.cp !== undefined &&
                      entreprise.cp !== null &&
                      entreprise.cp !== "" &&
                      entreprise.cp.match(/[0-9]{5}/) === null
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                    type="text"
                    size="12"
                    maxLength="5"
                    minLength="5"
                    pattern="[0-9]{5}"
                    placeholder="CP"
                    value={entreprise.cp || ""}
                    onChange={(e) =>
                      setentreprise({
                        ...entreprise,
                        cp: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="form-group col-9">
                  <label>Ville</label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Ville"
                    value={entreprise.ville || ""}
                    onChange={(e) =>
                      setentreprise({
                        ...entreprise,
                        ville: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="form-group row">
                <label className="col-6 col-form-label text-right">
                  N° de téléphone
                </label>
                <div className="col-6">
                  <input
                    className={
                      entreprise.tel !== undefined &&
                      entreprise.tel !== null &&
                      entreprise.tel !== "" &&
                      entreprise.tel.match(/[0-9]{10}/) === null
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                    type="text"
                    maxLength="10"
                    minLength="10"
                    pattern="[0-9]{10}"
                    placeholder="n° de Tel"
                    value={entreprise.tel || ""}
                    onChange={(e) =>
                      setentreprise({
                        ...entreprise,
                        tel: e.target.value,
                      })
                    }
                  />
                </div>
              </div>

              <hr />

              <div className="form-group">
                <label>
                  Adresse mail de l'admin{" "}
                  {!entreprise.entrepriseAdmin && <em>(facultatif)</em>}
                </label>
                <input
                  className="form-control"
                  type="email"
                  placeholder="Adresse mail de l'admin"
                  value={entreprise.mailAdmin || ""}
                  disabled={entreprise.entrepriseAdmin}
                  onChange={(e) =>
                    setentreprise({
                      ...entreprise,
                      mailAdmin: e.target.value,
                    })
                  }
                />
              </div>
              <div className="form-group">
                <label>
                  Autre adresse mail <em>(facultatif)</em>
                </label>
                <input
                  className="form-control"
                  type="email"
                  placeholder="Autre adresse mail"
                  value={entreprise.mailAdmin2 || ""}
                  onChange={(e) =>
                    setentreprise({
                      ...entreprise,
                      mailAdmin2: e.target.value,
                    })
                  }
                />
              </div>
              <div className="form-group">
                <label>Sites de rattachement *</label>
                <div className="custom-control custom-switch d-flex justify-content-center flex-wrap text-left">
                  {sites.sort(triByName).map((site, i) => {
                    if (
                      user.role === 1 ||
                      user.sites.some((el) => el["@id"] === site["@id"])
                    ) {
                      return (
                        <div key={i} className="col-md-6">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id={"chSite" + site.id}
                            disabled={
                              user.role !== 1 &&
                              !user.sites.some(
                                (el) => el["@id"] === site["@id"]
                              )
                            }
                            value={site["@id"]}
                            checked={
                              entreprise.user.sites !== undefined
                                ? entreprise.user.sites.includes(site["@id"])
                                : false
                            }
                            onChange={(e) => ChangeSites(site["@id"])}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor={"chSite" + site.id}
                          >
                            {site.name}
                          </label>
                        </div>
                      );
                    } else {
                      return null;
                    }
                  })}
                </div>
                <div className="mt-3 text-left">
                  <p>
                    <em>Sites rattachés : </em>
                    {sites === null || entreprise.user.sites === undefined
                      ? null
                      : entreprise.user.sites.map((site, i) => {
                          let result;
                          sites.forEach((el) => {
                            if (el["@id"] === site) {
                              result = (
                                <span
                                  className="badge badge-pill badge-primary m-1"
                                  key={i}
                                >
                                  {el.name}
                                </span>
                              );
                            }
                          });
                          return result;
                        })}
                  </p>
                </div>
              </div>

              <div className="custom-control custom-switch">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="chActif"
                  checked={entreprise.user.actif || false}
                  onChange={(e) =>
                    setentreprise({
                      ...entreprise,
                      user: {
                        ...entreprise.user,
                        actif: !entreprise.user.actif,
                      },
                    })
                  }
                />
                <label className="custom-control-label" htmlFor="chActif">
                  Actif
                </label>
              </div>
            </div>
          )}
          <div className="modal-footer">
            <button
              className="btn btn-secondary"
              type="button"
              data-dismiss="modal"
            >
              Fermer
            </button>
            <button
              className="btn btn-info"
              type="submit"
              onClick={handleSubmit}
              disabled={!validate()}
            >
              Enregistrer
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditEntreprise;
