import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MsgAlert from "../../common/MsgAlert";
import Spinner from "../../common/Spinner";
import { useEffect } from "react";
import { useContext } from "react";
import AppContext from "../../../Context/AppContext";
import UpdateBddProvider from "../../../Providers/UpdateBddProvider";
import PostBddProvider from "../../../Providers/PostBddProvider";
import GetBddProvider from "../../../Providers/GetBddProvider";
import { autoCloseMsg } from "../../tools/messagesUtils";

const ModifCommentMat = ({ selected, promo, setMaj }) => {
  const { url, user } = useContext(AppContext);

  const [msg, setMsg] = useState(null);
  const [comment, setComment] = useState(selected);
  const [formateurs, setFormateurs] = useState([]);
  const [save, setSave] = useState(true);

  useEffect(() => {
    if (selected !== null && user.role <= 3) {
      loadFormateurs();
    }
    if (selected !== comment) {
      setComment(selected);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  const loadFormateurs = () => {
    let uri =
      url + "api/formateurs?promos=" + promo + "&matieres=" + selected.matiere;
    GetBddProvider(uri).then((res) => {
      if (typeof res === "object") {
        setFormateurs(res["hydra:member"]);
      } else {
        setMsg({
          txt: "Erreur de chargement des formateurs",
          type: "danger",
        });
        autoCloseMsg(setMsg, 5000);
      }
    });
  };

  const valide = () => {
    return (
      comment &&
      comment.comment &&
      comment.formateur !== null &&
      comment.formateur !== "" &&
      comment.comment !== "" &&
      (comment.comment !== selected.comment ||
        comment.formateur !== selected.formateur)
    );
  };

  const controlExist = async () => {
    let uri =
      url +
      "api/comment_matieres?formateur=" +
      comment.formateur +
      "&matiere=" +
      comment.matiere +
      "&semestre=" +
      comment.semestre +
      "&eleve=" +
      comment.eleve;

    return GetBddProvider(uri).then((res) => {
      if (typeof res === "object") {
        if (res["hydra:member"].length === 0) {
          return true;
        } else {
          setMsg({ txt: "Le commentaire a déjà été saisie", type: "warning" });
          autoCloseMsg(setMsg, 5000);
          return false;
        }
      } else {
        setMsg({ txt: "Erreur de vérification.", type: "danger" });
        autoCloseMsg(setMsg, 5000);
        return false;
      }
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSave(false);
    if (comment.id) {
      let uri = url + "api/comment_matieres/" + comment.id;
      let data = {
        comment: comment.comment,
      };
      if (user.role <= 3) {
        data = { ...data, formateur: comment.formateur };
      }
      UpdateBddProvider(uri, data).then((res) => {
        setSave(true);
        if (typeof res === "object" && res.id) {
          setMsg({ txt: "Le commentaire a été mis à jour", type: "success" });
          autoCloseMsg(setMsg, 5000);
          setMaj(true);
        } else {
          setMsg({ txt: "Erreur d'enregistrement", type: "danger" });
          autoCloseMsg(setMsg, 5000);
        }
      });
    } else {
      let control = await controlExist();
      if (control) {
        let uri = url + "api/comment_matieres";
        PostBddProvider(uri, comment).then((res) => {
          setSave(true);
          if (typeof res === "object" && res.id) {
            setMsg({ txt: "Le commentaire a été enregistré", type: "success" });
            autoCloseMsg(setMsg, 5000);
            setMaj(true);
          } else {
            setMsg({ txt: "Erreur d'enregistrement", type: "danger" });
            autoCloseMsg(setMsg, 5000);
          }
        });
      } else {
        setSave(true);
      }
    }
  };

  return (
    <div
      className="modal fade"
      id="ModalModifCom"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header bg-info text-white">
            <h5 className="modal-title">
              <FontAwesomeIcon icon="edit" /> &nbsp;
              {comment && comment.id
                ? "Modifier le commentaire"
                : "Ajouter un commentaire"}
            </h5>
            <button
              type="button"
              className="close text-white"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            {msg !== null ? (
              <div className="mx-4">
                <MsgAlert msg={msg.txt} type={msg.type} close={() => autoCloseMsg(setMsg)} />
              </div>
            ) : null}
            {!save || comment === null ? (
              <Spinner />
            ) : (
              <React.Fragment>
                {user.role <= 3 ? (
                  <div className="form-group">
                    <label>Formateur</label>
                    <select
                      className="form-control"
                      value={comment.formateur || ""}
                      onChange={(e) =>
                        setComment({ ...comment, formateur: e.target.value })
                      }
                    >
                      <option value="">Sélectionnez un formateur</option>
                      {formateurs.map((f, i) => (
                        <option value={f["@id"]} key={i}>
                          {f.user.name} {f.user.firstName}
                        </option>
                      ))}
                    </select>
                  </div>
                ) : null}
                <div className="form-group">
                  <label>Commentaire</label>
                  <textarea
                    type="text"
                    rows="3"
                    className="form-control"
                    value={comment.comment || ""}
                    maxLength="250"
                    onChange={(e) =>
                      setComment({
                        ...comment,
                        comment: e.target.value.replace("\n", ""),
                      })
                    }
                  />
                </div>
              </React.Fragment>
            )}
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
            >
              Fermer
            </button>
            <button
              type="submit"
              className="btn btn-info"
              onClick={handleSubmit}
              disabled={!valide()}
            >
              {comment && comment.id ? "Mettre à jour" : "Enregistrer"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModifCommentMat;
