import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import AppContext from "../../../Context/AppContext";
import GetBddProvider from "../../../Providers/GetBddProvider";
import UpdateBddProvider from "../../../Providers/UpdateBddProvider";
import MsgAlert from "../../common/MsgAlert";
import Spinner from "../../common/Spinner";
import dateFormated from "../../tools/dateFormated";
import heuresFormH from "../../tools/heuresFormH";
import { autoCloseMsg } from "../../tools/messagesUtils";

const ViewSuiviComp = ({ suiviSelected, setMaj }) => {
  const { url, user } = useContext(AppContext);

  const [blocs, setBlocs] = useState([]);
  const [suivi, setSuivi] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [msg, setMsg] = useState(null);

  useEffect(() => {
    if (suiviSelected !== null) {
      setLoaded(false);
      setSuivi(suiviSelected);
      loadBlocs();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [suiviSelected]);

  const loadBlocs = () => {
    let uri =
      url + "api/bloc_competences?formation=" + suiviSelected.promo.formation;
    GetBddProvider(uri).then((res) => {
      if (typeof res === "object") {
        setBlocs(res["hydra:member"]);
        setLoaded(true);
      } else {
        setLoaded(true);
        setMsg({
          txt: "Erreur de chargement des blocs de compétence",
          type: "danger",
        });
        autoCloseMsg(setMsg, 5000);
      }
    });
  };

  const reload = () => {
    setMaj(true);
    GetBddProvider(url + suivi["@id"].slice(1)).then((res) => {
      if (typeof res === "object" && res.id) {
        setSuivi(res);
        setLoaded(true);
      } else {
        setMsg({
          txt: "Erreur de chargement du suivi",
          type: "danger",
        });
        autoCloseMsg(setMsg, 5000);
        setLoaded(true);
      }
    });
  };

  const validSuivi = () => {
    setLoaded(false);
    let data = {
      validate: !suivi.validate,
    };
    UpdateBddProvider(url + suivi["@id"].slice(1), data).then((res) => {
      if (typeof res === "object" && res.id) {
        setMsg({
          txt: "La mise a jour a bien été enregistrée",
          type: "success",
        });
        autoCloseMsg(setMsg, 5000);
        reload();
      } else {
        setMsg({
          txt: "Erreur de mise à jour",
          type: "danger",
        });
        autoCloseMsg(setMsg, 5000);
        setLoaded(true);
      }
    });
  };

  return (
    <div
      className="modal fade"
      id="ModalViewSuiviCompetences"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header bg-info text-white">
            <h5 className="modal-title">
              <FontAwesomeIcon icon="clipboard-list" /> &nbsp; Consultation du
              suivi de{" "}
              {suivi !== null
                ? suivi.eleve.user.name + " " + suivi.eleve.user.firstName
                : null}
            </h5>
            <button
              type="button"
              className="close text-white"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          {!loaded ? (
            <div className="pb-5 modal-body text-info">
              <Spinner />
            </div>
          ) : (
            <div className="modal-body mb-3">
              {msg !== null ? (
                <MsgAlert msg={msg.txt} type={msg.type} close={() => autoCloseMsg(setMsg)} />
              ) : null}
              <div className="d-flex">
                <div className="col-lg-4">
                  <h6>
                    <strong>
                      <u>Date</u>
                    </strong>
                  </h6>
                  <span>{dateFormated(suivi.createdAt)}</span>
                </div>
                <div className="col-lg-4">
                  <h6>
                    <strong>
                      <u>Durée</u>
                    </strong>
                  </h6>
                  <span>{heuresFormH(suivi.time)}</span>
                </div>
                <div className="col-lg-4">
                  <h6>
                    <strong>
                      <u>Évaluateur</u>
                    </strong>
                  </h6>
                  <span>
                    {suivi.evaluateur.name} {suivi.evaluateur.firstName}
                  </span>
                </div>
              </div>
              <div>
                <form>
                  <table className="mt-3 table table-sm table-responsive-md">
                    <thead>
                      <tr>
                        <th></th>
                        <th>
                          Non Concerné <br />
                          <span className="text-secondary">
                            <FontAwesomeIcon icon="times" />
                          </span>
                        </th>
                        <th>
                          Subit <br />
                          <span className="text-danger">
                            <FontAwesomeIcon icon="minus" />{" "}
                            <FontAwesomeIcon icon="minus" />
                          </span>
                        </th>
                        <th>
                          Exécute <br />
                          <span className="text-warning">
                            <FontAwesomeIcon icon="minus" />
                          </span>
                        </th>
                        <th>
                          Maîtrise <br />
                          <span className="text-green-light">
                            <FontAwesomeIcon icon="plus" />
                          </span>
                        </th>
                        <th>
                          Maitrise totale <br />
                          <span className="text-success">
                            <FontAwesomeIcon icon="plus" />{" "}
                            <FontAwesomeIcon icon="plus" />
                          </span>
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      {blocs.map((b, i) => (
                        <React.Fragment key={i}>
                          <tr>
                            <th colSpan="6">{b.name}</th>
                          </tr>

                          {suivi.notes
                            .filter(
                              (n) => n.competence.bloc["@id"] === b["@id"]
                            )
                            .map((n, i) => {
                              let tabI = [0, 1, 2, 3, 4];
                              return (
                                <tr key={i}>
                                  <td className="text-left">
                                    {n.competence.name}
                                  </td>
                                  {tabI.map((t, it) => {
                                    let check = n.note === t;
                                    return (
                                      <td
                                        className={
                                          check ? "text-success" : "text-muted"
                                        }
                                        key={"value" + it}
                                      >
                                        {check ? (
                                          <FontAwesomeIcon icon="check-circle" />
                                        ) : (
                                          "-"
                                        )}
                                      </td>
                                    );
                                  })}
                                </tr>
                              );
                            })}
                        </React.Fragment>
                      ))}
                    </tbody>
                  </table>
                </form>
              </div>
              <div className="mt-3 text-left border border-info p-1">
                <h6>
                  <strong>
                    <u>Commentaires :</u>
                  </strong>
                </h6>
                <p>
                  {suivi.comment.split("\n").map((item, key) => {
                    return (
                      <React.Fragment key={key}>
                        {item}
                        <br />
                      </React.Fragment>
                    );
                  })}
                </p>
              </div>
              {user.role > 5 && !suivi.validate ? (
                <button
                  type="submit"
                  className="btn btn-success mt-2 mr-2"
                  onClick={validSuivi}
                  disabled={suivi.validate}
                >
                  Valider
                </button>
              ) : null}
              {user.role <= 3.5 && !suivi.validate ? (
                <button
                  type="submit"
                  className="btn btn-success mt-2 mr-2"
                  onClick={validSuivi}
                  disabled={suivi.validate}
                >
                  Covalidation
                </button>
              ) : null}
              <button
                type="button"
                className="btn btn-secondary mt-2"
                data-dismiss="modal"
              >
                Fermer
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ViewSuiviComp;
