import React, { useEffect, useState } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import "./App.css";
import "./Components/FontAwesomeInit";
import ProtectedRoute from "./Components/ProtectedRoute";
import Navbar from "./Components/common/Navbar";
import SmecBeta from "./Components/contents/Beta/SmecBeta";
import AdminPurgeProgressions from "./Components/pages/AdminPurgeProgressions";
import AdminSites from "./Components/pages/AdminSites";
import AdminUsername from "./Components/pages/AdminUsername";
import Adminitratif from "./Components/pages/Administratif";
import Deploiement from "./Components/pages/Deploiement";
import Eleves from "./Components/pages/Eleves";
import EntrepriseAdmin from "./Components/pages/EntrepriseAdmin";
import Entreprises from "./Components/pages/Entreprises";
import ExportEleves from "./Components/pages/ExportEleves";
import ExportProg from "./Components/pages/ExportProg";
import ExportProgression from "./Components/pages/ExportProgression";
import Formateurs from "./Components/pages/Formateurs";
import Formations from "./Components/pages/Formations";
import GestAbsences from "./Components/pages/GestAbsences";
import GestPresences from "./Components/pages/GestPresences";
import GestRetards from "./Components/pages/GestRetards";
import GestSanction from "./Components/pages/GestSanction";
import GestSites from "./Components/pages/GestSites";
import Home from "./Components/pages/Home";
import Login from "./Components/pages/Login";
import Matieres from "./Components/pages/Matieres";
import Notes from "./Components/pages/Notes";
import PageEleve from "./Components/pages/PageEleve";
import Planning from "./Components/pages/Planning";
import PrepaConseil from "./Components/pages/PrepaConseil";
import PrepaLivret from "./Components/pages/PrepaLivret";
import ProgessionItems from "./Components/pages/ProgressionItems";
import Progressions from "./Components/pages/Progressions";
import Promos from "./Components/pages/Promos";
import ReleveHeures from "./Components/pages/ReleveHeures";
import { SanctionMotifs } from "./Components/pages/SanctionMotifs";
import Semestres from "./Components/pages/Semestres";
import SetAfest from "./Components/pages/SetAfest";
import SetCompetence from "./Components/pages/SetCompetence";
import SetItemSuivi from "./Components/pages/SetItemSuivi";
import SetMsginfo from "./Components/pages/SetMsgInfo";
import SmecCharts from "./Components/pages/SmecCharts";
import SuiviAfest from "./Components/pages/SuiviAfest";
import SuiviCompetences from "./Components/pages/SuiviCompetences";
import SuiviPedago from "./Components/pages/SuiviPedago";
import Support from "./Components/pages/Support";
import SyncEdusign from "./Components/pages/SyncEdusign";
import SyncGalia from "./Components/pages/SyncGalia";
import Users from "./Components/pages/Users";
import Validate from "./Components/pages/Validate";
import { customDebug } from "./Components/test/customDebug";
import { StorageService } from "./Components/tools/Storage/StorageService";
import checkToken from "./Components/tools/check/checkToken";
import AppContext from "./Context/AppContext";
import { getconfig } from "./config";
import logo from "./logo-symbol.svg";

const App = () => {
  const _DEBUG = 0;
  checkToken(false);

  const [user, setUser] = useState(JSON.parse(StorageService.getItem("user")));
  const [configJSON, setConfigJSON] = useState();

  useEffect(() => {
    fetch(`/config.json`)
      .then((res) => res.json())
      .then((data) => setConfigJSON(data));
  }, []);

  const contextValue = {
    ...getconfig(),
    user,
    setUser,
  };

  _DEBUG && customDebug();

  if (configJSON === undefined) {
    return <div></div>;
  }

  if (configJSON.maintenance) {
    return (
      <div className="container-fluid m-0 p-0 text-center vh-100 d-flex flex-column justify-content-center">
        <h1 className="m-4 text-info">Maintenance en cours !</h1>
        <img src={logo} alt="logo" className="mt-4 App-logo-home" />
      </div>
    );
  } else {
    return (
      <div className="container-fluid p-0 text-center">
        <div className="App" id="top">
          <BrowserRouter>
            <AppContext.Provider value={contextValue}>
              <Navbar />
              <Switch>
                <ProtectedRoute exact path="/" component={Home} validate />
                <ProtectedRoute
                  path="/planning"
                  component={Planning}
                  validate
                />
                <ProtectedRoute
                  path="/administratif"
                  component={Adminitratif}
                  role={3}
                  validate
                />
                <ProtectedRoute
                  path="/undefined"
                  component={Deploiement}
                  properties={{ page: true }}
                  validate
                />
                <ProtectedRoute
                  path="/absences"
                  component={GestAbsences}
                  role={3}
                  validate
                />
                <ProtectedRoute
                  path="/retards"
                  component={GestRetards}
                  role={3}
                  validate
                />
                <ProtectedRoute
                  path="/presences"
                  component={GestPresences}
                  role={3}
                  validate
                />
                <ProtectedRoute
                  path="/events"
                  component={GestSanction}
                  role={3}
                  validate
                />
                <ProtectedRoute
                  path="/releveHeures"
                  component={ReleveHeures}
                  role={4}
                  validate
                />
                <ProtectedRoute
                  path="/suiviPedago"
                  component={SuiviPedago}
                  role={4}
                  validate
                />
                <ProtectedRoute
                  path="/suiviComp"
                  component={SuiviCompetences}
                  role={4}
                  validate
                />
                <ProtectedRoute
                  path="/suiviAfest"
                  component={SuiviAfest}
                  role={4}
                  validate
                />
                <ProtectedRoute
                  path="/progressions"
                  component={Progressions}
                  validate
                />
                <ProtectedRoute
                  path="/notes"
                  component={Notes}
                  role={4}
                  validate
                />
                <ProtectedRoute
                  path="/prepaconseil"
                  component={PrepaConseil}
                  role={4}
                  validate
                />
                <ProtectedRoute
                  path="/preLivret"
                  component={PrepaLivret}
                  role={3}
                  validate
                />
                <ProtectedRoute
                  exact
                  path="/promos"
                  component={Promos}
                  role={3}
                  validate
                />
                {/* <ProtectedRoute
                path="/promos/:id"
                component={Promo}
                role={3}
                validate
              /> */}
                <ProtectedRoute
                  path="/progItems"
                  component={ProgessionItems}
                  role={3}
                  validate
                />
                <ProtectedRoute
                  path="/users"
                  component={Users}
                  role={3}
                  validate
                />
                <ProtectedRoute
                  path="/formateurs"
                  component={Formateurs}
                  role={3}
                  validate
                />
                <ProtectedRoute
                  path="/eleve"
                  component={PageEleve}
                  role={6}
                  exact
                  validate
                />
                <ProtectedRoute
                  path="/eleves"
                  component={Eleves}
                  role={3}
                  validate
                />
                <ProtectedRoute
                  path="/entreprises"
                  component={Entreprises}
                  role={3}
                  validate
                />
                <ProtectedRoute
                  path="/entrepriseAdmins"
                  component={EntrepriseAdmin}
                  role={3}
                  validate
                />
                <ProtectedRoute
                  path="/semestres"
                  component={Semestres}
                  role={3}
                  validate
                />
                <ProtectedRoute
                  path="/adminSites"
                  component={AdminSites}
                  role={3}
                  validate
                />
                <ProtectedRoute
                  path="/syncEdusign"
                  component={SyncEdusign}
                  role={3}
                  validate
                />
                <ProtectedRoute
                  path="/syncGalia"
                  component={SyncGalia}
                  role={3}
                  validate
                />
                <ProtectedRoute
                  path="/formations"
                  component={Formations}
                  role={1}
                  validate
                />
                <ProtectedRoute
                  path="/matieres"
                  component={Matieres}
                  role={1}
                  validate
                />
                <ProtectedRoute
                  path="/setItemSuivi"
                  component={SetItemSuivi}
                  role={1}
                  validate
                />
                <ProtectedRoute
                  path="/setCompetence"
                  component={SetCompetence}
                  role={1}
                  validate
                />
                <ProtectedRoute
                  path="/setAfest"
                  component={SetAfest}
                  role={1}
                  validate
                />
                <ProtectedRoute
                  path="/gestSites"
                  component={GestSites}
                  role={1}
                  validate
                />
                <ProtectedRoute
                  path="/gestMessageInfo"
                  component={SetMsginfo}
                  role={1}
                  validate
                />
                <ProtectedRoute
                  path="/AdminUsername"
                  component={AdminUsername}
                  role={1}
                  validate
                />
                <ProtectedRoute
                  path="/exportProg"
                  component={ExportProg}
                  role={3}
                  validate
                />
                <ProtectedRoute
                  path="/exportProgressions"
                  component={ExportProgression}
                  role={4}
                  validate
                />
                <ProtectedRoute
                  path="/exportEleves"
                  component={ExportEleves}
                  role={4}
                  validate
                />
                <ProtectedRoute
                  path="/support"
                  component={Support}
                  role={4}
                  validate
                />
                <ProtectedRoute
                  path="/smecBeta"
                  component={SmecBeta}
                  role={3}
                  beta={true}
                  validate
                />
                <ProtectedRoute
                  path="/smecCharts"
                  component={SmecCharts}
                  role={1}
                  beta={true}
                  validate
                />
                <ProtectedRoute
                  path="/purgeProg"
                  component={AdminPurgeProgressions}
                  role={1}
                  beta={true}
                  validate
                />
                <ProtectedRoute
                  path="/sanctionMotifs"
                  component={SanctionMotifs}
                  role={1}
                  validate
                />
                <ProtectedRoute path="/validate" component={Validate} />
                <Route path="/login" component={Login} />
                <Redirect to="/" />
              </Switch>
            </AppContext.Provider>
          </BrowserRouter>
        </div>
      </div>
    );
  }
};

export default App;
