import React from "react";
import { useContext } from "react";
import AppContext from "../../Context/AppContext";
import { useState } from "react";
import GetBddProvider from "../../Providers/GetBddProvider";
import { useEffect } from "react";
import Spinner from "../common/Spinner";
import Layout from "../common/Layout";
import List from "../contents/lists/List";

const Users = () => {
  const { url, user } = useContext(AppContext);
  const [users, setusers] = useState(null);
  const [loaded, setloaded] = useState(false);

  const load = () => {
    let uri = url + "api/users";
    if (user.role > 1) {
      uri += "?"
      user.sites.forEach((site, i, sites) => {
        uri += sites.length > 1 ? "sites[]=" : "sites=";
        uri += site["@id"];
        uri += i + 1 < sites.length ? "&" : "";
      });
    }
    GetBddProvider(uri).then((res) => {
      if (typeof res === "object") {
        setusers(res["hydra:member"]);
        setloaded(true);
      }
    });
  };

  useEffect(() => {
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const content = () => {
    if (!loaded) {
      return <Spinner />;
    }
    return user.sites.map((site, i) => {
      let usersTemp = null;
      users.forEach((el) => {
        el.sites.forEach((tmp) => {
          if (tmp.id === site.id) {
            usersTemp === null
              ? (usersTemp = [el])
              : (usersTemp = [...usersTemp, el]);
          }
        });
      });

      let eleves = usersTemp.filter((el) => el.roles.includes("ROLE_ELEVE"));
      let formateurs = usersTemp.filter((el) =>
        el.roles.includes("ROLE_FORMATEUR")
      );
      let entreprises = usersTemp.filter((el) =>
        el.roles.includes("ROLE_ENTREPRISE")
      );
      let pedago = usersTemp.filter((el) => el.roles.includes("ROLE_PEDAGO"));
      let direction = usersTemp.filter((el) =>
        el.roles.includes("ROLE_DIRECTION")
      );
      return (
        <div key={i}>
          <span className="h1">{site.name}</span>
          <span> ({usersTemp.length} membres)</span>
          <hr className="m-4"></hr>
          <div className="d-flex flex-wrap justify-content-center">
            <List datas={eleves} title="Elèves" />
            <List datas={formateurs} title="Formateurs" />
            <List datas={entreprises} title="Entreprises" />
            <List datas={pedago} title="Equipe pédago" />
            <List datas={direction} title="Adminitratif" />
          </div>
        </div>
      );
    });
  };

  return Layout(content());
};

export default Users;
