import React, { useContext, useEffect, useState } from "react";
import AppContext from "../../Context/AppContext";
import GetBddProvider from "../../Providers/GetBddProvider";
import Layout from "../common/Layout";
import MsgAlert from "../common/MsgAlert";
import Spinner from "../common/Spinner";
import SyncEleves from "../contents/syncEdusign/SyncEleves";
import SyncPromos from "../contents/syncEdusign/SyncPromos";
import { autoCloseMsg } from "../tools/messagesUtils";
import { triByName } from "../tools/sortUtils";

const SyncEdusign = () => {
  const { user, url, urlEdusign } = useContext(AppContext);

  const [sitesList, setSitesList] = useState([]);
  const [siteSelected, setSiteSelected] = useState(null);
  const [site, setSite] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [edusignGroups, setEdusignGroups] = useState([]);
  const [loadedGroup, setLoadedGroup] = useState(false);
  const [maj, setMaj] = useState(false);
  const [groupSelected, setGroupSelected] = useState(null);
  const [msg, setMsg] = useState(null);
  const [promo, setPromo] = useState(null);

  useEffect(() => {
    if (user.role > 1) {
      setSitesList(user.sites.sort(triByName));
    } else {
      loadSitesList();
    }
    setSiteSelected(user.sites[0]["@id"]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (siteSelected !== null) {
      setMsg(null)
      loadSites();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteSelected]);

  useEffect(() => {
    if (site !== null && site.TokenEdusign) {
      loadEdusignGroup();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [site]);

  useEffect(() => {
    if (maj) {
      loadSites();
      setMaj(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maj]);

  const razData = () => {
    setLoaded(false);
    setLoadedGroup(false);
    setGroupSelected(null);
    setPromo(null);
    setEdusignGroups([]);
  };

  const loadSitesList = () => {
    let uri = url + "api/sites";
    GetBddProvider(uri).then((res) => {
      if (typeof res === "object") {
        setSitesList(res["hydra:member"].sort(triByName));
      } else {
        setMsg({ txt: "Erreur de chargement", type: "danger" });
      }
    });
  };

  const loadSites = () => {
    let uri = url + siteSelected.slice(1);
    GetBddProvider(uri).then((res) => {
      if (typeof res === "object") {
        setSite(res);
        setLoaded(true);
      }
    });
  };

  const loadEdusignGroup = () => {
    let uri = urlEdusign["GROUPS"];
    GetBddProvider(uri, site.TokenEdusign).then((res) => {
      if (typeof res === "object") {
        if (res.status === "success") {
          setEdusignGroups(res["result"]);
        } else {
          setMsg({ txt: res.message || "token érroné", type: "danger" });
        }
        setLoadedGroup(true);
      } else {
        setMsg({ txt: "Erreur de connexion à Edusign", type: "danger" });
        setLoadedGroup(true);
      }
    });
  };

  return Layout(
    <div className="my-2 mx-1">
      <div className="d-flex justify-content-center flex-column mb-4">
        <h2>Sync Edusign</h2>
        <div className="form-inline justify-content-center">
          <select
            className="form-control"
            value={siteSelected || ""}
            onChange={(e) => {
              razData();
              setSiteSelected(e.target.value);
            }}
          >
            {sitesList !== null
              ? sitesList.map((s, i) => (
                  <option value={s["@id"]} key={i}>
                    {s.name}
                  </option>
                ))
              : null}
          </select>
        </div>
      </div>
      {msg && <MsgAlert msg={msg.txt} type={msg.type} close={() => autoCloseMsg(setMsg)} />}
      {!loaded || site === null ? (
        <Spinner />
      ) : !site.TokenEdusign ? (
        <MsgAlert
          msg='Aucun Token Edusign ! RDV au menu "administration -> Gestion des sites".'
          type="danger"
        />
      ) : (
        <div className="d-flex flex-wrap">
          <div className="col-lg-6 px-1">
            <SyncPromos
              loaded={loadedGroup}
              groups={edusignGroups}
              promos={site.promos.filter((p) => p.actif).sort(triByName)}
              maj={setMaj}
              groupSelected={groupSelected}
              setGroupSelected={setGroupSelected}
              setPromo={setPromo}
            />
          </div>
          <div className="col-lg-6 px-1">
            <SyncEleves
              promo={promo}
              groups={edusignGroups}
              group={groupSelected}
              tokenEdusign={site.TokenEdusign}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default SyncEdusign;
