import React from "react";

export default React.createContext({
  promos: [],
  matieres: [],
  matiere: "",
  promoLoaded: "",
  semestreSelected: "",
  formateur: "",
  controleSelected: "",
  setMatieres: (vval) => {},
  setMatiere: (val) => {},
  setPromoLoaded: (val) => {},
  setSemestreSelected: (val) => {},
  setFormateur: (val) => {},
  setNoteSelected: (val) => {},
  setEleveSelected: (val) => {},
  setMaj: (val) => {},
  setGlobalMsg: (val) => {},
  autoCloseGlobal: (val) => {},
  setControleSelected: (val) => {},
});
