import React, { useContext, useEffect, useState } from "react";
import AppContext from "../../Context/AppContext";
import GetBddProvider from "../../Providers/GetBddProvider";
import Layout from "../common/Layout";
import MsgAlert from "../common/MsgAlert";
import Spinner from "../common/Spinner";
import CalcMoyEleves from "../tools/Calculs/CalcMoyEleves";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import convertDecimal from "../tools/convertDecimal";
import { autoCloseMsg } from "../tools/messagesUtils";
import { triByName, triByUserName } from "../tools/sortUtils";

const PrepaLivret = () => {
  const { url, user } = useContext(AppContext);

  const [promos, setPromos] = useState(null);
  const [promoSelected, setPromoSelected] = useState(null);
  const [promo, setPromo] = useState(null);
  const [notes, setNotes] = useState(null);
  const [moyennes, setMoyennes] = useState(null);
  const [commentaires, setCommentaires] = useState(null);
  const [eleves, setEleves] = useState(null);
  const [formation, setFormation] = useState(null);
  const [charging, setCharging] = useState(false);
  const [msg, setMsg] = useState(null);

  useEffect(() => {
    loadPromos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (promoSelected !== null && promoSelected !== "") {
      init();
      setPromo(promos.filter((p) => p["@id"] === promoSelected)[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promoSelected]);

  useEffect(() => {
    if (charging) {
      loadEleves();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [charging]);

  useEffect(() => {
    if (notes !== null) {
      calculsMoyenne();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notes]);

  const init = () => {
    setNotes(null);
    setMoyennes(null);
    setCommentaires(null);
    setEleves(null);
  };

  const loadPromos = () => {
    let uri = url + "api/promos?";
    user.sites.forEach((s, i) => {
      uri += user.sites.length > 1 ? "site[]=" : "site=";
      uri += s["@id"];
      uri += i + 1 < user.sites.length ? "&" : "";
    });
    GetBddProvider(uri).then((res) => {
      if (typeof res === "object") {
        setPromos(res["hydra:member"]);
      } else {
        setMsg({ txt: "Erreur de chargement des promos", type: "danger" });
        autoCloseMsg(setMsg, 5000);
      }
    });
  };

  const loadEleves = () => {
    let uri = url + "api/eleves?user.actif=true&promos=" + promoSelected;
    GetBddProvider(uri).then((res) => {
      if (typeof res === "object") {
        setEleves(res["hydra:member"].sort(triByUserName));
        loadComments(res["hydra:member"].sort(triByUserName));
      } else {
        setMsg({ txt: "Erreur de chargement des élèves", type: "danger" });
        autoCloseMsg(setMsg, 5000);
        setCharging(false);
      }
    });
  };

  const loadComments = (el) => {
    let uri = url + "api/comment_matieres?";
    el.forEach((e, i, t) => {
      uri += t.length > 1 ? "eleve[]=" : "eleve=";
      uri += e["@id"];
      uri += "&";
    });
    promo.semestres.forEach((sem, i, sems) => {
      uri += sems.length > 1 ? "semestre[]=" : "semestre=";
      uri += sem["@id"];
      uri += i + 1 < sems.length ? "&" : "";
    });
    GetBddProvider(uri).then((res) => {
      if (typeof res === "object") {
        setCommentaires(res["hydra:member"]);
        loadNotes();
      } else {
        setMsg({
          txt: "Erreur de chargement des commentaires",
          type: "danger",
        });
        autoCloseMsg(setMsg, 5000);
        setCharging(false);
      }
    });
  };

  const loadNotes = () => {
    let uriMat = url + promo.formation["@id"].slice(1);
    GetBddProvider(uriMat).then((res) => {
      if (typeof res === "object") {
        setFormation(res);
        let uri = url + "api/notes?";
        promo.semestres.forEach((s, i, tab) => {
          uri += tab.length > 1 ? "semestre[]=" : "semestre=";
          uri += s["@id"];
          uri += i + 1 < tab.length ? "&" : "";
        });
        GetBddProvider(uri).then((res) => {
          if (typeof res === "object") {
            setNotes(res["hydra:member"]);
          } else {
            setMsg({ txt: "Erreur de chargement des notes", type: "danger" });
            autoCloseMsg(setMsg, 5000);
            setCharging(false);
          }
        });
      } else {
        setMsg({ txt: "Erreur de chargement des matières", type: "danger" });
        autoCloseMsg(setMsg, 5000);
        setCharging(false);
      }
    });
  };

  const calculsMoyenne = () => {
    let tmpMoy = [];
    promo.semestres.forEach((s) => {
      tmpMoy = [
        ...tmpMoy,
        {
          semestre: s["@id"],
          moyennes: CalcMoyEleves(formation.matieres, eleves, notes, s["@id"]),
        },
      ];
    });
    setMoyennes(tmpMoy);
    setCharging(false);
  };

  const valid = () => {
    return promoSelected !== null && promoSelected !== "";
  };

  return Layout(
    <div className="mt-4">
      <h1>Préparation du livret pédagogique</h1>
      {msg !== null ? (
        <MsgAlert
          msg={msg.txt}
          type={msg.type}
          close={() => autoCloseMsg(setMsg)}
        />
      ) : null}
      <hr className="m-4" />
      {promos === null ? (
        <Spinner></Spinner>
      ) : (
        <React.Fragment>
          <div className="form-row mx-4">
            <div className="form-group col-lg-6">
              <label>promo</label>
              <select
                className="form-control"
                value={promoSelected || ""}
                onChange={(e) => setPromoSelected(e.target.value)}
              >
                <option value="" disabled>
                  Sélectionnez une promo
                </option>
                {promos.map((p, i) => (
                  <option value={p["@id"]} key={i}>
                    {p.site.name} - {p.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group col-lg-6">
              <label>&nbsp;</label>
              <button
                className="btn btn-outline-primary form-control"
                onClick={() => setCharging(true)}
                disabled={!valid() || charging || moyennes !== null}
              >
                Afficher
              </button>
            </div>
          </div>
          <hr className="m-4" />
          {charging ? (
            <Spinner />
          ) : moyennes === null || eleves === null ? null : (
            <div>
              <ReactHTMLTableToExcel
                className="btn btn-outline-primary mb-4"
                table="tabLivret"
                filename={"Préparation Livret " + promo.name}
                sheet="Export"
                buttonText="Export Excel"
              />
              <div className="table-responsive table-perso">
                <table
                  className="table table-sm table-bordered border-0"
                  id="tabLivret"
                >
                  <thead>
                    <tr>
                      <th colSpan={eleves.length * promo.semestres.length + 2}>
                        <h4>{promo.name}</h4>
                      </th>
                    </tr>
                    <tr>
                      <th rowSpan="2">Matière</th>
                      <th rowSpan="2">coef</th>
                      {eleves.map((e, i) =>
                        promo.semestres.map((s, si) => (
                          <th key={i + "-" + si}>
                            {e.user.name} {e.user.firstName}
                          </th>
                        ))
                      )}
                    </tr>
                    <tr>
                      {eleves.map((e, ei) =>
                        promo.semestres
                          .sort(triByName)
                          .map((s, si) => <th key={ei + "-" + si}>{s.name}</th>)
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {formation.matieres
                      .filter((m) => !m.hidden)
                      .sort(triByName)
                      .map((matiere, mi) => {
                        let coef = formation.coefs.filter(
                          (c) => c.matiere["@id"] === matiere["@id"]
                        )[0].coefficient;
                        return (
                          <tr key={mi}>
                            <td>{matiere.name}</td>
                            <td>{convertDecimal(coef)}</td>
                            {eleves.map((eleve, ei) =>
                              promo.semestres
                                .sort(triByName)
                                .map((semestre, si) => {
                                  let moyenne = moyennes
                                    .filter(
                                      (moy) => moy.semestre === semestre["@id"]
                                    )[0]
                                    .moyennes.filter(
                                      (moy) =>
                                        moy.matiere === matiere["@id"] &&
                                        moy.eleve === eleve["@id"]
                                    )[0].moyenne;
                                  return (
                                    <td key={mi + "-" + ei + "-" + si}>
                                      {moyenne === null
                                        ? "NE"
                                        : convertDecimal(moyenne)}
                                    </td>
                                  );
                                })
                            )}
                          </tr>
                        );
                      })}
                  </tbody>
                  <thead>
                    <tr>
                      <th colSpan={eleves.length * promo.semestres.length + 2}>
                        <h4>Commentaires semestriels</h4>
                      </th>
                    </tr>
                    <tr>
                      <th rowSpan="2">Matière</th>
                      <th rowSpan="2"></th>
                      {eleves.map((e, i) =>
                        promo.semestres.map((s, si) => (
                          <th key={i + "-" + si}>
                            {e.user.name} {e.user.firstName}
                          </th>
                        ))
                      )}
                    </tr>
                    <tr>
                      {eleves.map((e, ei) =>
                        promo.semestres
                          .sort(triByName)
                          .map((s, si) => <th key={ei + "-" + si}>{s.name}</th>)
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {formation.matieres
                      .filter((m) => !m.hidden)
                      .sort(triByName)
                      .map((matiere, mi) => {
                        return (
                          <tr key={mi}>
                            <td>{matiere.name}</td>
                            <td></td>
                            {eleves.map((eleve, ei) =>
                              promo.semestres
                                .sort(triByName)
                                .map((semestre, si) => {
                                  let commentTmp = commentaires.filter(
                                    (com) =>
                                      com.semestre === semestre["@id"] &&
                                      com.matiere === matiere["@id"] &&
                                      com.eleve === eleve["@id"]
                                  );
                                  return (
                                    <td key={mi + "-" + ei + "-" + si}>
                                      {commentTmp.map((com, ci, tcom) => (
                                        <React.Fragment
                                          key={
                                            mi + "-" + ei + "-" + si + "-" + ci
                                          }
                                        >
                                          <span> {com.comment}</span>
                                          {ci + 1 < tcom.length ? " | " : null}
                                        </React.Fragment>
                                      ))}
                                    </td>
                                  );
                                })
                            )}
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </React.Fragment>
      )}
    </div>
  );
};

export default PrepaLivret;
