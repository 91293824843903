import React, { useContext, useEffect, useState } from "react";
import AppContext from "../../../Context/AppContext";
import NotesContext from "../../../Context/NotesContext";
import useGet from "../../../hooks/useGet";
import usePost from "../../../hooks/usePost";
import MsgAlert from "../../common/MsgAlert";
import Spinner from "../../common/Spinner";
import { autoCloseMsg } from "../../tools/messagesUtils";

function EditNote({ eleve }) {
  const { url, user } = useContext(AppContext);
  const {
    formateur,
    matiere,
    semestreSelected,
    promoLoaded,
    controleSelected,
    setMaj,
    setGlobalMsg,
    autoCloseGlobal,
  } = useContext(NotesContext);

  const PostBDD = usePost();
  const GetBdd = useGet();

  const [formateurs, setFormateurs] = useState([]);
  const [formateurSelected, setFormateurSelected] = useState(
    formateur ? formateur : null
  );
  const [loaded, setLoaded] = useState(false);
  const [msg, setMsg] = useState(null);
  const [note, setNote] = useState({
    coef: 1,
    matiere,
    eleve,
    semestre: semestreSelected !== null ? semestreSelected["@id"] : null,
    formateur: formateurSelected !== null ? formateurSelected["@id"] : null,
    exam: false,
    createdAt: new Date().toISOString().split("T")[0],
  });

  useEffect(() => {
    setFormateurSelected((prev) => formateur);
  }, [formateur]);

  useEffect(() => {
    if (matiere !== null) {
      setNote((prev) => {
        return {
          ...prev,
          matiere: matiere,
          formateur:
            formateurSelected !== null ? formateurSelected["@id"] : null,
        };
      });
      if (user.role <= 3) {
        setLoaded((prev) => false);
        setFormateurSelected((prev) => null);
        setFormateurs((prev) => []);
        loadFormateur();
      } else {
        setLoaded((prev) => true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matiere]);

  useEffect(() => {
    if (eleve !== null) {
      let temp = formateurs.filter((f) => f.user["@id"] === user["@id"]);

      if (temp.length > 0) {
        setNote((prev) => {
          return {
            ...prev,
            formateur: temp[0]["@id"],
            eleve: eleve["@id"],
            controle: controleSelected ? controleSelected["@id"] : undefined,
            comment: controleSelected?.name,
          };
        });
      } else {
        setNote((prev) => {
          return {
            ...prev,
            eleve: eleve["@id"],
            controle: controleSelected ? controleSelected["@id"] : undefined,
            comment: controleSelected?.name,
          };
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eleve, controleSelected]);

  useEffect(() => {
    setNote((prev) => {
      return {
        ...prev,
        semestre: semestreSelected !== null ? semestreSelected["@id"] : null,
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [semestreSelected]);

  useEffect(() => {
    setNote((prev) => {
      return {
        ...prev,
        formateur: formateurSelected !== null ? formateurSelected["@id"] : null,
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formateurSelected]);

  const init = () => {
    let temp = Object.assign({}, note);
    delete temp.note;
    delete temp.comment;
    setNote((prev) => {
      return {
        ...temp,
        eleve: null,
        formateur: user.role <= 3 ? null : prev.formateur,
      };
    });
  };

  const loadFormateur = () => {
    let uri =
      url +
      "api/formateurs?promos=" +
      promoLoaded["@id"] +
      "&matieres=" +
      matiere;
    GetBdd(uri).then((res) => {
      if (typeof res === "object") {
        let results = res["hydra:member"];
        setFormateurs((prev) => results);
        let temp = results.filter((f) => f.user["@id"] === user["@id"]);
        if (temp.length > 0) {
          setFormateurSelected((prev) => temp[0]);
        }
        setLoaded((prev) => true);
      } else {
        setMsg((prev) => {
          return { txt: "Erreur de chargement des formateurs", type: "danger" };
        });
        autoCloseMsg(setMsg, 5000);
      }
    });
  };

  const valid = () => {
    return (
      note.formateur &&
      note.createdAt &&
      note.eleve &&
      note.matiere &&
      note.coef &&
      note.semestre &&
      note.note &&
      note.comment
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let data = { ...note, note: parseFloat(note.note) };
    let uri = url + "api/notes";
    PostBDD(uri, data).then((res) => {
      if (typeof res === "object" && res.id) {
        init();
        setGlobalMsg((prev) => {
          return {
            txt: "La note a bien été enregistrée",
            type: "success",
          };
        });
        autoCloseGlobal(5000);
        setMaj((prev) => true);
      } else {
        setGlobalMsg((prev) => {
          return { txt: "erreur d'enregistrement", type: "danger" };
        });
        autoCloseGlobal(5000);
      }
    });
  };

  return (
    <div
      className="modal fade"
      id="NoteModal"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-dialog-centered modal-dialog-scrollable"
        role="document"
      >
        {eleve && (
          <div className="modal-content">
            <div className="modal-header bg-info text-white">
              <h5 className="modal-title">
                {eleve.user.name} {eleve.user.firstName}
              </h5>
              <button
                type="button"
                className="close text-white"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            {loaded ? (
              <div className="modal-body">
                <MsgAlert
                  msg="vous êtes actuellement sur la création d'une note individuelle (hors devoir) !"
                  type="danger"
                />
                {msg !== null && (
                  <MsgAlert
                    msg={msg.txt}
                    type={msg.type}
                    close={() => autoCloseMsg(setMsg)}
                  />
                )}
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label>
                      Date
                      {navigator.userAgent.indexOf("Safari") >= 1 &&
                      navigator.userAgent.indexOf("Chrome") <= 0 ? (
                        <em>
                          <small> (aaaa-mm-jj)</small>
                        </em>
                      ) : null}
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      placeholder={
                        navigator.userAgent.indexOf("Safari") >= 1 &&
                        navigator.userAgent.indexOf("Chrome") <= 0
                          ? "aaaa-mm-jj"
                          : null
                      }
                      pattern={
                        navigator.userAgent.indexOf("Safari") >= 1 &&
                        navigator.userAgent.indexOf("Chrome") <= 0
                          ? "\\d{4}-\\d{2}-\\d{2}"
                          : null
                      }
                      value={note.createdAt || ""}
                      max={new Date().toISOString().split("T")[0]}
                      onChange={(e) =>
                        setNote({ ...note, createdAt: e.target.value })
                      }
                      required
                    />
                  </div>
                  <div className="from-group col-md-3">
                    <label>Note</label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="x/20"
                      min="0"
                      max="20"
                      step="0.01"
                      value={note.note || ""}
                      onChange={(e) =>
                        setNote({ ...note, note: e.target.value })
                      }
                    />
                  </div>
                  <div className="from-group col-md-3">
                    <label>Coef</label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="coef"
                      min="0"
                      step="0.5"
                      value={note.coef || ""}
                      onChange={(e) =>
                        setNote({ ...note, coef: parseFloat(e.target.value) })
                      }
                    />
                  </div>
                </div>
                {user.role <= 3 && (
                  <div className="form-group">
                    <label>Formateur</label>
                    <select
                      className="form-control"
                      value={note.formateur || ""}
                      onChange={(e) =>
                        setNote({ ...note, formateur: e.target.value })
                      }
                      required
                    >
                      <option value="" disabled>
                        Sélectionner un formateur
                      </option>
                      {formateurs.map((f, i) => (
                        <option value={f["@id"]} key={i}>
                          {f.user.firstName} {f.user.name}
                        </option>
                      ))}
                    </select>
                  </div>
                )}
                <div className="form-group">
                  <label>Commentaire</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Veuillez saisir un commentaire (Obligatoire)"
                    maxLength="250"
                    value={note.comment || ""}
                    onChange={(e) =>
                      setNote({ ...note, comment: e.target.value })
                    }
                  />
                </div>
                <div className="form-group">
                  <div className="custom-control custom-switch">
                    <input
                      className="custom-control-input"
                      type="checkbox"
                      id="examNoteUniqueCheckbox"
                      checked={note.exam || false}
                      onChange={(e) => setNote({ ...note, exam: !note.exam })}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="examNoteUniqueCheckbox"
                    >
                      Examen Blanc
                    </label>
                  </div>
                </div>
              </div>
            ) : (
              <div className="modal-body p-0" style={{ minHeight: "200px" }}>
                <Spinner />
              </div>
            )}

            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Fermer
              </button>
              <button
                type="button"
                className="btn btn-info"
                onClick={handleSubmit}
                disabled={!valid()}
                data-dismiss="modal"
              >
                Enregistrer
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default EditNote;
