import React from "react";
import ListTuteursEntreprise from "../contents/lists/ListTuteursEntreprise";

function HomeAdminEntreprise({ tuteurs }) {
  return (
    <div className="mt-4">
      <h2 className="m-0">Mes alternants</h2>
      <hr className="m-4" />
      <div className="d-flex flex-wrap justify-content-center">
        {tuteurs.map((tuteur) => (
          <ListTuteursEntreprise tuteur={tuteur} key={tuteur["@id"]} />
        ))}
      </div>
    </div>
  );
}

export default HomeAdminEntreprise;
