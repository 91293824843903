import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MsgAlert from "../../common/MsgAlert";

const ListPromosDetail = ({ site, promos, setselected, selected, status }) => {
  const Details = ({ el }) => {
    let semestresActifs = el.semestres.filter((s) => s.actif);
    return (
      <div className="card border-dark mt-3" style={{ width: "330px" }}>
        <div className="card-header bg-transparent">
          <h5 className="card-title text-primary text-left m-0">
            {el.name}
            </h5>
        </div>
        <div className="card-body p-2">
          <div className="d-flex flex-column text-left">
            <div>
              <span className="">Formation : </span>
              <span className="text-info">{el.formation.name}</span>
            </div>
            <div>
              <span className="">Nb de semestres : </span>{" "}
              {el.semestres.length > 0 ? (
                <span className="text-info">{el.semestres.length}</span>
              ) : (
                <span
                  className="badge badge-danger"
                  title={`RDV dans le menu "Administration => Gestion des emestres"`}
                >
                  Semestres non initialisés
                </span>
              )}
            </div>
            <div>
              <span className="">Semestre(s) actif(s) : </span>
              {semestresActifs.length > 0 ? (
                semestresActifs.map((s, i) => (
                  <span className="badge badge-info mx-1" key={i}>
                    {s.name}
                  </span>
                ))
              ) : (
                <span className="badge badge-warning mx-1">
                  Aucun semestre actif
                </span>
              )}
            </div>
          </div>
        </div>
        <div className="card-footer bg-transparent">
          <div
            onClick={(e) =>
              setselected(
                JSON.parse(JSON.stringify({ ...el, site: site["@id"] }))
              )
            }
            style={{ cursor: "pointer" }}
            className="btn btn-outline-dark btn-sm text-nowrap"
            data-toggle="modal"
            data-target="#Modal"
            title="Modifier"
          >
            Modifier <FontAwesomeIcon icon="edit" className="shadow" />
          </div>
        </div>
      </div>
    );
  };

  const NoContent = () => (
    <div className="bg-white">
      <MsgAlert
        msg={`Pas de promos ${status ? `actives` : `inactives`}`}
        type="warning m-0 w-100"
      />
    </div>
  );

  return (
    <div
      className={`tab-pane ${selected && "show active"} border border-top-0`}
      id={status ? "Actives" : "Inactives"}
    >
      {promos.length > 0 ? (
        <div className="d-flex flex-wrap justify-content-around bg-white pb-3">
          {promos.map((el, i) => {
            return <Details el={el} key={i} />;
          })}
        </div>
      ) : (
        <NoContent />
      )}
    </div>
  );
};

export default ListPromosDetail;
