import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useContext } from "react";
import { NavLink } from "react-router-dom";
import AppContext from "../../Context/AppContext";

const SidebarDropdown = ({ role = 7, name, items, icon }) => {
  const { user } = useContext(AppContext);
  return user.role <= role ? (
    <li className="nav-item dropdown">
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a
        href="#"
        className="nav-link dropdown-toggle py-1"
        role="button"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <FontAwesomeIcon icon={icon} style={{ width: "20px" }} /> {name}
      </a>
      <div className="dropdown-menu p-1 bg-light">
        {items.map((item, i) =>
          user.role <= item.role ? (
            <NavLink
              className="dropdown-item py-0"
              activeClassName="active text-ligth bg-secondary font-weight-bold rounded-lg"
              exact={item.exact || false}
              to={item.path}
              key={i}
            >
              <FontAwesomeIcon icon={item.icon} style={{ width: "20px" }} />{" "}
              {item.name}
            </NavLink>
          ) : null
        )}
      </div>
    </li>
  ) : null;
};

export default SidebarDropdown;
