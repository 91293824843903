import React, { useEffect, useState } from "react";
import useGet from "../../../hooks/useGet";
import useUpdate from "../../../hooks/useUpdate";
import SmallSpinner from "../../common/SmallSpinner";
import { triByActifDESC } from "../sortUtils";

export default function SitePseudo({ site, siteName, url }) {
  const [users, setUsers] = useState([]);
  const [numberUser, setNumberUser] = useState(null);
  const [NbUserWithOutPseudo, setNbUserWithOutPseudo] = useState(null);
  const [anomalie, setAnomalie] = useState(null);
  const [maj, setMaj] = useState(false);
  const [progress, setProgress] = useState(0);
  const [loaded, setLoaded] = useState(false);

  const GetBdd = useGet();
  const UpdateBdd = useUpdate();

  let count = progress;

  useEffect(() => {
    setLoaded(false);
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [site]);

  useEffect(() => {
    if (maj) {
      setLoaded(false);
      load();
      setMaj(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maj]);

  const load = () => {
    let uri;
    if (site === "Exists") {
      uri = url + "api/users?exists[pseudo]=false";
    } else {
      uri = url + "api/users?sites=" + site;
    }
    GetBdd(uri).then((res) => {
      if (typeof res === "object") {
        let nbOk = res["hydra:member"].filter((u) => u.pseudo).length;
        let anomalie = res["hydra:member"].filter(
          (u) => u.pseudo.slice(-1) === " "
        );
        setUsers(res["hydra:member"]);
        setNumberUser(res["hydra:totalItems"]);
        setNbUserWithOutPseudo(nbOk);
        setAnomalie(anomalie);
        setProgress(nbOk);
        setLoaded(true);
      }
    });
  };

  const update = async (uri, data) => {
    let result = false;
    let el = document.querySelector(`#count${siteName}`);
    await UpdateBdd(uri, data).then((res) => {
      if (typeof res === "object" && res.id) {
        result = true;
        count++;
        if (el !== null) {
          el.innerHTML = count;
        }
      }
    });
    return result;
  };

  const correction = () => {
    anomalie.forEach(async (u, i) => {
      let save = false;
      for (let variation = 0; save === false; variation++) {
        let pseudo = u.pseudo.slice(0, -1);
        for (let ok = false; ok === false; ) {
          let pseudoTest = pseudo.slice(-1);
          pseudoTest === " " ? (pseudo = pseudo.slice(0, -1)) : (ok = true);
        }
        let data =
          variation > 0 ? { pseudo: pseudo + variation } : { pseudo: pseudo };
        save = await UpdateBdd(url + u["@id"].slice(1), data).then((res) => {
          if (typeof res === "object" && res.id) {
            return true;
          } else {
            return false;
          }
        });
      }

      if (i + 1 === anomalie.length && save) {
        setMaj(true);
      }
    });
  };

  const generatePseudo = () => {
    let list = users.sort(triByActifDESC).filter((u) => !u.pseudo);
    list.forEach(async (u, i) => {
      let save = false;
      if (!u.pseudo) {
        let pseudo =
          u.firstName.toLowerCase().slice(0, 1) + "." + u.name.toLowerCase();
        let uri = url + u["@id"].slice(1);

        for (let variation = 0; save === false; variation++) {
          let data =
            variation > 0 ? { pseudo: pseudo + variation } : { pseudo: pseudo };
          save = await update(uri, data);
        }
      }
      if (i + 1 === list.length && save) {
        setMaj(true);
      }
    });
  };

  return (
    <>
      <tr>
        <td>{siteName}</td>
        <td>{loaded ? numberUser : <SmallSpinner />}</td>
        <td>{loaded ? NbUserWithOutPseudo : <SmallSpinner />}</td>
        <td>
          {loaded ? (
            <span
              className={count === numberUser ? "text-success" : "text-danger"}
            >
              <span id={`count${siteName}`}>{count}</span>/{numberUser}
            </span>
          ) : (
            <SmallSpinner />
          )}
        </td>
        <td>
          {loaded ? (
            <button
              className="btn rounded-pill btn-sm btn-outline-info"
              onClick={generatePseudo}
            >
              Générer
            </button>
          ) : (
            <SmallSpinner />
          )}
        </td>
      </tr>
      <tr>
        <td colSpan={4} className="text-center text-danger">
          {loaded ? `nbre d'anomalie : ${anomalie.length}` : <SmallSpinner />}
        </td>
        <td>
          {loaded ? (
            <button
              className="btn rounded-pill btn-sm btn-outline-info"
              onClick={correction}
            >
              Corriger
            </button>
          ) : (
            <SmallSpinner />
          )}
        </td>
      </tr>
    </>
  );
}
