import React, { useContext, useEffect, useState } from "react";
import AppContext from "../../../Context/AppContext";
import GetBddProvider from "../../../Providers/GetBddProvider";
import PostBddProvider from "../../../Providers/PostBddProvider";
import MsgAlert from "../../common/MsgAlert";
import SmallSpinner from "../../common/SmallSpinner";
import Spinner from "../../common/Spinner";
import { autoCloseMsg } from "../../tools/messagesUtils";
import { triByName } from "../../tools/sortUtils";

const EditNotes = ({ formateur, selectedNotes, setMaj = undefined }) => {
  const { url, user } = useContext(AppContext);
  // const { formateur, selectedNotes } = useContext(PageFormateurContext);

  const [matieres, setMatieres] = useState(null);
  const [matiereSelected, setMatiereSelected] = useState(null);
  const [promo, setPromo] = useState(selectedNotes);
  const [createdAt, setCreatedAt] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [semestreSelected, setSemestreSelected] = useState(null);
  const [semestres, setSemestres] = useState(null);
  const [commonComment, setCommonComment] = useState(null);
  const [coef, setCoef] = useState(1);
  const [loaded, setLoaded] = useState(false);
  const [saveProcess, setSaveProcess] = useState(false);
  const [detailProcess, setDetailProcess] = useState("");
  const [msg, setMsg] = useState(null);
  const [notes, setnotes] = useState([]);
  const [exam, setExam] = useState(false);

  useEffect(() => {
    if (selectedNotes !== null && selectedNotes !== promo) {
      setLoaded(false);
      setPromo(selectedNotes);
      load();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedNotes]);

  const init = () => {
    let sem = selectedNotes.semestres.filter((sem) => sem.actif);
    user.role > 3 ? setSemestres(sem) : setSemestres(selectedNotes.semestres);
    setCommonComment(null);
    setCoef(1);
    setSemestreSelected(sem[0] ? sem[0]["@id"] : null);
    setMatiereSelected(null);
    setExam(false);
    let tempNotes = {};
    selectedNotes.eleves.forEach(
      (el) =>
        (tempNotes = {
          ...tempNotes,
          [el["@id"]]: {
            iri: el["@id"],
            name: el.user.name,
            firstName: el.user.firstName,
          },
        })
    );
    setnotes(tempNotes);
  };

  const load = () => {
    init();
    let uri =
      url +
      "api/matieres?&hidden=0&formations=" +
      selectedNotes.formation["@id"];
    if (user.role > 3) {
      uri += "&formateurs=" + formateur["@id"];
    }

    GetBddProvider(uri).then((res) => {
      setLoaded(true);
      if (typeof res === "object") {
        setMatieres(res["hydra:member"].sort(triByName));
      } else {
        setMsg({ txt: "Erreur de chargement", type: "danger" });
        autoCloseMsg(setMsg, 5000);
      }
    });
  };

  const valid = () => {
    let erreurs = [];
    if (matiereSelected === null) {
      erreurs.push("Veuillez renseigner la matière");
    }
    if (coef === null) {
      erreurs.push("Veuilez saisir un coefficient");
    }
    if (commonComment === null || commonComment === "") {
      erreurs.push("Veuillez saisir un commentaire");
    }
    if (promo !== null) {
      let n = null;
      promo.eleves.forEach((el) => {
        if (
          notes[el["@id"]].note !== "" &&
          notes[el["@id"]].note !== undefined
        ) {
          n = true;
        }
      });
      if (n === null) {
        erreurs.push("Veuillez saisir au moins une note");
      }
    }
    return erreurs.length > 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoaded(false);
    setSaveProcess(true);
    let uri = url + "api/notes";
    let n = 0;
    let controleURI = await PostBddProvider(url + "api/controles", {
      name: commonComment,
      promo: promo["@id"],
      createdAt: createdAt,
      matiere: matiereSelected,
      semestre: semestreSelected,
      exam: exam,
    }).then((res) => {
      return typeof res === "object" && res.id
        ? "/api/controles/" + res.id
        : null;
    });

    promo.eleves
      .filter((e) => e.user.actif)
      .forEach(async (el) => {
        let data = {
          eleve: el["@id"],
          matiere: matiereSelected,
          formateur: formateur["@id"],
          coef: parseFloat(coef),
          createdAt: createdAt,
          controle: controleURI,
          semestre: semestreSelected,
          exam: exam,
        };
        if (
          notes[el["@id"]].note !== undefined &&
          notes[el["@id"]].note !== ""
        ) {
          data = { ...data, note: parseFloat(notes[el["@id"]].note) };
        }
        if (
          notes[el["@id"]].comment !== undefined &&
          notes[el["@id"]].comment !== ""
        ) {
          data = {
            ...data,
            comment: `${commonComment} - ${notes[el["@id"]].comment}`,
          };
        } else if (commonComment !== undefined && commonComment !== "") {
          data = { ...data, comment: commonComment };
        }

        n = await PostBddProvider(uri, data).then((res) => {
          if (typeof res === "object" && res.id) {
            return n + 1;
          } else {
            setLoaded(true);
            setMsg({ txt: "erreur d'enregistrement", type: "danger" });
            autoCloseMsg(setMsg, 5000);
          }
        });

        setDetailProcess(
          `(note ${n} sur ${promo.eleves.filter((e) => e.user.actif).length})`
        );

        if (n === promo.eleves.length) {
          init();
          setMaj !== undefined && setMaj(true);
          setLoaded(true);
          setSaveProcess(false);
          setMsg({ txt: "les notes ont été enregistrées", type: "success" });
          autoCloseMsg(setMsg, 5000);
        }
      });
  };

  return (
    <div
      className="modal fade"
      id="NotesModal"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header bg-info text-white">
            <h5 className="modal-title">
              {promo !== null ? promo.name + " - " : null}
              Saisie des notes
            </h5>
            <button
              type="button"
              className="close text-white"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <form>
              {msg !== null ? (
                <div className="mx-4">
                  <MsgAlert
                    msg={msg.txt}
                    type={msg.type}
                    close={() => autoCloseMsg(setMsg)}
                  />
                </div>
              ) : null}
              {saveProcess ? (
                <div>
                  <SmallSpinner />{" "}
                  <span>enregistrement encours ... {detailProcess}</span>
                </div>
              ) : !loaded || matieres === null || notes === null ? (
                <Spinner />
              ) : semestreSelected === null ? (
                <h4>Aucun semestre actif</h4>
              ) : (
                <div>
                  <div className="form-row align-items-center">
                    <div className="form-group col-md-6">
                      <label>
                        Date
                        {navigator.userAgent.indexOf("Safari") >= 1 &&
                        navigator.userAgent.indexOf("Chrome") <= 0 ? (
                          <em>
                            <small> (aaaa-mm-jj)</small>
                          </em>
                        ) : null}
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        placeholder={
                          navigator.userAgent.indexOf("Safari") >= 1 &&
                          navigator.userAgent.indexOf("Chrome") <= 0
                            ? "aaaa-mm-jj"
                            : null
                        }
                        pattern={
                          navigator.userAgent.indexOf("Safari") >= 1 &&
                          navigator.userAgent.indexOf("Chrome") <= 0
                            ? "\\d{4}-\\d{2}-\\d{2}"
                            : null
                        }
                        value={createdAt || ""}
                        max={new Date().toISOString().split("T")[0]}
                        onChange={(e) => setCreatedAt(e.target.value)}
                        required
                      />
                    </div>

                    {semestres.length === 1 ? (
                      <div className="form-group col-md-6">
                        <strong>
                          <em>
                            <u>{semestres[0].name}</u>
                          </em>
                        </strong>
                      </div>
                    ) : (
                      <div className="form-group col-md-6">
                        <label>Semestre</label>
                        <select
                          name="selectSemestre"
                          className="form-control"
                          value={semestreSelected || ""}
                          onChange={(e) => setSemestreSelected(e.target.value)}
                          required
                        >
                          <option value="" disabled>
                            Sélectionnez un semestre
                          </option>
                          {semestres.map((sem, i) => (
                            <option value={sem["@id"]} key={i}>
                              {sem.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    )}
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <label>Matière</label>
                      <select
                        name="selectMatiere"
                        className={
                          matiereSelected !== null
                            ? "form-control"
                            : "is-invalid form-control"
                        }
                        value={matiereSelected || ""}
                        onChange={(e) => setMatiereSelected(e.target.value)}
                        required
                      >
                        <option value="" disabled>
                          Selectionnez une matière
                        </option>
                        {matieres.map((mat, i) => (
                          <option value={mat["@id"]} key={i}>
                            {mat.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="form-group col-4 col-md-3 col-lg-2 mx-auto">
                      <label>Coefficient</label>
                      <input
                        type="number"
                        className={
                          coef !== null && coef !== ""
                            ? "form-control"
                            : "is-invalid form-control"
                        }
                        placeholder="coef"
                        min="0"
                        step="0.5"
                        value={coef || ""}
                        onChange={(e) => setCoef(parseFloat(e.target.value))}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label>Nom du devoir</label>
                    <input
                      type="text"
                      className={
                        commonComment !== null && commonComment !== ""
                          ? "form-control"
                          : "is-invalid form-control"
                      }
                      placeholder="Veuillez saisir le nom du devoir (Obligatoire)"
                      maxLength="250"
                      value={commonComment || ""}
                      onChange={(e) => setCommonComment(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <div className="custom-control custom-switch">
                      <input
                        className="custom-control-input"
                        type="checkbox"
                        id="examCheckbox"
                        checked={exam || false}
                        onChange={(e) => setExam(!exam)}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="examCheckbox"
                      >
                        Examen Blanc
                      </label>
                    </div>
                  </div>
                  <hr />
                  {promo.eleves.map((el, i) => {
                    if (el.user.actif) {
                      return (
                        <div
                          className={
                            i + 1 < promo.eleves.length
                              ? "form-row align-items-center border-bottom border-info text-left"
                              : "form-row align-items-center text-left"
                          }
                          key={i}
                        >
                          <div className="col-lg-4 d-flex justify-content-center justify-content-lg-start my-3">
                            <div>
                              <span>
                                <strong>
                                  {notes[el["@id"]].name}{" "}
                                  {notes[el["@id"]].firstName}
                                </strong>
                              </span>
                            </div>
                          </div>
                          <div className="col-4 col-md-3 col-lg-2 my-2 m-auto">
                            <input
                              type="number"
                              className="form-control"
                              step="0.01"
                              placeholder="x/20"
                              value={notes[el["@id"]].note || ""}
                              min="0"
                              max="20"
                              onChange={(e) => {
                                setnotes({
                                  ...notes,
                                  [el["@id"]]: {
                                    ...notes[el["@id"]],
                                    note: e.target.value,
                                  },
                                });
                              }}
                            />
                          </div>
                          <div className="flex-grow-1 my-2">
                            <input
                              type="text"
                              className="form-control"
                              value={notes[el["@id"]].comment || ""}
                              placeholder="commentaire personnalisé (Facultatif)"
                              maxLength="250"
                              onChange={(e) =>
                                setnotes({
                                  ...notes,
                                  [el["@id"]]: {
                                    ...notes[el["@id"]],
                                    comment: e.target.value,
                                  },
                                })
                              }
                            />
                          </div>
                        </div>
                      );
                    } else {
                      return null;
                    }
                  })}
                </div>
              )}
            </form>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
            >
              Fermer
            </button>
            <button
              type="button"
              className="btn btn-info"
              onClick={handleSubmit}
              disabled={valid()}
            >
              Enregistrer
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditNotes;
