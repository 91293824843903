import React, { useContext } from "react";
import Layout from "../common/Layout";
import logo from "../../logo-symbol.svg";
import AppContext from "../../Context/AppContext";
import { useEffect } from "react";
import GetBddProvider from "../../Providers/GetBddProvider";
import { useState } from "react";
import Spinner from "../common/Spinner";
import Eleve from "../contents/Eleve";
import PageFormateur from "./PageFormateur";
import Adminitratif from "./Administratif";
import ListElevesTuteur from "../contents/lists/ListElevesTuteur";
import HomeAdminEntreprise from "./HomeAdminEntreprise";

const Home = () => {
  const { url, user } = useContext(AppContext);
  const [loaded, setloaded] = useState(false);
  const [profil, setprofil] = useState(null);
  const [tuteurs, setTuteurs] = useState(null);

  // role élève 5
  useEffect(() => {
    switch (user.role) {
      case 1:
        setloaded(true);
        setprofil("none");
        break;
      case 2:
      case 3:
        GetBddProvider(url + "api/formateurs?user=" + user["@id"]).then(
          (res) => {
            if (typeof res === "object" && res["hydra:member"].length > 0) {
              setloaded(true);
              setprofil({ formateur: res["hydra:member"][0] });
            } else {
              GetBddProvider(url + "api/eleves?user=" + user["@id"]).then(
                (res) => {
                  setloaded(true);
                  if (
                    typeof res === "object" &&
                    res["hydra:member"].length > 0
                  ) {
                    setprofil({ eleve: res["hydra:member"][0] });
                  } else {
                    setprofil("none");
                  }
                }
              );
            }
          }
        );
        break;
      case 3.5:
      case 4:
        GetBddProvider(url + "api/formateurs?user=" + user["@id"]).then(
          (res) => {
            if (typeof res === "object") {
              setloaded(true);
              setprofil({ formateur: res["hydra:member"][0] });
            }
          }
        );
        break;
      case 5:
        let uri = url + "api/eleves?user=" + user["@id"];
        GetBddProvider(uri).then((res) => {
          setloaded(true);
          if (typeof res === "object") {
            setprofil(res["hydra:member"][0]);
          }
        });
        break;
      case 5.5:
        GetBddProvider(url + "api/entreprise_admins?user=" + user["@id"]).then(
          (res) => {
            if (typeof res === "object") {
              let result = res["hydra:member"][0];
              setprofil(result);
              GetBddProvider(
                url + "api/entreprises?entrepriseAdmin=" + result["@id"]
              ).then((res) => {
                setloaded(true);
                if (typeof res === "object") {
                  setTuteurs(res["hydra:member"]);
                }
              });
            }
          }
        );
        break;
      case 6:
        GetBddProvider(
          url + "api/eleves?user.actif=true&entreprises.user=" + user["@id"]
        ).then((res) => {
          setloaded(true);
          if (typeof res === "object") {
            setprofil(res["hydra:member"]);
          }
        });
        break;
      default:
        setloaded(true);
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const Content = () => {
    switch (user.role) {
      case 1:
        if (loaded && profil !== null) {
          if (profil.formateur !== undefined) {
            return <PageFormateur profil={profil.formateur} />;
          } else {
            return <Adminitratif />;
          }
        } else {
          return <Spinner />;
        }
      case 2:
        return <Adminitratif />;
      case 3:
        if (profil !== null) {
          if (profil.formateur !== undefined) {
            return <PageFormateur profil={profil.formateur} />;
          }
          if (profil.eleve !== undefined) {
            return <Eleve id={profil.eleve.id} />;
          }
          if (profil === "none") {
            return <Adminitratif />;
          }
          return <Spinner />;
        }
        return <Spinner />;
      case 3.5:
      case 4:
        return profil !== null ? (
          <PageFormateur profil={profil.formateur} />
        ) : (
          <Spinner />
        );
      case 5:
        return profil !== null ? <Eleve id={profil.id} /> : <Spinner />;
      case 5.5:
        return tuteurs !== null ? (
          <HomeAdminEntreprise tuteurs={tuteurs} />
        ) : (
          <Spinner />
        );
      case 6:
        if (profil !== null) {
          if (profil.length !== 0) {
            if (profil.length > 1) {
              return (
                <div className="mt-4">
                  <h2 className="m-0">Mes étudiants</h2>
                  <hr className="m-4" />
                  <div className="d-flex flex-wrap justify-content-center">
                    <ListElevesTuteur eleves={profil} />
                  </div>
                </div>
              );
            }
            return <Eleve id={profil[0].id} />;
          } else {
            return (
              <div>
                <h1 className="mt-4 h1 text-danger">Pas d'élève rattaché !</h1>
                <img src={logo} alt="logo" className=" App-logo-home" />
              </div>
            );
          }
        } else {
          return <Spinner />;
        }
      default:
        return !loaded ? (
          <Spinner />
        ) : (
          <div>
            <h1 className="mt-4 h1">Déploiement en cours !</h1>

            <img src={logo} alt="logo" className=" App-logo-home" />
          </div>
        );
    }
  };

  return Layout(<Content />);
};

export default Home;
