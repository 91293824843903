import React from "react";
import { Pie, PieChart, Tooltip } from "recharts";

const max = 5;

const data = [
  {
    name: "Item A",
    note: Math.floor(Math.random() * max),
  },
  {
    name: "Item B",
    note: Math.floor(Math.random() * max),
  },
  {
    name: "Item C",
    note: Math.floor(Math.random() * max),
  },
  {
    name: "Item D",
    note: Math.floor(Math.random() * max),
  },
  {
    name: "Item E",
    note: Math.floor(Math.random() * max),
  },
  {
    name: "Item F",
    note: Math.floor(Math.random() * max),
  },
  {
    name: "Item G",
    note: Math.floor(Math.random() * max),
  },
  {
    name: "Item H",
    note: Math.floor(Math.random() * max),
  },
  {
    name: "Item I",
    note: Math.floor(Math.random() * max),
  },
  {
    name: "Item J",
    note: Math.floor(Math.random() * max),
  },
  {
    name: "Item K",
    note: Math.floor(Math.random() * max),
  },
  {
    name: "Item L",
    note: Math.floor(Math.random() * max),
  },
  {
    name: "Item M",
    note: Math.floor(Math.random() * max),
  },
  {
    name: "Item N",
    note: Math.floor(Math.random() * max),
  },
];

function PieChartsComponent() {
  return (
    <div className="d-flex justify-content-center">
      <PieChart width={1500} height={600}>
        <Pie
          dataKey="note"
          // isAnimationActive={false}
          data={data}
          cx="50%"
          cy="50%"
          outerRadius={200}
          fill="#82ca9d"
          label
        />
        <Tooltip />
      </PieChart>
    </div>
  );
}

export default PieChartsComponent;
