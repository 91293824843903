import React from 'react';
import Spinner from "../../common/Spinner";
import FormateurItemGalia from "./FormateurItemGalia";

export default function SyncFormateursGalia({
  loaded,
  formateurs,
  formateursGalia,
  site,
  maj,
}) {
  return (
    <div className="card text-left">
      <div className="card-header">
        <h5 className="card-title text-center">
          Synchonisation des formateurs
        </h5>
      </div>
      <div className="card-body p-0">
        {!loaded ? (
          <div className="text-center m-2">
            <Spinner />
          </div>
        ) : (
          <ul className="list-group list-group-flush">
            {formateurs.map((f) => (
              <FormateurItemGalia
                formateur={f}
                formateurs={formateurs}
                formateursGalia={formateursGalia}
                site={site}
                maj={maj}
                key={f["@id"]}
              />
            ))}
          </ul>
        )}
      </div>
    </div>
  );
}
