import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { useEffect } from "react";
import { useContext } from "react";
import AppContext from "../../../Context/AppContext";
import PageFormateurContext from "../../../Context/PageFormateurContext";
import UpdateBddProvider from "../../../Providers/UpdateBddProvider";
import MsgAlert from "../../common/MsgAlert";
import Spinner from "../../common/Spinner";
import dateFormated from "../../tools/dateFormated";
import { autoCloseMsg } from "../../tools/messagesUtils";

const ModifAbsence = ({ absSelected, promos, maj }) => {
  const { url, user } = useContext(AppContext);
  const { modifAbs, promoSelected } = useContext(PageFormateurContext);
  const [absence, setAbsence] = useState(null);
  const [promo, setPromo] = useState(null);
  const [msg, setMsg] = useState(null);
  const [save, setSave] = useState(true);

  useEffect(() => {
    if (modifAbs !== null && modifAbs !== absence) {
      setAbsence({ ...modifAbs, semestre: modifAbs.semestre["@id"] });
      setPromo(promoSelected);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modifAbs]);

  useEffect(() => {
    if (
      absSelected !== undefined &&
      absSelected !== absence &&
      absSelected !== null
    ) {
      setAbsence({ ...absSelected, semestre: absSelected.semestre["@id"] });
      setPromo(promos.filter((p) => p["@id"] === absSelected.promo["@id"])[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [absSelected]);

  const isValide = () => {
    return absence.motif !== undefined;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSave(false);
    let data = {
      time: absence.time,
      motif: absence.motif,
      justify: absence.justify,
      prev: absence.prev,
      comment: absence.comment,
      semestre: absence.semestre,
    };
    let uri = url + absence["@id"].slice(1);
    UpdateBddProvider(uri, data).then((res) => {
      setSave(true);
      if (typeof res === "object" && res.id) {
        if (maj !== undefined) {
          maj(true);
        }
        setMsg({ txt: "Modification enregistrée", type: "success" });
        autoCloseMsg(setMsg, 5000);
      } else {
        setMsg({ txt: "Erreur d'enregistrement", type: "danger" });
        autoCloseMsg(setMsg, 5000);
      }
    });
  };

  return (
    <div
      className="modal fade"
      id="ModalModifAbs"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <form onSubmit={handleSubmit}>
            <div className="modal-header bg-info text-white">
              <h5 className="modal-title">
                <FontAwesomeIcon icon="edit" /> &nbsp;Modifier l'Absence
              </h5>
              <button
                type="button"
                className="close text-white"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              {msg !== null ? (
                <div className="mx-4">
                  <MsgAlert msg={msg.txt} type={msg.type} close={() => autoCloseMsg(setMsg)} />
                </div>
              ) : null}
              {!save ? (
                <Spinner />
              ) : absence === null ? (
                <p className="text-danger">Pas d'absence sélectionnée</p>
              ) : (
                <div>
                  <div>
                    <strong>
                      <em>Absence du {dateFormated(absence.createdAt)}</em>
                    </strong>
                    <hr />
                    <strong>
                      <em>
                        <u>Elève</u>
                      </em>
                    </strong>
                    <p className="mt-1">
                      {absence.eleve.user.name} {absence.eleve.user.firstName}
                    </p>
                    <hr />
                  </div>
                  {user.role <= 3 ? (
                    <div className="form-group">
                      <label>semestre</label>
                      <select
                        className="form-control"
                        value={absence.semestre || ""}
                        onChange={(e) =>
                          setAbsence({ ...absence, semestre: e.target.value })
                        }
                        required
                      >
                        <option value="" disabled>
                          Sélectionnez un semestre
                        </option>
                        {promo.semestres.map((s, i) => (
                          <option value={s["@id"]} key={i}>
                            {s.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  ) : null}

                  <div className="form-group">
                    <label>Durée de la session</label>
                    <input
                      type="time"
                      title="durée de la session"
                      min="01:00"
                      max={user.role <= 3 ? "" : "04:00"}
                      step="1800"
                      pattern={
                        navigator.userAgent.indexOf("Safari") >= 1 &&
                        navigator.userAgent.indexOf("Chrome") <= 0
                          ? "0[1-4]:\\d{2}"
                          : null
                      }
                      value={
                        absence.time.length > 5
                          ? absence.time.split("T")[1].split(":")[0] +
                            ":" +
                            absence.time.split("T")[1].split(":")[1]
                          : absence.time || "00:00"
                      }
                      className="form-control"
                      onChange={(e) =>
                        setAbsence({
                          ...absence,
                          time: e.target.value,
                        })
                      }
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label>motif</label>
                    <select
                      className="form-control"
                      value={absence.motif || ""}
                      onChange={(e) =>
                        setAbsence({ ...absence, motif: e.target.value })
                      }
                    >
                      <option value="">Pas de motif</option>
                      <option value="Retenu(e) en entreprise">
                        Retenu(e) en entreprise
                      </option>
                      <option value="Arrêt de travail">Arrêt de travail</option>
                      <option value="Motif personnel">Motif personnel</option>
                      <option value="Congé payé">Congé payé</option>
                      <option value="Examen">Examen</option>
                      <option value="Cours non obligatoire">
                        Cours non obligatoire
                      </option>
                      <option value="COVID">COVID</option>
                      <option value="Accident du travail">
                        Accident du travail
                      </option>
                      <option value="Problème de connexion">
                        Problème de connexion
                      </option>
                    </select>
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Commentaire"
                      value={absence.comment || ""}
                      onChange={(e) =>
                        setAbsence({ ...absence, comment: e.target.value })
                      }
                    />
                  </div>
                  <div className="form-row align-items-center">
                    <div className="form-group col-md-6">
                      <div className="custom-control custom-switch">
                        <input
                          className="custom-control-input"
                          type="checkbox"
                          id="justifyModifCheckbox"
                          checked={absence.justify || false}
                          disabled={user.role > 3}
                          onChange={(e) =>
                            setAbsence({
                              ...absence,
                              justify: !absence.justify,
                            })
                          }
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="justifyModifCheckbox"
                        >
                          Justificatif
                        </label>
                      </div>
                    </div>
                    <div className="form-group col-md-6">
                      <div className="custom-control custom-switch">
                        <input
                          className="custom-control-input"
                          type="checkbox"
                          id="prevModifCheckbox"
                          checked={absence.prev || false}
                          disabled={user.role > 3}
                          onChange={(e) =>
                            setAbsence({
                              ...absence,
                              prev: !absence.prev,
                            })
                          }
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="prevModifCheckbox"
                        >
                          Prévenue
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-dismiss="modal"
                    >
                      Fermer
                    </button>
                    <button
                      type="submit"
                      className="btn btn-info"
                      disabled={!isValide()}
                    >
                      Mettre à jour
                    </button>
                  </div>
                </div>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ModifAbsence;
