import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import AppContext from "../../../Context/AppContext";
import GetBddProvider from "../../../Providers/GetBddProvider";
import MsgAlert from "../../common/MsgAlert";
import Spinner from "../../common/Spinner";
import AbsPromoEdusign from "./AbsPromoEdusign";
import { autoCloseMsg } from "../../tools/messagesUtils";
import { triByNAME } from "../../tools/sortUtils";

const AbsEdusign = ({
  site,
  start,
  end,
  load,
  setLoad,
  absences,
  setMaj,
  clear,
  setClear,
}) => {
  const { urlEdusign, galiaUser } = useContext(AppContext);

  const [courses, setCourses] = useState(null);
  const [seances, setSeances] = useState(null);
  const [lockedCourses, setLockedCourses] = useState([]);
  const [unlockedCourses, setUnlockedCourses] = useState([]);
  const [connector, setConnector] = useState("");
  const [msg, setMsg] = useState(null);
  const token = site.TokenEdusign;

  useEffect(() => {
    getConnector();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (load) {
      getCourses();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [load]);

  useEffect(() => {
    if (clear) {
      setCourses(null);
      setClear(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clear]);

  const getConnector = async () => {
    setConnector(
      await GetBddProvider(urlEdusign.SCHOOL, token).then((res) => {
        if (typeof res === "object" && res.status === "success") {
          return res.result.CONNECTOR;
        }
        return "";
      })
    );
  };

  const getCourses = async () => {
    let uri = urlEdusign.COURSES + "?start=" + start + "&end=" + end;
    let tmpCourses = [];
    let tmpLockedCourses = [];
    let tmpUnlockedCourses = [];
    await GetBddProvider(uri, token).then((res) => {
      if (typeof res === "object" && res.status === "success") {
        tmpCourses = res.result;
        tmpLockedCourses = res.result.sort(triByNAME).filter((r) => r.LOCKED);
        tmpUnlockedCourses = res.result.filter((r) => !r.LOCKED);
      } else {
      }
    });
    if (site.idGaliaSociete) {
      let uriGalia = `https://groupe-alternance.sc-form.net/GaliaEDC/API/PresenceSta/GetSeance?IDSociete=${site.idGaliaSociete}&Debut=${start}&Fin=${end}`;
      await GetBddProvider(uriGalia, undefined, galiaUser).then((res) => {
        if (typeof res === "object") {
          setSeances(res);
        } else {
        }
      });
    }
    setCourses(tmpCourses);
    setLockedCourses(tmpLockedCourses);
    setUnlockedCourses(tmpUnlockedCourses);
    setLoad(false);
  };

  if (load) {
    return (
      <div>
        <Spinner className="m-0" />
        <hr className="m-4" />
      </div>
    );
  }

  return courses === null ? null : (
    <div className="card border-info bg-ligth mx-4">
      <div className="card-header d-flex justify-content-between p-2">
        <h5 className="text-left m-0">
          Edusign : {lockedCourses.length} cours cloturés /{" "}
          {unlockedCourses.length} cours non cloturés
        </h5>
        <button
          className="btn btn-sm rounded-pill btn-outline-danger"
          onClick={() => {
            setCourses(null);
          }}
        >
          <FontAwesomeIcon icon="times" /> Fermer
        </button>
      </div>
      <div className="card-body">
        {msg !== null ? (
          <MsgAlert msg={msg.txt} type={msg.type} close={() => autoCloseMsg(setMsg)} />
        ) : null}
        {courses.length === 0 ? (
          <MsgAlert msg="Pas de cours dans Edusign !" type="warning" />
        ) : lockedCourses.length === 0 ? (
          <MsgAlert
            msg="Les cours ne sont pas cloturés dans Edusign !"
            type="info"
          />
        ) : (
          lockedCourses.map((c) => {
            let seance = undefined;
            if (c.API_ID) {
              let tmp = seances.filter((s) => s.ID === c.API_ID);
              if (tmp.length > 0) {
                seance = tmp[0];
              }
            }
            return (
              <AbsPromoEdusign
                cours={c}
                token={token}
                seance={seance}
                connector={connector}
                absencesNocx={absences}
                setMaj={setMaj}
                key={c.ID}
              />
            );
          })
        )}
      </div>
    </div>
  );
};

export default AbsEdusign;
