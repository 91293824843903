import React from "react";
import heuresFormated from "../../tools/heuresFormated";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import dateFormated from "../../tools/dateFormated";

import ReactHTMLTableToExcel from "react-html-table-to-excel";

const ListSuivis = ({ suivis, delSuivi, debut, setSuiviSelected }) => {
  let totalH = 0;
  return (
    <div>
      <h4 className="m-4">Liste des suivis</h4>
      {suivis.length < 1 ? (
        <h4 className="text-danger m-4">
          <strong>Pas de suivi sur la période</strong>
        </h4>
      ) : (
        <div>
          <table
            className="table table-sm table-bordered table-responsive-md"
            id="TableSuivis"
          >
            <thead>
              <tr className="bg-info text-white">
                <th>Action</th>
                <th>Date</th>
                <th>Eleve</th>
                <th>Evaluateur</th>
                <th>Validé</th>
                <th>Durée</th>
              </tr>
            </thead>
            <tbody className="text-left">
              {suivis.map((s, i) => {
                let hString = null;
                let h = parseFloat(heuresFormated(s.time));
                hString =
                  String(h).split(".")[1] !== undefined
                    ? String(h).split(".")[0] + "," + String(h).split(".")[1]
                    : String(h).split(".")[0];
                totalH += h;
                return (
                  <tr key={i}>
                    <td>
                      <FontAwesomeIcon
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setSuiviSelected(JSON.parse(JSON.stringify(s)));
                        }}
                        data-toggle="modal"
                        data-target="#ModalViewSuiviCompetences"
                        className="text-info"
                        icon="clipboard-list"
                        title="Visualiser"
                      />{" "}
                      &nbsp;{" "}
                      <FontAwesomeIcon
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          if (window.confirm("Supprimer le suivi ?")) {
                            delSuivi(s["@id"]);
                          }
                        }}
                        className="text-danger"
                        icon="trash-alt"
                        title="Supprimer"
                      />
                    </td>
                    <td>{dateFormated(s.createdAt)}</td>
                    <td>
                      {s.eleve.user.name} {s.eleve.user.firstName}
                    </td>
                    <td>
                      {s.evaluateur.name} {s.evaluateur.firstName}
                    </td>
                    <td className="text-center">
                      {s.validate ? (
                        <span className="badge badge-pill badge-success m-1 text-white">
                          Oui
                        </span>
                      ) : (
                        <span className="badge badge-pill badge-danger m-1 text-white">
                          Non
                        </span>
                      )}
                    </td>
                    <td className="text-center">{hString}</td>
                  </tr>
                );
              })}
              <tr className="text-info">
                <td colSpan="5" className="text-right">
                  <h4>Total :</h4>
                </td>
                <td className="text-center">
                  <h4>{totalH}</h4>
                </td>
              </tr>
            </tbody>
          </table>
          <ReactHTMLTableToExcel
            className="btn btn-outline-primary rounded-pill"
            table="TableSuivis"
            filename={
              "relevé-suivis-" +
              (new Date(debut).getMonth() + 1) +
              "-" +
              new Date(debut).getFullYear()
            }
            sheet="Export"
            buttonText="Export Excel"
          />
        </div>
      )}
    </div>
  );
};

export default ListSuivis;
