import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useContext } from "react";
import { NavLink } from "react-router-dom";
import AppContext from "../../../Context/AppContext";
import GetBddProvider from "../../../Providers/GetBddProvider";
import SmallSpinner from "../../common/SmallSpinner";

function ListTuteursEntreprise({ tuteur }) {
  const { url } = useContext(AppContext);

  const [eleves, setEleves] = useState(null);

  useEffect(() => {
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const load = () => {
    let uri =
      url + "api/eleves?user.actif=true&entreprises.user=" + tuteur.user["@id"];
    GetBddProvider(uri).then((res) => {
      if (typeof res === "object") {
        setEleves(res["hydra:member"]);
      }
    });
  };

  return eleves ? (
    eleves.map((eleve) => (
      <NavLink
        to={{
          pathname: "/eleve/",
          state: { id: eleve.id },
        }}
        className="col-md-4 p-5 m-4 btn btn-outline-info"
        key={eleve["@id"]}
      >
        <h3>
          {eleve.user.name} {eleve.user.firstName}
        </h3>
        <small>
          ({tuteur.user.name} {tuteur.user.firstName})
        </small>
      </NavLink>
    ))
  ) : (
    <div className="col-md-4 p-5 m-4 btn btn-outline-info">
      <SmallSpinner />
    </div>
  );
}

export default ListTuteursEntreprise;
