import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useState } from "react";
import AppContext from "../../../Context/AppContext";
import UpdateBddProvider from "../../../Providers/UpdateBddProvider";
import MsgAlert from "../../common/MsgAlert";
import SmallSpinner from "../../common/SmallSpinner";
import { autoCloseMsg } from "../../tools/messagesUtils";

const PromoItem = ({
  group,
  promos,
  maj,
  groupSelected,
  setGroupSelected,
  setPromo,
}) => {
  const { url } = useContext(AppContext);

  const assign = promos.filter((p) => p.IdEdusign === group.ID);

  const [save, setSave] = useState(true);
  const [promoSelected, setPromoSelected] = useState(null);
  const [msg, setMsg] = useState(null);

  const saveSync = () => {
    setSave(false);
    let uri = url + promoSelected.slice(1);
    let data = { IdEdusign: group.ID };
    update(uri, data);
  };

  const supSync = () => {
    if (groupSelected && groupSelected.ID === group.ID) {
      setPromo(null);
      setGroupSelected(null);
    }
    setSave(false);
    let uri = url + assign[0]["@id"].slice(1);
    let data = { IdEdusign: null };
    update(uri, data);
  };

  const update = (uri, data) => {
    UpdateBddProvider(uri, data).then((res) => {
      if (typeof res === "object" && res.id) {
        setMsg({
          txt: "les données ont bien été modifiées",
          type: "success",
        });
        autoCloseMsg(setMsg, 2000);
        setSave(true);
        maj(true);
      } else {
        setSave(true);
        setMsg({ txt: "Erreur d'enregistrement", type: "danger" });
        autoCloseMsg(setMsg, 2000);
      }
    });
  };

  return (
    <li
      className={`list-group-item  list-group-item-${
        groupSelected && groupSelected.ID === group.ID ? "info" : "light"
      }`}
    >
      {msg !== null ? (
        <MsgAlert msg={msg.txt} type={msg.type} close={() => autoCloseMsg(setMsg)} />
      ) : null}
      <div className="d-flex justify-content-between">
        <div>
          <h6>{group.NAME}</h6>
          <div className="form-inline">
            <FontAwesomeIcon
              icon="sync-alt"
              className={`mr-2 ${
                assign.length !== 0 ? "text-success" : "text-warning"
              }`}
            />
            {assign.length === 0 ? (
              <>
                <select
                  className="form-control"
                  value={promoSelected || ""}
                  onChange={(e) => setPromoSelected(e.target.value)}
                >
                  <option value="" disabled>
                    Sélectionnez une promo
                  </option>
                  {promos
                    .filter((p) => !p.IdEdusign)
                    .map((promo) => (
                      <option
                        value={promo["@id"]}
                        key={`${group.ID}${promo["@id"]}`}
                      >
                        {promo.name}
                      </option>
                    ))}
                </select>
                <button
                  className="btn btn-outline-success btn-sm ml-2"
                  title="enregistrer"
                  onClick={() => {
                    saveSync();
                  }}
                  disabled={promoSelected === null}
                >
                  {save ? <FontAwesomeIcon icon="check" /> : <SmallSpinner />}
                </button>
              </>
            ) : (
              <>
                {assign[0].name}{" "}
                <button
                  className="btn btn-outline-danger btn-sm ml-2"
                  title="Désynchroniser"
                  onClick={() => supSync()}
                >
                  {save ? (
                    <FontAwesomeIcon icon="times" role="button" />
                  ) : (
                    <SmallSpinner />
                  )}
                </button>
              </>
            )}
          </div>
        </div>
        {assign.length === 0 ? null : (
          <div
            className={`btn btn-sm btn-${
              groupSelected && groupSelected.ID === group.ID ? "" : "outline-"
            }primary align-self-center`}
            onClick={() => {
              if (groupSelected && groupSelected.ID === group.ID) {
                setGroupSelected(null);
                setPromo(null);
              } else {
                setGroupSelected(group);
                setPromo(assign[0]);
              }
            }}
          >
            {groupSelected && groupSelected.ID === group.ID
              ? "Désélectionner"
              : "Sélectionner"}
          </div>
        )}
      </div>
    </li>
  );
};

export default PromoItem;
