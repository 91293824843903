import React from "react";
import { useContext } from "react";
import { useState } from "react";
import MsgAlert from "../../common/MsgAlert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ListMatieresProg from "./ListMatieresProg";
import ProgContext from "../../../Context/ProgContext";
import { autoCloseMsg } from "../../tools/messagesUtils";

const ListProgressions = ({
  promos,
  siteId,
  formateur,
  promoSelected,
  matiereSelected,
}) => {
  const { setPromoSelected, setMatiereSelected } = useContext(ProgContext);

  const [msg, setMsg] = useState(null);

  const idAccordion = "accordionSite" + siteId;

  return (
    <div className="accordion" id={idAccordion}>
      {msg !== null ? (
        <MsgAlert msg={msg.txt} type={msg.type} close={() => autoCloseMsg(setMsg)} />
      ) : null}
      {promos.map((promo) => {
        return (
          <div className="card shadow rounded mx-3" key={promo["@id"]}>
            <div
              className="card-header bg-info p-0"
              id={"headingPromo" + promo.id}
            >
              <h2 className="mb-0">
                <button
                  className="btn btn-block font-weight-bold text-white pb-0"
                  type="button"
                  onClick={(e) => {
                    setPromoSelected(
                      promo["@id"] === promoSelected ? null : promo["@id"]
                    );
                    setMatiereSelected(null);
                  }}
                >
                  <h5 className="m-0">{promo.name}</h5>
                  <FontAwesomeIcon icon="chevron-down" />
                </button>
              </h2>
            </div>

            {(promos.length < 2 || promo["@id"] === promoSelected) && (
              <div
                id={"collapsePromo" + promo.id}
                aria-labelledby={"headingPromo" + promo.id}
                data-parent={"#" + idAccordion}
              >
                <ListMatieresProg
                  promo={promo}
                  formateur={formateur}
                  matiereSelected={matiereSelected}
                />
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default React.memo(ListProgressions);
