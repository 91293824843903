import React from "react";
import { useContext } from "react";
import AppContext from "../../../Context/AppContext";
import { useState } from "react";
import MsgAlert from "../../common/MsgAlert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Spinner from "../../common/Spinner";
import GetBddProvider from "../../../Providers/GetBddProvider";
import ListMatiereProgDetail from "./ListMatiereProgDetail";
import { useEffect } from "react";
import ProgContext from "../../../Context/ProgContext";
import { autoCloseMsg } from "../../tools/messagesUtils";
import { triByName } from "../../tools/sortUtils";

const ListMatieresProg = ({ promo, formateur, matiereSelected }) => {
  const { url } = useContext(AppContext);
  const { setMatiereSelected, matieres } = useContext(ProgContext);

  const [progItems, setProgItems] = useState(null);
  const [progressions, setProgressions] = useState(null);
  const [msg, setMsg] = useState(null);

  const idAccordion = "accordionPro" + promo.id;

  useEffect(() => {
    if (matiereSelected !== null) {
      load(matiereSelected);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matiereSelected]);

  const laodProgressions = (items) => {
    let uri = url + "api/progressions?promos=" + promo["@id"] + "&";
    items.forEach((el, i, els) => {
      uri += els.length > 1 ? "progressionItem[]=" : "progressionItem=";
      uri += el["@id"];
      uri += i + 1 < els.length ? "&" : "";
    });
    GetBddProvider(uri).then((res) => {
      if (typeof res === "object") {
        setProgressions(res["hydra:member"]);
      } else {
        setMsg({
          txt: "Erreur de chargement des progressions",
          type: "danger",
        });
        autoCloseMsg(setMsg, 5000);
      }
    });
  };

  const load = (matiereIri) => {
    let uri =
      url +
      "api/progression_items?matiere=" +
      matiereIri +
      "&formation=" +
      promo.formation["@id"];
    GetBddProvider(uri).then((res) => {
      if (typeof res === "object") {
        setProgItems(res["hydra:member"]);
        uri = url + "api/progressions?promos=" + promo["@id"] + "&";
        laodProgressions(res["hydra:member"]);
      } else {
        setMsg({
          txt: "Erreur de chargement des items de progression",
          type: "danger",
        });
        autoCloseMsg(setMsg, 5000);
      }
    });
  };

  return matieres !== null ? (
    <div className="accordion mt-1" id={idAccordion}>
      {msg !== null ? (
        <MsgAlert msg={msg.txt} type={msg.type} close={() => autoCloseMsg(setMsg)} />
      ) : null}

      {matieres.sort(triByName).map((matiere) =>
        matiere.name.slice(0, 2) !== "z-" ? (
          <div className="card shadow rounded" key={matiere["@id"]}>
            <div
              className="card-header p-0"
              id={"headingMatiere" + matiere.id + "Promo" + promo.id}
            >
              <h2 className="mb-0">
                <button
                  className="btn btn-block font-weight-bold pb-0"
                  type="button"
                  onClick={(e) => {
                    setMatiereSelected(
                      matiere["@id"] === matiereSelected ? null : matiere["@id"]
                    );
                  }}
                >
                  <h5 className="m-0">{matiere.name}</h5>
                  <FontAwesomeIcon icon="chevron-down" />
                </button>
              </h2>
            </div>
            {matiere["@id"] === matiereSelected && (
              <div
                id={"collapseMatiere" + matiere.id + "Promo" + promo.id}
                aria-labelledby={
                  "headingMatiere" + matiere.id + "Promo" + promo.id
                }
                data-parent={"#" + idAccordion}
              >
                <div className="card-body">
                  {progItems !== null && progressions !== null ? (
                    progItems.map((progItem, i) => {
                      let temp = [];
                      progressions.forEach((progression) => {
                        progression.progressionItem.forEach((item) => {
                          if (item["@id"] === progItem["@id"]) {
                            temp.push(progression);
                          }
                        });
                      });
                      return (
                        <ListMatiereProgDetail
                          progItem={progItem}
                          progressions={temp}
                          matiere={matiere}
                          formateur={formateur}
                          promo={promo}
                          key={i}
                        />
                      );
                    })
                  ) : (
                    <Spinner />
                  )}
                </div>
              </div>
            )}
          </div>
        ) : null
      )}
    </div>
  ) : (
    <Spinner />
  );
};

export default ListMatieresProg;
