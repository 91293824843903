import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useState } from 'react';
import AppContext from '../../../Context/AppContext';
import PostBddProvider from '../../../Providers/PostBddProvider';
import UpdateBddProvider from '../../../Providers/UpdateBddProvider';
import MsgAlert from '../../common/MsgAlert';
import Spinner from '../../common/Spinner';
import { autoCloseMsg } from '../../tools/messagesUtils';

const EditBlocAfest = ({ data, setMaj }) => {
  const { url } = useContext(AppContext);
  const [bloc, setBloc] = useState({});
  const [msg, setMsg] = useState(null);
  const [save, setSave] = useState(true);

  useEffect(() => {
    if (data !== null && bloc !== data) {
      setBloc(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setSave(false);
    let uri = url + "api/bloc_afests";
    if (bloc.id) {
      uri += "/" + bloc.id;
      UpdateBddProvider(uri, bloc).then((res) => {
        setSave(true);
        if (typeof res === "object" && res.id) {
          setMsg({
            txt: "Le bloc été modifée",
            type: "success",
          });
          setBloc({});
          setMaj(true);
          autoCloseMsg(setMsg, 5000);
        } else {
          setMsg({
            txt: "erreur d'enregistrement du bloc",
            type: "danger",
          });
        }
      });
    } else {
      PostBddProvider(uri, bloc).then((res) => {
        setSave(true);
        if (typeof res === "object" && res.id) {
          setMsg({
            txt: "Le bloc a bien été créée",
            type: "success",
          });
          setBloc({});
          setMaj(true);
          autoCloseMsg(setMsg, 5000);
        } else {
          setMsg({
            txt: "erreur d'enregistrement du bloc",
            type: "danger",
          });
        }
      });
    }
  };

  return (
    <div
      className="modal fade"
      id="ModalBloc"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <form>
            <div className="modal-header bg-info text-white">
              <h5 className="modal-title">
                <FontAwesomeIcon icon="edit" />
                &nbsp; {bloc.id ? "Modifer" : "Ajouter"} un bloc
              </h5>
              <button
                type="button"
                className="close text-white"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            {!save ? (
              <div className="pb-5 modal-body text-info">
                <Spinner />
              </div>
            ) : (
              <div className="modal-body">
                {msg !== null ? (
                  <div className="mx-4">
                    <MsgAlert msg={msg.txt} type={msg.type} close={()=>autoCloseMsg(setMsg)} />
                  </div>
                ) : null}
                <div className="form-group">
                  <label htmlFor="name">Nom du bloc</label>
                  <input
                    className="form-control"
                    type="text"
                    name="name"
                    value={bloc.name || ""}
                    placeholder="Nom"
                    onChange={(e) =>
                      setBloc({
                        ...bloc,
                        name: e.target.value,
                      })
                    }
                    required
                  />
                </div>
              </div>
            )}
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Fermer
              </button>
              <button
                type="submit"
                className="btn btn-info"
                onClick={handleSubmit}
                disabled={!bloc.name}
              >
                Enregistrer
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
 
export default EditBlocAfest;