import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { NavLink } from "react-router-dom";
import AppContext from "../../../Context/AppContext";
import GetBddProvider from "../../../Providers/GetBddProvider";
import UpdateBddProvider from "../../../Providers/UpdateBddProvider";
import MsgAlert from "../../common/MsgAlert";
import Spinner from "../../common/Spinner";
import { autoCloseMsg } from "../../tools/messagesUtils";
import { triByUserName } from "../../tools/sortUtils";

const ListElevesPromo = ({ datas, GenPwd, setselected }) => {
  const { url } = useContext(AppContext);

  const [eleves, setEleves] = useState(null);
  const [actif, setActif] = useState(true);
  const [promo, setPromo] = useState(datas);
  const [tuteurs, setTuteurs] = useState(null);
  const [msg, setMsg] = useState(null);

  useEffect(() => {
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const load = () => {
    let uri = url + "api/eleves?promos=" + promo["@id"];
    GetBddProvider(uri).then((res) => {
      if (typeof res === "object") {
        setEleves(res["hydra:member"]);
      } else {
        setMsg({ txt: `Erreur de chargement des élèves !`, type: "danger" });
        autoCloseMsg(setMsg, 5000);
      }
    });
  };
  const loadPromo = () => {
    let uri = url + promo["@id"].slice(1);
    GetBddProvider(uri).then((res) => {
      if (typeof res === "object") {
        setPromo(res);
      } else {
        setMsg({ txt: `Erreur de chargement de la promo !`, type: "danger" });
        autoCloseMsg(setMsg, 5000);
      }
    });
  };

  const delEleve = (uri) => {
    let data = { actif: false };
    UpdateBddProvider(url + uri.slice(1), data).then((res) => {
      if (typeof res === "object" && res.id) {
        load();
        loadPromo();
        setMsg({ txt: `l'élève a été désactivé !`, type: "success" });
        autoCloseMsg(setMsg, 5000);
      } else {
        setMsg({ txt: `Erreur d'exécution !`, type: "danger" });
        autoCloseMsg(setMsg, 5000);
      }
    });
  };

  const loadTuteurs = () => {
    let uri = url + "api/entreprises?eleves.promos=" + promo["@id"];
    GetBddProvider(uri).then((res) => {
      if (typeof res === "object") {
        setTuteurs(res["hydra:member"]);
      } else {
      }
    });
  };

  return (
    <div
      className="card align-self-start shadow border-info col-lg-3 p-0 m-3"
      style={{ minWidth: "20rem" }}
    >
      {eleves === null ? (
        <div className="text-center">
          <Spinner />
        </div>
      ) : (
        <React.Fragment>
          <div className="card-header" id={"headingEl" + promo.id}>
            <button
              className="btn btn-block pb-0"
              type="button"
              data-toggle="collapse"
              data-promo={promo["@id"]}
              data-target={"#collapseEl" + promo.id}
              aria-expanded={false}
              aria-controls={"collapseEl" + promo.id}
              onClick={(e) => {
                if (
                  e.currentTarget.getAttribute("aria-expanded") === "true" &&
                  tuteurs === null
                ) {
                  loadTuteurs();
                }
              }}
            >
              <span className="h4">
                {promo.name}{" "}
                {!promo.actif ? (
                  <em className="text-danger">(inactive)</em>
                ) : null}
              </span>
              <br />
              {promo.semestres.length === 0 ? (
                <>
                  <em className="text-danger">
                    Les semestres n'ont pas été initialisés
                  </em>{" "}
                  <br />
                </>
              ) : null}
              <em className="font-weight-bold">
                {eleves.length === 0 ? (
                  <span className="text-danger">Pas d'élèves</span>
                ) : (
                  <>
                    <span className="text-primary">{eleves.length} élèves</span>{" "}
                    -{" "}
                    <span className="text-warning">
                      {eleves.filter((e) => e.user.actif).length} actifs
                    </span>{" "}
                    -{" "}
                    <span className="text-success">
                      {eleves.filter((e) => e.user.actif && e.entreprises.length > 0).length} en
                      entreprise
                    </span>
                  </>
                )}
              </em>
              <br />
              <FontAwesomeIcon icon="chevron-down" />
            </button>
          </div>
          <div
            className="card-body text-info collapse"
            id={"collapseEl" + promo.id}
            aria-labelledby={"headingEl" + promo.id}
            data-parent={"#accordionListEleves"}
          >
            {msg && (
              <MsgAlert msg={msg.txt} type={msg.type} close={() => autoCloseMsg(setMsg)} />
            )}

            {tuteurs === null ? (
              <Spinner />
            ) : (
              <>
                <div
                  className={`btn btn-sm ${
                    actif ? "btn-outline-success" : "btn-outline-danger"
                  } rounded-pill`}
                  onClick={() => setActif(!actif)}
                >
                  {actif ? `Afficher les inactifs` : `Masquer les inactifs`}
                </div>
                <ul className="list-group list-group-flush">
                  {eleves
                    .sort(triByUserName)
                    .filter((el) => (actif ? el.user.actif === actif : el))
                    .map((el, i, tab) => {
                      let tuteur =
                        el.entreprises.length > 0
                          ? tuteurs.filter(
                              (t) => t["@id"] === el.entreprises[0]["@id"]
                            )[0]
                          : null;
                      return (
                        <li className="list-group-item text-left px-0" key={i}>
                          <div className=" d-flex justify-content-between">
                            <div className="">
                              {el.user.actif ? (
                                el.entreprises.length > 0 ? (
                                  <span className="badge badge-pill badge-success m-1 text-success">
                                    I
                                  </span>
                                ) : (
                                  <span className="badge badge-pill badge-warning m-1 text-warning">
                                    I
                                  </span>
                                )
                              ) : (
                                <span className="badge badge-pill badge-secondary m-1 text-secondary">
                                  I
                                </span>
                              )}
                              <NavLink
                                className="text-info"
                                to={{
                                  pathname: "/eleve",
                                  state: {
                                    id: el.id,
                                    list: tab,
                                    promo: promo["@id"],
                                  },
                                }}
                              >
                                {" "}
                                {el.user.name} {el.user.firstName}
                              </NavLink>
                              <div className="text-primary">
                                <em>{el.user.pseudo}</em>
                              </div>
                              <span
                                className="badge badge-pill badge-secondary"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  if (
                                    window.confirm(
                                      "Envoyer un mail de connexion ?"
                                    )
                                  ) {
                                    GenPwd(JSON.parse(JSON.stringify(el.user)));
                                  }
                                }}
                              >
                                <FontAwesomeIcon icon="paper-plane" /> Mail de
                                connexion
                              </span>
                            </div>
                            <div className="text-right">
                              <span className="text-nowrap">
                                <FontAwesomeIcon
                                  onClick={(e) =>
                                    setselected(JSON.parse(JSON.stringify(el)))
                                  }
                                  style={{ cursor: "pointer" }}
                                  className="h6 mb-0"
                                  title="Modifier"
                                  data-toggle="modal"
                                  data-target="#Modal"
                                  icon="edit"
                                />
                                <FontAwesomeIcon
                                  onClick={(e) => {
                                    if (window.confirm(`Désactiver l'élève`)) {
                                      delEleve(el.user["@id"]);
                                    }
                                  }}
                                  style={{ cursor: "pointer" }}
                                  className="h6 text-danger mb-0 ml-1 "
                                  title="Déssactiver"
                                  icon="trash-alt"
                                />
                              </span>
                              <br />
                              {el.user.roles.includes("ROLE_PEDAGO") ? (
                                <span
                                  className="badge badge-pill badge-success"
                                  key={i}
                                >
                                  Pédago
                                </span>
                              ) : null}
                            </div>
                          </div>
                          {tuteur !== null ? (
                            <div className="text-secondary">
                              <em>
                                {tuteur.raisonSociale} <br /> {tuteur.user.name}{" "}
                                {tuteur.user.firstName} <br />{" "}
                                {tuteur.user.email}{" "}
                              </em>
                            </div>
                          ) : null}
                        </li>
                      );
                    })}
                </ul>
              </>
            )}
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default ListElevesPromo;
