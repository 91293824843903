import React, { useContext, useEffect, useState } from "react";
import AppContext from "../../../Context/AppContext";
import GetBddProvider from "../../../Providers/GetBddProvider";
import Spinner from "../../common/Spinner";
import ListPromosDetail from "./ListPromosDetail";
import { triByName } from "../../tools/sortUtils";

const ListPromos = ({ sites, setselected, siteSelected, statusSelected, setStatusSelected}) => {
  const __STATUS = [true, false];

  const [promos, setPromos] = useState(null);
  const [loading, setLoading] = useState(false);

  const { url } = useContext(AppContext);

  useEffect(() => {
    setPromos(null);
    setLoading(false);
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteSelected]);

  const load = () => {
    GetBddProvider(url + "api/promos?site=" + siteSelected).then((res) => {
      if (typeof res === "object") {
        setPromos(res["hydra:member"]);
        setLoading(true);
      }
    });
  };

  const content =
    promos !== null ? (
      <>
        <ul className="nav nav-tabs">
          {__STATUS.map((status, i) => (
            <li
              className="nav-item "
              style={{ cursor: "pointer" }}
              onClick={() =>
                statusSelected !== status && setStatusSelected(status)
              }
              key= {i}
            >
              <span
                className={`nav-link ${statusSelected === status && "active"}`}
              >
                <small>
                  <span
                    className={`badge badge-pill badge-${
                      status ? "success" : "secondary"
                    } m-1 text-${status ? "success" : "secondary"}`}
                  >
                    I
                  </span>
                </small>

                <span className="align-middle">
                  {status ? "Actives" : "Inactives"}
                </span>
              </span>
            </li>
          ))}
        </ul>
        <div className="tab-content" id="myTabContent">
          {__STATUS.map((status, i) => (
            <ListPromosDetail
              site={sites.filter((site) => site["@id"] === siteSelected)[0]}
              promos={promos.filter((p) => p.actif === status).sort(triByName)}
              selected = {status === statusSelected}
              status={status}
              setselected={setselected}
              key={i}
            />
          ))}
        </div>
      </>
    ) : (
      <div className="alert alert-danger">
        <strong>Pas de promos !</strong>
      </div>
    );

  return loading ? content : <Spinner />;
};

export default ListPromos;
