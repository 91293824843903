import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext } from "react";
import AppContext from "../../../Context/AppContext";
import NotesContext from "../../../Context/NotesContext";
import dateFormated from "../../tools/dateFormated";

export const ListGestNotesDetail = ({
  semestres,
  notes,
  el,
  delNote,
  controle = null,
  mat = null,
}) => {
  const { user } = useContext(AppContext);
  const {
    formateur,
    semestreSelected,
    setControleSelected,
    setEleveSelected,
    setNoteSelected,
  } = useContext(NotesContext);

  if (
    controle &&
    notes.length === 0 &&
    (user.role <= 3 ||
      (formateur.matieres.filter((m) => m["@id"] === mat).length > 0 &&
        semestres.filter((s) => s["@id"] === semestreSelected["@id"])[0].actif))
  ) {
    return (
      <FontAwesomeIcon
        className="ml-2 text-primary"
        style={{ cursor: "pointer" }}
        data-toggle="modal"
        data-target="#NoteModal"
        onClick={() => {
          setControleSelected((c) => controle);
          setEleveSelected((e) => JSON.parse(JSON.stringify(el)));
        }}
        icon="plus-circle"
      />
    );
  }

  return notes.map((n, i) =>
    user.role <= 3 ||
    (n.formateur["@id"] === formateur["@id"] &&
      semestres.filter((s) => s["@id"] === semestreSelected["@id"])[0]
        .actif) ? (
      <React.Fragment key={i}>
        <span
          className={
            n.exam && n.note !== undefined && n.note !== null
              ? "badge ml-2 badge-danger"
              : n.note !== undefined && n.note !== null
              ? "badge ml-2 badge-info"
              : "badge ml-2 badge-secondary"
          }
          style={{ cursor: "pointer" }}
          data-toggle="modal"
          data-target="#ModalModifNote"
          onClick={() => {
            setEleveSelected(JSON.parse(JSON.stringify(el)));
            setNoteSelected(JSON.parse(JSON.stringify(n)));
          }}
          data-tooltip="tooltip"
          data-html="true"
          data-placement="top"
          title={`<u><em>${
            n.formateur.user.firstName.slice(0, 1) +
            n.formateur.user.name.slice(0, 1)
          } - ${dateFormated(n.createdAt)}</em></u><br>${n.comment}`}
        >
          <u>{n.note !== undefined && n.note !== null ? n.note : "NE"}</u>
          <sup>
            <em>({n.coef})</em>
          </sup>
        </span>
        <sup>
          <FontAwesomeIcon
            style={{ cursor: "pointer" }}
            title="Supprimer"
            className="text-danger"
            onClick={() => {
              if (window.confirm("Supprimer la note ?")) {
                delNote(n["@id"]);
              }
            }}
            icon="times-circle"
          />
        </sup>
      </React.Fragment>
    ) : (
      <span
        className={
          n.exam
            ? "badge ml-2 badge-danger"
            : n.note !== undefined && n.note !== null
            ? "badge ml-2 badge-light"
            : "badge ml-2 badge-secondary"
        }
        data-tooltip="tooltip"
        data-html="true"
        data-placement="top"
        title={`<u><em>${
          n.formateur.user.firstName.slice(0, 1) +
          n.formateur.user.name.slice(0, 1)
        } - ${dateFormated(n.createdAt)}</em></u><br> ${n.comment}`}
        key={i}
      >
        {n.note !== undefined && n.note !== null ? n.note : "NE"}{" "}
        <sup>
          <em>({n.coef})</em>
        </sup>
      </span>
    )
  );
};
