/**
 * Calcul les moyennes générales des élèves
 * @param {array} eleves - Liste des élèves
 * @param {array} moyennes - Liste des moyennes par matières
 * @param {array} coefs - Liste des Coefficiants par matières
 * @returns {array} Liste des moyennes générales
 */
const CalcMoyGenEleves = (eleves, moyennes, coefs) => {
  let moyennesGenTemp = [];
  eleves.forEach((e) => {
    let temp = moyennes.filter((m) => m.eleve === e["@id"]);
    let x = null;
    let y = null;
    temp.forEach((m) => {
      if (m.moyenne !== null) {
        let coef = coefs.filter(
          (c) => c.matiere["@id"] === m.matiere
        )[0].coefficient;
        if (coef !== 0) {
          x += parseFloat(m.moyenne) * parseFloat(coef);
          y += parseFloat(coef);
        }
      }
    });
    moyennesGenTemp = [
      ...moyennesGenTemp,
      {
        eleve: e["@id"],
        moyenne: x !== null ? Math.round((x / y) * 100) / 100 : null,
      },
    ];
  });

  return moyennesGenTemp;
};

export default CalcMoyGenEleves