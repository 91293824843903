import React from "react";
import { useContext } from "react";
import AppContext from "../../Context/AppContext";
import { useState } from "react";
import GetBddProvider from "../../Providers/GetBddProvider";
import Spinner from "../common/Spinner";
import Layout from "../common/Layout";
import { useEffect } from "react";
import ListFormateurs from "../contents/lists/ListFormateurs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import EditFormateur from "../contents/edits/EditFormateur";
import EditFormateurPromos from "../contents/edits/EditFromateurPromos";
import MsgAlert from "../common/MsgAlert";
import usePost from "../../hooks/usePost";
import useGenPwd from "../../hooks/useGenPwd";
import { autoCloseMsg } from "../tools/messagesUtils";
import { triByName, triByUserName } from "../tools/sortUtils";

const Formateurs = () => {
  const { url, user } = useContext(AppContext);
  const [formateurs, setformateurs] = useState(null);
  const [sites, setSites] = useState([]);
  const [siteSelected, setSiteSelected] = useState(null);
  const [msg, setMsg] = useState(null);
  const [actifs, setActifs] = useState(true);
  const [loaded, setloaded] = useState(false);
  const [maj, setmaj] = useState(false);
  const [selected, setselected] = useState({
    user: { actif: true, roles: ["ROLE_FORMATEUR"] },
  });

  const PostBDD = usePost();
  const GenPWD = useGenPwd(url, PostBDD);

  useEffect(() => {
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (siteSelected !== null) {
      setloaded(false);
      loadFormateurs();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteSelected]);

  useEffect(() => {
    if (siteSelected !== null) {
      setmaj(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actifs]);

  useEffect(() => {
    if (maj) {
      setmaj(false);
      setloaded(false);
      loadFormateurs();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maj]);

  const load = () => {
    let uriSites = url + "api/sites";
    if (user.role > 1) {
      setSites(user.sites.sort(triByName));
      setSiteSelected(user.sites[0]["@id"]);
    } else {
      GetBddProvider(uriSites).then((res) => {
        if (typeof res === "object") {
          setSites(res["hydra:member"].sort(triByName));
          setSiteSelected(user.sites[0]["@id"]);
        } else {
          setMsg({ txt: "Erreur de chargement des sites", type: "danger" });
          autoCloseMsg(setMsg, 5000);
        }
      });
    }
  };

  const loadFormateurs = () => {
    let uri = url + "api/formateurs";
    if (actifs) {
      uri += "?user.actif=" + actifs;
    }
    if (siteSelected !== "All") {
      uri += actifs ? "&" : "?";
      uri += "user.sites=" + siteSelected;
    }
    GetBddProvider(uri).then((res) => {
      if (typeof res === "object") {
        setformateurs(res["hydra:member"].sort(triByUserName));
        setloaded(true);
      } else {
        setMsg({ txt: "Erreur de chargement des formateurs", type: "danger" });
        autoCloseMsg(setMsg, 5000);
      }
    });
  };

  const mailConnexion = async (user) => {
    setMsg({
      txt: "Envoie du mail encours ...",
      type: "success",
    });
    let res = await GenPWD(user);
    if (res.status === "ok") {
      setMsg({
        txt: `Un mail de connexion pour ${user.name} ${user.firstName} a été envoyé à l'adresse : ${res.email}`,
        type: "success",
      });
      autoCloseMsg(setMsg, 5000);
    } else if (res.status === 404) {
      setMsg({ txt: res.message, type: "danger" });
      autoCloseMsg(setMsg, 5000);
    } else if (res !== 666) {
      setMsg({ txt: "Erreur serveur !", type: "danger" });
      autoCloseMsg(setMsg, 5000);
    }
  };

  return Layout(
    <div className="mt-4">
      <span className="d-flex flex-wrap justify-content-md-between justify-content-center m-3">
        <h2 className="m-0">Liste des formateurs</h2>
        <div className="form-row align-items-center justify-content-center">
          <div className="form-group">
            <select
              className="form-control"
              value={siteSelected || ""}
              onChange={(e) => setSiteSelected(e.target.value)}
            >
              <option value="" disabled>
                Sites
              </option>
              <option value="All">Tous</option>
              {sites !== null
                ? sites.map((s, i) => (
                    <option value={s["@id"]} key={i}>
                      {s.name}
                    </option>
                  ))
                : null}
            </select>
          </div>
          <div className="form-group ml-4">
            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                checked={actifs || false}
                onChange={(e) => setActifs(!actifs)}
              />
              <label className="form-check-label">
                <strong>Formateurs actifs uniquement</strong>
              </label>
            </div>
          </div>
        </div>
        <button
          type="button"
          className="btn btn-info"
          onClick={(e) =>
            setselected({ user: { actif: true, roles: ["ROLE_FORMATEUR"] } })
          }
          data-toggle="modal"
          data-target="#Modal"
        >
          <FontAwesomeIcon icon="plus-circle" /> &nbsp; Ajouter
        </button>
      </span>
      <EditFormateur maj={setmaj} sites={sites} selected={selected} />
      <EditFormateurPromos maj={setmaj} sites={sites} selected={selected} />
      <hr className="m-4"></hr>
      {msg === null ? null : (
        <MsgAlert msg={msg.txt} type={msg.type} close={() => autoCloseMsg(setMsg)} />
      )}
      {!loaded ? (
        <Spinner />
      ) : (
        <ListFormateurs
          data={formateurs}
          site={
            siteSelected === "All"
              ? { name: "Tous" }
              : sites.filter((s) => s["@id"] === siteSelected)[0]
          }
          setselected={setselected}
          GenPwd={mailConnexion}
        />
      )}
    </div>
  );
};

export default Formateurs;
