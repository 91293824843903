/**
 * Permet de calculer la moyenne des élèves par matières
 * @param {array} matieres - liste des matieres
 * @param {array} eleves - Liste des élèves
 * @param {array} notes - Liste des notes
 * @param {string} semestre - "@id" du semestre
 * @param {boolean} all - prise en charge des notes de participation et de comprotement
 * @param {boolean} exam - ne tien pas compte des coefficients des notes
 * @returns {array} Liste des moyenne par élèves et par matières
 */
const CalcMoyEleves = (
  matieres,
  eleves,
  notes,
  semestre,
  all = true,
  exam = false
) => {
  let moyennesTmp = [];
  let mat = all
    ? matieres
    : matieres.filter((m) => m.name.slice(0, 2) !== "z-");
  mat.forEach((m) => {
    eleves.forEach((e) => {
      let temp = notes.filter(
        (n) =>
          n.matiere === m["@id"] &&
          n.eleve === e["@id"] &&
          n.semestre === semestre
      );
      let x = null;
      let y = null;
      if (exam) {
        temp.forEach((n) => {
          if (n.note !== undefined) {
            x += parseFloat(n.note);
            y ++;
          }
        });
      } else {
        temp.forEach((n) => {
          if (n.note !== undefined) {
            x += parseFloat(n.note) * parseFloat(n.coef);
            y += parseFloat(n.coef);
          }
        });
      }
      let moy = x !== null ? Math.round((x / y) * 100) / 100 : null;
      moyennesTmp = [
        ...moyennesTmp,
        { eleve: e["@id"], matiere: m["@id"], moyenne: moy },
      ];
    });
  });
  return moyennesTmp;
};

export default CalcMoyEleves;
