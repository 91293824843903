import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import Spinner from "../../common/Spinner";
import dateFormated from "../../tools/dateFormated";
import heuresFormated from "../../tools/heuresFormated";

const ViewAbsEleve = ({abs}) => {
  let totalH = 0
  return (
    <div
      className="modal fade"
      id="ModalViewAbs"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header bg-info text-white">
            <h5 className="modal-title">
              <FontAwesomeIcon icon="clipboard-list" /> &nbsp; Consultation des
              absences
            </h5>
            <button
              type="button"
              className="close text-white"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          {abs === null ? (
            <div className="pb-5 modal-body text-info">
              <Spinner />
            </div>
          ) : (
            <div className="p-3 modal-body">
              <div>
                <table className="table table-sm">
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Motif</th>
                      <th>Heures</th>
                    </tr>
                  </thead>
                  <tbody>
                      {abs.map((a, i) => {
                        let h = parseFloat(heuresFormated(a.time));
                        let hstring =
                          String(h).split(".")[1] !== undefined
                            ? String(h).split(".")[0] +
                              "," +
                              String(h).split(".")[1]
                            : String(h).split(".")[0];
                        if (a.motif !== "Cours non obligatoire") {
                          a.rattrapage ? totalH -= h : totalH += h;
                        }
                      return (
                        <tr
                          key={i}
                          className={a.rattrapage ? "text-info" : null}
                        >
                          <td className="text-left">
                            {dateFormated(a.createdAt)} - {a.moment}
                          </td>
                          <td className="text-left">
                            {a.motif === "" || a.motif === undefined
                              ? "Pas de motif"
                              : a.motif}
                            {a.comment === "" ||
                            a.comment === undefined ? null : (
                              <em> ({a.comment})</em>
                            )}
                          </td>
                          <td>
                            {a.motif !== "Cours non obligatoire" ? (
                              a.rattrapage ? (
                                "- " + hstring
                              ) : (
                                hstring
                              )
                            ) : (
                              <em>({hstring})</em>
                            )}
                          </td>
                        </tr>
                      );
                      })}
                      <tr className="text-info">
                        <td colSpan="2" className="text-right">
                          <h4>Total :</h4></td>
                        <td className="text-center">
                          <h4>{totalH}</h4></td>
                      </tr>
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ViewAbsEleve;
