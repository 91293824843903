import React, { useContext, useEffect, useState } from "react";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import AppContext from "../../Context/AppContext";
import GetBddProvider from "../../Providers/GetBddProvider";
import Layout from "../common/Layout";
import MsgAlert from "../common/MsgAlert";
import Spinner from "../common/Spinner";
import heuresFormated from "../tools/heuresFormated";
import { autoCloseMsg } from "../tools/messagesUtils";
import { triByUserName } from "../tools/sortUtils";

const GestPresences = () => {
  const { url, user } = useContext(AppContext);

  const safari =
    navigator.userAgent.indexOf("Safari") >= 1 &&
    navigator.userAgent.indexOf("Chrome") <= 0
      ? true
      : false;
  const [actif, setActif] = useState(true);
  const [debut, setDebut] = useState(null);
  const [fin, setFin] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [msg, setMsg] = useState(null);
  const [eleves, setEleves] = useState(null);
  const [absences, setAbsences] = useState(null);
  const [progressions, setProgressions] = useState(null);
  const [promos, setPromos] = useState(null);
  const [promoSelected, setPromoSelected] = useState(null);
  const [datas, setDatas] = useState(null);

  useEffect(() => {
    init();
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(()=>{
    load()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actif])

  useEffect(() => {
    if (absences !== null && progressions !== null) {
      calculData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [absences, progressions]);

  const init = () => {
    let tmp = new Date();
    setDebut(
      new Date(
        tmp.getFullYear(),
        tmp.getMonth(),
        1,
        -tmp.getTimezoneOffset() / 60
      )
        .toISOString()
        .split("T")[0]
    );
    setFin(
      new Date(
        tmp.getFullYear(),
        tmp.getMonth() + 1,
        0,
        -tmp.getTimezoneOffset() / 60
      )
        .toISOString()
        .split("T")[0]
    );
  };

  const load = () => {
    let uri = url + "api/promos?";
    user.sites.forEach((site, i, sites) => {
      uri += sites.length > 1 ? "site[]=" : "site=";
      uri += site["@id"];
      uri += i + 1 < sites.length ? "&" : "";
    });
    if (actif){
      uri += `&actif=${actif}`
    }
    GetBddProvider(uri).then((res) => {
      setLoaded(true);
      if (typeof res === "object") {
        setPromos(res["hydra:member"]);
      } else {
        setMsg({ txt: "Erreur de Chargement des promos", type: "danger" });
        autoCloseMsg(setMsg, 5000);
      }
    });
  };

  const loadData = () => {
    // Création des uri
    let uriEleves = url + "api/eleves?user.actif=true&promos=" + promoSelected;
    let uriProg =
      url +
      "api/progressions?order[createdAt]=asc&createdAt[after]=" +
      debut +
      "&createdAt[before]=" +
      fin +
      "&promos=" +
      promoSelected;
    let uriAbsences =
      url +
      "api/absences?order[createdAt]=asc&createdAt[after]=" +
      debut +
      "&createdAt[before]=" +
      fin +
      "&promo=" +
      promoSelected;
    // Chargement des élèves
    GetBddProvider(uriEleves).then((res) => {
      if (typeof res === "object") {
        setEleves(res["hydra:member"]);
      } else {
        setMsg({ txt: "Erreur de Chargement des élèves", type: "danger" });
        autoCloseMsg(setMsg, 5000);
      }
    });
    // Chargement des progressions
    GetBddProvider(uriProg).then((res) => {
      if (typeof res === "object") {
        setProgressions(res["hydra:member"]);
      } else {
        setMsg({
          txt: "Erreur de Chargement des progressions",
          type: "danger",
        });
        autoCloseMsg(setMsg, 5000);
      }
    });
    // Chargement des absences et rattrapage
    GetBddProvider(uriAbsences).then((res) => {
      if (typeof res === "object") {
        setAbsences(res["hydra:member"]);
      } else {
        setMsg({ txt: "Erreur de Chargement des absences", type: "danger" });
        autoCloseMsg(setMsg, 5000);
      }
    });
  };

  const calculData = () => {
    let totalProgs = 0;
    let tmpDatas = [];
    progressions.forEach((p) => {
      totalProgs += parseFloat(heuresFormated(p.time));
    });
    eleves.sort(triByUserName).forEach((e) => {
      let tmpAbsJ = 0;
      let tmpAbsI = 0
      let tmpRat = 0;
      absences
        .filter((a) => !a.rattrapage && a.justify && a.eleve["@id"] === e["@id"])
        .forEach((a) => {
          tmpAbsJ += parseFloat(heuresFormated(a.time));
        });
        absences
        .filter((a) => !a.rattrapage && !a.justify && a.eleve["@id"] === e["@id"])
        .forEach((a) => {
          tmpAbsI += parseFloat(heuresFormated(a.time));
        });
      absences
        .filter((a) => a.rattrapage && a.eleve["@id"] === e["@id"])
        .forEach((a) => {
          tmpRat += parseFloat(heuresFormated(a.time));
        });
      tmpDatas.push({
        name: e.user.name,
        firstName: e.user.firstName,
        cours: totalProgs,
        absencesJ: tmpAbsJ,
        absencesI: tmpAbsI,
        rattrapages: tmpRat,
        presences: totalProgs - tmpAbsJ - tmpAbsI + tmpRat,
      });
    });
    setDatas({
      promo: promos.filter((p) => p["@id"] === promoSelected)[0],
      detail: tmpDatas,
    });
    setLoaded(true);
  };

  return Layout(
    <div className="mt-4">
      <h2>Temps de présence</h2>
      <div className="form-check">
        <input
          type="checkbox"
          className="form-check-input"
          checked={actif || false}
          onChange={(e) => setActif(!actif)}
        />
        <label className="form-check-label">
          <strong>Promos actives</strong>
        </label>
      </div>
      {msg !== null ? (
        <MsgAlert msg={msg.txt} type={msg.type} close={() => autoCloseMsg(setMsg)} />
      ) : null}
      <hr className="m-4" />
      {!loaded || promos === null ? (
        <Spinner />
      ) : (
        <React.Fragment>
          <div className="form-row mx-4">
            <div className="form-group col-lg-6">
              <label>Promos</label>
              <select
                className="form-control rounded-pill"
                value={promoSelected || ""}
                onChange={(e) => {
                  setPromoSelected(e.target.value);
                }}
              >
                <option value="">Selectionnez une promo</option>
                {promos.map((p, i) => (
                  <option value={p["@id"]} key={i}>
                    {p.site.name} - {p.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group col-lg-3">
              <label>
                Début de période{" "}
                {safari ? (
                  <em>
                    <small> (aaaa-mm-jj)</small>
                  </em>
                ) : null}
              </label>
              <input
                type="date"
                className="form-control rounded-pill"
                placeholder={safari ? "aaaa-mm-jj" : null}
                pattern={safari ? "\\d{4}-\\d{2}-\\d{2}" : null}
                value={debut || ""}
                onChange={(e) => setDebut(e.target.value)}
                required
              />
            </div>
            <div className="form-group col-lg-3">
              <label>
                Fin de période{" "}
                {safari ? (
                  <em>
                    <small> (aaaa-mm-jj)</small>
                  </em>
                ) : null}
              </label>
              <input
                type="date"
                className="form-control rounded-pill"
                placeholder={safari ? "aaaa-mm-jj" : null}
                pattern={safari ? "\\d{4}-\\d{2}-\\d{2}" : null}
                value={fin || ""}
                onChange={(e) => setFin(e.target.value)}
                required
              />
            </div>
          </div>
          <div className="form-row mx-4 justify-content-center">
            <div className="form-group col-lg-2">
              <button
                className="btn btn-sm rounded-pill btn-outline-primary form-control"
                onClick={() => {
                  setLoaded(false);
                  loadData();
                }}
                disabled={promoSelected === null || promoSelected === ""}
              >
                Afficher
              </button>
            </div>
          </div>
          <hr className="m-4" />
          <div className="m-4">
            {datas === null ? (
              <h4>Pas de selection</h4>
            ) : (
              <React.Fragment>
                <div className="d-flex justify-content-between">
                  <h4>{datas.promo.name}</h4>
                  <ReactHTMLTableToExcel
                    className="btn btn-sm rounded-pill btn-outline-primary"
                    table="TablePresence"
                    filename={"relevé-presences"}
                    sheet="Export"
                    buttonText="Export Excel"
                  />
                </div>
                <div className="table-responsive mt-4">
                  <table
                    className="table table-bordered table-sm"
                    id="TablePresence"
                  >
                    <thead>
                      <tr className="bg-info text-white">
                        <th className="align-middle" rowSpan="2">Elève</th>
                        <th className="align-middle" rowSpan="2">Cours</th>
                        <th className="align-middle" colSpan="2">Absences</th>
                        <th className="align-middle" rowSpan="2">Rattrapages</th>
                        <th className="align-middle" rowSpan="2">Total</th>
                      </tr>
                      <tr className="bg-info text-white">
                        <th>Justifiées</th>
                        <th>Injustifiées</th>
                      </tr>
                    </thead>
                    <tbody>
                      {datas.detail.map((d, i) => (
                        <tr key={i}>
                          <td className="text-left">
                            {d.name} {d.firstName}
                          </td>
                          <td className="font-weight-bold">{d.cours}</td>
                          <td className="text-danger font-weight-bold">
                            {d.absencesJ > 0 ? d.absencesJ : "-"}
                          </td>
                          <td className="text-danger font-weight-bold">
                            {d.absencesI > 0 ? d.absencesI : "-"}
                          </td>
                          <td className="text-info font-weight-bold">
                            {d.rattrapages > 0 ? d.rattrapages : "-"}
                          </td>
                          <td>{d.presences}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </React.Fragment>
            )}
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default GestPresences;
