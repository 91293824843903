import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import AppContext from "../../Context/AppContext";
import DeleteBddProvider from "../../Providers/DeleteBddProvider";
import GetBddProvider from "../../Providers/GetBddProvider";
import PostBddProvider from "../../Providers/PostBddProvider";
import UpdateBddProvider from "../../Providers/UpdateBddProvider";
import Layout from "../common/Layout";
import MsgAlert from "../common/MsgAlert";
import Spinner from "../common/Spinner";
import { autoCloseMsg } from "../tools/messagesUtils";

const SetMsginfo = () => {
  const { url } = useContext(AppContext);

  const role = [1, 2, 3, 4, 5, 6];

  const [message, setMessage] = useState(null);
  const [messages, setMessages] = useState([]);
  const [msg, setMsg] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [maj, setMaj] = useState(false);

  useEffect(() => {
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (maj) {
      setMaj(false);
      load();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maj]);

  const init = () => {
    setMessage({ actif: false });
  };

  const load = () => {
    setLoaded(false);
    init();
    let uri = url + "api/message_infos";
    GetBddProvider(uri).then((res) => {
      setLoaded(true);
      if (typeof res === "object") {
        setMessages(res["hydra:member"]);
      } else {
        setMsg({ txt: "Erreur de chargement", type: "danger" });
        autoCloseMsg(setMsg, 5000);
      }
    });
  };

  const delMsg = (uri) => {
    setLoaded(false);
    DeleteBddProvider(url + uri.slice(1)).then((res) => {
      if (res === 204) {
        setMaj(true);
        setMsg({ txt: "Message supprimé", type: "success" });
        autoCloseMsg(setMsg, 5000);
      } else {
        setLoaded(true);
        setMsg({ txt: "Erreur de supression", type: "danger" });
        autoCloseMsg(setMsg, 5000);
      }
    });
  };

  const handleSubmit = () => {
    setLoaded(false);
    if (message.id) {
      //Update
      let uri = url + message["@id"].slice(1);
      UpdateBddProvider(uri, message).then((res) => {
        if (typeof res === "object" && res.id) {
          setMaj(true);
          setMsg({ txt: "Modification enregistrée", type: "success" });
          autoCloseMsg(setMsg, 5000);
        } else {
          setLoaded(true);
          setMsg({ txt: "Erreur d'enregistrement", type: "danger" });
          autoCloseMsg(setMsg, 5000);
        }
      });
    } else {
      //Post
      let uri = url + "api/message_infos";
      PostBddProvider(uri, message).then((res) => {
        if (typeof res === "object" && res.id) {
          setMaj(true);
          setMsg({ txt: "Message créé", type: "success" });
          autoCloseMsg(setMsg, 5000);
        } else {
          setLoaded(true);
          setMsg({ txt: "Erreur d'enregistrement", type: "danger" });
          autoCloseMsg(setMsg, 5000);
        }
      });
    }
  };

  const validate = () => {
    return message.title && message.message && message.role;
  };

  return Layout(
    <div className="mt-4">
      <h2>Messages d'informations</h2>
      <hr className="m-4" />
      {msg !== null ? (
        <MsgAlert msg={msg.txt} type={msg.type} close={() => autoCloseMsg(setMsg)} />
      ) : null}
      {!loaded ? (
        <Spinner />
      ) : (
        <div>
          <div className="form-row align-items-center m-4">
            <div className="form-group col-lg-3 m-0">
              <label>Titre</label>
              <input
                type="text"
                className="form-control"
                placeholder="Titre du message"
                value={message.title || ""}
                onChange={(e) =>
                  setMessage({ ...message, title: e.target.value })
                }
              />
            </div>
            <div className="form-group col-lg-5 m-0">
              <label>Message</label>
              <input
                type="text"
                className="form-control"
                placeholder="Détail du message"
                value={message.message || ""}
                onChange={(e) =>
                  setMessage({ ...message, message: e.target.value })
                }
              />
            </div>
            <div className="form-group col-lg-1 m-0">
              <label>Role</label>
              <select
                className="form-control"
                value={message.role || ""}
                onChange={(e) =>
                  setMessage({ ...message, role: parseInt(e.target.value) })
                }
              >
                <option value="">--</option>
                {role.map((r, i) => (
                  <option key={i} value={r}>
                    {r}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group col-lg-1 m-0">
                <label>
                  Actif
                </label>
                <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input position-static"
                  checked={message.actif}
                  onChange={(e) =>
                    setMessage({
                      ...message,
                      actif: !message.actif,
                    })
                  }
                />
              </div>
            </div>

            <div className="form-group col-lg-2 m-0">
              <button
                type="submit"
                className="btn btn-info"
                onClick={handleSubmit}
                disabled={!validate()}
              >
                {message.id ? "Mettre à jour" : "Enregistrer"}
              </button>
            </div>
          </div>
          <hr className="m-4" />
          <h2>Liste des messages</h2>
          <table className="table table-sm table-bordered table-strped table-responsive-md">
            <thead>
              <tr>
                <th>Titre</th>
                <th>Message</th>
                <th>Role</th>
                <th>Actif</th>
                <th></th>
              </tr>
            </thead>
            <tbody className="text-left">
              {messages.map((m, i) => (
                <tr key={i}>
                  <td>{m.title}</td>
                  <td>{m.message}</td>
                  <td className="text-center">{m.role}</td>
                  <td className="text-center">
                    {!m.actif ? (
                      <span className="badge badge-pill badge-danger">Non</span>
                    ) : (
                      <span className="badge badge-pill badge-success">
                        Oui
                      </span>
                    )}
                  </td>
                  <td className="text-center">
                    <FontAwesomeIcon
                      className="text-success"
                      icon="edit"
                      onClick={() => setMessage(m)}
                      style={{ cursor: "pointer" }}
                    />{" "}
                    -{" "}
                    <FontAwesomeIcon
                      className="text-danger"
                      icon="trash-alt"
                      onClick={() => delMsg(m["@id"])}
                      style={{ cursor: "pointer" }}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default SetMsginfo;
