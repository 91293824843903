const addTime = (time1, time2) => {
  let temp1 = time1;
  let temp2 = time2;
  let t1 = {
    h: temp1.split(":")[0],
    m: temp1.split(":")[1]
  }
  let t2 = {
    h: temp2.split(":")[0],
    m: temp2.split(":")[1],
  };
  let hSup = 0
  let newM = 0
  let addM = parseInt(t1.m) + parseInt(t2.m);
  if (addM >= 60) {
    hSup = 1
    newM = addM - 60 < 10 ? "0"+(addM-60) : addM-60
  } else {
    newM = addM < 10 ? "0" + addM : addM;
  }
  let newH = parseInt(t1.h) + parseInt(t2.h) + hSup;

  return newH + ":" + newM
}

export default addTime