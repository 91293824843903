import React from "react";
import Layout from "../common/Layout";
import Eleve from "../contents/Eleve";

const PageEleve = (props) => {

    return Layout(
      <Eleve id={props.match.params.id} />
    );
}

export default PageEleve;
