import React, { useContext, useEffect, useState } from "react";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import AppContext from "../../Context/AppContext";
import GetBddProvider from "../../Providers/GetBddProvider";
import Layout from "../common/Layout";
import MsgAlert from "../common/MsgAlert";
import Spinner from "../common/Spinner";
import { autoCloseMsg } from "../tools/messagesUtils";

const ExportProg = () => {
  const { url } = useContext(AppContext);

  const [formations, setFormations] = useState(null);
  const [progItems, setProgItems] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [msg, setMsg] = useState(null);

  useEffect(() => {
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (msg !== null) {
      setLoaded(true);
    }
  }, [msg]);

  useEffect(() => {
    if (formations !== null && progItems !== null) {
      setLoaded(true);
    }
  }, [formations, progItems]);

  const load = () => {
    // Chargement des formations
    GetBddProvider(url + "api/formations").then((res) => {
      if (typeof res === "object") {
        setFormations(res["hydra:member"]);
      } else {
        setMsg({ txt: "Erreur de chargement des formations", type: "danger" });
        autoCloseMsg(setMsg, 5000);
      }
    });
    // Cha&rgement des items de progressions
    GetBddProvider(url + "api/progression_items").then((res) => {
      if (typeof res === "object") {
        setProgItems(res["hydra:member"]);
      } else {
        setMsg({ txt: "Erreur de chargement des prog", type: "danger" });
        autoCloseMsg(setMsg, 5000);
      }
    });
  };

  return Layout(
    <div className="m-4">
      <span className="d-flex justify-content-between">
      <h1>Export des programmes</h1>
      <div>
        {loaded ? (
          <ReactHTMLTableToExcel
            className="btn btn-outline-primary"
            table="TabExportProg"
            filename="Export Programme"
            sheet="Export"
            buttonText="Export Excel"
          />
        ) : null}
      </div></span>
      {msg !== null ? (
        <MsgAlert msg={msg.txt} type={msg.type} close={() => autoCloseMsg(setMsg)} />
      ) : null}
      {loaded ? (
        <table
          className="table table-responsive-lg table-sm text-left "
          id="TabExportProg"
        >
          {formations.map((f) => (
            <React.Fragment key={f["@id"]}>
              <thead>
                <tr>
                  <td colSpan="3"><strong>{f.name}</strong></td>
                </tr>
              </thead>
              <tbody>
                {f.matieres.map((m) => (
                  <React.Fragment key={f.id + "/" + m.id}>
                    <tr>
                      <td> </td>
                      <td colSpan="2">{m.name}</td>
                    </tr>
                    {progItems
                      .filter(
                        (p) =>
                          p.formation === f["@id"] && p.matiere === m["@id"]
                      )
                      .map((p) => (
                        <tr key={f.id + "-" + m.id + "-" + p.id}>
                          <td> </td>
                          <td> </td>
                          <td>
                            <em>{p.name}</em>
                          </td>
                        </tr>
                      ))}
                  </React.Fragment>
                ))}
              </tbody>
            </React.Fragment>
          ))}
        </table>
      ) : <Spinner/> }
    </div>
  );
};

export default ExportProg;
