import React from "react";

export default function Spinner(props) {
  const className =
      "spinner-border mt-5 text-" + String(props.color ? props.color : "info");
    
  return (
      <div
        className={className}
        style={{ height: "100px", width: "100px" }}
        role="status"
      >
        <span className="sr-only">Loading...</span>
      </div>
  );
}
