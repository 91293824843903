import React, { useContext } from "react";
import YouTube from "react-youtube";
import AppContext from "../../Context/AppContext";
import Layout from "../common/Layout";

const Support = () => {
  const { user } = useContext(AppContext);

  const opts = {
    width: "426",
    height: "240",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
    },
  };

  const videosFormateur = [
    {
      title: "Saisie des progressions",
      id: "6xgU5dONDl0",
    },
    {
      title: "Saisie des notes",
      id: "bttrLfjnMjA",
    },
    {
      title: "Saisie des absences",
      id: "AKfeXiUoIlI",
    },
    {
      title: "Saisie des appréciations",
      id: "xc0mVqEGJL8",
    },
    {
      title: "Relevé d'heures",
      id: null,
    },
  ];

  const videosAdmin = [
    {
      title: "Gestion des promos",
      id: null,
    },
    {
      title: "Gestion des Semestres",
      id: null,
    },
    {
      title: "Gestion des élèves",
      id: null,
    },
    {
      title: "Gestion des Formateurs",
      id: null,
    },
    {
      title: "Gestion des tuteurs",
      id: null,
    },
    {
      title: "Gestion des absences et retards",
      id: null,
    },
    {
      title: "Relevé d'heures",
      id: null,
    },
    {
      title: "Progressions pédagogiques",
      id: null,
    },
    {
      title: "Suivi Comportement",
      id: null,
    },
    {
      title: "Suivi Compétences",
      id: null,
    },
    {
      title: "Évaluation en Situation de Travail",
      id: null,
    },
    {
      title: "Péparation du livret pédagogique",
      id: null,
    },
  ];

  const _onReady = (event) => {
    event.target.pauseVideo();
  };

  const ViewVideo = ({ videos, role }) => {
    return (
      <React.Fragment>
        <h2>Tutoriels {role}</h2>
        <div className="d-flex flex-wrap justify-content-center border border-info rounded my-4">
          {videos.map((video, i) => (
            <div className="m-2" key={video.id !== null ? video.id : role + i}>
              <h4>{video.title}</h4>
              {video.id !== null ? (
                <div
                  className="bg-secondary"
                  style={{ height: "240px", width: "426px" }}
                >
                  <YouTube
                    videoId={video.id}
                    opts={opts}
                    onReady={_onReady}
                    className="p-0 m-0"
                  />
                </div>
              ) : (
                <div
                  className="d-flex bg-secondary"
                  style={{ height: "240px" }}
                >
                  <h5
                    className="align-self-center text-white"
                    style={{ width: "426px" }}
                  >
                    Bientôt disponible !
                  </h5>
                </div>
              )}
            </div>
          ))}
        </div>
      </React.Fragment>
    );
  };

  return Layout(
    <div className="m-4">
      {user.role < 5 ? (
        <ViewVideo videos={videosFormateur} role="formateur" />
      ) : null}
      <hr />
      {user.role <= 3 ? <ViewVideo videos={videosAdmin} role="admin" /> : null}
    </div>
  );
};

export default Support;
