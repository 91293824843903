/**
 * Permet de récupérer la moyenne maximum et minimum
 * @param {array} moyennes - liste des moyennes
 * @returns {object} objet contenant les moyenne maximum et minimum
 */
const CalcMinGenClasse = (moyennes) => {
  let tmp = moyennes
    .filter((m) => m.moyenne !== null)
    .sort((a, b) => {
      return a.moyenne > b.moyenne ? 1 : -1;
    });
  let tempMin = tmp[0];
  let tempMax = tmp[tmp.length - 1];
  let minTmp =
    tempMin !== undefined && tempMin.moyenne !== undefined
      ? tempMin.moyenne
      : null;
  let maxTmp =
    tempMax !== undefined && tempMax.moyenne !== undefined
      ? tempMax.moyenne
      : null;
  return { min: minTmp, max: maxTmp };
};

export default CalcMinGenClasse;
