import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { triByName } from "../../tools/sortUtils";

const ListSetCompetences = ({
  formation,
  setBlocSelected,
  setCompSelected,
  blocs
}) => {

  return (
    <div className="card shadow border-info col-md-5 p-0 m-1">
      <div className="card-header text-info d-flex justify-content-between">
        <h5 className="m-0">{formation.name}</h5>
        <button
          className="text-info close"
          title="Ajouter un bloc"
          onClick={(e) => setBlocSelected({ formation: formation["@id"] })}
          data-toggle="modal"
          data-target="#ModalBloc"
        >
          <FontAwesomeIcon icon="plus-circle" />
        </button>
      </div>
      <div className="card-body text-left px-3">
        {blocs.map((b) => (
          <div key={b.id}>
          <hr className="m-1" />
            <div className="d-flex justify-content-md-between">
              <h5 className="text-info bold">
                {b.name}
              </h5>
              <div className="col-4 text-right p-0">
                <button
                  className="btn btn-outline-info"
                  title="Ajouter une compétence"
                  onClick={(e) => setCompSelected({ bloc: b["@id"] })}
                  data-toggle="modal"
                  data-target="#ModalComp"
                >
                  <FontAwesomeIcon icon="plus-circle" />
                </button>
                &nbsp;-&nbsp;
                <button
                  className="btn btn-outline-info"
                  title="Modifier un bloc"
                  onClick={(e) => setBlocSelected({ id: b.id, name: b.name })}
                  data-toggle="modal"
                  data-target="#ModalBloc"
                >
                  <FontAwesomeIcon icon="edit" />
                </button>
              </div>
            </div>
            <hr className="m-1" />
            <ul className="list-group text-left">
              {b.competences
                ? b.competences.sort(triByName).map((c,i) => (
                    <li
                      key={i}
                      className="list-group-item d-flex justify-content-between p-0 border-0"
                    >
                      {c.name}
                      <button
                        className="btn btn-outline-info"
                        title="Modifier la compétence"
                        onClick={(e) =>
                          setCompSelected(JSON.parse(JSON.stringify(c)))
                        }
                        data-toggle="modal"
                        data-target="#ModalComp"
                      >
                        <FontAwesomeIcon icon="edit" />
                      </button>
                    </li>
                  ))
                : null}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ListSetCompetences;
