const heuresFormated = (e) => {
  let temp = e.split("T")[1];
  let h = temp.split(":")[0];
  let minutes = parseInt(temp.split(":")[1]);
  let m = minutes / 0.6 ;
  let time = h + "."
  time += minutes < 6 ? "0" + m : m;
  time = Math.round(parseFloat(time)*100)/100
  return time;
};
export default heuresFormated;
