import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import AppContext from "../../../Context/AppContext";
import PageFormateurContext from "../../../Context/PageFormateurContext";
import UpdateBddProvider from "../../../Providers/UpdateBddProvider";
import MsgAlert from "../../common/MsgAlert";
import Spinner from "../../common/Spinner";
import dateFormated from "../../tools/dateFormated";
import { autoCloseMsg } from "../../tools/messagesUtils";

const ModifRetard = ({ rtdSelected, maj }) => {
  const { url } = useContext(AppContext);
  const { formateur } = useContext(PageFormateurContext);

  const [retard, setRetard] = useState(null);
  const [msg, setMsg] = useState(null);
  const [save, setSave] = useState(true);

  useEffect(() => {
    if (
      rtdSelected !== undefined &&
      rtdSelected !== retard &&
      rtdSelected !== null
    ) {
      setRetard(rtdSelected);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rtdSelected]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setSave(false);
    let data = {
      time: retard.time,
      motif: retard.motif,
      justify: retard.justify,
      prev: retard.prev,
      depart: retard.depart,
    };
    let uri = url + retard["@id"].slice(1);
    UpdateBddProvider(uri, data).then((res) => {
      setSave(true);
      if (typeof res === "object" && res.id) {
        if (maj !== undefined) {
          maj(true);
        }
        setMsg({ txt: "Modifcation enregistrée", type: "success" });
        autoCloseMsg(setMsg, 5000);
      } else {
        setMsg({ txt: "Erreur d'enregistrement", type: "danger" });
        autoCloseMsg(setMsg, 5000);
      }
    });
  };

  const isValide = () => {
    return retard.motif !== "";
  };

  return (
    <div
      className="modal fade"
      id="ModalModifRtd"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <form onSubmit={handleSubmit}>
            <div className="modal-header bg-info text-white">
              <h5 className="modal-title">
                <FontAwesomeIcon icon="edit" /> &nbsp;Modifier le retard ou
                départ anticipé
              </h5>
              <button
                type="button"
                className="close text-white"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              {msg !== null ? (
                <div className="mx-4">
                  <MsgAlert
                    msg={msg.txt}
                    type={msg.type}
                    close={() => autoCloseMsg(setMsg)}
                  />
                </div>
              ) : null}
              {!save ? (
                <Spinner />
              ) : retard === null ? (
                <p className="text-danger">
                  Pas de retard ou départ sélectionné
                </p>
              ) : (
                <div>
                  <div>
                    <strong>
                      <em>
                        {retard.depart === undefined || retard.depart === false
                          ? "Retard"
                          : "Départ anticipé"}{" "}
                        du {dateFormated(retard.createdAt)}
                      </em>
                    </strong>
                    <hr />
                    <strong>
                      <em>
                        <u>Elève</u>
                      </em>
                    </strong>
                    <p className="mt-1">
                      {retard.eleve.user.name} {retard.eleve.user.firstName}
                    </p>
                  </div>
                  <hr />
                  <div className="form-group form-inline justify-content-between">
                    <div className="form-check col-6">
                      <input
                        type="radio"
                        className="form-check-input"
                        name="radioDepartOption"
                        id="radioRetard"
                        value="false"
                        onChange={(e) =>
                          setRetard({ ...retard, depart: false })
                        }
                        checked={retard.depart === false}
                      />
                      <label htmlFor="radioRetard" className="form-check-label">
                        Retard
                      </label>
                    </div>
                    <div className="form-check col-6">
                      <input
                        type="radio"
                        className="form-check-input"
                        name="radioDepartOption"
                        id="radioDepart"
                        value="true"
                        onChange={(e) => setRetard({ ...retard, depart: true })}
                        checked={retard.depart === true}
                      />
                      <label htmlFor="radioDepart" className="form-check-label">
                        Départ anticipé
                      </label>
                    </div>
                  </div>
                  <div className="form-group">
                    <label>Temps</label>
                    <input
                      type="time"
                      title="durée de la session"
                      min="00:01"
                      max="04:00"
                      step="60"
                      pattern={
                        navigator.userAgent.indexOf("Safari") >= 1 &&
                        navigator.userAgent.indexOf("Chrome") <= 0
                          ? "0[1-4]:\\d{2}"
                          : null
                      }
                      value={
                        retard.time.length > 5
                          ? retard.time.split("T")[1].split(":")[0] +
                            ":" +
                            retard.time.split("T")[1].split(":")[1]
                          : retard.time || "00:00"
                      }
                      className="form-control"
                      onChange={(e) =>
                        setRetard({
                          ...retard,
                          time: e.target.value,
                        })
                      }
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label>motif</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Motif du retard"
                      required
                      maxLength="250"
                      value={retard.motif || ""}
                      onChange={(e) =>
                        setRetard({
                          ...retard,
                          motif: e.target.value,
                        })
                      }
                    />
                  </div>
                  {!formateur && (
                    <div className="form-row align-items-center">
                      <div className="form-group col-md-6">
                        <div className="custom-control custom-switch">
                          <input
                            className="custom-control-input"
                            type="checkbox"
                            id="justifyModifCheckbox"
                            checked={retard.justify || false}
                            onChange={(e) =>
                              setRetard({
                                ...retard,
                                justify: !retard.justify,
                              })
                            }
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="justifyModifCheckbox"
                          >
                            Justificatif
                          </label>
                        </div>
                      </div>
                      <div className="form-group col-md-6">
                        <div className="custom-control custom-switch">
                          <input
                            className="custom-control-input"
                            type="checkbox"
                            id="prevModifCheckbox"
                            checked={retard.prev || false}
                            onChange={(e) =>
                              setRetard({
                                ...retard,
                                prev: !retard.prev,
                              })
                            }
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="prevModifCheckbox"
                          >
                            Prévenue
                          </label>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-dismiss="modal"
                    >
                      Fermer
                    </button>
                    <button
                      type="submit"
                      className="btn btn-info"
                      disabled={!isValide()}
                    >
                      Mettre à jour
                    </button>
                  </div>
                </div>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ModifRetard;
