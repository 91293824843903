import React, { useContext } from "react";
import AppContext from "../../../Context/AppContext";
import { useState } from "react";
import GetBddProvider from "../../../Providers/GetBddProvider";
import { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Spinner from "../../common/Spinner";
import MsgAlert from "../../common/MsgAlert";
import PostBddProvider from "../../../Providers/PostBddProvider";
import UpdateBddProvider from "../../../Providers/UpdateBddProvider";
import GenPass from "../../GenPass";
import { autoCloseMsg } from "../../tools/messagesUtils";

const EditFormateur = ({ maj, sites, selected }) => {
  const { url, user } = useContext(AppContext);
  const [save, setsave] = useState(false);
  const [msg, setMsg] = useState(null);
  const [formateur, setformateur] = useState(selected);
  const [formations, setformations] = useState();

  if (formateur.matieres !== undefined) {
    formateur.matieres.forEach((el, i) => {
      if (typeof el === "object") {
        formateur.matieres[i] = el["@id"];
      }
    });
  }

  if (formateur.user.sites !== undefined) {
    formateur.user.sites.forEach((el, i) => {
      if (typeof el === "object") {
        formateur.user.sites[i] = el["@id"];
      }
    });
  }

  if (formateur.promos !== undefined) {
    formateur.promos.forEach((el, i) => {
      if (typeof el === "object") {
        formateur.promos[i] = el["@id"];
      }
    });
  }

  const load = () => {
    let uri = url + "api/formations";
    GetBddProvider(uri).then((res) => {
      if (typeof res === "object") {
        setformations(res["hydra:member"]);
        setsave(true);
      }
    });
  };

  const changeProfil = (role) => {
    if (formateur.user.roles.includes(role)) {
      setformateur({
        ...formateur,
        user: {
          ...formateur.user,
          roles: formateur.user.roles.filter((el) => el !== role),
        },
      });
    } else {
      setformateur({
        ...formateur,
        user: { ...formateur.user, roles: [...formateur.user.roles, role] },
      });
    }
  };

  const ChangeSites = (site) => {
    if (formateur.user.sites === undefined) {
      setformateur({
        ...formateur,
        user: { ...formateur.user, sites: [site] },
      });
    } else if (formateur.user.sites.includes(site)) {
      setformateur({
        ...formateur,
        user: {
          ...formateur.user,
          sites: formateur.user.sites.filter((el) => el !== site),
        },
      });
    } else {
      setformateur({
        ...formateur,
        user: { ...formateur.user, sites: [...formateur.user.sites, site] },
      });
    }
  };

  const ChangeMatieres = (matiere) => {
    if (formateur.matieres === undefined) {
      setformateur({
        ...formateur,
        matieres: [matiere],
      });
    } else if (formateur.matieres.includes(matiere)) {
      setformateur({
        ...formateur,
        matieres: formateur.matieres.filter((mat) => mat !== matiere),
      });
    } else {
      setformateur({
        ...formateur,
        matieres: [...formateur.matieres, matiere],
      });
    }
  };

  const validateForm = () => {
    if (formateur.user === undefined) {
      return false;
    }
    return (
      formateur.user.name &&
      formateur.user.firstName &&
      formateur.user.pseudo !== undefined &&
      formateur.user.pseudo.length >= 5 &&
      formateur.user.email &&
      formateur.user.sites &&
      formateur.user.sites.length > 0 &&
      formateur.matieres &&
      formateur.matieres.length > 0
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setsave(false);
    let temp = Object.assign({}, formateur);
    for (let ok = false; ok === false; ) {
      let pseudoTest = temp.user.pseudo.slice(-1);
      pseudoTest === " "
        ? (temp.user.pseudo = temp.user.pseudo.slice(0, -1))
        : (ok = true);
    }
    if (temp.id) {
      UpdateBddProvider(url + "api/users/" + temp.user.id, temp.user).then(
        (res) => {
          if (typeof res === "object" && res.id) {
            temp = { ...temp, user: temp.user["@id"] };
            UpdateBddProvider(url + "api/formateurs/" + temp.id, temp).then(
              (res) => {
                setsave(true);
                if (typeof res === "object" && res.id) {
                  setMsg({
                    text: "Le formateur a bien été mise à jour",
                    type: "success",
                  });
                  maj(true);
                  autoCloseMsg(setMsg, 5000);
                } else {
                  setMsg({ text: "erreur", type: "danger" });
                  autoCloseMsg(setMsg, 5000);
                }
              }
            );
          } else {
            let txt = res.violations
              ? res.violations.map((violation, i) => {
                  return <div key={i}>- {violation.message}</div>;
                })
              : "Erreur";
            setMsg({ text: txt, type: "danger" });
            setsave(true);
            autoCloseMsg(setMsg, 5000);
          }
        }
      );
    } else {
      temp.user.password = GenPass();
      PostBddProvider(url + "api/users", temp.user).then((res) => {
        if (typeof res === "object" && res.id) {
          temp.user = "/api/users/" + res.id;
          PostBddProvider(url + "api/formateurs", temp).then((res) => {
            setsave(true);
            if (typeof res === "object") {
              setMsg({
                text: "Le formateur a bien été créé",
                type: "success",
              });
              setformateur({
                user: { actif: true, roles: ["ROLE_FORMATEUR"] },
              });
              maj(true);
              autoCloseMsg(setMsg, 5000);
            } else {
              setMsg({ text: "erreur", type: "danger" });
              autoCloseMsg(setMsg, 5000);
            }
          });
        } else {
          let txt = res.violations
            ? res.violations.map((violation, i) => {
                return <div key={i}>- {violation.message}</div>;
              })
            : "Erreur";
          setMsg({ text: txt, type: "danger" });
          setsave(true);
          autoCloseMsg(setMsg, 5000);
        }
      });
    }
  };

  useEffect(() => {
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (formateur !== selected) {
      setformateur(selected);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  return (
    <div
      className="modal fade"
      id="Modal"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-lg modal-dialog-centered"
        role="document"
      >
        <div className="modal-content">
          <form>
            <div className="modal-header bg-info text-white">
              <h5 className="modal-title">
                <FontAwesomeIcon icon="edit" /> &nbsp;{" "}
                {formateur.id ? "Modifier" : "Ajouter"} un formateur
              </h5>
              <button
                type="button"
                className="close text-white"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            {!save || sites === null ? (
              <div className="pb-5 modal-body text-info">
                <Spinner />
              </div>
            ) : (
              <div className="modal-body">
                {msg !== null ? (
                  <div className="mx-4">
                    <MsgAlert
                      msg={msg.text}
                      type={msg.type}
                      close={()=>autoCloseMsg(setMsg)}
                    />
                  </div>
                ) : null}
                <div className="form-group">
                  <label htmlFor="name">
                    <strong>Nom</strong>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    name="name"
                    autoComplete="off"
                    value={formateur.user.name || ""}
                    placeholder="Nom"
                    onChange={(e) =>
                      setformateur({
                        ...formateur,
                        user: {
                          ...formateur.user,
                          name: e.target.value,
                          pseudo: `${
                            formateur.user.firstName
                              ? formateur.user.firstName
                                  .toLowerCase()
                                  .slice(0, 1)
                              : ""
                          }.${e.target.value.toLowerCase()}`,
                        },
                      })
                    }
                    disabled={formateur.user.roles.includes("ROLE_DEV")}
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="firstName">
                    <strong>Prénom</strong>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    name="firstName"
                    autoComplete="off"
                    value={formateur.user.firstName || ""}
                    placeholder="Prénom"
                    onChange={(e) =>
                      setformateur({
                        ...formateur,
                        user: {
                          ...formateur.user,
                          firstName: e.target.value,
                          pseudo: `${e.target.value
                            .toLowerCase()
                            .slice(0, 1)}.${
                            formateur.user.name.toLowerCase() || ""
                          }`,
                        },
                      })
                    }
                    disabled={
                      formateur.user.roles.includes("ROLE_DEV") ||
                      !formateur.user.name
                    }
                    required
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="pseudo">
                    <strong>Nom d'utilisateur</strong>
                  </label>
                  <input
                    className={`form-control ${
                      (formateur.user.pseudo === undefined ||
                        formateur.user.pseudo.length < 5) &&
                      "is-invalid"
                    }`}
                    type="text"
                    name="pseudo"
                    minLength={5}
                    autoComplete="off"
                    value={formateur.user.pseudo || ""}
                    placeholder="Nom d'utilisateur"
                    onChange={(e) => {
                      setformateur({
                        ...formateur,
                        user: { ...formateur.user, pseudo: e.target.value },
                      });
                    }}
                    disabled={formateur.user.roles.includes("ROLE_DEV")}
                    required
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="email">
                    <strong>Adresse mail</strong>
                  </label>
                  <input
                    className="form-control"
                    type="email"
                    name="email"
                    autoComplete="off"
                    value={formateur.user.email || ""}
                    placeholder="Adresse mail"
                    onChange={(e) =>
                      setformateur({
                        ...formateur,
                        user: {
                          ...formateur.user,
                          email: e.target.value,
                        },
                      })
                    }
                    required
                    disabled={formateur.user.roles.includes("ROLE_DEV")}
                  />
                </div>
                <div className="form-group">
                  <label>
                    <strong>Matières :</strong>
                  </label>
                  {formations === null
                    ? null
                    : formations.map((formation, i) => {
                        return (
                          <div key={i}>
                            <label>
                              <strong>{formation.name}</strong>
                            </label>
                            <div className="custom-control custom-switch d-flex justify-content-between shadow flex-wrap rounded text-left border py-2">
                              {formation.matieres.map((matiere, i) => {
                                return (
                                  <div key={i} className="col-md-6">
                                    <input
                                      type="checkbox"
                                      className="custom-control-input"
                                      id={"chMat" + formation.id + "-" + i}
                                      value={matiere["@id"]}
                                      checked={
                                        formateur.matieres !== undefined
                                          ? formateur.matieres.includes(
                                              matiere["@id"]
                                            )
                                          : false
                                      }
                                      onChange={(e) =>
                                        ChangeMatieres(matiere["@id"])
                                      }
                                    />
                                    <label
                                      className="custom-control-label"
                                      htmlFor={"chMat" + formation.id + "-" + i}
                                    >
                                      {matiere.name}
                                    </label>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        );
                      })}
                </div>

                <div className="form-group">
                  <label>
                    <strong>Affectation</strong>
                  </label>
                  <div className="custom-control custom-switch d-flex justify-content-center flex-wrap text-left">
                    {sites.map((site, i) => {
                      return (
                        <div key={i} className="col-md-3">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id={"chSite" + site.id}
                            disabled={
                              user.role > 1
                                ? !user.sites
                                    .map((el) => el["@id"] === site["@id"])
                                    .includes(true)
                                : false
                            }
                            value={site["@id"]}
                            checked={
                              formateur.user.sites !== undefined
                                ? formateur.user.sites.includes(site["@id"])
                                : false
                            }
                            onChange={(e) => ChangeSites(site["@id"])}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor={"chSite" + site.id}
                          >
                            {site.name}
                          </label>
                        </div>
                      );
                    })}
                  </div>
                  <div className="mt-3 text-left">
                    <p>
                      <em>Sites d'intervention : </em>
                      {sites === null || formateur.user.sites === undefined
                        ? null
                        : formateur.user.sites.map((site, i) => {
                            let result;
                            sites.forEach((el) => {
                              if (el["@id"] === site) {
                                result = (
                                  <span
                                    className="badge badge-pill badge-primary m-1"
                                    key={i}
                                  >
                                    {el.name}
                                  </span>
                                );
                              }
                            });
                            return result;
                          })}
                    </p>
                  </div>
                </div>

                <div className="custom-control custom-switch">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="chPedago"
                    checked={formateur.user.roles.includes("ROLE_PEDAGO")}
                    onChange={(e) => changeProfil("ROLE_PEDAGO")}
                  />
                  <label className="custom-control-label" htmlFor="chPedago">
                    <strong>Membre de l'équipe pédagogique du centre</strong>
                  </label>
                </div>

                <div className="custom-control custom-switch">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="chReferent"
                    checked={
                      formateur.user.roles.includes("ROLE_REFERENT") ||
                      formateur.user.roles.includes("ROLE_PEDAGO")
                    }
                    disabled={formateur.user.roles.includes("ROLE_PEDAGO")}
                    onChange={(e) => changeProfil("ROLE_REFERENT")}
                  />
                  <label className="custom-control-label" htmlFor="chReferent">
                    <strong>Accès à la gestion des suivis</strong>
                  </label>
                </div>

                <div className="custom-control custom-switch">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="chActif"
                    checked={formateur.user.actif || false}
                    onChange={(e) =>
                      setformateur({
                        ...formateur,
                        user: {
                          ...formateur.user,
                          actif: !formateur.user.actif,
                        },
                      })
                    }
                  />
                  <label className="custom-control-label" htmlFor="chActif">
                    <strong>Actif</strong>
                  </label>
                </div>
              </div>
            )}
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Fermer
              </button>
              <button
                type="submit"
                className="btn btn-info"
                onClick={handleSubmit}
                disabled={!validateForm()}
              >
                Enregistrer
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditFormateur;
