import React, { useContext, useEffect, useState } from "react";
import AppContext from "../../Context/AppContext";
import useGet from "../../hooks/useGet";
import Layout from "../common/Layout";
import Spinner from "../common/Spinner";
import SitePseudo from "../tools/AdminPseudo/SitePseudo";
import { triByName } from "../tools/sortUtils";

export default function AdminUsername() {
  const { url, user } = useContext(AppContext);

  const [loaded, setLoaded] = useState(false);
  const [Sites, setSites] = useState([]);
  const [siteSelected, setSiteSelected] = useState(user.sites[0]["@id"]);

  const GetBdd = useGet();

  useEffect(() => {
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const load = () => {
    let uri = url + "api/sites";
    GetBdd(uri).then((res) => {
      if (typeof res === "object") {
        setSites(res["hydra:member"]);
        setLoaded(true);
      }
    });
  };

  return Layout(
    <div className="mt-4 mx-4">
      <h2 className="text-left">Gestion des Pseudos !</h2>
      <hr className="my-4" />
      Visualistaion avancement + erreurs.
      <select
        className="custom-select"
        value={siteSelected || "All"}
        onChange={(e) => {
          setSiteSelected(e.target.value);
        }}
      >
        <option value="None">Utilisateurs sans pseudo</option>
        <option value="All">Synthèse</option>
        {Sites.sort(triByName).map((s) => (
          <option value={s["@id"]} key={s["@id"]}>
            {s.name}
          </option>
        ))}
      </select>
      {!loaded ? (
        <Spinner />
      ) : (
        <table className="table table-striped text-left">
          <thead>
            <tr>
              <th>Site</th>
              <th>Nb User</th>
              <th>Nb User avec pseudo</th>
              <th>Progress</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {siteSelected === "None" ? (
              <SitePseudo
                site="Exists"
                siteName="No Name"
                url={url}
                key={"detail" + siteSelected}
              />
            ) : siteSelected !== "All" ? (
              <SitePseudo
                site={siteSelected}
                siteName={
                  Sites.filter((s) => s["@id"] === siteSelected)[0].name
                }
                url={url}
                key={"detail" + siteSelected}
              />
            ) : (
              Sites.sort(triByName).map((site) => (
                <SitePseudo
                  site={site["@id"]}
                  siteName={site.name}
                  url={url}
                  key={"detail" + site["@id"]}
                />
              ))
            )}
          </tbody>
        </table>
      )}
    </div>
  );
}
