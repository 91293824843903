import React, { useEffect, useState } from "react";
import { useContext } from "react";
import AppContext from "../../../Context/AppContext";
import GetBddProvider from "../../../Providers/GetBddProvider";
import PostBddProvider from "../../../Providers/PostBddProvider";
import MsgAlert from "../../common/MsgAlert";
import Spinner from "../../common/Spinner";
import { autoCloseMsg } from "../../tools/messagesUtils";

const EditReunion = ({ setMaj }) => {
  const { user, url } = useContext(AppContext);

  const [msg, setMsg] = useState(null);
  const [createdAt, setCreatedAt] = useState(null);
  const [time, setTime] = useState(null);
  const [intitule, setIntitule] = useState(null);
  const [siteSelected, setSiteSelected] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [formateurs, setFormateurs] = useState(null);
  const [save, setSave] = useState(true);
  const [formTimes, setFormTimes] = useState({});
  const [presents, setPresents] = useState([]);

  useEffect(() => {
    if (siteSelected !== null) {
      loadFormateurs();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteSelected]);

  const init = () => {
    setCreatedAt(null);
    setTime(null);
    setIntitule(null);
    setFormateurs(null);
    setSiteSelected(null);
    setFormTimes({});
    setPresents([]);
  };

  const loadFormateurs = () => {
    let uri = url + "api/formateurs?user.sites=" + siteSelected;
    GetBddProvider(uri).then((res) => {
      setLoaded(true);
      if (typeof res === "object") {
        setFormateurs(res["hydra:member"]);
      } else {
        setMsg({ msg: "Erreur de chargement des formateurs", type: "danger" });
        autoCloseMsg(setMsg, 5000);;
      }
    });
  };

  const valid = () => {
    return (
      createdAt &&
      time &&
      presents.length > 0 &&
      intitule !== null &&
      intitule.length > 0
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSave(false);
    let uri = url + "api/reunions";
    let data = {
      createdAt: createdAt,
      time: time,
      label: intitule,
      site: siteSelected,
    };
    PostBddProvider(uri, data).then((res) => {
      if (typeof res === "object" && res.id) {
        let uri2 = url + "api/participants_rs";
        let iri = "/api/reunions/" + res.id;
        presents.forEach((p, i) => {
          let participants = {
            user: p,
            time: formTimes[p] ? formTimes[p] : time,
            reunion: iri,
          };
          PostBddProvider(uri2, participants).then((res) => {
            if (typeof res === "object" && res.id) {
              if (i + 1 === presents.length) {
                setMsg({
                  msg: "La réunion a bien été enregistrée",
                  type: "success",
                });
                autoCloseMsg(setMsg, 5000);;
                init();
                setMaj(true);
                setSave(true);
              }
            } else {
              setSave(true);
              setMsg({
                msg: "Erreur d'enregistrement des participants",
                type: "danger",
              });
              autoCloseMsg(setMsg, 5000);;
            }
          });
        });
      } else {
        setSave(true);
        setMsg({
          msg: "Erreur d'enregistrement de la réunion",
          type: "danger",
        });
        autoCloseMsg(setMsg, 5000);;
      }
    });
  };

  return (
    <div
      className="modal fade"
      id="SaisiReunion"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-dialog-centered modal-dialog-scrollable"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header bg-info text-white">
            <h5 className="modal-title">
              Saisie des heures de réunion/conseil
            </h5>
            <button
              type="button"
              className="close text-white"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            {msg !== null ? (
              <div className="mx-4">
                <MsgAlert msg={msg.msg} type={msg.type} close={() => autoCloseMsg(setMsg)} />
              </div>
            ) : null}
            <div className="modal-body">
              {user.sites.length > 1 ? (
                <div className="form-group">
                  <label>Site</label>
                  <select
                    name="selectSemestre"
                    className="form-control"
                    value={siteSelected || ""}
                    onChange={(e) => {
                      init();
                      setSiteSelected(e.target.value);
                    }}
                    required
                  >
                    <option value="" disabled>
                      Sélectionnez un site
                    </option>
                    {user.sites.map((s, i) => (
                      <option value={s["@id"]} key={i}>
                        {s.name}
                      </option>
                    ))}
                  </select>
                </div>
              ) : siteSelected === null ? (
                setSiteSelected(user.sites[0]["@id"])
              ) : null}
              {siteSelected === null ? null : !loaded ||
                formateurs === null ||
                !save ? (
                <Spinner />
              ) : (
                <div>
                  <div className="form-row align-items-center">
                    <div className="form-group col-md-6">
                      <label>
                        Date
                        {navigator.userAgent.indexOf("Safari") >= 1 &&
                        navigator.userAgent.indexOf("Chrome") <= 0 ? (
                          <em>
                            <small> (aaaa-mm-jj)</small>
                          </em>
                        ) : null}
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        placeholder={
                          navigator.userAgent.indexOf("Safari") >= 1 &&
                          navigator.userAgent.indexOf("Chrome") <= 0
                            ? "aaaa-mm-jj"
                            : null
                        }
                        pattern={
                          navigator.userAgent.indexOf("Safari") >= 1 &&
                          navigator.userAgent.indexOf("Chrome") <= 0
                            ? "\\d{4}-\\d{2}-\\d{2}"
                            : null
                        }
                        value={createdAt || ""}
                        onChange={(e) => setCreatedAt(e.target.value)}
                        required
                      />
                    </div>
                    <div className="form-group col-md-6">
                      <label>Durée</label>
                      <input
                        type="time"
                        title="Durée initiale de la rénion"
                        pattern={
                          navigator.userAgent.indexOf("Safari") >= 1 &&
                          navigator.userAgent.indexOf("Chrome") <= 0
                            ? "0[1-4]:\\d{2}"
                            : null
                        }
                        value={time || "00:00"}
                        className="form-control"
                        onChange={(e) => setTime(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label>Objet</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Objet de la réunion/conseil"
                      maxLength="250"
                      value={intitule || ""}
                      onChange={(e) => setIntitule(e.target.value)}
                      required
                    />
                  </div>
                  <hr />
                  {formateurs.map((f, i) => (
                    <div
                      className={
                        i + 1 < formateurs.length
                          ? "form-row align-items-center border-bottom border-info text-left"
                          : "form-row align-items-center text-left"
                      }
                      key={i}
                    >
                      <div className="form-group col-2">
                        <div className="custom-control custom-switch">
                          <input
                            className="custom-control-input"
                            type="checkbox"
                            id={"presCheckbox" + f.id}
                            checked={presents.includes(f.user["@id"]) || false}
                            disabled={user.role > 3}
                            onChange={(e) =>
                              presents.includes(f.user["@id"])
                                ? setPresents(
                                    presents.filter((e) => e !== f.user["@id"])
                                  )
                                : setPresents([...presents, f.user["@id"]])
                            }
                          />
                          <label
                            className="custom-control-label"
                            htmlFor={"presCheckbox" + f.id}
                          ></label>
                        </div>
                      </div>
                      <div className="col-6 d-flex my-3">
                        <div>
                          <span>
                            <strong>
                              {f.user.name} {f.user.firstName}
                            </strong>
                          </span>
                        </div>
                      </div>
                      <div className="form-group col-4 my-2 m-auto">
                        <input
                          type="time"
                          title="Temps de présence"
                          pattern={
                            navigator.userAgent.indexOf("Safari") >= 1 &&
                            navigator.userAgent.indexOf("Chrome") <= 0
                              ? "0[1-4]:\\d{2}"
                              : null
                          }
                          max={time}
                          value={formTimes[f.user["@id"]] || time || "00:00"}
                          className="form-control"
                          onChange={(e) => {
                            setFormTimes({
                              ...formTimes,
                              [f.user["@id"]]: e.target.value,
                            });
                          }}
                          disabled={!presents.includes(f.user["@id"])}
                          required
                        />
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
            >
              Fermer
            </button>
            <button
              type="button"
              className="btn btn-info"
              onClick={handleSubmit}
              disabled={!valid()}
            >
              Enregistrer
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditReunion;
