import React from "react";
import dateFormated from "../../tools/dateFormated";
import AbsElevePromoNoSync from "./AbsElevePromoNoSync";

const AbsPromoNoSync = ({
  cours,
  periode,
  duree,
  absences,
  absencesNocx,
  token,
  setMaj,
}) => {
  return (
    <div className="card my-2">
      <div className="card-header p-2 text-left">
        <div className="d-flex">
          <div className="badge badge-warning text-warning badge-pill align-self-center">
            I
          </div>
          <h5 className="text-left m-0 ml-2">{cours.NAME}</h5>
          <span className="text-info m-0 ml-2">
            (la promo n'est pas synchronisée - Rdv à la page "Administration -
            Sync Edusign")
          </span>
        </div>
        <small className="mr-4">
          {`${dateFormated(cours.START)} ${
            periode !== undefined && duree !== undefined
              ? `- ${periode} (${duree})`
              : ""
          }`}
        </small>
      </div>
      <div className="card-body text-left p-0">
        {absences.length === 0 ? (
          <div className="text-success m-3">Pas d'absents !</div>
        ) : (
          <ul className="list-group list-group-flush">
            {absences.map((absence) => (
              <AbsElevePromoNoSync
                absence={absence}
                absencesNocx={absencesNocx}
                token={token}
                cours={cours}
                periode={periode}
                duree={duree}
                setMaj={setMaj}
                key={absence._id}
              />
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default AbsPromoNoSync;
