import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ListFormations = ({
  formations,
  setselected,
  setselectedMatiere,
  setSelectedCoef,
  del,
}) => {
  return formations.map((formation, i) => {
    return (
      <div
        className="card shadow border-info col-md-4 p-0 m-3"
        style={{ maxWidth: "20rem" }}
        key={i}
      >
        <div className="card-header text-info d-flex justify-content-between">
          <h5 className="m-0">{formation.name}</h5>
          <div className="float-right">
            <button
              className="text-danger close"
              title="Supprimer"
              onClick={(e) => {
                if (window.confirm("Supprimer la formation ?"))
                  del(formation.id);
              }}
            >
              <FontAwesomeIcon icon="trash-alt" />
            </button>
            <button
              type="button"
              onClick={(e) =>
                setselected(JSON.parse(JSON.stringify(formation)))
              }
              className="text-info close mr-2 "
              data-toggle="modal"
              data-target="#Modal"
              title="modifier"
            >
              <FontAwesomeIcon icon="edit" />
            </button>
          </div>
        </div>
        <div className="card-body text-info px-3">
          <ul className="list-group list-group-flush text-left">
            {formation.matieres.map((el, i) => {
              let coef = formation.coefs.filter(
                (cf) => cf.matiere["@id"] === el["@id"]
              );
              return (
                <li
                  className={
                    coef[0] !== undefined
                      ? "list-group-item d-flex justify-content-between px-0"
                      : "list-group-item text-danger d-flex justify-content-between px-0"
                  }
                  key={i}
                >
                  <div>
                    {el.name}{" "}
                    <em>
                      (
                      {coef[0] !== undefined
                        ? "coef : " + coef[0].coefficient
                        : null}
                      )
                    </em>
                  </div>
                  <button
                    type="button"
                    onClick={(e) => {
                      setSelectedCoef(
                        coef[0] !== undefined
                          ? JSON.parse(JSON.stringify(coef[0]))
                          : null
                      );
                      setselected(JSON.parse(JSON.stringify(formation)));
                      setselectedMatiere(JSON.parse(JSON.stringify(el)));
                    }}
                    className="text-info close mr-2 "
                    data-toggle="modal"
                    data-target="#ModalCoef"
                    title="modifier"
                  >
                    <FontAwesomeIcon icon="edit" />
                  </button>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    );
  });
};

export default ListFormations;
