import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useState } from "react";
import { useEffect } from "react";
import AppContext from "../../../Context/AppContext";
import GetBddProvider from "../../../Providers/GetBddProvider";
import PostBddProvider from "../../../Providers/PostBddProvider";
import MsgAlert from "../../common/MsgAlert";
import Spinner from "../../common/Spinner";
import { autoCloseMsg } from "../../tools/messagesUtils";
import { triByName } from "../../tools/sortUtils";

const EditSuivi = ({ setMaj, listPromos }) => {
  const { url, user } = useContext(AppContext);

  const [suivi, setSuivi] = useState({
    createdAt: new Date().toISOString().split("T")[0],
    validate: false,
    evaluateur: user["@id"],
  });
  const [evaluateurs, setEvaluateurs] = useState(null);
  const [notesComp, setNotesComp] = useState([]);
  const [notesReal, setNotesReal] = useState([]);
  const [itemComp, setItemComp] = useState(null);
  const [itemReal, setItemReal] = useState(null);
  const [saveComp, setSaveComp] = useState(false);
  const [saveReal, setSaveReal] = useState(false);
  const [promos, setPromos] = useState(null);
  const [promo, setpromo] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [eleves, setEleves] = useState(null);
  const [elevesloading, setElevesloading] = useState(false);
  const [msg, setMsg] = useState(null);

  useEffect(()=>{
    loadEvaluateur()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  useEffect(() => {
    if (listPromos !== null && listPromos !== promos) {
      setPromos(listPromos);
      loadCompItems();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listPromos]);

  useEffect(() => {
    if (promo !== null) {
      setElevesloading(true);
      loadEleves();
      init();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promo]);

  useEffect(() => {
    if (saveComp && saveReal) {
      init();
      setLoaded(true);
      setMsg({
        txt: "Le suivi a bien été enregistré",
        type: "success",
      });
      autoCloseMsg(setMsg, 5000);
      setMaj(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveReal, saveComp]);

  const init = () => {
    setSuivi({
      validate: false,
      evaluateur: suivi.evaluateur,
      createdAt: suivi.createdAt,
      time: suivi.time,
      comment: null,
    });
    setNotesReal([]);
    setNotesComp([]);
    setSaveComp(false);
    setSaveReal(false);
  };

  const loadEleves = () => {
    if (promo !== "") {
      let uri = url + "api/eleves?user.actif=1&promos=" + promo;
      GetBddProvider(uri).then((res) => {
        setElevesloading(false);
        if (typeof res === "object") {
          setEleves(res["hydra:member"]);
        } else {
          setMsg({ txt: "Erreur de Chargement des élèves", type: "danger" });
          autoCloseMsg(setMsg, 5000);
        }
      });
    } else {
      setEleves(null);
      setElevesloading(false);
    }
  };

  const loadEvaluateur = () => {
    let uri = url + "api/users?roles=ROLE_PEDAGO&";
    let uri2 = url + "api/users?roles=ROLE_DIRECTION&";
    let uri3 = url + "api/users?roles=ROLE_REFERENT&";
    user.sites.forEach((site, i, sites) => {
      uri += sites.length > 1 ? "sites[]=" : "sites=";
      uri2 += sites.length > 1 ? "sites[]=" : "sites=";
      uri3 += sites.length > 1 ? "sites[]=" : "sites=";
      uri += site["@id"];
      uri2 += site["@id"];
      uri3 += site["@id"];
      uri += i + 1 < sites.length ? "&" : "";
      uri2 += i + 1 < sites.length ? "&" : "";
      uri3 += i + 1 < sites.length ? "&" : "";
    });
    let temp = [];
    GetBddProvider(uri).then((res) => {
      if (typeof res === "object") {
        temp = res["hydra:member"].filter(
          (e) => !e.roles.includes("ROLE_ELEVE")
        );
        GetBddProvider(uri2).then((res) => {
          if (typeof res === "object") {
            temp = [...temp, ...res["hydra:member"]];
            GetBddProvider(uri3).then((res) => {
              if (typeof res === "object") {
                setEvaluateurs(temp.concat(res["hydra:member"]).sort(triByName));
                setLoaded(true);
              } else {
                setLoaded(true);
                setMsg({
                  txt: "Erreur de chargement des évaluateurs SUIVI",
                  type: "danger",
                });
                autoCloseMsg(setMsg, 5000);
              }
            });
          } else {
            setLoaded(true);
            setMsg({
              txt: "Erreur de chargement des évaluateurs DIRECTION",
              type: "danger",
            });
            autoCloseMsg(setMsg, 5000);
          }
        });
      } else {
        setLoaded(true);
        setMsg({
          txt: "Erreur de chargement des évaluateurs PEDAGO",
          type: "danger",
        });
        autoCloseMsg(setMsg, 5000);
      }
    });
  };

  const loadCompItems = () => {
    let uri = url + "api/comportement_items";
    GetBddProvider(uri).then((res) => {
      if (typeof res === "object") {
        setItemComp(res["hydra:member"]);
        loadRealItems();
      } else {
        setLoaded(true);
        setMsg({
          txt: "Erreur de chargement des items comportement",
          type: "danger",
        });
        autoCloseMsg(setMsg, 5000);
      }
    });
  };

  const loadRealItems = () => {
    let uri = url + "api/realisation_items";
    GetBddProvider(uri).then((res) => {
      if (typeof res === "object") {
        setItemReal(res["hydra:member"]);
      } else {
        setLoaded(true);
        setMsg({
          txt: "Erreur de chargement des items réalisation",
          type: "danger",
        });
        autoCloseMsg(setMsg, 5000);
      }
    });
  };

  const validate = () => {
    return (
      suivi.createdAt &&
      suivi.time &&
      suivi.eleve &&
      suivi.evaluateur &&
      suivi.comment
    );
  };

  const handleSubmit = (e, validate) => {
    e.preventDefault();
    setSaveComp(false);
    setSaveReal(false);
    setLoaded(false);
    let tmp = { ...suivi, validate: validate };
    let uri = url + "api/suivis";
    PostBddProvider(uri, tmp).then((res) => {
      if (typeof res === "object" && res.id) {
        let iri = "api/suivis/" + res.id;
        let uriComp = url + "api/note_suivi_comps";
        let uriReal = url + "api/note_suivi_reals";
        if (Object.keys(notesComp).length === 0) {
          setSaveComp(true);
        }
        Object.values(notesComp).forEach(async (note, i) => {
          let temp = { ...note, suivi: iri };
          await PostBddProvider(uriComp, temp).then((res) => {
            if (typeof res === "object" && res.id) {
              if (i + 1 === Object.keys(notesComp).length) {
                setSaveComp(true);
              }
            } else {
              setMsg({
                txt:
                  "Erreur d'enregistrement : " + temp.itemComportement.slice(4),
                type: "danger",
              });
              autoCloseMsg(setMsg, 5000);
            }
          });
        });
        if (Object.keys(notesReal).length === 0) {
          setSaveReal(true);
        }
        Object.values(notesReal).forEach(async (note, i) => {
          let temp = { ...note, suivi: iri };
          await PostBddProvider(uriReal, temp).then((res) => {
            if (typeof res === "object" && res.id) {
              if (i + 1 === Object.keys(notesReal).length) {
                setSaveReal(true);
              }
            } else {
              setMsg({
                txt:
                  "Erreur d'enregistrement : " + temp.itemRealisation.slice(4),
                type: "danger",
              });
              autoCloseMsg(setMsg, 5000);
            }
          });
        });
      } else {
        setMsg({
          txt: "Erreur lors de l'enregistrement du suivi",
          type: "danger",
        });
        autoCloseMsg(setMsg, 5000);
      }
    });
  };

  return (
    <div
      className="modal fade"
      id="ModalAccomp"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header bg-info text-white">
            <h5 className="modal-title">
              <FontAwesomeIcon icon="edit" /> &nbsp;{" "}
              {suivi.id ? "Modifier" : "Ajouter"} un suivi
            </h5>
            <button
              type="button"
              className="close text-white"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            {msg !== null ? (
              <MsgAlert msg={msg.txt} type={msg.type} close={() => autoCloseMsg(setMsg)} />
            ) : null}
            {!loaded || promos === null ? (
              <div className="pb-5">
                <Spinner />
              </div>
            ) : (
              <React.Fragment>
                <form>
                  <div className="form-row">
                    <div className="form-group col-lg-6">
                      <label>
                        Date
                        {navigator.userAgent.indexOf("Safari") >= 1 &&
                        navigator.userAgent.indexOf("Chrome") <= 0 ? (
                          <em>
                            <small> (aaaa-mm-jj)</small>
                          </em>
                        ) : null}
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        placeholder={
                          navigator.userAgent.indexOf("Safari") >= 1 &&
                          navigator.userAgent.indexOf("Chrome") <= 0
                            ? "aaaa-mm-jj"
                            : null
                        }
                        pattern={
                          navigator.userAgent.indexOf("Safari") >= 1 &&
                          navigator.userAgent.indexOf("Chrome") <= 0
                            ? "\\d{4}-\\d{2}-\\d{2}"
                            : null
                        }
                        value={suivi.createdAt || ""}
                        onChange={(e) => {
                          setSuivi({
                            ...suivi,
                            createdAt: e.target.value,
                          });
                        }}
                        required
                      />
                    </div>
                    <div className="form-group col-lg-6">
                      <label>Durée</label>
                      <input
                        type="time"
                        title="Durée de l'entretien"
                        step="60"
                        pattern={
                          navigator.userAgent.indexOf("Safari") >= 1 &&
                          navigator.userAgent.indexOf("Chrome") <= 0
                            ? "0[1-4]:\\d{2}"
                            : null
                        }
                        value={suivi.time || "00:00"}
                        className={
                          suivi.time !== null &&
                          suivi.time !== undefined &&
                          suivi.time !== ""
                            ? "form-control"
                            : "is-invalid form-control"
                        }
                        onChange={(e) =>
                          setSuivi({
                            ...suivi,
                            time: e.target.value,
                          })
                        }
                        required
                      />
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group col-lg-4">
                      <label>Evaluateur</label>
                      <select
                        className="form-control"
                        value={suivi.evaluateur || ""}
                        onChange={(e) =>
                          setSuivi({ ...suivi, evaluateur: e.target.value })
                        }
                      >
                        <option value="">Sélectionnez un évaluateur</option>
                        {evaluateurs.filter(e=>e.actif).map((ev, i) => (
                          <option value={ev["@id"]} key={i}>
                            {ev.name} {ev.firstName}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="form-group col-lg-4">
                      <label>Promo</label>
                      <select
                        className="form-control"
                        value={promo || ""}
                        onChange={(e) => setpromo(e.target.value)}
                      >
                        <option value="">Sélectionnez une promo</option>
                        {promos.map((p, i) => (
                          <option value={p["@id"]} key={i}>
                            {p.site.name} {p.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="form-group col-lg-4">
                      <label>Elève</label>
                      <select
                        className="form-control"
                        value={suivi.eleve || ""}
                        onChange={(e) =>
                          setSuivi({ ...suivi, eleve: e.target.value })
                        }
                        disabled={eleves === null}
                      >
                        <option value="">
                          {elevesloading
                            ? "Chargement en cours ..."
                            : eleves === null
                            ? "Veuillez sélectionner une promo"
                            : eleves.length < 1
                            ? "Pas d'élèves"
                            : "Selectionnez un élève"}
                        </option>
                        {eleves === null
                          ? null
                          : eleves.map((el, i) => (
                              <option value={el["@id"]} key={i}>
                                {el.user.name} {el.user.firstName}
                              </option>
                            ))}
                      </select>
                    </div>
                  </div>
                  <div className="form-group">
                    <table className="mt-3 table table-sm table-responsive-md">
                      <thead>
                        <tr>
                          <th></th>
                          <th>
                            Insuffisant <br />
                            <span className="text-danger">
                              <FontAwesomeIcon icon="minus" />{" "}
                              <FontAwesomeIcon icon="minus" />
                            </span>
                          </th>
                          <th>
                            A améliorer <br />
                            <span className="text-warning">
                              <FontAwesomeIcon icon="minus" />
                            </span>
                          </th>
                          <th>
                            Satisfaisant <br />
                            <span className="text-green-light">
                              <FontAwesomeIcon icon="plus" />
                            </span>
                          </th>
                          <th>
                            Très satisfaisant <br />
                            <span className="text-success">
                              <FontAwesomeIcon icon="plus" />{" "}
                              <FontAwesomeIcon icon="plus" />
                            </span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th colSpan="5">Comportement Général</th>
                        </tr>
                        {itemComp.map((item, i) => {
                          let tabI = [1, 2, 3, 4];
                          return (
                            <tr key={i}>
                              <th className="text-left">{item.name}</th>
                              {tabI.map((t, it) => (
                                <th key={it}>
                                  <div className="form-check form-check-inline">
                                    <input
                                      type="radio"
                                      name={item["@id"]}
                                      value={t}
                                      onChange={(e) => {
                                        setNotesComp({
                                          ...notesComp,
                                          [i]: {
                                            itemComportement: item["@id"],
                                            note: parseInt(e.target.value),
                                          },
                                        });
                                      }}
                                      checked={
                                        notesComp[i]
                                          ? notesComp[i].note === t
                                          : false
                                      }
                                    />
                                  </div>
                                </th>
                              ))}
                            </tr>
                          );
                        })}

                        <tr>
                          <th colSpan="5">Réalisation du travail</th>
                        </tr>
                        {itemReal.map((itemR, i) => {
                          let tabI = [1, 2, 3, 4];
                          return (
                            <tr key={i}>
                              <th className="text-left">{itemR.name}</th>
                              {tabI.map((t, it) => (
                                <th key={it}>
                                  <div className="form-check form-check-inline">
                                    <input
                                      type="radio"
                                      name={itemR["@id"]}
                                      value={t}
                                      onChange={(e) => {
                                        setNotesReal({
                                          ...notesReal,
                                          [i]: {
                                            itemRealisation: itemR["@id"],
                                            note: parseInt(e.target.value),
                                          },
                                        });
                                      }}
                                      checked={
                                        notesReal[i]
                                          ? notesReal[i].note === t
                                          : false
                                      }
                                    />
                                  </div>
                                </th>
                              ))}
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                  <div className="form-group">
                    <label>Commentaires</label>
                    <textarea
                      className="form-control"
                      type="textarea"
                      rows="5"
                      placeholder={"Veuillez saisir un commentaire"}
                      value={suivi.comment || ""}
                      onChange={(e) =>
                        setSuivi({ ...suivi, comment: e.target.value })
                      }
                    />
                  </div>
                </form>
              </React.Fragment>
            )}
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
            >
              Fermer
            </button>
            <button
              type="submit"
              className="btn btn-info"
              onClick={(e) => {
                handleSubmit(e, true);
              }}
              disabled={!validate()}
            >
              CoValidation
            </button>
            <button
              type="submit"
              className="btn btn-info"
              onClick={(e) => {
                handleSubmit(e, false);
              }}
              disabled={!validate()}
            >
              A valider par le tuteur
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditSuivi;
