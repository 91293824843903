import React, { useContext, useEffect, useState } from "react";
import AppContext from "../../../Context/AppContext";
import NotesContext from "../../../Context/NotesContext";
import GetBddProvider from "../../../Providers/GetBddProvider";
import MsgAlert from "../../common/MsgAlert";
import Spinner from "../../common/Spinner";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import $ from "jquery";
import useDelete from "../../../hooks/useDelete";
import dateFormated from "../../tools/dateFormated";
import { autoCloseMsg } from "../../tools/messagesUtils";
import {
  triByCreatedAt,
  triByName,
  triByUserName,
} from "../../tools/sortUtils";
import { ListGestNotesDetail } from "./ListGestNotesDetail";

const ListGestNotes = ({ promos, site }) => {
  const {
    promoLoaded,
    formateur,
    matieres,
    semestreSelected,
    setMaj,
    setGlobalMsg,
    autoCloseGlobal,
    setPromoLoaded,
    setMatieres,
    setMatiere,
    setSemestreSelected,
    setEleveSelected,
    setControleSelected,
  } = useContext(NotesContext);

  const { url, user } = useContext(AppContext);
  const [msg, setMsg] = useState(null);
  const [mat, setMat] = useState(null);
  const [promo, setPromo] = useState(null);
  const [eleves, setEleves] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [notes, setNotes] = useState(null);
  const [controle, setControle] = useState([]);
  const [semestres, setSemestres] = useState(null);

  const DelBDD = useDelete();

  $(function () {
    $('[data-tooltip="tooltip"]').tooltip();
  });

  useEffect(() => {
    if (promo !== promoLoaded) {
      setLoaded(false);
      setPromo(promoLoaded);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promoLoaded]);

  useEffect(() => {
    if (promo !== null) {
      if (promo.site["@id"] === site["@id"]) {
        let temp = promo.semestres.filter((sem) => sem.actif);
        setSemestres(promo.semestres);
        setSemestreSelected(
          temp.length > 0
            ? temp[0]
            : promo.semestres[promo.semestres.length - 1]
        );
        load();
      } else {
        init();
        setLoaded(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promo]);

  useEffect(() => {
    setMatiere(mat);
    if (semestreSelected !== null && mat !== null) {
      setNotes(null);
      setControle([]);
    }
    loadNote();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mat, semestreSelected]);

  const init = () => {
    setNotes(null);
    setMatieres(null);
    setMat(null);
    setEleveSelected(null);
    setControle([]);
  };

  const loadEleves = () => {
    GetBddProvider(
      url + "api/eleves?user.actif=true&promos=" + promoLoaded["@id"]
    ).then((res) => {
      if (typeof res === "object") {
        setEleves(res["hydra:member"]);
        setLoaded(true);
      } else {
        setMsg({ txt: "Erreur de chargement des élèves", type: "danger" });
        autoCloseMsg(setMsg, 5000);
        setLoaded(true);
      }
    });
  };

  const loadNote = async () => {
    if (mat !== null && semestreSelected !== null) {
      let uri = `${url}api/notes?semestre=${semestreSelected["@id"]}&matiere=${mat}&exists[controle]=false`;
      let uri2 = `${url}api/controles?semestre=${semestreSelected["@id"]}&matiere=${mat}`;
      let noteResult = await GetBddProvider(uri).then((res) => {
        if (typeof res === "object") {
          return res["hydra:member"];
        } else {
          setMsg({ txt: "Erreur de chargement des notes", type: "danger" });
          autoCloseMsg(setMsg, 5000);
          return null;
        }
      });
      let controleResult = await GetBddProvider(uri2).then((res) => {
        if (typeof res === "object") {
          return res["hydra:member"];
        } else {
          setMsg({ txt: "Erreur de chargement des controles", type: "danger" });
          autoCloseMsg(setMsg, 5000);
          return [];
        }
      });
      setNotes(() => noteResult.sort(triByCreatedAt));
      setControle(() => controleResult.sort(triByCreatedAt));
    }
  };

  const delNote = (noteId, controle = false) => {
    let uri = url + noteId.slice(1);
    DelBDD(uri).then((res) => {
      !controle && setLoaded(true);
      if (res === 204) {
        if (!controle) {
          setGlobalMsg({
            txt: "La note a bien été supprimée",
            type: "success",
          });
          autoCloseGlobal(3000);
          setMaj(true);
        }
      } else {
        setGlobalMsg({
          txt: `Erreur de suppression de la note ${noteId}`,
          type: "danger",
        });
        autoCloseGlobal(3000);
      }
    });
  };

  const delControle = async (controle) => {
    for (const note of controle.notes) {
      await delNote(note["@id"], true);
    }
    let uri = url + controle["@id"].slice(1);
    DelBDD(uri).then((res) => {
      setLoaded(true);
      if (res === 204) {
        setGlobalMsg({
          txt: "Le controle a bien été supprimé",
          type: "success",
        });
        autoCloseGlobal(3000);
        setMaj(true);
      } else {
        setGlobalMsg({
          txt: "Erreur de suppression du controle",
          type: "danger",
        });
        autoCloseGlobal(3000);
      }
    });
  };

  const load = () => {
    let uri =
      url + "api/matieres?hidden=0&formations=" + promo.formation["@id"];
    GetBddProvider(uri).then((res) => {
      if (typeof res === "object") {
        setMatieres(res["hydra:member"].sort(triByName));
        loadEleves();
      } else {
        setMsg({ txt: "Erreur de chargement des matières", type: "danger" });
        autoCloseMsg(setMsg, 5000);
        setLoaded(true);
      }
    });
  };

  return (
    <div className="mx-4 ">
      <h3>{site.name}</h3>
      {msg !== null ? (
        <MsgAlert
          msg={msg.txt}
          type={msg.type}
          close={() => autoCloseMsg(setMsg)}
        />
      ) : null}
      <ul className="nav d-flex flex-wrap">
        {promos.map((p, i) => (
          <li className="nav-item" key={i}>
            <span
              className={
                promo !== null && p["@id"] === promo["@id"]
                  ? "btn btn-info"
                  : "btn btn-outline-info"
              }
              style={{ cursor: "pointer" }}
              onClick={() => {
                init();
                setPromoLoaded(p);
              }}
            >
              {p.name}
            </span>
          </li>
        ))}
      </ul>
      {!loaded ? (
        <div className="bg-white border-right border-left border-bottom py-1">
          <Spinner />
        </div>
      ) : (
        <div className="bg-white border-right border-left animFadeIn border-bottom p-0 m-0">
          {matieres !== null &&
          semestres !== null &&
          promoLoaded.site["@id"] === site["@id"] ? (
            semestreSelected === undefined ? (
              <div>
                <MsgAlert
                  msg="Les semestres de la promo n'ont pas été activés"
                  type="danger"
                  close={null}
                />
              </div>
            ) : (
              <div className="w-100 overflow-auto">
                <div className="form-group row mx-0 py-2">
                  <label className="col-md-2 col-form-label">Matière</label>
                  <select
                    className="form-control col-md-6"
                    value={mat !== null ? mat : ""}
                    onChange={(e) => {
                      setMat(e.target.value);
                    }}
                  >
                    <option value="" disabled hidden>
                      Sélectionnez une matière
                    </option>
                    {matieres.map((mat, i) => (
                      <option value={mat["@id"]} key={i}>
                        {mat.name}
                      </option>
                    ))}
                  </select>
                  <div className="col-md-4">
                    {semestres.length > 1 ? (
                      <select
                        className="form-control"
                        value={semestreSelected["@id"] || ""}
                        onChange={(e) =>
                          setSemestreSelected(
                            semestres.filter(
                              (sem) => sem["@id"] === e.target.value
                            )[0]
                          )
                        }
                      >
                        <option value="" disabled hidden>
                          Sélectionnez un semestre
                        </option>
                        {semestres.map((s, i) => (
                          <option value={s["@id"]} key={i}>
                            {s.name}
                          </option>
                        ))}
                      </select>
                    ) : (
                      <span>
                        <strong>
                          <em>{semestreSelected.name}</em>
                        </strong>
                      </span>
                    )}
                  </div>
                </div>
                <table className="table table-responsive-xl table-sm table-bordered text-left">
                  <thead>
                    <tr>
                      <th>Etudiants</th>
                      {controle.map((c) => {
                        let support = false;
                        if (user.role <= 3) {
                          support = true;
                        } else if (c.notes.length > 0) {
                          support =
                            c.notes[0].formateur["@id"] === formateur["@id"];
                        } else if (
                          semestres.filter(
                            (s) => s["@id"] === semestreSelected["@id"]
                          )[0].actif
                        ) {
                          support =
                            formateur.matieres.filter((m) => m["@id"] === mat)
                              .length > 0;
                        }
                        return (
                          <th
                            key={c["@id"]}
                            title={`<u><em>${dateFormated(
                              c.createdAt
                            )}</em></u><br>${c.name}`}
                            data-tooltip="tooltip"
                            data-html="true"
                            data-placement="top"
                            className="text-nowrap"
                          >
                            {c.name.substring(0, 4)}...
                            {support && (
                              <sup>
                                <FontAwesomeIcon
                                  style={{ cursor: "pointer" }}
                                  title="Supprimer"
                                  className="text-danger"
                                  onClick={() => {
                                    if (
                                      window.confirm("Supprimer le controle ?")
                                    ) {
                                      delControle(c);
                                    }
                                  }}
                                  icon="times-circle"
                                />
                              </sup>
                            )}
                          </th>
                        );
                      })}
                      <th className="w-100"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {eleves.sort(triByUserName).map((el, i) =>
                      !el.user.actif ? null : (
                        <tr key={i}>
                          <th className="text-nowrap">
                            {el.user.name} {el.user.firstName}
                          </th>
                          {controle.map((c) => (
                            <td key={c["@id"]} className="h5 text-nowrap">
                              <ListGestNotesDetail
                                semestres={semestres}
                                controle={c}
                                mat={mat}
                                notes={c.notes.filter(
                                  (n) =>
                                    n.matiere === mat &&
                                    n.semestre === semestreSelected["@id"] &&
                                    n.eleve === el["@id"]
                                )}
                                el={el}
                                delNote={delNote}
                              />
                            </td>
                          ))}
                          <td className="h5 text-nowrap">
                            {mat === null ? null : notes !== null ? (
                              <div className="text-nowrap">
                                <ListGestNotesDetail
                                  semestres={semestres}
                                  notes={notes.filter(
                                    (n) =>
                                      n.matiere === mat &&
                                      n.semestre === semestreSelected["@id"] &&
                                      n.eleve === el["@id"]
                                  )}
                                  el={el}
                                  delNote={delNote}
                                />
                                {user.role <= 3 ||
                                (formateur.matieres.filter(
                                  (m) => m["@id"] === mat
                                ).length > 0 &&
                                  semestres.filter(
                                    (s) => s["@id"] === semestreSelected["@id"]
                                  )[0].actif) ? (
                                  <FontAwesomeIcon
                                    className="ml-2 text-primary"
                                    style={{ cursor: "pointer" }}
                                    data-toggle="modal"
                                    data-target="#NoteModal"
                                    onClick={() => {
                                      setControleSelected(undefined);
                                      setEleveSelected(
                                        JSON.parse(JSON.stringify(el))
                                      );
                                    }}
                                    icon="plus-circle"
                                  />
                                ) : null}
                              </div>
                            ) : (
                              <small>Chargement en cours ...</small>
                            )}
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              </div>
            )
          ) : null}
        </div>
      )}
      <hr />
    </div>
  );
};

export default ListGestNotes;
