export function getconfig() {
  return {
    appName: process.env.REACT_APP_NAME,
    url: process.env.REACT_APP_API_URL,
    urlPdf: process.env.REACT_APP_PDF_URL,
    su: process.env.REACT_APP_SU.split(", "),
    connectors: {
      V1: process.env.REACT_APP_EDUSIGN_CONNECTORS_V1,
      V2: process.env.REACT_APP_EDUSIGN_CONNECTORS_V2,
    },
    betaUsers: process.env.REACT_APP_BETA_USERS.split(", "),
    urlEdusign: {
      GROUPS: process.env.REACT_APP_EDUSIGN_URL_GROUPS,
      SCHOOL: process.env.REACT_APP_EDUSIGN_URL_SCHOOL,
      STUDENTS: process.env.REACT_APP_EDUSIGN_URL_STUDENTS,
      COURSES: process.env.REACT_APP_EDUSIGN_URL_COURSES,
      ABSENCES: process.env.REACT_APP_EDUSIGN_URL_ABSENCES,
    },
    galiaUser: process.env.REACT_APP_GALIA_USER,
  };
}
