import { useContext, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import AppContext from "../../../Context/AppContext";
import GetBddProvider from "../../../Providers/GetBddProvider";
import MsgAlert from "../../common/MsgAlert";
import Spinner from "../../common/Spinner";

const ListElevesTuteur = ({ eleves }) => {
  const { url } = useContext(AppContext);

  const [list, setList] = useState([]);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const load = async () => {
    let tmpList = [];
    for (const el of eleves) {
      await GetBddProvider(url + el["@id"].slice(1)).then((result) => {
        if (typeof result === "object" && result.id) {
          let tmp = result.promos.filter((p) => p.actif);
          if (tmp.length > 0) {
            tmpList.push(el);
          }
        }
      });
    }
    setList(tmpList);
    setLoaded(true);
  };

  return loaded ? (
    list.length > 0 ? (
      list.map((el, i, tab) => (
        <NavLink
          to={{
            pathname: "/eleve/",
            state: { id: el.id, list: tab },
          }}
          className="col-md-4 p-5 m-4 btn btn-outline-info"
          key={i}
        >
          <h3>
            {el.user.name} {el.user.firstName}
          </h3>
        </NavLink>
      ))
    ) : (
      <div className="col-12">
        <MsgAlert msg="Pas d'élève actif rattaché !" type="warning" />
      </div>
    )
  ) : (
    <Spinner />
  );
};

export default ListElevesTuteur;
