import React, { useContext, useState } from "react";
import AppContext from "../../Context/AppContext";
import GetBddProvider from "../../Providers/GetBddProvider";
import ListPromos from "../contents/lists/ListPromos";
import Spinner from "../common/Spinner";
import Layout from "../common/Layout";
import EditPromo from "../contents/edits/EditPromo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import { triByName } from "../tools/sortUtils";

const Promos = () => {
  const [loaded, setLoaded] = useState(false);
  const [sites, setSites] = useState(null);
  const [siteSelected, setSiteSelected] = useState(null);
  const [statusSelected, setStatusSelected] = useState(true);
  const [maj, setMaj] = useState(false);
  const [selected, setSelected] = useState({ actif: true });

  const { user, url } = useContext(AppContext);

  useEffect(() => {
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (maj) {
      setLoaded(false);
      setMaj(false);
      load();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maj]);

  const load = () => {
    if (user.role > 1) {
      let tmp = user.sites.sort(triByName);
      setSites(tmp);
      siteSelected === null && setSiteSelected(tmp[0]["@id"]);
      setLoaded(true);
    } else {
      let uri = url + "api/sites";
      GetBddProvider(uri).then((res) => {
        if (typeof res === "object") {
          let tmp = res["hydra:member"].sort(triByName);
          setSites(tmp);
          siteSelected === null && setSiteSelected(tmp[0]["@id"]);
          setLoaded(true);
        }
      });
    }
  };

  return Layout(
    <div className="mt-4">
      <span className="d-flex flex-wrap flex-column flex-xl-row justify-content-center justify-content-xl-between m-3">
        <h2 className="m-0">Liste des promos</h2>
        {sites === null ? null : (
          <div className="mr-2">
            <select
              className="form-control"
              value={siteSelected || ""}
              onChange={(e) => setSiteSelected(e.target.value)}
            >
              <option value="" disabled hidden>
                Sites
              </option>
              {sites !== null
                ? sites.map((s, i) => (
                    <option value={s["@id"]} key={i}>
                      {s.name}
                    </option>
                  ))
                : null}
            </select>
          </div>
        )}
        {user.role <= 3 ? (
          <div className="align-self-center mt-1 mt-xl-0">
            <button
              type="button"
              className="btn btn-info"
              onClick={(e) => setSelected({ actif: true, site: siteSelected })}
              data-toggle="modal"
              data-target="#Modal"
            >
              <FontAwesomeIcon icon="plus-circle" /> &nbsp; Ajouter
            </button>
          </div>
        ) : null}
      </span>
      <EditPromo maj={setMaj} sites={sites} selected={selected} />
      <div className="mt-4">
        {!loaded ? (
          <Spinner />
        ) : (
          <ListPromos
            sites={sites}
            setselected={setSelected}
            statusSelected={statusSelected}
            setStatusSelected={setStatusSelected}
            siteSelected={siteSelected}
          />
        )}
      </div>
    </div>
  );
};

export default Promos;
