import React, { useContext, useEffect, useState } from "react";
import AppContext from "../../../Context/AppContext";
import GetBddProvider from "../../../Providers/GetBddProvider";
import PostBddProvider from "../../../Providers/PostBddProvider";
import UpdateBddProvider from "../../../Providers/UpdateBddProvider";
import SmallSpinner from "../../common/SmallSpinner";
import AbsEleveNoSync from "./AbsEleveNoSync";

const AbsElevePromoNoSync = ({
  absence,
  absencesNocx,
  token,
  cours,
  periode,
  duree,
  setMaj,
}) => {
  const _DATE_COURS = cours.START.split("T")[0];
  const _MOTIFS = [
    "Retenu(e) en entreprise",
    "Arrêt de travail",
    "Motif personnel",
    "Congé payé",
    "Examen",
    "Cours non obligatoire",
    "COVID",
    "Accident du travail",
    "Problème de connexion",
  ];
  const _MOTIFEDUSIGN = {
    1: _MOTIFS[1],
    2: _MOTIFS[2],
    3: _MOTIFS[2],
    4: _MOTIFS[2],
    5: _MOTIFS[2],
    6: _MOTIFS[0],
    7: _MOTIFS[2],
  };

  const [eleve, setEleve] = useState(null);
  const [promos, setPromos] = useState(null);
  const [promoSelect, setPromoSelect] = useState(null);
  const [semestreSelect, setSemestreSelect] = useState(null);
  const [load, setLoad] = useState(false);
  const [verif, setVerif] = useState(null);
  const [saved, setSaved] = useState(true);
  const [msg, setMsg] = useState(null);

  const [justify, setJustify] = useState(false);
  const [prevent, setPrevent] = useState(false);
  const [comment, setComment] = useState("");
  const [motif, setMotif] = useState("");

  const { url, user, urlEdusign } = useContext(AppContext);

  useEffect(() => {
    setComment(absence.comment || "");
    if (absence.absenceId) {
      loadAbsJust();
    }
    loadEleve();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (eleve !== null && eleve !== undefined && absencesNocx !== null) {
      verifExist();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eleve, absencesNocx]);

  useEffect(() => {
    if (verif !== null && promos !== null) {
      setLoad(true);
      let selectedTmp = promos.filter((p) => p.actif === true);
      if (selectedTmp.length > 0) {
        let promoTmp = selectedTmp[0];
        setPromoSelect(promoTmp["@id"]);
        let tmpSmestres = promoTmp.semestres.filter((s) => s.actif === true);
        if (tmpSmestres.length > 0) {
          setSemestreSelect(tmpSmestres[0]["@id"]);
        } else {
          setMsg({
            txt: `pas de semestre actif sur la promo ${promoTmp.name}`,
            type: `danger`,
          });
        }
      } else {
        setMsg({
          txt: `${eleve.user.name} ${
            eleve.user.firstName
          } promos inactive ! (${promos.map((p) => p.name)})`,
          type: `danger`,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verif, promos]);

  const verifExist = () => {
    setVerif(
      absencesNocx.filter(
        (a) =>
          a.eleve["@id"] === eleve["@id"] &&
          a.moment === periode &&
          a.createdAt.split("T")[0] === _DATE_COURS
      )
    );
  };

  const loadEleve = () => {
    let uri = url + "api/eleves?IdEdusign=" + absence.studentId;
    GetBddProvider(uri).then((res) => {
      if (typeof res === "object") {
        let result = res["hydra:member"];
        setEleve(result[0]);
        if (result.length > 0) {
          loadPromos(result[0].promos);
        } else {
          setLoad(true)
        }
      }
    });
  };

  const loadPromos = async (listPromos) => {
    let tmp = [];
    for (const p of listPromos) {
      let result = await GetBddProvider(url + p.slice(1));
      if (typeof result === "object" && result.id) {
        tmp.push(result);
      }
    }
    setPromos(tmp);
  };

  const loadAbsJust = async () => {
    let uri = urlEdusign.ABSENCES + absence.absenceId;
    await GetBddProvider(uri, token).then((res) => {
      if (typeof res === "object") {
        let result = res.result;
        if (result !== undefined) {
          if (result.TYPE) {
            let tempMotif = _MOTIFEDUSIGN[result.TYPE];
            setJustify(true);
            setMotif(tempMotif);
          }
          setComment(result.COMMENT);
        }
      } else {
        // Message d'erreur
      }
    });
  };

  const updateAbs = (abs) => {
    setSaved(false);
    let data = { IdEdusign: cours.ID, time: duree, user: user["@id"] };
    let uri = url + abs["@id"].slice(1);
    UpdateBddProvider(uri, data).then((res) => {
      setSaved(true);
      if (typeof res === "object" && res.id) {
        setMaj(true);
      } else {
        // message erreur
      }
    });
  };

  const saveAbs = () => {
    setSaved(false);
    let uri = url + "api/absences";
    let data = {
      IdEdusign: cours.ID,
      createdAt: _DATE_COURS,
      eleve: eleve["@id"],
      motif: motif,
      semestre: semestreSelect,
      promo: promoSelect,
      time: duree,
      moment: periode,
      justify: justify,
      prev: prevent,
      comment: comment,
      rattrapage: false,
      user: user["@id"],
    };
    let uriTest =
      url +
      "api/absences?eleve=" +
      data.eleve +
      "&createdAt[after]=" +
      data.createdAt +
      "&createdAt[before]=" +
      data.createdAt +
      "&moment=" +
      data.moment +
      "&rattrapage=" +
      data.rattrapage;

    GetBddProvider(uriTest).then(async (res) => {
      if (res["hydra:member"].length !== 0) {
        setSaved(true);
        setMaj(true);
      } else {
        PostBddProvider(uri, data).then((res) => {
          setSaved(true);
          if (typeof res === "object" && res.id) {
            setMaj(true);
          } else {
            // mesage d'erreur
          }
        });
      }
    });
  };

  if (msg !== null) {
    return (
      <li className={`list-group-item list-group-item-${msg.type}`}>{msg.txt}</li>
    );
  }
  // il faut récupérer l'élève si ok absElevesEdusign si non absEleveNoSync
  // si eleve est undefined afficher AbsEleveNoSync
  return (
    <>
      {!load && (
        <li className="list-group-item">
          <SmallSpinner />
        </li>
      )}
      {load && eleve === undefined && (
        <AbsEleveNoSync student={absence} token={token} />
      )}
      {load &&
        eleve !== undefined &&
        (verif.length > 0 ? (
          verif[0].IdEdusign ? (
            <li className="list-group-item list-group-item-success ">
              {eleve.user.name} {eleve.user.firstName} -{" "}
              {verif[0].motif === "" ? "Pas de motif" : verif[0].motif} -
              L'absence est synchronisée !
            </li>
          ) : (
            <li className="list-group-item text-warning ">
              <button
                className="btn btn-sm rounded-pill btn-outline-info mr-4"
                onClick={() => updateAbs(verif[0])}
                disabled={!saved}
              >
                {saved ? "Synchroniser l'absence" : <SmallSpinner />}
              </button>
              {eleve.user.name} {eleve.user.firstName} -{" "}
              {verif[0].motif === "" ? "Pas de motif" : verif[0].motif}
            </li>
          )
        ) : (
          <li className="list-group-item">
            <div>
              <label className="col-form-label">
                {eleve.user.name} {eleve.user.firstName}
              </label>
            </div>
            <div className=" form-inline d-flex">
              <div className="form-group mr-1">
                <select
                  value={promoSelect}
                  className="form-control rounded-pill"
                  onChange={(e) => {
                    setPromoSelect(e.target.value);
                    setSemestreSelect(null);
                  }}
                >
                  <option value="" disabled>
                    Choisir une promo
                  </option>
                  {promos
                    .filter((p) => p.actif === true)
                    .map((p) => (
                      <option value={p["@id"]} key={p["@id"]}>
                        {p.name}
                      </option>
                    ))}
                </select>
              </div>
              <div className="form-group mr-1">
                <select
                  value={semestreSelect}
                  className="form-control rounded-pill"
                  onChange={(e) => setSemestreSelect(e.target.value)}
                >
                  <option value="" disabled>
                    Choisir un semestre
                  </option>
                  {promos
                    .filter((p) => p["@id"] === promoSelect)[0]
                    .semestres.map((s) => (
                      <option value={s["@id"]} key={s["@id"]}>
                        {s.name}
                      </option>
                    ))}
                </select>
              </div>
              <div className="form-group mx-1">
                <select
                  value={motif}
                  className="form-control rounded-pill"
                  onChange={(e) => setMotif(e.target.value)}
                >
                  <option value="">Pas de motif</option>
                  {_MOTIFS.map((m, i) => (
                    <option value={m} key={i}>
                      {m}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group mx-1">
                <input
                  className="form-control rounded-pill"
                  type="text"
                  placeholder="Commentaire"
                  value={comment || ""}
                  onChange={(e) => setComment(e.target.value)}
                />
              </div>
            </div>
            <div className=" form-inline d-flex my-2">
              <div className="form-group mx-1">
                <div className="form-check">
                  <input
                    id={eleve["@id"] + "-" + cours.ID + "-justify"}
                    type="checkbox"
                    className="form-check-input"
                    checked={justify}
                    onChange={() => setJustify(!justify)}
                  />
                  <label htmlFor={eleve["@id"] + "-" + cours.ID + "-justify"}>
                    Justifiée
                  </label>
                </div>
              </div>
              <div className="form-group ml-1 mr-3">
                <div className="form-check">
                  <input
                    id={eleve["@id"] + "-" + cours.ID + "-prevent"}
                    type="checkbox"
                    className="form-check-input"
                    checked={prevent}
                    onChange={() => setPrevent(!prevent)}
                  />
                  <label htmlFor={eleve["@id"] + "-" + cours.ID + "-prevent"}>
                    Prévenue
                  </label>
                </div>
              </div>
            </div>
            <div className=" form-inline d-flex">
              <div>
                <button
                  className="btn btn-sm rounded-pill btn-outline-info"
                  disabled={semestreSelect === "" || !saved}
                  onClick={() => saveAbs()}
                >
                  {saved ? "Enregistrer" : <SmallSpinner />}
                </button>
              </div>
            </div>
          </li>
        ))}
    </>
  );
};

export default AbsElevePromoNoSync;
