import React, { useContext, useState } from "react";
import AppContext from "../../../Context/AppContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Spinner from "../../common/Spinner";
import MsgAlert from "../../common/MsgAlert";
import PostBddProvider from "../../../Providers/PostBddProvider";
import GetBddProvider from "../../../Providers/GetBddProvider";
import { useEffect } from "react";
import UpdateBddProvider from "../../../Providers/UpdateBddProvider";
import { autoCloseMsg } from "../../tools/messagesUtils";

const EditFormation = ({ maj, selected }) => {
  const { url } = useContext(AppContext);
  const [save, setsave] = useState(false);
  const [msg, setMsg] = useState(null);
  const [formation, setformation] = useState(selected);
  const [matieres, setmatieres] = useState(null);

  const load = () => {
    let uri = url + "api/matieres";
    GetBddProvider(uri).then((res) => {
      if (typeof res === "object" && res["hydra:member"]) {
        setmatieres(res["hydra:member"]);
        setsave(true);
      }
    });
  };

  const ChangeMatieres = (matiere) => {
    if (formation.matieres === null) {
      setformation({
        ...formation,
        matieres: [matiere],
      });
    } else if (formation.matieres.includes(matiere)) {
      setformation({
        ...formation,
        matieres: formation.matieres.filter((mat) => mat !== matiere),
      });
    } else {
      setformation({
        ...formation,
        matieres: [...formation.matieres, matiere],
      });
    }
  };

  useEffect(() => {
    if (formation["@id"] !== selected["@id"]) {
      let temp = JSON.parse(JSON.stringify(selected))
      temp.coefs.forEach((c, i) => {
        temp.coefs[i] = c["@id"]
      })
      setformation(temp);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  useEffect(() => {
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    setsave(false);
    if (formation.id) {
      UpdateBddProvider(url + "api/formations/" + formation.id, formation).then(
        (res) => {
          setsave(true);
          if (typeof res === "object" && res.id) {
            setMsg({
              text: "La formation a bien été mise à jour",
              type: "success",
            });
            maj(true);
            autoCloseMsg(setMsg, 5000);
          } else {
            setMsg({ text: "erreur", type: "danger" });
            autoCloseMsg(setMsg, 5000);
          }
        }
      );
    } else {
      PostBddProvider(url + "api/formations", formation).then((res) => {
        setsave(true);
        if (typeof res === "object" && res.id) {
          setMsg({
            text: "La formation a bien été créée",
            type: "success",
          });
          setformation({ matieres: null });
          maj(true);
          autoCloseMsg(setMsg, 5000);
        } else {
          setMsg({ text: "erreur", type: "danger" });
          autoCloseMsg(setMsg, 5000);
        }
      });
    }
  };

  return (
    <div
      className="modal fade"
      id="Modal"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <form>
            <div className="modal-header bg-info text-white">
              <h5 className="modal-title">
                <FontAwesomeIcon icon="edit" /> &nbsp;{" "}
                {formation.id ? "Modifier" : "Ajouter"} une formation
              </h5>
              <button
                type="button"
                className="close text-white"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            {!save ? (
              <div className="pb-5 modal-body text-info">
                <Spinner />
              </div>
            ) : (
                <div className="modal-body">
                  {msg !== null ? (
                    <div className="mx-4">
                      <MsgAlert
                        msg={msg.text}
                        type={msg.type}
                        close={()=>autoCloseMsg(setMsg)}
                      />
                    </div>
                  ) : null}
                  <div className="form-group">
                    <label htmlFor="name">Nom de la formation</label>
                    <input
                      className="form-control"
                      type="text"
                      name="name"
                      autoComplete="off"
                      value={formation.name || ""}
                      placeholder="Nom"
                      onChange={(e) =>
                        setformation({
                          ...formation,
                          name: e.target.value,
                        })
                      }
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label>Nombre de semestres</label>
                    <select
                      className="form-control"
                      value={
                        formation.nbreSemestres || ""
                      }
                      onChange={(e) => {
                        setformation({ ...formation, nbreSemestres: parseInt(e.target.value) })
                      }}
                      required
                    >
                      <option value="" disabled>
                        Nombre de semestre
                    </option>
                      <option value="1">1 semestre</option>
                      <option value="2">2 semestres</option>
                      <option value="4">4 semestres</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <label>Matières</label>
                    <div className="form-group form-check d-flex justify-content-between flex-wrap text-left">
                      {matieres === null
                        ? null
                        : matieres.map((matiere, i) => {
                          if (formation.matieres !== null) {
                            let n = formation.matieres.findIndex(
                              (el) => el["@id"] === matiere["@id"]
                            );
                            if (n > -1) {
                              formation.matieres[n] = matiere["@id"];
                            }
                          }
                          return (
                            <div key={i} className="col-md-6">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                value={matiere["@id"]}
                                checked={
                                  formation.matieres !== null
                                    ? formation.matieres.includes(
                                      matiere["@id"]
                                    )
                                    : false
                                }
                                onChange={(e) => ChangeMatieres(matiere["@id"])}
                              />
                              <label className="form-check-label">
                                {matiere.name}
                              </label>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              )}
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Fermer
              </button>
              <button
                type="submit"
                className="btn btn-info"
                onClick={handleSubmit}
                disabled={!formation.name || !formation.nbreSemestres}
              >
                Enregistrer
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditFormation;
