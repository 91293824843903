import React, { useContext, useEffect, useState } from "react";
import AppContext from "../../Context/AppContext";
import GetBddProvider from "../../Providers/GetBddProvider";
import Layout from "../common/Layout";
import MsgAlert from "../common/MsgAlert";
import Spinner from "../common/Spinner";
import SyncElevesGalia from "../contents/syncGalia/SyncElevesGalia";
import SyncFormateursGalia from "../contents/syncGalia/SyncFormateursGalia";
import SyncPromosGalia from "../contents/syncGalia/SyncPromosGalia";
import { autoCloseMsg } from "../tools/messagesUtils";
import {
  triByLibelle_Court,
  triByLibelle_Lieu_Court,
  triByName,
  triByUserName,
} from "../tools/sortUtils";

const SyncGalia = () => {
  const { user, url, galiaUser } = useContext(AppContext);

  const _SYNC_FORMATEURS = "1";
  const _SYNC_PROMOS = "2";

  const [sitesList, setSitesList] = useState([]);
  const [siteSelected, setSiteSelected] = useState(null);
  const [site, setSite] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [galiaActions, setGaliaActions] = useState([]);
  const [galiaTC, setGaliaTC] = useState([]);
  const [loadedAction, setLoadedAction] = useState(false);
  const [loadedFormateur, setLoadedFormateur] = useState(false);
  const [maj, setMaj] = useState(false);
  const [actionSelected, setActionSelected] = useState(null);
  const [msg, setMsg] = useState(null);
  const [typeSynchro, settypeSynchro] = useState(_SYNC_PROMOS);
  const [formateurs, setFormateurs] = useState([]);
  const [galiaFormateurs, setGaliaFormateurs] = useState([]);
  const [promo, setPromo] = useState(null);

  useEffect(() => {
    if (user.role > 1) {
      setSitesList(user.sites.sort(triByName));
    } else {
      loadSitesList();
    }
    setSiteSelected(user.sites[0]["@id"]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (siteSelected !== null) {
      setMsg(null);
      loadSites();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteSelected]);

  useEffect(() => {
    if (site !== null && site.idGaliaSociete) {
      if (typeSynchro === _SYNC_PROMOS) {
        loadGaliaAction();
        loadGaliaTC();
      }
      if (typeSynchro === _SYNC_FORMATEURS) {
        setLoadedFormateur(false);
        loadGaliaFormateurs();
        loadFormateurs();
      }
      if (promo !== null) {
        setPromo((prev) => site.promos.find((p) => p.id === promo.id));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [site, typeSynchro]);

  useEffect(() => {
    if (maj) {
      loadSites();
      setMaj(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maj]);

  const razData = () => {
    setLoaded(false);
    setLoadedAction(false);
    setActionSelected(null);
    setPromo(null);
    setGaliaActions([]);
    setGaliaTC([]);
    setGaliaFormateurs([]);
  };

  const loadSitesList = () => {
    let uri = url + "api/sites";
    GetBddProvider(uri).then((res) => {
      if (typeof res === "object") {
        setSitesList(res["hydra:member"].sort(triByName));
      } else {
        setMsg({ txt: "Erreur de chargement", type: "danger" });
      }
    });
  };

  const loadFormateurs = () => {
    let uri = url + `api/formateurs?user.sites=${siteSelected}&user.actif=1`;
    GetBddProvider(uri).then((res) => {
      if (typeof res === "object") {
        setFormateurs(res["hydra:member"].sort(triByUserName));
      } else {
        setMsg({ txt: "Erreur de chargement des formateurs", type: "danger" });
      }
    });
  };

  const loadSites = () => {
    let uri = url + siteSelected.slice(1);
    GetBddProvider(uri).then((res) => {
      if (typeof res === "object") {
        setSite(res);
        setLoaded(true);
      }
    });
  };
  //  Ajout du filtre &CSecteur=site.secteurGalia
  const loadGaliaAction = () => {
    let uri = `https://groupe-alternance.sc-form.net/GaliaEDC/API/Entite/GetEntite?IDSociete=${site.idGaliaSociete}&ACTION=ACTION&TC=false&Csecteur=${site.secteurGalia}`;
    GetBddProvider(uri, undefined, galiaUser).then((res) => {
      if (typeof res === "object") {
        setGaliaActions(
          res.sort(triByLibelle_Court).sort(triByLibelle_Lieu_Court)
        );
        setLoadedAction(true);
      } else {
        setMsg({ txt: "Erreur de connexion à Galia", type: "danger" });
        setLoadedAction(true);
      }
    });
  };

  const loadGaliaTC = () => {
    let uri = `https://groupe-alternance.sc-form.net/GaliaEDC/API/Entite/GetEntite?IDSociete=${site.idGaliaSociete}&ACTION=ACTION&TC=true&Csecteur=${site.secteurGalia}`;
    GetBddProvider(uri, undefined, galiaUser).then((res) => {
      if (typeof res === "object") {
        setGaliaTC(res);
        setLoadedAction(true);
      } else {
        setMsg({ txt: "Erreur de connexion à Galia", type: "danger" });
        setLoadedAction(true);
      }
    });
  };

  const loadGaliaFormateurs = () => {
    let uri = `https://groupe-alternance.sc-form.net/GaliaEDC/API/Entite/GetEntite?IDSociete=${site.idGaliaSociete}&ACTION=INTERVENANT`;
    GetBddProvider(uri, undefined, galiaUser).then((res) => {
      if (typeof res === "object") {
        setGaliaFormateurs(res.filter((r) => r.Est_Formateur === true));
        setLoadedFormateur(true);
      } else {
        setMsg({ txt: "Erreur de connexion à Galia", type: "danger" });
        setLoadedFormateur(true);
      }
    });
  };

  return Layout(
    <div className="my-2 mx-1">
      <div className="d-flex justify-content-center flex-column mb-4">
        <h2>Sync Galia</h2>
        <div className="form-inline justify-content-center">
          <select
            className="form-control"
            value={siteSelected || ""}
            onChange={(e) => {
              razData();
              setSiteSelected(e.target.value);
            }}
          >
            {sitesList !== null
              ? sitesList.map((s, i) => (
                  <option value={s["@id"]} key={i}>
                    {s.name}
                  </option>
                ))
              : null}
          </select>
        </div>
        <div className="form-inline justify-content-center mt-2">
          <div className="form-check mx-2">
            <input
              className="form-check-input"
              type="radio"
              id="type1"
              name="typeSynchro"
              value={_SYNC_FORMATEURS}
              onChange={(e) => settypeSynchro(e.target.value)}
              checked={typeSynchro === _SYNC_FORMATEURS}
            />
            <label className="form-check-label" htmlFor="type1">
              Formateurs
            </label>
          </div>
          <div className="form-check mx-2">
            <input
              className="form-check-input"
              type="radio"
              id="type2"
              name="typeSynchro"
              value={_SYNC_PROMOS}
              onChange={(e) => settypeSynchro(e.target.value)}
              checked={typeSynchro === _SYNC_PROMOS}
            />
            <label className="form-check-label" htmlFor="type2">
              Promos
            </label>
          </div>
        </div>
      </div>
      {msg && (
        <MsgAlert
          msg={msg.txt}
          type={msg.type}
          close={() => autoCloseMsg(setMsg)}
        />
      )}
      {!loaded || site === null ? (
        <Spinner />
      ) : !site.idGaliaSociete ? (
        <MsgAlert msg="Aucune connexion avec Galia !" type="danger" />
      ) : (
        <>
          {typeSynchro === _SYNC_PROMOS && (
            <div className="d-flex flex-wrap">
              <div className="col-lg-6 px-1">
                <SyncPromosGalia
                  loaded={loadedAction}
                  actions={galiaActions}
                  promos={site.promos.filter((p) => p.actif).sort(triByName)}
                  maj={setMaj}
                  actionSelected={actionSelected}
                  setActionSelected={setActionSelected}
                  setPromo={setPromo}
                />
              </div>
              <div className="col-lg-6 px-1">
                <SyncElevesGalia
                  promo={promo}
                  galiaTC={galiaTC}
                  action={actionSelected}
                  site={site}
                  setMajSite={setMaj}
                />
              </div>
            </div>
          )}
          {typeSynchro === _SYNC_FORMATEURS && (
            <SyncFormateursGalia
              loaded={loadedFormateur}
              formateurs={formateurs}
              formateursGalia={galiaFormateurs}
              maj={setMaj}
              site={site}
            />
          )}
        </>
      )}
    </div>
  );
};

export default SyncGalia;
