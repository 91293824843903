import React, { useEffect, useState } from "react";
import { useContext } from "react";
import AppContext from "../../../Context/AppContext";
import DeleteBddProvider from "../../../Providers/DeleteBddProvider";
import GetBddProvider from "../../../Providers/GetBddProvider";
import PostBddProvider from "../../../Providers/PostBddProvider";
import UpdateBddProvider from "../../../Providers/UpdateBddProvider";
import MsgAlert from "../../common/MsgAlert";
import Spinner from "../../common/Spinner";
import DateFormatedReverse from "../../tools/DateFormatedReverse";
import heuresFormH from "../../tools/heuresFormH";
import { autoCloseMsg } from "../../tools/messagesUtils";

/**
 * Modal permettant de modifier une réunion
 * @param {{reunion: object, formateur: object, setMaj: function)}} param0 
 */
const ModifReunion = ({ reunion, formateurs, setMaj }) => {
  const { url } = useContext(AppContext);

  const [presents, setPresents] = useState([]);
  const [copyReunion, setCopyReunion] = useState(null);
  const [msg, setMsg] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [save, setSave] = useState(true);

  useEffect(() => {
    if (reunion !== null) {
      load(reunion);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reunion]);

  const load = (r) => {
    setCopyReunion({
      ...r,
      createdAt: DateFormatedReverse(r.createdAt),
      time: heuresFormH(r.time),
    });
    formatHeures(r);
    setLoaded(true);
  };

  const reload = () => {
    setMsg({ txt: "Modifcation enregistrée", type: "success" });
    autoCloseMsg(setMsg, 5000);
    let uri = url + copyReunion["@id"].slice(1);
    GetBddProvider(uri).then((res) => {
      setMaj(true);
      setSave(true);
      if (typeof res === "object") {
        load(res);
      } else {
        setMsg({ txt: "Erreur de chargement", type: "danger" });
        autoCloseMsg(setMsg, 5000);
      }
    });
  };

  const formatHeures = (r) => {
    let tmp = [...r.participants];
    tmp.forEach((e, i) => {
      if (e.time.length > 5) {
        tmp[i] = { ...tmp[i], time: heuresFormH(tmp[i].time) };
      }
    });
    setPresents(tmp);
    setLoaded(true);
  };

  const getPresent = (iri) => {
    let result = false;
    let index = null;
    presents.forEach((e, i) => {
      if (e.user["@id"] === iri || e.user === iri) {
        result = true;
        index = i;
      }
    });
    return { result: result, index: index };
  };

  const removePresent = (index) => {
    let temp = [...presents];
    temp[index].user = "";
    setPresents(temp);
  };

  const modifPresent = (index, val) => {
    let temp = [...presents];
    temp[index].time = val;
    setPresents(temp);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSave(false);
    let uri = url + copyReunion["@id"].slice(1);
    let data = { ...copyReunion, site: copyReunion.site["@id"] };
    delete data.participants;
    UpdateBddProvider(uri, data).then((res) => {
      if (typeof res === "object" && res.id) {
        let n = 0;
        presents.forEach((p, i) => {
          if (p["@id"] && p.user === "") {
            //delete
            let uriD = url + p["@id"].slice(1);
            DeleteBddProvider(uriD).then((res) => {
              if (res === 204) {
                n++;
                if (n === presents.length) {
                  reload();
                }
              } else {
                setSave(true);
                setMsg({
                  txt: "Erreur de supression des participants",
                  type: "danger",
                });
                autoCloseMsg(setMsg, 5000);
              }
            });
          } else if (p["@id"]) {
            //update
            let uriU = url + p["@id"].slice(1);
            let tmp = { ...p, user: p.user["@id"] };
            UpdateBddProvider(uriU, tmp).then((res) => {
              if (typeof res === "object" && res.id) {
                n++;
                if (n === presents.length) {
                  reload();
                }
              } else {
                setSave(true);
                setMsg({
                  txt: "Erreur de modification des participants",
                  type: "danger",
                });
                autoCloseMsg(setMsg, 5000);
              }
            });
          } else {
            //post
            let uriP = url + "api/participants_rs";
            let tmp = { ...p, reunion: copyReunion["@id"] };
            PostBddProvider(uriP, tmp).then((res) => {
              if (typeof res === "object" && res.id) {
                n++;
                if (n === presents.length) {
                  reload();
                }
              } else {
                setSave(true);
                setMsg({
                  txt: "Erreur d'enregistrement des participants",
                  type: "danger",
                });
                autoCloseMsg(setMsg, 5000);
              }
            });
          }
        });
      } else {
        setSave(true);
        setMsg({ txt: "Erreur d'enregistrement", type: "danger" });
        autoCloseMsg(setMsg, 5000);
      }
    });
  };

  return (
    <div
      className="modal fade"
      id="ModalModifReunion"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-dialog-centered modal-dialog-scrollable"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header bg-info text-white">
            <h5 className="modal-title">
              Saisie des heures de réunion/conseil
            </h5>
            <button
              type="button"
              className="close text-white"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            {msg !== null ? (
              <div className="mx-4">
                <MsgAlert msg={msg.txt} type={msg.type} close={() => autoCloseMsg(setMsg)} />
              </div>
            ) : null}
            <div className="modal-body">
              {!loaded || copyReunion === null || !save ? (
                <Spinner />
              ) : (
                <div>
                  <div className="form-row align-items-center">
                    <div className="form-group col-md-6">
                      <label>
                        Date
                        {navigator.userAgent.indexOf("Safari") >= 1 &&
                        navigator.userAgent.indexOf("Chrome") <= 0 ? (
                          <em>
                            <small> (aaaa-mm-jj)</small>
                          </em>
                        ) : null}
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        placeholder={
                          navigator.userAgent.indexOf("Safari") >= 1 &&
                          navigator.userAgent.indexOf("Chrome") <= 0
                            ? "aaaa-mm-jj"
                            : null
                        }
                        pattern={
                          navigator.userAgent.indexOf("Safari") >= 1 &&
                          navigator.userAgent.indexOf("Chrome") <= 0
                            ? "\\d{4}-\\d{2}-\\d{2}"
                            : null
                        }
                        value={copyReunion.createdAt || ""}
                        onChange={(e) =>
                          setCopyReunion({
                            ...copyReunion,
                            createdAt: e.target.value,
                          })
                        }
                        required
                      />
                    </div>
                    <div className="form-group col-md-6">
                      <label>Durée</label>
                      <input
                        type="time"
                        title="Durée initiale de la rénion"
                        pattern={
                          navigator.userAgent.indexOf("Safari") >= 1 &&
                          navigator.userAgent.indexOf("Chrome") <= 0
                            ? "0[1-4]:\\d{2}"
                            : null
                        }
                        value={copyReunion.time || "00:00"}
                        className="form-control"
                        onChange={(e) =>
                          setCopyReunion({
                            ...copyReunion,
                            time: e.target.value,
                          })
                        }
                        required
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label>Objet</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Objet de la réunion/conseil"
                      maxLength="250"
                      value={copyReunion.label || ""}
                      onChange={(e) =>
                        setCopyReunion({
                          ...copyReunion,
                          label: e.target.value,
                        })
                      }
                      required
                    />
                  </div>
                  <hr />
                  {formateurs.map((f, i) => (
                    <div
                      className={
                        i + 1 < formateurs.length
                          ? "form-row align-items-center border-bottom border-info text-left"
                          : "form-row align-items-center text-left"
                      }
                      key={i}
                    >
                      <div className="form-group col-1">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id={"presCheckbox" + f.id}
                            checked={
                              getPresent(f.user["@id"]).result || false
                            }
                            onChange={() => {
                              if (getPresent(f.user["@id"]).result) {
                                removePresent(
                                  getPresent(f.user["@id"]).index
                                );
                              } else {
                                setPresents([
                                  ...presents,
                                  {
                                    user: f.user["@id"],
                                    time: copyReunion.time,
                                  },
                                ]);
                              }
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-7 d-flex my-3">
                          <span className={getPresent(f.user["@id"]).result ? "text-success" : "text-danger"}>
                            <strong>
                              {f.user.name} {f.user.firstName}
                            </strong>
                          </span>
                      </div>
                      <div className="form-group col-4 my-2 m-auto">
                        <input
                          type="time"
                          title="Temps de présence"
                          pattern={
                            navigator.userAgent.indexOf("Safari") >= 1 &&
                            navigator.userAgent.indexOf("Chrome") <= 0
                              ? "0[1-4]:\\d{2}"
                              : null
                          }
                          max={copyReunion.time}
                          value={
                            presents[getPresent(f.user["@id"]).index]
                              ? presents[getPresent(f.user["@id"]).index].time
                              : copyReunion.time || "00:00"
                          }
                          className="form-control"
                          onChange={(e) => {
                            modifPresent(
                              getPresent(f.user["@id"]).index,
                              e.target.value
                            );
                          }}
                          disabled={!getPresent(f.user["@id"]).result}
                          required
                        />
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
            >
              Fermer
            </button>
            <button
              type="button"
              className="btn btn-info"
              onClick={handleSubmit}
            >
              Mettre à jour
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModifReunion;
