export const Motifs = [
  {
    IDTypAbsen: 1,
    typeEdusign: 200,
    nom: "Maladie",
  },
  {
    IDTypAbsen: 2,
    typeEdusign: 201,
    nom: "Injustifiée",
  },
  {
    IDTypAbsen: 3,
    typeEdusign: 202,
    nom: "Exceptionnelle (décès, mariage, ...)",
  },
  {
    IDTypAbsen: 4,
    typeEdusign: 203,
    nom: "Cas de force majeure",
  },
  {
    IDTypAbsen: 5,
    typeEdusign: 204,
    nom: "Congés payés",
  },
  {
    IDTypAbsen: 6,
    typeEdusign: 205,
    nom: "Absent à l'appel",
  },
  {
    IDTypAbsen: 7,
    typeEdusign: 206,
    nom: "Retard",
  },
  {
    IDTypAbsen: 8,
    typeEdusign: 207,
    nom: "Démarche individuelle",
  },
  {
    IDTypAbsen: 9,
    typeEdusign: 208,
    nom: "Pas de motif",
  },
  {
    IDTypAbsen: 10,
    typeEdusign: 209,
    nom: "Retenu(e) en entreprise",
  },
  {
    IDTypAbsen: 11,
    typeEdusign: 210,
    nom: "Arrêt de travail",
  },
  {
    IDTypAbsen: 12,
    typeEdusign: 211,
    nom: "Motif personnel",
  },
  {
    IDTypAbsen: 13,
    typeEdusign: 212,
    nom: "Examen",
  },
  {
    IDTypAbsen: 14,
    typeEdusign: 213,
    nom: "Cours non obligatoire",
  },
  {
    IDTypAbsen: 15,
    typeEdusign: 214,
    nom: "COVID",
  },
  {
    IDTypAbsen: 16,
    typeEdusign: 215,
    nom: "Accident du travail",
  },
  {
    IDTypAbsen: 17,
    typeEdusign: 216,
    nom: "Problème de connexion",
  },
];
