import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import { useContext } from "react";
import { useState } from "react";
import AppContext from "../../../Context/AppContext";
import UpdateBddProvider from "../../../Providers/UpdateBddProvider";
import MsgAlert from "../../common/MsgAlert";
import SmallSpinner from "../../common/SmallSpinner";
import { autoCloseMsg } from "../../tools/messagesUtils";
import { triByNom } from "../../tools/sortUtils";

export default function FormateurItemGalia({
  formateur,
  formateurs,
  formateursGalia,
  site,
  maj,
}) {
  const { url } = useContext(AppContext);

  const assign = formateur.idGalia
    ? formateursGalia.filter((p) => p.ID === formateur.idGalia)
    : [];

  const [msg, setMsg] = useState(null);
  const [formateurSelected, setFormateurSelected] = useState(null);
  const [save, setSave] = useState(true);

  useEffect(() => {
    let tmp = formateursGalia.filter(
      (f) =>
        f.Nom.localeCompare(formateur.user.name, undefined, {
          sensitivity: "base",
        }) === 0
    );
    if (tmp.length > 0) {
      setFormateurSelected(tmp[0].ID);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const saveSync = () => {
    setSave(false);
    let uri = url + formateur["@id"].slice(1);
    let data = { idGalia: formateurSelected };
    update(uri, data);
  };

  const supSync = () => {
    setSave(false);
    let uri = url + formateur["@id"].slice(1);
    let data = { idGalia: null };
    update(uri, data);
  };

  const update = (uri, data) => {
    UpdateBddProvider(uri, data).then((res) => {
      if (typeof res === "object" && res.id) {
        setMsg({
          txt: "les données ont bien été modifiées",
          type: "success",
        });
        autoCloseMsg(setMsg, 2000);
        setSave(true);
        maj(true);
      } else {
        setSave(true);
        setMsg({ txt: "Erreur d'enregistrement", type: "danger" });
        autoCloseMsg(setMsg, 2000);
      }
    });
  };

  return (
    <li className="list-group-item">
      {msg !== null ? (
        <MsgAlert msg={msg.txt} type={msg.type} close={() => autoCloseMsg(setMsg)} />
      ) : null}
      <div className="d-flex flex-wrap align-items-center">
        <div className="h6 m-0 mr-4">{`${formateur.user.name} ${formateur.user.firstName}`}</div>
        <FontAwesomeIcon
          icon="sync-alt"
          className={`mr-4 ${
            formateur.idGalia ? "text-success" : "text-warning"
          }`}
        />
        {!formateur.idGalia ? (
          <>
            <select
              className="form-control form-control-sm col-md-3"
              value={formateurSelected || ""}
              onChange={(e) => setFormateurSelected(parseInt(e.target.value))}
            >
              <option value="" disabled>
                Formateur Galia
              </option>
              {formateursGalia
                .filter(
                  (f) => f.Code_Secteur === site.secteurGalia
                )
                .sort(triByNom)
                .map((f) => {
                  let test = formateurs.filter(
                    (form) => form.idGalia && form.idGalia === f.ID
                  );
                  if (test.length > 0) {
                    return null;
                  }
                  return (
                    <option value={f.ID} key={f.ID + formateur["@id"]}>
                      {f.Nom} {f.Prenom}
                    </option>
                  );
                })}
            </select>
            <button
              className="btn btn-outline-success btn-sm ml-2"
              title="enregistrer"
              onClick={() => {
                saveSync();
              }}
              disabled={formateurSelected === null}
            >
              {save ? <FontAwesomeIcon icon="check" /> : <SmallSpinner />}
            </button>
          </>
        ) : assign.length > 0 ? (
          <>
            {assign[0].Nom} {assign[0].Prenom}{" "}
            {assign[0].Code_Secteur === site.secteurGalia ? (
              <button
                className="btn btn-outline-danger btn-sm ml-2"
                title="Désynchroniser"
                onClick={() => supSync()}
              >
                {save ? (
                  <FontAwesomeIcon icon="times" role="button" />
                ) : (
                  <SmallSpinner />
                )}
              </button>
            ) : (
              <small>
                <em className="ml-2 text-info">({assign[0].Lieu_Principal})</em>
              </small>
            )}
          </>
        ) : null}
        <span className="ml-4"></span>
      </div>
    </li>
  );
}
