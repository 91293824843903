import React, { useContext, useEffect, useState } from "react";
import AppContext from "../../../Context/AppContext";
import { useLoadBackCollectionData } from "../../../Providers/Utils/Loaders/Nocx/Loaders";
import Spinner from "../../common/Spinner";
import ListExportElevesLine from "./ListExportElevesLine";

export default function ListExportEleves({ promo, eleveActif, setMsg }) {
  const { url } = useContext(AppContext);
  const [eleves, setEleves] = useState([]);
  const [tuteurs, setTuteurs] = useState([]);
  const [loaded, setLoaded] = useState(false);

  const loadBackCollectionData = useLoadBackCollectionData();

  useEffect(() => {
    let uriEleves = `${url}api/eleves?promos=${promo["@id"]}`;
    if (eleveActif) {
      uriEleves += "&user.actif=" + eleveActif;
    }
    let uriTuteurs = `${url}api/entreprises?eleves.promos=${promo["@id"]}`;
    /**
     *
     * @param {string} uriData
     * @param {()=>{}} setter
     */
    async function fetchData(uriData, setter) {
      await loadBackCollectionData(uriData, setter, {
        setMsg: setMsg,
        errorMessage: `Erreur de chargement des tuteurs de la promo ${promo.name} !`,
        typeError: "danger",
      });
    }
    fetchData(uriEleves, setEleves);
    fetchData(uriTuteurs, setTuteurs);
    setLoaded(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return !loaded ? (
    <tr>
      <td colSpan={12}>
        <Spinner />
      </td>
    </tr>
  ) : (
    eleves.map((e) => (
      <ListExportElevesLine
        promo={promo}
        eleve={e}
        tuteur={
          e.entreprises.length > 0
            ? tuteurs.find((t) => t["@id"] === e.entreprises[0]["@id"])
            : null
        }
        key={e["@id"]}
        setMsg={setMsg}
      />
    ))
  );
}
