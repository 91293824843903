import React, { useContext, useEffect, useState } from "react";
import AppContext from "../../Context/AppContext";
import GetBddProvider from "../../Providers/GetBddProvider";
import Layout from "../common/Layout";
import MsgAlert from "../common/MsgAlert";
import Spinner from "../common/Spinner";
import EditBlocAfest from "../contents/edits/EditBlocAfest";
import EditCompAfest from "../contents/edits/EditCompAfest";
import ListSetCompetences from "../contents/lists/ListSetCompetences";
import { autoCloseMsg } from "../tools/messagesUtils";
import { triByName } from "../tools/sortUtils";

const SetAfest = () => {
  const { url } = useContext(AppContext);
  const [formations, setFormations] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [blocSelected, setBlocSelected] = useState(null);
  const [compSelected, setCompSelected] = useState(null);
  const [blocs, setBlocs] = useState([]);
  const [msg, setMsg] = useState(null);
  const [maj, setMaj] = useState(false);

  useEffect(() => {
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (maj) {
      setFormations([]);
      setMaj(false);
      setLoaded(false);
      load();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maj]);

  const load = () => {
    GetBddProvider(url + "api/formations").then((res) => {
      if (typeof res === "object") {
        setFormations(res["hydra:member"]);
        let uri = url + "api/bloc_afests";
        GetBddProvider(uri).then((res) => {
          if (typeof res === "object") {
            setBlocs(res["hydra:member"]);
            setLoaded(true);
          } else {
            setMsg({ txt: "Erreur de chargement des blocs", type: "danger" });
            autoCloseMsg(setMsg, 5000);
            setLoaded(true);
          }
        });
      } else {
        setMsg({ txt: "Erreur de chargement des formations", type: "danger" });
        autoCloseMsg(setMsg, 5000);
      }
    });
  };

  return Layout(
    <div className="mt-4">
      <span className="d-flex flex-wrap justify-content-center m-3">
        <h2 className="m-0">
          Bloc de compétence d'Évaluation en Situation de Travail
        </h2>
      </span>
      {msg !== null ? (
        <MsgAlert msg={msg.txt} type={msg.type} close={() => autoCloseMsg(setMsg)} />
      ) : null}
      <EditCompAfest data={compSelected} setMaj={setMaj} />
      <EditBlocAfest data={blocSelected} setMaj={setMaj} />
      <hr className="m-4" />
      {!loaded ? (
        <Spinner />
      ) : (
        <div className="d-flex flex-wrap justify-content-center">
          {formations.map((f) => {
            let temp = blocs.filter((b) => b.formation === f["@id"]);
            return (
              <ListSetCompetences
                formation={f}
                key={f.id}
                blocs={temp.sort(triByName)}
                setBlocSelected={setBlocSelected}
                setCompSelected={setCompSelected}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

export default SetAfest;
