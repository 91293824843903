import React from "react";
import { useContext } from "react";
import AppContext from "../../Context/AppContext";
import { useState } from "react";
import GetBddProvider from "../../Providers/GetBddProvider";
import { useEffect } from "react";
import Layout from "../common/Layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Spinner from "../common/Spinner";
import ListEntreprises from "../contents/lists/ListEntreprises";
import EditEntreprise from "../contents/edits/EditEntreprise";
import MsgAlert from "../common/MsgAlert";
import DeleteBddProvider from "../../Providers/DeleteBddProvider";
import usePost from "../../hooks/usePost";
import useGenPwd from "../../hooks/useGenPwd";
import { autoCloseMsg } from "../tools/messagesUtils";
import { triByName } from "../tools/sortUtils";

const Entreprises = () => {
  const { url, user } = useContext(AppContext);
  const [loaded, setloaded] = useState(false);
  const [maj, setmaj] = useState(false);
  const [siteSelected, setSiteSelected] = useState(null);
  const [listSites, setListSites] = useState([]);
  const [msg, setMsg] = useState(null);
  const [selected, setselected] = useState({
    user: { actif: true, roles: ["ROLE_ENTREPRISE"] },
  });
  const [entreprises, setentreprises] = useState(null);
  const [entrepriseAdmins, setEntrepriseAdmins] = useState([]);

  const PostBDD = usePost();
  const GenPWD = useGenPwd(url, PostBDD);

  useEffect(() => {
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (siteSelected !== null) {
      setloaded(false);
      loadEntreprises();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteSelected]);

  useEffect(() => {
    if (maj) {
      setmaj(false);
      setloaded(false);
      loadEntreprises();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maj]);

  const load = () => {
    if (user.role === 1) {
      let uriSites = url + "api/sites";
      GetBddProvider(uriSites).then((res) => {
        if (typeof res === "object") {
          setListSites(res["hydra:member"].sort(triByName));
          setSiteSelected(user.sites[0]["@id"]);
        } else {
          setMsg({ txt: "Erreur de chargement des sites", type: "danger" });
          autoCloseMsg(setMsg, 5000);
        }
      });
    } else {
      setListSites(user.sites.sort(triByName));
      setSiteSelected(user.sites[0]["@id"]);
    }
  };

  const loadEntreprises = () => {
    let uri = url + "api/entreprises";
    if (siteSelected !== "All") {
      uri += "?user.sites=" + siteSelected;
    }
    GetBddProvider(uri).then((res) => {
      if (typeof res === "object") {
        setentreprises(res["hydra:member"]);
        loadAdmin();
      } else {
        setMsg({ txt: "Erreur de chargement des tuteurs", type: "danger" });
        autoCloseMsg(setMsg, 5000);
      }
    });
  };

  const loadAdmin = () => {
    let uri = url + "api/entreprise_admins?user.sites=" + siteSelected;
    GetBddProvider(uri).then((res) => {
      if (typeof res === "object") {
        setEntrepriseAdmins(res["hydra:member"]);
        setloaded(true);
      }
    });
  };

  const delEntreprise = (ent) => {
    if (ent !== undefined && ent["@id"]) {
      setloaded(false);
      let uri = url + ent["@id"].slice(1);
      DeleteBddProvider(uri).then((res) => {
        if (res === 204) {
          setMsg({ txt: "Le tuteur à bien été supprimé", type: "success" });
          autoCloseMsg(setMsg, 5000);
          setmaj(true);
        } else {
          setloaded(true);
          setMsg({ txt: "Erreur lors de la suppression", type: "danger" });
          autoCloseMsg(setMsg, 5000);
        }
      });
    }
  };

  const mailConnexion = async (user) => {
    setMsg({
      txt: "Envoie du mail encours ...",
      type: "success",
    });
    let res = await GenPWD(user);
    if (res.status === "ok") {
      setMsg({
        txt: `Un mail de connexion pour ${user.name} ${user.firstName} a été envoyé à l'adresse : ${res.email}`,
        type: "success",
      });
      autoCloseMsg(setMsg, 5000);
    } else if (res.status === 404) {
      setMsg({ txt: res.message, type: "danger" });
      autoCloseMsg(setMsg, 5000);
    } else if (res !== 666) {
      setMsg({ txt: "Erreur serveur !", type: "danger" });
      autoCloseMsg(setMsg, 5000);
    }
  };

  return Layout(
    <div className="mt-4">
      <span className="d-flex flex-wrap justify-content-md-between justify-content-center m-3">
        <h2 className="m-0">Liste des tuteurs</h2>
        <div>
          <select
            className="form-control"
            value={siteSelected || ""}
            onChange={(e) => setSiteSelected(e.target.value)}
          >
            <option value="" disabled>
              Sites
            </option>
            <option value="All">Tous</option>
            {listSites !== null
              ? listSites.map((s, i) => (
                  <option value={s["@id"]} key={i}>
                    {s.name}
                  </option>
                ))
              : null}
          </select>
        </div>
        <button
          type="button"
          className="btn btn-info"
          onClick={(e) =>
            setselected({
              user: { actif: true, roles: ["ROLE_ENTREPRISE"] },
            })
          }
          data-toggle="modal"
          data-target="#Modal"
        >
          <FontAwesomeIcon icon="plus-circle" /> &nbsp; Ajouter
        </button>
      </span>
      <EditEntreprise
        maj={setmaj}
        entrepriseAdmins={entrepriseAdmins}
        selected={selected}
      />
      <hr className="m-4" />
      {msg !== null ? (
        <MsgAlert msg={msg.txt} type={msg.type} close={() => autoCloseMsg(setMsg)} />
      ) : null}
      {!loaded ? (
        <Spinner />
      ) : (
        <ListEntreprises
          data={entreprises}
          site={
            siteSelected === "All"
              ? { name: "Tous" }
              : listSites.filter((s) => s["@id"] === siteSelected)[0]
          }
          setselected={setselected}
          delEntreprise={delEntreprise}
          GenPWD={mailConnexion}
        />
      )}
    </div>
  );
};

export default Entreprises;
