import React, { useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect } from "react";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import AppContext from "../../Context/AppContext";
import DeleteBddProvider from "../../Providers/DeleteBddProvider";
import GetBddProvider from "../../Providers/GetBddProvider";
import Layout from "../common/Layout";
import MsgAlert from "../common/MsgAlert";
import Spinner from "../common/Spinner";
import EditAbsencesAdmin from "../contents/edits/EditAbsencesAdmin";
import EditRattrapage from "../contents/edits/EditRattrapage";
import ModifAbsence from "../contents/modifs/ModifAbsences";
import AbsEdusign from "../contents/syncAbsences/AbsEdusign";
import convertDecimal from "../tools/convertDecimal";
import dateFormated from "../tools/dateFormated";
import heuresFormated from "../tools/heuresFormated";
import { autoCloseMsg } from "../tools/messagesUtils";
import {
  triByCreatedAt,
  triByEleveUserName,
  triByName,
  triByUserName,
} from "../tools/sortUtils";

const GestAbsences = () => {
  const { url, user } = useContext(AppContext);

  let temp = new Date().toISOString().split("T")[0];

  const [debut, setDebut] = useState(temp);
  const [fin, setFin] = useState(temp);

  const [actif, setActif] = useState(true);
  const [sites, setSites] = useState([]);
  const [siteSelected, setSiteSelected] = useState(null);
  const [promos, setPromos] = useState(null);
  const [promoSelected, setPromoSelected] = useState(null);
  const [eleves, setEleves] = useState(null);
  const [eleveSelected, setEleveSelected] = useState(null);
  const [elevesloading, setElevesloading] = useState(false);
  const [absences, setAbsences] = useState(null);
  const [absSelected, setAbsSelected] = useState(null);
  const [rattrapageSelect, setRattrapageSelect] = useState(null);
  const [moment, setMoment] = useState(null);
  const [motif, setMotif] = useState(null);
  const [rattrapage, setRattrapage] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [loadEdusign, setLoadEdusign] = useState(false);
  const [clear, setClear] = useState(false);
  const [maj, setMaj] = useState(false);
  const [msg, setMsg] = useState(null);
  const [justify, setJustify] = useState("All");
  const motifs = [
    "Pas de motif",
    "Retenu(e) en entreprise",
    "Arrêt de travail",
    "Motif personnel",
    "Congé payé",
    "Examen",
    "Cours non obligatoire",
    "COVID",
    "Accident du travail",
    "Problème de connexion",
  ];
  const tabJour = [
    "Dimanche",
    "Lundi",
    "Mardi",
    "Mercredi",
    "Jeudi",
    "Vendredi",
    "Samedi",
  ];
  let totalH = 0;
  let totalJustify = 0;
  let totalNotJustify = 0;

  useEffect(() => {
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (siteSelected !== null) {
      setLoaded(false);
      loadPromos();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteSelected, actif]);

  useEffect(() => {
    if (maj === true) {
      setMaj(false);
      loadAbsences();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maj]);

  useEffect(() => {
    setEleves(null);
    setEleveSelected(null);
    setElevesloading(true);
    if (promoSelected !== null && promoSelected !== "") {
      loadEleves(promoSelected);
    } else {
      setElevesloading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promoSelected]);

  useEffect(() => {
    if (loadEdusign) {
      loadAbsences();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadEdusign]);

  const razData = () => {
    setMoment(null);
    setMotif(null);
    setRattrapage(null);
    setPromoSelected(null);
    setEleves(null);
    setEleveSelected(null);
    setAbsences(null);
  };

  const filter = (val) => {
    let temp = JSON.parse(JSON.stringify(absences));
    if (val === "date") {
      setAbsences(temp.sort(triByCreatedAt));
    } else if (val === "name") {
      setAbsences(temp.sort(triByEleveUserName));
    }
    setLoaded(true);
  };

  const loadPromos = () => {
    let uri = `${url}api/promos?site=${siteSelected}`;
    if (actif) {
      uri += "&actif=true";
    }
    GetBddProvider(uri).then((res) => {
      setLoaded(true);
      if (typeof res === "object") {
        setPromos(res["hydra:member"]);
        loadAbsences();
      } else {
        setMsg({ txt: "Erreur de Chargement des promos", type: "danger" });
        autoCloseMsg(setMsg, 5000);
      }
    });
  };

  const load = async () => {
    if (user.role > 1) {
      let listSites = user.sites.sort(triByName);
      setSites(listSites);
      if (siteSelected === null) {
        setSiteSelected(listSites[0]["@id"]);
      }
    } else {
      let listSites = user.sites.sort(triByName);
      let uri = url + "api/sites";
      await GetBddProvider(uri).then((res) => {
        if (typeof res === "object") {
          setSites(res["hydra:member"].sort(triByName));
        } else {
          // Message d'erreur
        }
      });
      if (siteSelected === null) {
        setSiteSelected(listSites[0]["@id"]);
      }
    }
  };

  const loadEleves = () => {
    let uri = url + "api/eleves?promos=" + promoSelected;
    // if (actif) {
    //   uri += "&user.actif=true";
    // }
    GetBddProvider(uri).then((res) => {
      setElevesloading(false);
      if (typeof res === "object") {
        setEleves(res["hydra:member"]);
      } else {
        setMsg({ txt: "Erreur de Chargement des élèves", type: "danger" });
        autoCloseMsg(setMsg, 5000);
      }
    });
  };

  const loadAbsences = () => {
    let uri = `${url}api/absences?order[createdAt]=asc&createdAt[after]=${debut}&createdAt[before]=${fin}`;
    if (eleveSelected !== null) {
      uri += `&eleve=${eleveSelected}`;
    }
    if (promoSelected === null || promoSelected === "") {
      uri += `&promo.site=${siteSelected}`;
    } else {
      uri += `&promo=${promoSelected}`;
    }
    if (moment !== null && moment !== "") {
      uri += `&moment=${moment}`;
    }
    if (motif !== null && motif !== "") {
      motif === "Pas de motif"
        ? (uri += "&motif=")
        : (uri += `&motif=${motif}`);
    }
    if (rattrapage !== null && rattrapage !== "") {
      uri += `&rattrapage=${rattrapage}`;
    }
    GetBddProvider(uri).then((res) => {
      if (typeof res === "object") {
        let result = res["hydra:member"].sort(triByEleveUserName);
        setAbsences(result);
      } else {
        setMsg({ txt: "Erreur de chargement des absences", type: "danger" });
        autoCloseMsg(setMsg, 5000);
      }
    });
  };

  const delAbsence = (iri) => {
    setAbsences(null);
    let uri = url + iri.slice(1);
    DeleteBddProvider(uri).then((res) => {
      loadAbsences();
      if (res === 204) {
        setMsg({
          txt: "L'absence est supprimée !",
          type: "success",
        });
        autoCloseMsg(setMsg, 5000);
      } else {
        setMsg({
          txt: "Erreur lors de la suppression",
          type: "danger",
        });
        autoCloseMsg(setMsg, 5000);
      }
    });
  };

  return Layout(
    <div className="mt-4">
      <div className="d-flex flex-wrap align-items-center justify-content-md-between justify-content-center mx-4">
        <h2>Absences/Rattrapages</h2>
        <div className="form-row align-items-center">
          <div className="my-2">
            <select
              className="form-control rounded-pill"
              value={siteSelected || ""}
              onChange={(e) => {
                razData();
                setSiteSelected(e.target.value);
              }}
            >
              {sites !== null
                ? sites.map((s, i) => (
                    <option value={s["@id"]} key={i}>
                      {s.name}
                    </option>
                  ))
                : null}
            </select>
          </div>
          <div className="ml-4">
            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                checked={actif || false}
                onChange={(e) => setActif(!actif)}
              />
              <label className="form-check-label">
                <strong>Promos actifs</strong>
              </label>
            </div>
          </div>
        </div>
        <div>
          <button
            className="btn btn-sm rounded-pill btn-danger mr-2"
            data-toggle="modal"
            data-target="#AbsenceModalAdmin"
          >
            <FontAwesomeIcon icon="edit" /> Saisir une absence
          </button>
          <button
            className="btn btn-sm rounded-pill btn-info"
            data-toggle="modal"
            data-target="#RattrapageModal"
            onClick={() => setRattrapageSelect(null)}
          >
            <FontAwesomeIcon icon="edit" /> Saisir un rattrapage
          </button>
        </div>
      </div>

      <ModifAbsence absSelected={absSelected} promos={promos} maj={setMaj} />
      <EditAbsencesAdmin setMaj={setMaj} promos={promos} />
      <EditRattrapage
        setMaj={setMaj}
        promos={promos}
        selected={rattrapageSelect}
      />
      {msg !== null ? (
        <MsgAlert
          msg={msg.txt}
          type={msg.type}
          close={() => autoCloseMsg(setMsg)}
        />
      ) : null}
      <hr className="m-4" />
      {!loaded || promos === null ? (
        <Spinner />
      ) : (
        <div>
          <div className="form-row mx-4">
            <div className="form-group col-lg-3">
              <label>Promo</label>
              <select
                className="form-control rounded-pill"
                value={promoSelected || ""}
                onChange={(e) => {
                  setPromoSelected(e.target.value);
                }}
              >
                <option value="">Toutes les promos</option>
                {promos.map((p, i) => (
                  <option
                    value={p["@id"]}
                    disabled={p.semestres.length === 0}
                    key={i}
                  >
                    {p.site.name} - {p.name}
                    {p.semestres.length === 0 && " (Pas de semestre)"}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group col-lg-3">
              <label>Eleves</label>
              <select
                className="form-control rounded-pill"
                value={eleveSelected || ""}
                onChange={(e) => setEleveSelected(e.target.value)}
                disabled={eleves === null}
              >
                <option value="">
                  {elevesloading
                    ? "Chargement en cours ..."
                    : eleves === null
                    ? "Veuillez sélectionner une promo"
                    : eleves.length < 1
                    ? "Pas d'élèves"
                    : "Tous"}
                </option>
                {eleves === null
                  ? null
                  : eleves.sort(triByUserName).map((el, i) => (
                      <option
                        className={!el.user.actif ? "text-danger" : ""}
                        value={el["@id"]}
                        key={i}
                      >
                        {el.user.name} {el.user.firstName}{" "}
                        {!el.user.actif && "(inactif)"}
                      </option>
                    ))}
              </select>
            </div>
            <div className="form-group col-lg-3">
              <label>1/2 journée</label>
              <select
                className="form-control rounded-pill"
                value={moment || ""}
                onChange={(e) => setMoment(e.target.value)}
              >
                <option value="">Tous</option>
                <option value="Matin">Matin</option>
                <option value="Après-midi">Après-midi</option>
              </select>
            </div>
            <div className="form-group col-lg-3">
              <label>Justifiées/Injustifiées</label>
              <select
                className="form-control rounded-pill"
                value={justify || "All"}
                onChange={(e) => setJustify(e.target.value)}
              >
                <option value="All">Tous</option>
                <option value="1">Justifiées</option>
                <option value="0">Injustifiées</option>
              </select>
            </div>
          </div>
          <div className="form-row mx-4">
            <div className="form-group col-lg-3">
              <label>
                Début de période{" "}
                {navigator.userAgent.indexOf("Safari") >= 1 &&
                navigator.userAgent.indexOf("Chrome") <= 0 ? (
                  <em>
                    <small> (aaaa-mm-jj)</small>
                  </em>
                ) : null}
              </label>
              <input
                className="form-control rounded-pill"
                type="date"
                placeholder={
                  navigator.userAgent.indexOf("Safari") >= 1 &&
                  navigator.userAgent.indexOf("Chrome") <= 0
                    ? "aaaa-mm-jj"
                    : null
                }
                pattern={
                  navigator.userAgent.indexOf("Safari") >= 1 &&
                  navigator.userAgent.indexOf("Chrome") <= 0
                    ? "\\d{4}-\\d{2}-\\d{2}"
                    : null
                }
                value={debut || ""}
                onChange={(e) => setDebut(e.target.value)}
                required
              />
            </div>
            <div className="form-group col-lg-3">
              <label>
                Fin de période{" "}
                {navigator.userAgent.indexOf("Safari") >= 1 &&
                navigator.userAgent.indexOf("Chrome") <= 0 ? (
                  <em>
                    <small> (aaaa-mm-jj)</small>
                  </em>
                ) : null}
              </label>
              <input
                className="form-control  rounded-pill"
                type="date"
                placeholder={
                  navigator.userAgent.indexOf("Safari") >= 1 &&
                  navigator.userAgent.indexOf("Chrome") <= 0
                    ? "aaaa-mm-jj"
                    : null
                }
                pattern={
                  navigator.userAgent.indexOf("Safari") >= 1 &&
                  navigator.userAgent.indexOf("Chrome") <= 0
                    ? "\\d{4}-\\d{2}-\\d{2}"
                    : null
                }
                value={fin || ""}
                onChange={(e) => setFin(e.target.value)}
                required
              />
            </div>
            <div className="form-group col-lg-3">
              <label>Motif</label>
              <select
                className="form-control rounded-pill"
                value={motif || ""}
                onChange={(e) => setMotif(e.target.value)}
              >
                <option value="">Tous</option>
                {motifs.map((m, i) => (
                  <option value={m} key={i}>
                    {m}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group col-lg-3">
              <label>Absences/Rattrapages</label>
              <select
                className="form-control rounded-pill"
                value={rattrapage || ""}
                onChange={(e) => setRattrapage(e.target.value)}
              >
                <option value="">Tous</option>
                <option value="0">Absences</option>
                <option value="1">Rattrapages</option>
              </select>
            </div>
          </div>
          <div className="form-group">
            <button
              className="btn btn-sm rounded-pill btn-outline-primary"
              onClick={() => {
                setAbsences(null);
                setClear(true);
                loadAbsences();
              }}
            >
              Afficher
            </button>
            {sites.filter((s) => s["@id"] === siteSelected)[0].TokenEdusign ? (
              <button
                className="btn btn-sm rounded-pill btn-outline-info ml-2"
                onClick={() => {
                  razData();
                  setLoadEdusign(true);
                }}
              >
                Sync Edusign
              </button>
            ) : null}
          </div>
          <hr className="mx-4 mt-4 mb-0" />
          {sites.filter((s) => s["@id"] === siteSelected)[0].TokenEdusign ? (
            <AbsEdusign
              site={sites.filter((s) => s["@id"] === siteSelected)[0]}
              start={debut}
              absences={
                absences !== null
                  ? absences.filter((abs) => !abs.rattrapage)
                  : absences
              }
              end={fin}
              load={loadEdusign}
              setLoad={setLoadEdusign}
              setMaj={setMaj}
              clear={clear}
              setClear={setClear}
            />
          ) : null}
          <div className="m-4">
            {absences === null ? (
              <Spinner />
            ) : (
              <div className="animFadeIn">
                {absences.length < 1 ? (
                  <h4 className="text-danger m-4">
                    <strong>
                      La sélection ne contient aucune absence et aucun
                      rattrapage
                    </strong>
                  </h4>
                ) : (
                  <div>
                    <div className="d-flex justify-content-between p-4">
                      <h4>Liste des Absences</h4>
                      <ReactHTMLTableToExcel
                        className="btn btn-sm rounded-pill btn-outline-primary"
                        table="TableAbs"
                        filename={
                          "relevé-absence-" +
                          (new Date(debut).getMonth() + 1) +
                          "-" +
                          new Date(debut).getFullYear()
                        }
                        sheet="Export"
                        buttonText="Export Excel"
                      />
                    </div>
                    <div className="table-responsive">
                      <table
                        className="table table-bordered table-sm"
                        id="TableAbs"
                      >
                        <thead>
                          <tr className="bg-info text-white">
                            <th>Sync</th>
                            <th>Action</th>
                            <th>Saisie</th>
                            <th>
                              Date{" "}
                              <FontAwesomeIcon
                                icon="chevron-down"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  setLoaded(false);
                                  filter("date");
                                }}
                              />
                            </th>
                            <th>Promo</th>
                            <th>
                              Élève{" "}
                              <FontAwesomeIcon
                                icon="chevron-down"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  setLoaded(false);
                                  filter("name");
                                }}
                              />
                            </th>
                            <th className="d-none">Mail Élève</th>
                            <th>Motif</th>
                            <th>commentaire</th>
                            <th>Tuteur</th>
                            <th className="d-none">Mail tuteur</th>
                            <th className="d-none">Mail admin</th>
                            <th className="d-none">Autre e-mail</th>
                            <th>Justificatif</th>
                            <th>Prévenue</th>
                            <th>Heures</th>
                          </tr>
                        </thead>
                        <tbody className="text-left">
                          {absences.map((abs, i) => {
                            let h = parseFloat(heuresFormated(abs.time));
                            let dateCreated = new Date(abs.createdAt);
                            if (justify === "1" && !abs.justify) {
                              return null;
                            }
                            if (justify === "0" && abs.justify) {
                              return null;
                            }
                            if (abs.justify) {
                              totalJustify += h;
                            }
                            if (!abs.justify && !abs.rattrapage) {
                              totalNotJustify += h;
                            }
                            if (abs.rattrapage) {
                              totalH -= h;
                            } else if (abs.motif !== "Cours non obligatoire") {
                              totalH += h;
                            }
                            let classValue = abs.rattrapage ? "text-info" : "";
                            return (
                              <tr key={i} className={classValue}>
                                <td className="text-center align-middle">
                                  {sites.filter(
                                    (s) => s["@id"] === siteSelected
                                  )[0].TokenEdusign ? (
                                    <>
                                      <FontAwesomeIcon
                                        icon="sync-alt"
                                        className={`${
                                          abs.IdEdusign
                                            ? "text-success"
                                            : "text-warning"
                                        }`}
                                        title={
                                          abs.IdEdusign
                                            ? "Absence synchronisée"
                                            : "Absence non synchronisée"
                                        }
                                      />{" "}
                                      <span
                                        className={`text-white badge badge-${
                                          abs.saveGalia ? "success" : "warning"
                                        }`}
                                      >
                                        G
                                      </span>
                                    </>
                                  ) : null}
                                </td>
                                <td className="text-center align-middle">
                                  {abs.rattrapage ? (
                                    <FontAwesomeIcon
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        setRattrapageSelect(
                                          JSON.parse(JSON.stringify(abs))
                                        );
                                      }}
                                      data-toggle="modal"
                                      data-target="#RattrapageModal"
                                      className="text-info"
                                      icon="edit"
                                    />
                                  ) : (
                                    <FontAwesomeIcon
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        setAbsSelected(
                                          JSON.parse(JSON.stringify(abs))
                                        );
                                      }}
                                      data-toggle="modal"
                                      data-target="#ModalModifAbs"
                                      className="text-info"
                                      icon="edit"
                                    />
                                  )}
                                  <FontAwesomeIcon
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      if (
                                        window.confirm(
                                          "Supprimer l'enregistrement ?"
                                        )
                                      ) {
                                        delAbsence(abs["@id"]);
                                      }
                                    }}
                                    className="ml-2 text-danger"
                                    icon="trash-alt"
                                  />
                                </td>
                                <td className="align-middle">
                                  {abs.user ? (
                                    <span
                                      className="badge badge-pill badge-info"
                                      title={`${abs.user.firstName} ${abs.user.name}`}
                                    >
                                      {abs.user.firstName.slice(0, 1) +
                                        abs.user.name.slice(0, 1)}
                                    </span>
                                  ) : abs.formateur === undefined ? (
                                    <span className="badge badge-pill badge-success">
                                      P
                                    </span>
                                  ) : (
                                    <span
                                      className="badge badge-pill badge-info"
                                      title={`${abs.formateur.user.firstName} ${abs.formateur.user.name}`}
                                    >
                                      {abs.formateur.user.firstName.slice(
                                        0,
                                        1
                                      ) + abs.formateur.user.name.slice(0, 1)}
                                    </span>
                                  )}
                                </td>
                                <td className="align-middle">
                                  {abs.rattrapage ? (
                                    <span>
                                      <span className="badge badge-pill badge-info">
                                        R
                                      </span>
                                      &nbsp;
                                    </span>
                                  ) : null}
                                  {tabJour[dateCreated.getDay()]}{" "}
                                  {dateFormated(abs.createdAt)} - {abs.moment}
                                </td>
                                <td className="align-middle">
                                  {abs.promo.site.name} - {abs.promo.name}
                                </td>
                                <td
                                  className={`align-middle ${
                                    abs.eleve.user.actif ? "" : "text-danger"
                                  }`}
                                >
                                  {abs.eleve.user.name}{" "}
                                  {abs.eleve.user.firstName}{" "}
                                  {!abs.eleve.user.actif && "(inactif)"}
                                </td>
                                <td className="d-none">
                                  {abs.eleve.user.email}
                                </td>
                                <td className="align-middle">
                                  {abs.motif === "" || abs.motif === undefined
                                    ? "Pas de motif"
                                    : abs.motif}
                                </td>
                                <td className="align-middle">
                                  {abs.comment === "" ||
                                  abs.comment === undefined
                                    ? null
                                    : abs.comment}
                                </td>
                                {abs.eleve.entreprises.length < 1 ? (
                                  <React.Fragment>
                                    <td className="align-middle">
                                      Pas d'entreprise affectée
                                    </td>
                                    <td className="d-none"></td>
                                    <td className="d-none"></td>
                                    <td className="d-none"></td>
                                  </React.Fragment>
                                ) : (
                                  <React.Fragment>
                                    <td className="align-middle">
                                      {abs.eleve.entreprises[0].user.name}{" "}
                                      {abs.eleve.entreprises[0].user.firstName}{" "}
                                      <em>
                                        (
                                        {abs.eleve.entreprises[0].raisonSociale}
                                        )
                                      </em>
                                    </td>
                                    <td className="d-none">
                                      {abs.eleve.entreprises[0].user.email}
                                    </td>
                                    <td className="d-none">
                                      {abs.eleve.entreprises[0].mailAdmin}
                                    </td>
                                    <td className="d-none">
                                      {abs.eleve.entreprises[0].mailAdmin2}
                                    </td>
                                  </React.Fragment>
                                )}
                                <td className="align-middle text-center">
                                  {abs.rattrapage ? (
                                    "-"
                                  ) : abs.justify === undefined ||
                                    !abs.justify ? (
                                    <span className="badge badge-pill badge-danger">
                                      Non
                                    </span>
                                  ) : (
                                    <span className="badge badge-pill badge-success">
                                      Oui
                                    </span>
                                  )}
                                </td>
                                <td className="align-middle text-center">
                                  {abs.rattrapage ? (
                                    "-"
                                  ) : abs.prev === undefined || !abs.prev ? (
                                    <span className="badge badge-pill badge-danger">
                                      Non
                                    </span>
                                  ) : (
                                    <span className="badge badge-pill badge-success">
                                      Oui
                                    </span>
                                  )}
                                </td>
                                <td className="align-middle text-center">
                                  {abs.rattrapage ? "- " : ""}
                                  {abs.motif !== "Cours non obligatoire" ? (
                                    convertDecimal(h)
                                  ) : (
                                    <em>({convertDecimal(h)})</em>
                                  )}
                                </td>
                              </tr>
                            );
                          })}
                          <tr className="text-info">
                            <td colSpan="11" className="text-right">
                              <h4>Total :</h4>
                              <em>Justifées :</em> <br />
                              <em>Injustifées :</em>
                            </td>
                            <td className="d-none"></td>
                            <td className="d-none"></td>
                            <td className="d-none"></td>
                            <td className="d-none"></td>
                            <td className="text-center">
                              <h4>{convertDecimal(totalH)}</h4>
                              <em>{convertDecimal(totalJustify)}</em> <br />
                              <em>{convertDecimal(totalNotJustify)}</em>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default GestAbsences;
