/**
 * Calcul les moyennes de la classe par matières
 * @param {array} matieres - lisqte des matières
 * @param {array} moyennes - tableau des moyennes des élèves par matieres
 * @param {boolean} all - prise en charge des notes de participation et de comprotement
 * @return {array} les moyennes de la classes par matieres
 */
const CalcMoyClasse = (matieres, moyennes, all = true) => {
  let result = [];
  let mat = all
    ? matieres
    : matieres.filter((m) => m.name.slice(0, 2) !== "z-");
  mat.forEach((m) => {
    let temp = moyennes.filter((n) => n.matiere === m["@id"]);
    let x = null;
    let y = null;
    temp.forEach((n) => {
      if (n.moyenne !== undefined && n.moyenne !== null) {
        x += parseFloat(n.moyenne);
        y += 1;
      }
    });
    let moy = x !== null ? Math.round((x / y) * 100) / 100 : null;
    result = [...result, { matiere: m["@id"], moyenne: moy }];
  });
  return result;
};

export default CalcMoyClasse;
