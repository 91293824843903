import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { triByName } from "../../tools/sortUtils";

const ListFormateurs = ({ data, site, GenPwd, setselected }) => {

  const List = () => {
    return data === null ? null : (
      <div className="p-0">
        <div className="m-3 d-flex justify-content-between text-left">
          <div>
            <h5>{site.name}</h5>
            <em>
              (
              {data.length > 1
                ? data.length + " formateurs"
                : data.length + " formateur"}
              )
            </em>
          </div>
          <div></div>
        </div>
        <div className="table-responsive table-perso">
          <table
            className="table table-striped table-info text-left"
            id="tableFormateurs"
          >
            <thead>
              <tr>
                <th>Nom / Prénom</th>
                <th>Matières</th>
                <th>Promos</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {data === null ? (
                <tr>
                  <td colSpan="4" className="text-center text-danger">
                    Pas de Formateurs
                  </td>
                </tr>
              ) : (
                data.map((el, i) => {
                  let promos = el.promos.filter((p) => p.actif);
                  return (
                    <tr key={i}>
                      <td>
                        <div>
                          {el.user.actif ? (
                            <span className="badge badge-pill badge-success m-1 text-success">
                              I
                            </span>
                          ) : (
                            <span className="badge badge-pill badge-secondary m-1 text-secondary">
                              I
                            </span>
                          )}{" "}
                          {el.user.name} {el.user.firstName}{" "}
                          {el.user.roles.includes("ROLE_PEDAGO") && (
                            <span
                              className="badge badge-pill badge-success m-1"
                              key={i}
                            >
                              Pédago
                            </span>
                          )}
                          {el.user.roles.includes("ROLE_REFERENT") &&
                            !el.user.roles.includes("ROLE_PEDAGO") && (
                              <span
                                className="badge badge-pill badge-secondary m-1"
                                key={i}
                              >
                                Suivi
                              </span>
                            )}
                        </div>
                        <div className="text-primary">
                          <em>{el.user.pseudo}</em>
                        </div>
                        <span
                          className="badge badge-pill badge-secondary"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            if (
                              window.confirm("Envoyer un mail de connexion ?")
                            ) {
                              GenPwd(JSON.parse(JSON.stringify(el.user)));
                            }
                          }}
                        >
                          <FontAwesomeIcon icon="paper-plane" /> Mail de
                          connexion
                        </span>
                      </td>
                      <td>
                        <ul className="list-group border-0">
                          {el.matieres.length === 0 ? (
                            <span className="text-danger">
                              Pas de matieres !
                            </span>
                          ) : (
                            el.matieres.sort(triByName).map((m, i) => (
                              <li
                                className="list-group-item border-0 show bg-transparent p-0"
                                key={i}
                              >
                                - {m.name}
                              </li>
                            ))
                          )}
                        </ul>
                      </td>
                      <td>
                        <ul className="list-group border-0">
                          {el.promos.length === 0 ? (
                            <span className="text-danger">Pas de promos !</span>
                          ) : promos.length === 0 ? (
                            <span className="text-danger">
                              Pas de promos actives !
                            </span>
                          ) : (
                            promos.map((p, i) => (
                              <li
                                className="list-group-item border-0 bg-transparent p-0"
                                key={i}
                              >
                                - {p.name} <em>({p.site.name})</em>
                              </li>
                            ))
                          )}
                          <li className="list-group-item border-0 bg-transparent p-0">
                            <button
                              type="button"
                              onClick={(e) =>
                                setselected(JSON.parse(JSON.stringify(el)))
                              }
                              className="btn shadow rounded btn-outline-primary"
                              data-toggle="modal"
                              data-target="#ModalPromos"
                              title="Affecter les promos"
                            >
                              <FontAwesomeIcon icon="plus-circle" />
                            </button>
                          </li>
                        </ul>
                      </td>
                      <td>
                        <button
                          type="button"
                          onClick={(e) =>
                            setselected(JSON.parse(JSON.stringify(el)))
                          }
                          className="btn shadow rounded btn-outline-primary mr-2"
                          data-toggle="modal"
                          data-target="#Modal"
                          title="modifier"
                        >
                          <FontAwesomeIcon icon="edit" />
                        </button>
                      </td>
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  return (
    <div className="animFadeIn">
      {data === null || data.length === 0 ? (
        <div>
          <h4>{site.name}</h4>
          <div className="alert alert-danger m-3">
            <strong>Pas de formateurs !</strong>
          </div>
        </div>
      ) : (
        <List />
      )}
    </div>
  );
};

export default ListFormateurs;
