import { useCallback, useEffect, useRef } from "react";
import GetBddProvider from "../../../GetBddProvider";
import { LoaderErrorMessage } from "../LoadersMessage";
import { loginProcess } from "./LoginTools";

/**
 * Hook de chargement d'une collection de données depuis l'api de Nocx
 */
export const useLoadBackCollectionData = () => {
  const abortController = useRef(new AbortController());
  useEffect(() => () => abortController.current.abort(), []);
  return useCallback(
    /**
     *
     * @param {string} url
     * @param {function():void} setter
     * @param {Object{
     *    loader?: function(boolean):void,
     *    setMsg?: function(string):void,
     *    errorMessage?: string
     *    typeError?: string
     *    autoCloseTime?: number
     * }} options
     */
    (url, setter, options = {}) =>
      GetBddProvider(
        url,
        undefined,
        undefined,
        abortController.current.signal
      ).then((res) => {
        options.loader && options.loader(true);
        if (typeof res === "object" && res["hydra:member"]) {
          setter(res["hydra:member"]);
        } else {
          options.setMsg && LoaderErrorMessage(res, options);
        }
      }),
    []
  );
};

/**
 * Hook de chargement d'une donnée depuis l'api de Nocx
 */
export const useLoadBackSingleData = () => {
  const abortController = useRef(new AbortController());
  useEffect(() => () => abortController.current.abort(), []);
  return useCallback(
    /**
     *
     * @param {string} url
     * @param {function():void} setter
     * @param {Object{
     *    login?: boolean,
     *    loader?: function(boolean):void,
     *    setMsg?: function(string):void,
     *    errorMessage?: string
     *    typeError?: string
     *    autoCloseTime?: number
     * }} options
     */
    (url, setter, options = {}) =>
      GetBddProvider(
        url,
        undefined,
        undefined,
        abortController.current.signal
      ).then((res) => {
        options.loader && options.loader(true);
        if (typeof res === "object" && res.id) {
          options.login ? loginProcess(res, setter, options) : setter(res);
        } else {
          LoaderErrorMessage(res, options);
        }
      }),
    []
  );
};
