/**
 * @param {string} a
 * @param {string} b
 * @returns {number}
 */
const compareString = (a, b) => {
  a = a.toUpperCase();
  b = b.toUpperCase();
  return a.localeCompare(b, "fr", { ignorePunctuation: true });
};

const compare = (a, b) => (a > b ? 1 : -1);

// *** Tri des objets par "id"
/**
 * Tri par "id" ASC
 */
export const triById = (a, b) => compare(a.id, b.id);
/**
 * Tri par "id" DESC
 */
export const triByIdDESC = (a, b) => compare(b.id, a.id);

// *** Tri des objets par "createdAt"
/**
 * Tri par "createdAt" ASC
 */
export const triByCreatedAt = (a, b) => compare(a.createdAt, b.createdAt);
/**
 * Tri par "createdAt" DESC
 */
export const triByCreatedAtDESC = (a, b) => compare(b.createdAt, a.createdAt);

// *** Tri des objets par "name"
/**
 * Tri par "name" ASC
 */
export const triByName = (a, b) => compareString(a.name, b.name);
/**
 * Tri par "name" DESC
 */
export const triByNameDESC = (a, b) => compareString(b.name, a.name);

// *** Tri des objets par "Nom"
/**
 * Tri par "Nom" ASC
 */
export const triByNom = (a, b) => compareString(a.Nom, b.Nom);
/**
 * Tri par "Nom" DESC
 */
export const triByNomDESC = (a, b) => compareString(b.Nom, a.Nom);

// *** Tri des objets par "moyenne"
/**
 * Tri par "moyenne" ASC
 */
export const triByMoyenne = (a, b) => compare(a.moyenne, b.moyenne);
/**
 * Tri par "moyenne" DESC
 */
export const triByMoyenneDESC = (a, b) => compare(b.moyenne, a.moyenne);

// *** Tri des objets par "actif"
/**
 * Tri par "actif" ASC
 */
export const triByActif = (a, b) => compare(a.actif, b.actif);
/**
 * Tri par "actif" DESC
 */
export const triByActifDESC = (a, b) => compare(b.actif, a.actif);

// *** Tri des objets par "user.name"
/**
 * Tri par "user.name" ASC
 */
export const triByUserName = (a, b) => compareString(a.user.name, b.user.name);
/**
 * Tri par "user.name" DESC
 */
export const triByUserNameDESC = (a, b) =>
  compareString(b.user.name, a.user.name);

// *** Tri des objets par "eleve.user.name"
/**
 * Tri par "eleve.user.name" ASC
 */
export const triByEleveUserName = (a, b) =>
  compareString(a.eleve.user.name, b.eleve.user.name);
/**
 * Tri par "eleve.user.name" DESC
 */
export const triByEleveUserNameDESC = (a, b) =>
  compareString(b.eleve.user.name, a.eleve.user.name);

// *** Tri des objets par "competence.name"
/**
 * Tri par "competence.name" ASC
 */
export const triByCompetenceName = (a, b) =>
  compareString(a.competence.name, b.competence.name);
/**
 * Tri par "competence.name" DESC
 */
export const triByCompetenceNameDESC = (a, b) =>
  compareString(b.competence.name, a.competence.name);

// *** Tri des objets par "itemComportement.name"
/**
 * Tri par "itemComportement.name" ASC
 */
export const triByComportementName = (a, b) =>
  compareString(a.itemComportement.name, b.itemComportement.name);
/**
 * Tri par "itemComportement.name" DESC
 */
export const triByComportementNameDESC = (a, b) =>
  compareString(b.itemComportement.name, a.itemComportement.name);

// *** Tri des objets par "itemRealisation.name"
/**
 * Tri par "itemRealisation.name" ASC
 */
export const triByRealisationName = (a, b) =>
  compareString(a.itemRealisation.name, b.itemRealisation.name);
/**
 * Tri par "itemRealisation.name" DESC
 */
export const triByRealisationNameDESC = (a, b) =>
  compareString(b.itemRealisation.name, a.itemRealisation.name);

// *** Tri des objets par "Stagiaire.Nom"
/**
 * Tri par "Stagiaire.Nom" ASC
 */
export const triByStagiaireNom = (a, b) =>
  compareString(a.Stagiaire.Nom, b.Stagiaire.Nom);
/**
 * Tri par "Stagiaire.Nom" DESC
 */
export const triByStagiaireNomDESC = (a, b) =>
  compareString(b.Stagiaire.Nom, a.Stagiaire.Nom);

// ******************************* Edusign

// *** Tri des objets par "NAME"
/**
 * Tri par "NAME" ASC
 */
export const triByNAME = (a, b) => compareString(a.NAME, b.NAME);
/**
 * Tri par "NAME" DESC
 */
export const triByNAME_DESC = (a, b) => compareString(b.NAME, a.NAME);

// ******************************* Galia

// *** Tri des objets par "Libelle_ACTION"
/**
 * Tri par "Libelle_ACTION" ASC
 */
export const triByLibelle_Action = (a, b) =>
  compareString(a.Libelle_ACTION, b.Libelle_ACTION);
/**
 * Tri par "Libelle_ACTION" DESC
 */
export const triByLibelle_ActionDESC = (a, b) =>
  compareString(b.Libelle_ACTION, a.Libelle_ACTION);
/**
 * Tri par "Libelle_Court" ASC
 */
export const triByLibelle_Court = (a, b) =>
  compareString(a.Libelle_Court, b.Libelle_Court);
/**
 * Tri par "Libelle_Court" DESC
 */
export const triByLibelle_CourtDESC = (a, b) =>
  compareString(b.Libelle_Court, a.Libelle_Court);
/**
 * Tri par "Libelle_Lieu_Court" ASC
 */
export const triByLibelle_Lieu_Court = (a, b) =>
  compareString(a.Libelle_Lieu_Court, b.Libelle_Lieu_Court);
/**
 * Tri par "Libelle_Lieu_Court" DESC
 */
export const triByLibelle_Lieu_CourtDESC = (a, b) =>
  compareString(b.Libelle_Lieu_Court, a.Libelle_Lieu_Court);
