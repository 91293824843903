import React from "react";
import MsgAlert from "../../common/MsgAlert";
import Spinner from "../../common/Spinner";
import PromoItem from "./PromoItem";

const SyncPromos = ({
  loaded,
  groups,
  promos,
  maj,
  groupSelected,
  setGroupSelected,
  setPromo,
}) => {
  return (
    <div className="card text-left">
      <div className="card-header">
        <h5 className="card-title text-center">Synchronisation des promos</h5>
      </div>
      <div className="card-body p-0">
        {!loaded ? (
          <div className="text-center m-2">
            <Spinner />
          </div>
        ) : (
          <ul className="list-group list-group-flush">
            {groups !== undefined ? (
              groups.filter(g=>g.PARENT === "").map((group) => (
                  <PromoItem
                    group={group}
                    promos={promos}
                    groupSelected={groupSelected}
                    setGroupSelected={setGroupSelected}
                    setPromo={setPromo}
                    maj={maj}
                    key={group.ID}
                  />
                ))
            ) : (
              <MsgAlert
                msg="Pas de groupes dans Edusign !"
                type="warning text-center"
              />
            )}
          </ul>
        )}
      </div>
    </div>
  );
};

export default SyncPromos;
