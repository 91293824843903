const formateTel = (num) => {
  let newTel =
    num === undefined
      ? null
      : num.slice(0, 2) +
        " " +
        num.slice(2, 4) +
        " " +
        num.slice(4, 6) +
        " " +
        num.slice(6, 8) +
        " " +
        num.slice(8, 10);
  return newTel
}

export default formateTel