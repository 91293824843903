import React, { useContext } from "react";
import { Redirect, Route } from "react-router-dom";
import AppContext from "../Context/AppContext";
import { StorageService } from "./tools/Storage/StorageService";
import checkToken from "./tools/check/checkToken";

const ProtectedRoute = (props) => {
  const { user, betaUsers } = useContext(AppContext);

  if (user && StorageService.getItem("token") === null) {
    checkToken();
    return;
  }

  const isLog = () => {
    let role;

    // On récupére le niveau du role de l'utilisateur
    let userRole = user ? user.role : null;

    props.role ? (role = props.role) : (role = 7);

    if (props.beta && !betaUsers.includes(user.pseudo)) {
      return false;
    }

    //On check le statut de l'utilisateur
    return StorageService.getItem("token") !== null && userRole <= role && user
      ? true
      : false;
  };

  const isValidate = () => {
    return props.validate ? user.validate : true;
  };

  const { component: Component, ...rest } = props;
  return (
    <Route
      {...rest}
      render={(props) => {
        if (isLog()) {
          if (isValidate()) {
            return <Component {...props} {...(rest.properties || null)} />;
          } else {
            return <Redirect to="/validate" />;
          }
        } else {
          return <Redirect to="/login" />;
        }
      }}
    />
  );
};

export default ProtectedRoute;
