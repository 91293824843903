import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useState } from "react";
import { Redirect } from "react-router-dom";
import AppContext from "../../Context/AppContext";
import { useLoadBackSingleData } from "../../Providers/Utils/Loaders/Nocx/Loaders";
import usePost from "../../hooks/usePost";
import logo from "../../logo-symbol.svg";
import GenPass from "../GenPass";
import MsgAlert from "../common/MsgAlert";
import Spinner from "../common/Spinner";
import { StorageService } from "../tools/Storage/StorageService";
import { autoCloseMsg } from "../tools/messagesUtils";

const Login = () => {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [loaded, setLoaded] = useState(true);
  const [forget, setForget] = useState(false);

  const { url, appName, user, setUser, su } = useContext(AppContext);

  const PostBddProvider = usePost();
  const LoadBackSingleData = useLoadBackSingleData();

  const annee = new Date().getFullYear();

  const validateForm = () => {
    return username.length > 0 && password.length > 0;
  };

  const validateReset = () => {
    return username.length > 0 && email.length > 0;
  };

  const login = (data) => {
    PostBddProvider(url + "login", data).then((res) => {
      if (typeof res === "object" && res.data) {
        let result = res.data;
        StorageService.setItem("token", res.token);
        // StorageService.setItem("galiaUser", result.galiaUser);
        LoadBackSingleData(url + "api/users/" + result.id, setUser, {
          login: true,
          su: su,
          loader: setLoaded,
          setMsg: setError,
          errorMessage: "Utilisateur introuvable",
          typeError: "danger",
          // autoCloseTime: 3000,
        });
      } else if (res !== 666) {
        setError({ txt: "Identifiants incorrects !", type: "danger" });
        autoCloseMsg(setError, 3000);
        setLoaded(true);
      }
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoaded(false);
    setError(null);
    let data = {
      username: username,
      password: password,
    };
    login(data);
  };

  const resetPwd = (newPass) => {
    let uri = url + "genpass";
    let data = {
      username: username,
      email: email,
      pwd: newPass,
    };
    setLoaded(false);
    PostBddProvider(uri, data).then((res) => {
      setLoaded(true);
      if (res.status === "ok") {
        setForget(false);
        setError({
          txt:
            "Un mail contenant votre mot de passe a été envoyer à l'adresse : " +
            res.email,
          type: "success",
        });
        autoCloseMsg(setError, 3000);
      } else if (res.status === 404) {
        setError({ txt: res.message, type: "danger" });
        autoCloseMsg(setError, 3000);
      } else if (res !== 666) {
        setError({ txt: "Erreur serveur !", type: "danger" });
        autoCloseMsg(setError, 3000);
      }
    });
  };

  const formForget = () => {
    let newPass = GenPass(10);
    return (
      <div>
        <small className="text-danger">
          <em>Nom d'utilisateur oublié, contactez votre centre</em>
        </small>
        <div className="input-group mb-2">
          <input
            type="text"
            className="form-control rounded-pill"
            value={username || ""}
            placeholder="Nom d'utilisateur"
            onChange={(e) => setUsername(e.target.value)}
            required
          />
        </div>
        <div className="input-group mb-4">
          <input
            type="email"
            className="form-control rounded-pill"
            value={email || ""}
            placeholder="adresse mail"
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <button
          className="btn btn-info rounded-pill mt-3"
          disabled={!validateReset()}
          onClick={() => {
            resetPwd(newPass);
          }}
        >
          Me renvoyer un nouveau mot de passe
        </button>
        <br />
        <div
          className="btn btn-sm btn-outline-secondary rounded-pill mt-2"
          onClick={() => {
            setForget(false);
          }}
        >
          <FontAwesomeIcon icon="chevron-left" /> &nbsp; Retour à l'écran de
          connexion
        </div>
      </div>
    );
  };

  const formulaire = () => {
    return (
      <div>
        <div className="input-group mb-2 mt-4">
          <input
            type="text"
            className="form-control rounded-pill"
            value={username || ""}
            placeholder="Nom d'utilisateur"
            onChange={(e) => setUsername(e.target.value)}
            required
          />
        </div>
        <div className="input-group mb-4">
          <input
            value={password}
            placeholder="Mot de passe"
            className="form-control rounded-pill"
            onChange={(e) => setPassword(e.target.value)}
            type="password"
            required
          />
        </div>
        <button
          disabled={!validateForm()}
          type="submit"
          className="btn btn-info rounded-pill mt-3"
        >
          Se connecter
        </button>
        <div className="mt-2">
          <span
            className="btn btn-sm rounded-pill btn-outline-secondary"
            onClick={() => {
              setForget(true);
            }}
          >
            Mot de passe oublié ?
          </span>
        </div>
      </div>
    );
  };

  return (
    <div
      className="vh-100 d-flex align-items-center justify-content-center"
      style={{ minHeight: "510px" }}
    >
      {user !== null && StorageService.getItem("token") && <Redirect to="/" />}
      <form
        className="card border-info bg-light px-5 py-4 shadow-lg text-center"
        style={{ maxWidth: "500px" }}
        onSubmit={handleSubmit}
      >
        <div className="d-flex justify-content-center align-items-center mb-5">
          <img
            src={logo}
            height="80"
            alt="logo NOCx"
            loading="lazy"
            className="pr-2 border-right border-info m-0"
          />
          <div className="text-left m-0 pl-2 border-left border-info">
            <h1 className="m-0 font-weight-bold text-info">{appName}</h1>
            <h4 className="m-0">Connexion</h4>
          </div>
        </div>

        {error === null ? null : (
          <MsgAlert
            msg={error.txt}
            type={error.type}
            close={() => autoCloseMsg(setError)}
          />
        )}

        {loaded ? (
          forget ? (
            formForget()
          ) : (
            formulaire()
          )
        ) : (
          <Spinner color="info m-auto" />
        )}
        <p className="mt-5 mb-0">
          &copy; 2020{annee > 2020 ? " - " + annee : null}{" "}
          <a href="https://admike.fr" className="text-info" target="blank">
            <strong>admike</strong> - <em>Michaël GROSS</em>
          </a>
          .<br />
          Tous droits réservés.
        </p>
      </form>
    </div>
  );
};

export default Login;
