import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import AppContext from "../../../Context/AppContext";
import PostBddProvider from "../../../Providers/PostBddProvider";
import UpdateBddProvider from "../../../Providers/UpdateBddProvider";
import MsgAlert from "../../common/MsgAlert";
import Spinner from "../../common/Spinner";
import { autoCloseMsg } from "../../tools/messagesUtils";

const EditCoefMat = ({ maj, formation, matiere, coefSelect }) => {
  const { url } = useContext(AppContext);

  const [coef, setCoef] = useState(coefSelect);
  const [msg, setMsg] = useState(null);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (coefSelect !== null && coef !== coefSelect && formation !== null) {
      setCoef({
        id: coefSelect.id,
        coefficient: coefSelect.coefficient,
        matiere: coefSelect.matiere["@id"],
        formation: formation["@id"],
      });
    } else if (matiere !== null) {
      let tmpCoef = formation.coefs.filter(
        (cf) => cf.matiere["@id"] === matiere["@id"]
      );
      if (tmpCoef[0] === undefined) {
        setCoef(null);
      } else if (tmpCoef[0].coefficient !== coef) {
        setCoef({ coefficient: tmpCoef[0].coefficient });
      }
    }
    setLoaded(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matiere, formation, coefSelect]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoaded(false);
    let uri = url + "api/coefs";
    if (coef.id === undefined) {
      let data = {
        coefficient: parseFloat(coef.coefficient),
        formation: formation["@id"],
        matiere: matiere["@id"],
      };
      PostBddProvider(uri, data).then((res) => {
        setLoaded(true);
        if (typeof res === "object" && res.id) {
          setMsg({
            txt: "le coefficient a bien été enregistré",
            type: "success",
          });
          autoCloseMsg(setMsg, 5000);
          maj(true);
        } else {
          setMsg({
            txt: "Erreur d'enregistrement",
            type: "danger",
          });
          autoCloseMsg(setMsg, 5000);
        }
      });
    } else {
      uri += "/" + coef.id;
      let data = coef;
      data = { ...data, coefficient: parseFloat(coef.coefficient) };
      UpdateBddProvider(uri, data).then((res) => {
        setLoaded(true);
        if (typeof res === "object" && res.id) {
          setMsg({
            txt: "le coefficient a bien été modifié",
            type: "success",
          });
          autoCloseMsg(setMsg, 5000);
          maj(true);
        } else {
          setMsg({
            txt: "Erreur d'enregistrement",
            type: "danger",
          });
          autoCloseMsg(setMsg, 5000);
        }
      });
    }
  };

  return (
    <div
      className="modal fade"
      id="ModalCoef"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header bg-info text-white">
            <h5 className="modal-title">
              <FontAwesomeIcon icon="edit" /> &nbsp; Modifier le coefficient (
              {matiere !== null ? matiere.name + " - " + formation.name : ""})
            </h5>
            <button
              type="button"
              className="close text-white"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            {msg !== null ? (
              <div className="mx-4">
                <MsgAlert
                  msg={msg.txt}
                  type={msg.type}
                  close={() => autoCloseMsg(setMsg)}
                />
              </div>
            ) : null}
            {matiere !== null && loaded ? (
              <div className="form-group">
                <label>Coefficient</label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="coef"
                  value={coef !== null ? coef.coefficient : ""}
                  step="0.01"
                  min="0"
                  onChange={(e) =>
                    setCoef({
                      ...coef,
                      coefficient: e.target.value,
                    })
                  }
                />
              </div>
            ) : (
              <Spinner />
            )}
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
            >
              Fermer
            </button>
            <button
              type="submit"
              className="btn btn-info"
              onClick={handleSubmit}
              disabled={coef === null || coef.coefficient === ""}
            >
              Enregistrer
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditCoefMat;
